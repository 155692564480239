import React, { useEffect } from 'react';
import { CloseSVG } from 'assets/svg/CloseSVG';
import { Wrapper, Box, CloseIcon, Message, Dialog, Title } from './styles';

const stopPropagation = (event) => event.stopPropagation();

const isEscapeKey = ({ code }) => code === 'Escape';

export const Modal = ({
  children,
  canClose = true,
  onClose,
  closeButtonClassName,
  noBox = false,
}) => {
  useEffect(() => {
    const onKeyDown = (event) => {
      if (isEscapeKey(event)) {
        // Don't let anything else handle this press of escape.
        stopPropagation(event);

        if (canClose && onClose) {
          onClose();
        }
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onClose, canClose]);
  return (
    <Wrapper onClick={onClose}>
      <Box onClick={stopPropagation} noBox={noBox}>
        {canClose ? (
          <CloseIcon
            onClick={onClose}
            rightmost={true}
            leftmost={true}
            className={closeButtonClassName}
          >
            <CloseSVG />
          </CloseIcon>
        ) : null}
        {children}
      </Box>
    </Wrapper>
  );
};

Modal.Message = Message;
Modal.Dialog = Dialog;
Modal.Title = Title;
