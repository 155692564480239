import { groups } from 'd3-array';

import { CMU_SIGNALS } from 'common/DataContext/constants';
import {
  STAT_NAMES_LIST,
  STAT_NAMES,
  COUNTRY_CODE_TYPES,
  REGION_TYPES,
} from 'common/constants';
import { fetchData } from 'common/DataContext/fetchData';
import {
  isSupportedCountry,
  getParentRegionId,
} from 'common/DataContext/accessors';
import {
  parseDashlessDate,
  parseDate,
  stripGADMSuffix,
} from 'common/DataContext/util';
import { processFetchedResults } from './processData';

const parseGADMMovementRows = (rows) => {
  return rows.slice(1).map((row) => ({
    regionId: stripGADMSuffix(row[1]),
    date: parseDate(row[2]),
    all_day_bing_tiles_visited_relative_change_day_of_week: parseFloat(row[3]),
    all_day_ratio_single_tile_users: parseFloat(row[4]),
  }));
};

const parseGADMSymptomRows = (rows) => {
  const parsedRows = rows.slice(1).map((row) => ({
    regionId: stripGADMSuffix(row[0]),
    date: parseDashlessDate(row[1]),
    denominator: parseFloat(row[2]),
    pctCLI: parseFloat(row[3]),
    pctILI: parseFloat(row[4]),
  }));
  return parsedRows;
};

const parseFIPSSymptomRows = (rows) => {
  const parsedRows = [];
  const grouped = groups(
    rows.slice(1),
    (d) => d[1],
    (d) => d[0]
  );
  grouped.forEach(([dateString, dateGroup]) => {
    const date = parseDashlessDate(dateString);
    dateGroup.forEach(([regionId, regionGroup]) => {
      const denominator = parseFloat(regionGroup[0][5]);
      const pctCLI =
        parseFloat(regionGroup.find((d) => d[6] === CMU_SIGNALS.CLI)[3]) /
        100.0;
      const pctILI =
        parseFloat(regionGroup.find((d) => d[6] === CMU_SIGNALS.ILI)[3]) /
        100.0;
      parsedRows.push({
        regionId,
        date,
        denominator,
        pctCLI,
        pctILI,
      });
    });
  });
  return parsedRows;
};

// 0: "country_code"
// 1: "gadm_code"
// 2: "province_state"
// 3: "case_date"
// 4: "new_confirmed"
// 5: "new_death"
// 6: "total_confirmed"
// 7: "total_death"
// 8: "total_recovered"
// 9: "total_test"
// 10: "total_hospitalized"
// 11: "incident_rate"
const parseGADMCasesRows = (rows) => {
  //console.log('GADM ROWS ->', rows);
  return rows
    .slice(1)
    .filter((row) => row[1] !== '')
    .map((row) => ({
      regionId: stripGADMSuffix(row[1]),
      date: parseDate(row[3]),
      new_confirmed: parseFloat(row[4]),
      new_death: parseFloat(row[5]),
      total_confirmed: parseFloat(row[6]),
      total_death: parseFloat(row[7]),
      total_recovered: parseFloat(row[8]),
      total_test: parseFloat(row[9]),
      total_hospitalized: parseFloat(row[10]),
      incident_rate: parseFloat(row[11]),
    }));
};

// Same as above but a FIPS code is in the gadm_code column
const parseFIPSCasesRows = (rows) => {
  //console.log('FIPS ROWS ->', rows);
  return rows
    .slice(1)
    .filter((row) => row[1] !== '')
    .map((row) => ({
      regionId: row[1],
      date: parseDate(row[3]),
      new_confirmed: parseFloat(row[4]),
      new_death: parseFloat(row[5]),
      total_confirmed: parseFloat(row[6]),
      total_death: parseFloat(row[7]),
      total_recovered: parseFloat(row[8]),
      total_test: parseFloat(row[9]),
      total_hospitalized: parseFloat(row[10]),
      incident_rate: parseFloat(row[11]),
    }));
};

const countryRowParsersByCountryCodeTypeByStatName = {
  [COUNTRY_CODE_TYPES.GADM]: {
    [STAT_NAMES.MOVEMENT]: {
      [REGION_TYPES.ADMIN1]: parseGADMMovementRows,
      [REGION_TYPES.ADMIN2]: parseGADMMovementRows,
    },
    [STAT_NAMES.SYMPTOM]: {
      [REGION_TYPES.ADMIN1]: parseGADMSymptomRows,
      [REGION_TYPES.ADMIN2]: parseGADMSymptomRows,
    },
    [STAT_NAMES.CASES]: {
      [REGION_TYPES.ADMIN1]: parseGADMCasesRows,
      [REGION_TYPES.ADMIN2]: parseGADMCasesRows,
    },
  },
  [COUNTRY_CODE_TYPES.FIPS]: {
    [STAT_NAMES.MOVEMENT]: {
      [REGION_TYPES.ADMIN1]: (rows) => {
        return rows.slice(1).map((row) => ({
          regionId: row[0],
          date: parseDate(row[1]),
          all_day_bing_tiles_visited_relative_change_day_of_week: parseFloat(
            row[2]
          ),
          all_day_ratio_single_tile_users: parseFloat(row[3]),
        }));
      },
      [REGION_TYPES.ADMIN2]: (rows) => {
        return rows.slice(1).map((row) => ({
          regionId: row[2],
          date: parseDate(row[4]),
          all_day_bing_tiles_visited_relative_change_day_of_week: parseFloat(
            row[5]
          ),
          all_day_ratio_single_tile_users: parseFloat(row[6]),
        }));
      },
    },
    [STAT_NAMES.SYMPTOM]: {
      [REGION_TYPES.ADMIN1]: parseFIPSSymptomRows,
      [REGION_TYPES.ADMIN2]: parseFIPSSymptomRows,
    },
    [STAT_NAMES.CASES]: {
      [REGION_TYPES.ADMIN1]: parseFIPSCasesRows,
      [REGION_TYPES.ADMIN2]: parseFIPSCasesRows,
    },
  },
};

export const fetchCountryStats = ({
  regionId,
  countryCodeType,
  fipsToName,
  metadata,
}) => () =>
  new Promise((res, rej) => {
    const rowParsersByStatName =
      countryRowParsersByCountryCodeTypeByStatName[countryCodeType];

    // Implicitly decides which admin level is being fetch based on if
    // regionId is acountry or not.
    const adminLevel = isSupportedCountry(regionId)
      ? REGION_TYPES.ADMIN1
      : REGION_TYPES.ADMIN2;

    // Regardless of which data we are fetching, the regionId in the URLs
    // must be the country code for GADM.
    let fetchRegionId;
    if (
      countryCodeType === COUNTRY_CODE_TYPES.GADM &&
      adminLevel === REGION_TYPES.ADMIN2
    ) {
      fetchRegionId = getParentRegionId(regionId);
    } else {
      fetchRegionId = regionId;
    }

    // Creates a data fetcher for each stat
    const fetchers = STAT_NAMES_LIST.map((statName) =>
      fetchData({
        adminLevel,
        statName,
        parseRows: rowParsersByStatName[statName][adminLevel],
        countryCodeType,
      })
    );

    Promise.all(
      fetchers.map((doFetch) => doFetch(fetchRegionId, metadata))
    ).then((fetchersResults) => {
      let processedResults = processFetchedResults(fetchersResults, fipsToName);

      // If we are on a GADM ADMIN2, makes the bold assumption that the
      // relevant regions in the fetched data will be starting with the
      // currently selected regionId.  So if IND.6 is selected, we want
      // IND.6.15_1, IND.6.16_1, etc.
      if (
        countryCodeType === COUNTRY_CODE_TYPES.GADM &&
        adminLevel === REGION_TYPES.ADMIN2
      ) {
        processedResults = processedResults.filter((d) =>
          d.regionId.startsWith(regionId)
        );
      }

      res(processedResults);
    });
  });
