import styled from 'styled-components';

import { mobileBreakpoint, mobileGap, Content, crispness } from 'styles/styles';

export const Top = styled(Content)`
  min-height: ${(props) => (props.isMobile ? 100 : 50)}px;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isMobile ? '8px' : '0 20px 0 20px')};
  margin-bottom: 10px;
  @media (max-width: ${mobileBreakpoint}px) {
    margin-bottom: 1px;
  }
  font-size: 14px;
  justify-content: space-between;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  background-color: white;
  color: #4a585e;
  ${crispness}
`;

export const TopLeft = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  font-weight: bold;
`;

export const TopRight = styled.div`
  display: flex;
  align-items: center;
`;

export const Breadcrumbs = styled.div`
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: ${(props) => (props.isMobile ? 5 : 0)}px;
`;

export const Breadcrumb = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const Bottom = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: ${mobileBreakpoint}px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  flex: 1;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  @media (min-width: ${mobileBreakpoint}px) {
    margin-right: 10px;
    min-width: ${mobileBreakpoint}px;
  }
`;

export const Right = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

export const RightTop = styled.div`
  flex: 1;
  position: relative;
  border-radius: 3px;
  // ${crispness}
`;

export const Legend = styled.img`
  position: absolute;
  bottom: 8px;
  left: 8px;
`;

export const RightBottom = styled.div`
  flex: 0.66;
  ${(props) =>
    props.isEmbed
      ? `margin-top: 1px;`
      : `
          margin-top: 10px;
          @media (max-width: ${mobileBreakpoint}px) {
            margin-top: 5px;
          }
        `}
  min-height: 230px;

  display: flex;
  flex-direction: column;
  border-radius: 3px;
  ${crispness}
`;

export const LineChartContainer = styled.div`
  height: 110px;
  width: 100%;
  display: flex;

  g.y-axis {
    g.tick {
      text {
        font-family: 'Facebook Reader';
        font-size: 12px;
        fill: #666;
      }
    }
  }

  path.active-line {
    stroke-width: 3;
  }
  path.parent-line {
    stroke: black;
    stroke-width: 1;
    stroke-dasharray: 3 3;
  }
  line.active-date-line {
    stroke: #ccc;
    stroke-width: 1;
    stroke-dasharray: 3 3;
  }
`;

export const MobileLineChartContainer = styled.div`
  height: 110px;
  width: 100%;
  display: flex;

  g.y-axis {
    g.tick {
      text {
        font-family: 'Facebook Reader';
        font-size: 12px;
        fill: #666;
      }
    }
  }

  path.active-line {
    stroke-width: 3;
  }
  path.parent-line {
    stroke: black;
    stroke-width: 1;
    stroke-dasharray: 3 3;
  }
  line.active-date-line {
    stroke: #ccc;
    stroke-width: 1;
    stroke-dasharray: 3 3;
  }
`;

export const LegendContainer = styled.div`
  background: white;
  flex: 0 0 2.5rem;
  display: flex;
  justify-content: space-between;
`;

export const AttributionWrapper = styled(Content)`
  padding: 10px 32px 10px 32px;
  margin-top: ${(props) => (props.isEmbed ? 1 : 10)}px;
  @media (max-width: ${mobileBreakpoint}px) {
    margin-top: ${mobileGap}px;
    padding: 5px;
  }
  display: flex;
  align-items: center;
  ${crispness}
`;

export const AttributionText = styled.div`
  line-height: 1.33;
  a {
    color: #0972a6;
    text-decoration: underline;
  }
  a:hover {
    color: #053f5d;
  }
`;

export const Mobile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileRow = styled.div`
  flex: 1;
`;

export const MobileMapContainer = styled.div`
  position: relative;
`;

export const LineChartAttribution = styled(Content)`
  padding-left: ${(props) => props.marginLeft}px;
  padding-bottom: 10px;
  padding-top: 5px;
`;

export const EmbedLink = styled.span`
  margin-right: 30px;
  cursor: pointer;
`;

export const EmbedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #4a585e;
  background-color: white;
  padding: 5px 10px 5px 10px;
  margin-bottom: 1px;
`;

export const EmbedBackground = styled.div`
  background-color: #e5ecf0;
`;

export const Open = styled.div`
  color: #00a4eb;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 0.5em;
`;

export const HeaderTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-stretch: condensed;
  color: #0972a6;
`;

export const FloatingCharts = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1200;
`;

export const MobileChart = styled.div`
  box-shadow: none;
  width: unset;
  z-index: auto;
  position: static;
  border-radius: 2px;
  margin: 0 8px;
`;

export const EmbedChart = styled.div`
  box-shadow: none;
  z-index: auto;
  margin: 2px 8px;
  border-radius: 2px;
`;

export const Chart = styled.div`
  box-sizing: border-box;
  border-radius: ${(props) => (props.isEmbed ? '2px 2px 6px 6px' : '6px')};
  margin: 0 0 8px 0;
  padding: 8px;
  background: #ffffff;
  font-size: 12px;
  color: #232323;
  width: ${(props) => (props.isEmbed ? '100%' : '530px')};
  height: 170px;
  display: flex;
  flex-direction: column;
  line-height: 20px;
  box-shadow: ${(props) =>
    props.isEmbed ? 'none' : '0px 2px 25px rgba(0, 0, 0, 0.1)'};
  @media (max-width: ${mobileBreakpoint}px) {
    width: 100%;
    height: unset;
    box-shadow: none;
    border-radius: 2px;
    margin: 2px 0 2px 0;
  }
`;

export const LineChartHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Facebook Reader';
  font-size: 11px;
  line-height: 18px;
  flex: 0 1 auto;
  width: 100%;
  @media (max-width: ${mobileBreakpoint}px) {
    flex-direction: column;
  }
`;

export const DataDateFooter = styled.div`
  font-family: Facebook Reader;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  color: #979797;
`;
