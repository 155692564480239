import React from 'react';
import { Wrapper, NoDataText } from './styles';
import locale from 'common/locale';

const NoDataPill = (props) => {
  return (
    <Wrapper>
      <NoDataText>{locale('i18n_data_not_avail')}</NoDataText>
    </Wrapper>
  );
};

export default NoDataPill;
