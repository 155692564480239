import React, { useRef, useEffect } from 'react';
import { select } from 'd3-selection';
import { axisTop } from 'd3-axis';

export const XAxis = ({ xScale, innerHeight, tickFormat, numTicks }) => {
  const ref = useRef();
  useEffect(() => {
    const xAxisG = select(ref.current);
    const xAxis = axisTop(xScale)
      .tickSize(-innerHeight)
      .tickFormat(tickFormat)
      .ticks(numTicks);
    xAxisG.call(xAxis);
  }, [innerHeight, xScale, tickFormat, numTicks]);
  return <g ref={ref} />;
};
