import en from './intro';
import fr from './translations/fr';
import ar from './translations/ar';
import zh_cn from './translations/zh_cn';

export default {
  en,
  ar,
  fr,
  zh_cn,
};
