import React from 'react';

import svgCopyIcon from 'assets/svg/icon-copy.svg';
import locale from 'common/locale';

import './EmbedCopyButton.scss';

const EmbedCopyButton = ({ onClick }) => {
  return (
    <div className="copy-button">
      <button onClick={onClick}>
        <img src={svgCopyIcon} alt="Copy Embed Code" />
        <div>{locale('i18n_copy')}</div>
      </button>
    </div>
  );
};

export default EmbedCopyButton;
