import React from 'react';

import XAxisLabels from './XAxisLabels';

export const XAxis = ({
  xScale,
  innerHeight,
  height,
  showPageLeft,
  showPageRight,
}) => {
  return (
    <g transform={`translate(0,${innerHeight - 0.5 * height})`}>
      <XAxisLabels
        xScale={xScale}
        height={height}
        showPageLeft={showPageLeft}
        showPageRight={showPageRight}
      />
    </g>
  );
};
