import React from 'react';
import { GovtGanttRowContainer } from './styles';
import GovtGanttEntry from './GovtGanttEntry';

const GovtGanttRow = ({ govtActionType, entries }) => {
  return (
    <GovtGanttRowContainer>
      {entries.map((e, i) => (
        <GovtGanttEntry
          key={`${govtActionType}-${i}`}
          govtActionType={govtActionType}
          {...e}
        />
      ))}
    </GovtGanttRowContainer>
  );
};

export default GovtGanttRow;
