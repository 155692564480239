export default function getLanguage() {
  let lang;
  if (navigator.languages && navigator.languages.length) {
    lang = navigator.languages[0];
  } else {
    lang =
      navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      'en';
  }
  lang = lang.replace(/-/g, '_').toLowerCase();
  return lang === 'en_US' ? 'en' : lang;
}
