export const supportedCountries = {
  USA: true,
  AGO: true,
  ALB: true,
  AND: true,
  ARE: true,
  ARG: true,
  ARM: true,
  ASM: true,
  AUS: true,
  AUT: true,
  AZE: true,
  BEL: true,
  BEN: true,
  BFA: true,
  BGD: true,
  BGR: true,
  BHR: true,
  BHS: true,
  BIH: true,
  BLR: true,
  BLZ: true,
  BOL: true,
  BRA: true,
  BWA: true,
  CAF: true,
  CAN: true,
  CHE: true,
  CHL: true,
  CIV: true,
  CMR: true,
  COD: true,
  COL: true,
  CRI: true,
  CZE: true,
  DEU: true,
  DNK: true,
  DOM: true,
  DZA: true,
  ECU: true,
  EGY: true,
  ESP: true,
  ETH: true,
  FIN: true,
  FRA: true,
  GBR: true,
  GHA: true,
  GIN: true,
  GRC: true,
  GTM: true,
  HKG: true,
  HND: true,
  HRV: true,
  HTI: true,
  HUN: true,
  IDN: true,
  IND: true,
  IRL: true,
  IRQ: true,
  ISR: true,
  ITA: true,
  JOR: true,
  JPN: true,
  KAZ: true,
  KEN: true,
  KGZ: true,
  KHM: true,
  KOR: true,
  KWT: true,
  LAO: true,
  LBN: true,
  LBY: true,
  LKA: true,
  MAR: true,
  MDA: true,
  MDG: true,
  MEX: true,
  MLI: true,
  MMR: true,
  MOZ: true,
  MRT: true,
  MYS: true,
  NGA: true,
  NIC: true,
  NLD: true,
  NOR: true,
  NPL: true,
  NZL: true,
  OMN: true,
  PAK: true,
  PAN: true,
  PER: true,
  PHL: true,
  POL: true,
  PRI: true,
  PRT: true,
  PRY: true,
  PSE: true,
  QAT: true,
  ROU: true,
  RUS: true,
  SAU: true,
  SDN: true,
  SEN: true,
  SGP: true,
  SLV: true,
  SRB: true,
  SVK: true,
  SVN: true,
  SWE: true,
  THA: true,
  TUN: true,
  TUR: true,
  TWN: true,
  TZA: true,
  UKR: true,
  URY: true,
  UZB: true,
  VEN: true,
  VNM: true,
  YEM: true,
  ZAF: true,
};
