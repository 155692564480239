import React, { useContext } from 'react';
import { TimelineRangeButton, TimelineRangeButtonsContainer } from './styles';
import { InteractionContext } from 'common/InteractionContext';
import { DispatcherContext } from 'common/DispatcherContext';
import { INTERVALS, INTERVAL_ID } from 'common/constants';

const allowedIntervalOptions = [
  INTERVAL_ID.ONE_W,
  INTERVAL_ID.ONE_M,
  INTERVAL_ID.THREE_M,
  INTERVAL_ID.ALL,
];

export const TimelineRangeButtons = () => {
  const { selectedTimeInterval } = useContext(InteractionContext);
  const { dispatchSelectTimeInterval } = useContext(DispatcherContext);

  return (
    <TimelineRangeButtonsContainer>
      {INTERVALS.filter(({ intervalId }) =>
        allowedIntervalOptions.includes(intervalId)
      ).map((interval) => (
        <TimelineRangeButton
          active={selectedTimeInterval === interval}
          key={interval.intervalId}
          onClick={() => dispatchSelectTimeInterval(interval)}
        >
          {interval.label}
        </TimelineRangeButton>
      ))}
    </TimelineRangeButtonsContainer>
  );
};
