import { timeParse } from 'd3-time-format';
import { format } from 'd3-format';
import { group } from 'd3-array';
import { csv } from 'd3-fetch';

import { GOVT_ACTION_TYPES, GOVT_ACTION_DATASETS } from 'common/constants';
import USSocialDistancingDataURL from 'data/SocialDistancing/data/USstatesCov19distancingpolicy.csv';
import WHOGovtMeasuresDataURL from 'data/who_govt_measures.csv';
import { toISO3CountryCode } from './accessors';

const parseDate = timeParse('%Y%m%d');
const parseDashedDate = timeParse('%Y-%m-%d');
const parseBool = (b) => Boolean(b === '1');
const parseFIPS = format('02');

const cleanText = (text) => text.toLowerCase().trim();

const usPolicyToGovtActionType = (policy) => {
  switch (policy) {
    case 'SchoolClose':
      return GOVT_ACTION_TYPES.SCHOOL_CLOSED;

    case 'StayAtHome':
      return GOVT_ACTION_TYPES.SHELTER_IN_PLACE;

    case 'EmergDec':
      return GOVT_ACTION_TYPES.EMERGENCY;

    default:
      return GOVT_ACTION_TYPES.OTHER;
  }
};

const whoRowToGovtActionType = (measure, subcategory) => {
  if (
    cleanText(measure) === 'closing' &&
    cleanText(subcategory) === 'school measures'
  ) {
    return GOVT_ACTION_TYPES.SCHOOL_CLOSED;
  } else if (cleanText(measure) === 'stay-at-home order') {
    return GOVT_ACTION_TYPES.SHELTER_IN_PLACE;
  } else {
    return GOVT_ACTION_TYPES.OTHER;
  }
};

const parseUSStatesDistancingData = () =>
  new Promise((res, rej) => {
    // Throw away all points that are not "mandates" (i.e. just govt suggestions)
    csv(USSocialDistancingDataURL).then((usSocialDistancingData) => {
      res(
        usSocialDistancingData
          .filter((d) => parseBool(d.Mandate))
          .map((d) => ({
            FIPS: parseFIPS(d.StateFIPS),
            regionId: parseFIPS(d.StateFIPS),
            policy: d.StatePolicy,
            govtActionType: usPolicyToGovtActionType(d.StatePolicy),
            govtActionDataset: GOVT_ACTION_DATASETS.US_STATES,
            // TODO: These dates are relative to the data point's locale
            dateIssued: parseDate(d.DateIssued),
            dateEnacted: parseDate(d.DateEnacted),
            dateExpiry: parseDate(d.DateExpiry),
            dateEased: parseDate(d.DateEased),
            dateEnded: parseDate(d.DateEnded),
            policyCodingNotes: d.PolicyCodingNotes,
            policySource: d.PolicySource,
            statewide: parseBool(d.StateWide),
            lastUpdated: parseDate(d.LastUpdated),
          }))
      );
    });
  });

const parseWHOGovtMeasures = () =>
  new Promise((res, rej) => {
    csv(WHOGovtMeasuresDataURL).then((whoGovtMeasuresData) => {
      res(
        whoGovtMeasuresData
          .filter((d) => d.NON_COMPLIANCE !== 'Recommended')
          .map((d) => ({
            regionId: d.COUNTRY_CODE,
            govtActionType: whoRowToGovtActionType(
              d.WHO_MEASURE,
              d.WHO_SUBCATEGORY
            ),
            govtActionDataset: GOVT_ACTION_DATASETS.WHO_PHSM,
            dateEnacted: parseDashedDate(d.DATE_START),
            dateEnded: parseDashedDate(d.DATE_END),
          }))
      );
    });
  });

const parseGovtActionsData = function () {
  return new Promise((res, rej) => {
    Promise.all([parseUSStatesDistancingData(), parseWHOGovtMeasures()]).then(
      ([usStatesDistancingData, whoGovtMeasures]) => {
        const byAdmin1ByGovtActionType = {
          USA: group(
            usStatesDistancingData,
            (d) => d.FIPS,
            (d) => d.govtActionType
          ),
        };
        //const byCountryByGovtActionType = group(
        //whoGovtMeasures,
        //(d) => d.regionId,
        //(d) => d.govtActionType
        //);
        const getActionsByTypeForRegion = (country, admin1) => {
          try {
            let result;
            const countryCode = toISO3CountryCode(country);
            if (admin1) {
              result = byAdmin1ByGovtActionType[countryCode].get(admin1);
            } else {
              //result = byCountryByGovtActionType.get(countryCode);
              // Currently all country-wide govt actions are disabled.
              result = null;
            }
            return result || null;
          } catch (e) {
            return null;
          }
        };

        res({
          getActionsByTypeForRegion,
        });
      }
    );
  });
};
export default parseGovtActionsData;
