import React from 'react';

import FBHeader from 'components/symptom/FBHeader';
import FBFooter from 'components/symptom/FBFooter';

import SymptomSurveyViz from 'components/symptom/SymptomSurveyViz';
import { EmbedSection } from './styles';
import { Container } from 'styles/styles';
import embedIntro from './EmbedIntro/';
import getLanguage from 'common/language';
import EmbedControls from './EmbedControls';
import EyebrowHeader from './EyebrowHeader';
import CodeSnippetSection from './CodeSnippetSection';
import { DataProvider } from 'common/DataContext';
import { InteractionProvider } from 'common/InteractionContext';
import { DispatcherProvider } from 'common/DispatcherContext';

const srcRoot = 'https://covid-survey.dataforgood.fb.com/?embed';

export default class Embed extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.iframeRef = React.createRef();
    this.snippetRef = React.createRef();
    this.state = {
      generatedSnippet: null,
      width: 800,
      //height: 610,
    };
  }

  generateCode() {
    const generatedSnippet = `<iframe id="map" src="${srcRoot}" width="${this.state.width}" frameborder="0"></iframe>`;
    this.setState({ generatedSnippet }, () => {
      setTimeout(
        () =>
          this.snippetRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          }),
        2000
      );
    });
  }

  copySnippet() {
    const el = document.createElement('textarea');
    el.value = this.state.generatedSnippet;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  calcHeight(event) {
    let width = parseInt(event.target.value);
    this.setState({ width: width });
    //this.setState({ height: width * 0.7625 }); //maintain ratio for 800:610px
  }

  render() {
    return (
      <Container>
        <FBHeader parent="embed" />
        <div className="section-outer embed">
          <div className="section-inner">
            <EmbedSection>
              <EyebrowHeader>Embed Map</EyebrowHeader>
              {embedIntro(getLanguage())}
            </EmbedSection>
            <EmbedControls
              width={this.state.width}
              calcHeight={this.calcHeight.bind(this)}
              generateCode={this.generateCode.bind(this)}
            />
            <EmbedSection>
              {/* this container simulates an iframe */}
              <div
                style={{
                  width: this.state.width,
                  height: this.state.height,
                }}
              >
                <InteractionProvider>
                  <DataProvider>
                    <DispatcherProvider>
                      <SymptomSurveyViz isEmbed={true} />
                    </DispatcherProvider>
                  </DataProvider>
                </InteractionProvider>
              </div>
            </EmbedSection>
            <CodeSnippetSection
              generatedSnippet={this.state.generatedSnippet}
              onCopyClicked={this.copySnippet.bind(this)}
              ref={this.snippetRef}
            />
          </div>
        </div>

        <FBFooter />
      </Container>
    );
  }
}
