import React, { useContext } from 'react';
import classNames from 'classnames';
import { InteractionContext } from 'common/InteractionContext';
import { Map } from 'components/common/Map';
import MapControlPanel from 'components/symptom/MapControlPanel';
import { Bottom, Right, RightTop } from '../styles';
import { MapExplainer } from './MapExplainer';
import { BreadLoaf } from './BreadLoaf';
import { Legend } from './Legend';
import RightPanel from './RightPanel';
import { MapGlossary } from './MapGlossary';
import Scatterplot from 'components/symptom/Scatterplot';
import MapDisclaimer from './MapDisclaimer';

// Desktop layout.
export const SymptomSurveyLayout = () => {
  const {
    isGlossaryOpen,
    activeDemographicMetric,
    selectedRegion,
  } = useContext(InteractionContext);

  return (
    <Bottom className={classNames({ 'modal-enabled': isGlossaryOpen })}>
      <Right>
        <RightTop>
          <Map />
          <MapControlPanel />
          {activeDemographicMetric ? <Scatterplot /> : null}
          <Legend />
          <MapExplainer />
          <BreadLoaf />

          {selectedRegion !== 'WORLD' ? <RightPanel /> : null}
        </RightTop>
      </Right>
      <MapGlossary />
      <MapDisclaimer />
    </Bottom>
  );
};
