import React from 'react';
import './index.scss';
import FBHeader from 'components/symptom/FBHeader/index.js';
import FBFooter from 'components/symptom/FBFooter/index.js';

export default function survey() {
  console.log('survey');
  // prettier-ignore
  return (
    <div>
      <FBHeader parent="data_info" />
      <div className="data-info">
        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164943">
                <h4 className="eyebrow">Survey & map data</h4>
                <h3>Supporting COVID-19 Research</h3>
                <p>
                  With over 2 billion people on Facebook, we are in a unique
                  position to support public health research. We have partnered
                  with trusted academic and nonprofit institutions since 2017,
                  through our Data For Good program, to build a research network
                  to address some of the world's greatest humanitarian issues.
                  Recently, we partnered with<span> </span>
                  <a
                    className="bold-link"
                    href="https://delphi.cmu.edu/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Carnegie Mellon University
                  </a><span> </span>
                  and the<span> </span>
                  <a
                    className="bold-link"
                    href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    University of Maryland
                  </a><span> </span>
                  to invite people to participate in surveys about COVID-19
                  symptoms and risk factors. We use the survey data on our map,
                  and we also show other publicly available aggregate data to
                  provide a more holistic view of the COVID-19 pandemic. Our
                  partner initiatives are designed with privacy in mind from the
                  start.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="data-image-section">
          <img alt="" src={`${process.env.PUBLIC_URL}/images/image-survey-methodology.jpg`} className="survey-image"/>
        </div>

        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164945">
                <h4 className="sub-header">Survey Methodology</h4>
                <p>
                  In partnership with Carnegie Mellon University and the
                  University of Maryland, we invite people on Facebook to
                  participate in surveys that ask about how they are feeling,
                  including any COVID-19 symptoms they or members of their
                  household have experienced, and about their risk factors for
                  contracting COVID-19. The surveys are designed to provide
                  valuable information to help monitor and forecast how COVID-19
                  may be spreading, without trading off the privacy of survey
                  respondents. Over a million people responded to the surveys
                  within the first two weeks.
                </p>

                <div className="survey-grid">
                  <div className="survey-grid-item col-2">
                    <h4>About the surveys</h4>
                    <p>
                      The surveys conducted by Carnegie Mellon University and
                      the University of Maryland ask people to report symptoms
                      associated with COVID-19 or the flu that they or members
                      of their household have experienced in the last 24 hours.
                      Public health research surveys like this have been
                      conducted globally, and used to forecast the spread of the
                      flu and other illnesses in the past.
                    </p>
                  </div>

                  <div className="survey-grid-item col-2">
                    <h4>Who’s taking the surveys</h4>
                    <p>
                      Facebook reaches large segments of the population allowing
                      for a significant representation of age, gender and
                      location. Every day, a new sample of Facebook users over
                      18 years old are invited to participate in a survey. Users
                      in the United States take a survey conducted by Carnegie
                      Mellon University, and users outside of the United States
                      take a survey conducted by the University of Maryland.
                      Facebook does not receive, collect or store individual
                      survey responses. Carnegie Mellon University and the
                      University of Maryland do not learn who took the surveys.
                    </p>
                  </div>

                  <div className="survey-grid-item col-2">
                    <h4>Adjusting for sample bias</h4>
                    <p>
                      Facebook shares a single statistic, known as a weight
                      value, to correct for survey sample bias. The weight value
                      does not identify the survey respondent. Adjusting for
                      sample bias with weight values ensures that the sample
                      more accurately reflects the characteristics of the
                      population represented. The weight value for a person can
                      be thought of as the number of people in the adult
                      population that they represent, based on their age, gender
                      and location. We do not show any data for countries that
                      do not reflect sample bias adjustments.
                    </p>
                  </div>

                  <div className="survey-grid-item col-2">
                    <h4>Using the survey data</h4>
                    <p>
                      The survey data can help policymakers and researchers
                      forecast COVID-19 outbreaks and respond to the pandemic in
                      their communities. Facebook’s research partners are
                      committed to only using symptom survey results to study
                      and help contain COVID-19.<span> </span>
                      <a
                        className="bold-link"
                        href="https://dataforgood.fb.com/docs/covid-19-symptom-survey-request-for-data-access/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a><span> </span>
                      about requesting access to survey data as an academic or
                      nonprofit researcher.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="data-image-section">
          <img alt="" src={`${process.env.PUBLIC_URL}/images/image-map-data.jpg`} className="survey-image"/>
        </div>

        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164947">
                <h4 className="sub-header">Data on Our Map</h4>
                <p>
                  The data on our map can be used to better understand reported
                  COVID-19 symptoms and confirmed cases, as well as how
                  preventative measures and policies or population
                  characteristics, such as age and density, are affecting
                  disease spread. When used together, the data can help
                  policymakers and public health systems plan for their
                  communities more effectively. There are three categories of
                  data we show on our map: symptoms and cases, physical
                  distancing and risk factors.
                </p>

                <div className="survey-grid">
                  <div className="survey-grid-item col-3">
                    <h4>Symptoms & cases</h4>
                    <p>
                      Reported symptoms and confirmed cases are key in
                      understanding the pandemic because they give an indication
                      of how preventative measures and policies, such as
                      physical distancing, affect COVID-19 spread.
                    </p>
                    <ul>
                      <li>
                        <p>
                          Reported COVID-19 and flu symptoms data sourced from<span> </span>
                          <a
                            className="bold-link"
                            href="https://cmu-delphi.github.io/delphi-epidata/api/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Carnegie Mellon University
                          </a><span> </span>
                          and the<span> </span>
                          <a
                            className="bold-link"
                            href="https://covidmap.umd.edu/api.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            University of Maryland
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          Confirmed total cases and new cases data sourced from<span> </span>
                          <a
                            className="bold-link"
                            href="https://github.com/CSSEGISandData/COVID-19"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Johns Hopkins University
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div className="survey-grid-item col-3">
                    <h4>Physical distancing</h4>
                    <p>
                      Physical distancing, school closure and state of emergency
                      dates, along with mobility data give an indication of how
                      shelter-in-place and stay-at-home policies decrease
                      mobility to slow COVID-19 spread.
                    </p>
                    <ul>
                      <li>
                        <p>
                          Physical distancing, school closure and state of
                          emergency data sourced from<span> </span>
                          <a
                            className="bold-link"
                            href="https://github.com/COVID19StatePolicy/SocialDistancing/blob/master/data/USstatesCov19distancingpolicy.csv"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            COVID-19 State Policy Repository
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          Percentage of people staying in place and change in
                          movement data sourced from Facebook's<span> </span>
                          <a
                            className="bold-link"
                            href="https://data.humdata.org/dataset/movement-range-maps"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Data For Good movement range maps
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div className="survey-grid-item col-3">
                    <h4>Risk factors</h4>
                    <p>
                      Risk factors are population characteristics associated
                      with an increased risk of COVID-19 spread or the
                      development of serious illness, including population
                      total, density and people age 65 and over. These three
                      characteristics can be used to understand the other
                      categories of data more effectively.
                    </p>
                    <ul>
                      <li>
                        <p>
                          Total population, population density per sq km and
                          percentage of people age 65+ data sourced from the<span> </span>
                          <a
                            className="bold-link"
                            href="https://censusreporter.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            US Census Bureau
                          </a><span> </span>
                          and Facebook's<span> </span>
                          <a
                            className="bold-link"
                            href="https://data.humdata.org/organization/facebook"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Data For Good population maps
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 20 }} />
      <FBFooter />
    </div>
  );
}
