import { timeParse, timeFormat } from 'd3-time-format';
import { ascending, extent } from 'd3-array';
import { getParentRegionId, getDate } from './accessors';

import { supportedCountries } from 'data/supportedCountries';
import {
  STAT_NAMES,
  DATASETS_BY_ID,
  DATASET_ID,
  COUNTRY_CODE_TYPES,
} from 'common/constants';

export const parseDate = timeParse('%Y-%m-%d');
export const parseDashlessDate = timeParse('%Y%m%d');
export const formatDashlessDate = timeFormat('%Y%m%d');

// We need these functions because GADM admin 1 and 2 IDs have a "_1" on the end. We removed these from our data files, but they're still in the IDs present in the map tiles.
export const stripGADMSuffix = (gadmRegionId) =>
  gadmRegionId.replace(/_1$/, '');

export const addPostfix = (regionId) => {
  if (
    regionId &&
    supportedCountries[regionId.substring(0, 3)] &&
    regionId.length > 3
  )
    return `${regionId}_1`;
  else return regionId;
};

export const removePostfix = (regionId) => {
  if (
    regionId &&
    supportedCountries[regionId.substring(0, 3)] &&
    regionId.length > 3 &&
    regionId.slice(-2) === '_1'
  )
    return regionId.slice(0, -2);
  else return regionId;
};

// Joins fields from multiple tables based on a key.
// Immutable (does not mutate any original array elements).
//
// @param  {Function}  keyFunction - For a row in any table, return the key
// string that joins them.
// @param  {Object}  emptyRow - A template to provide null values for fields
// on each row.
// @param  {Array}  arrays - List of arrays to join
export const join = (keyFunction, emptyRow, arrays) => {
  const rowsByKey = {};
  arrays.forEach((array) => {
    array
      .filter(
        (d) =>
          supportedCountries[d.regionId] ||
          supportedCountries[getParentRegionId(stripGADMSuffix(d.regionId))] ||
          supportedCountries[getParentRegionId(getParentRegionId(d.regionId))]
      )
      .forEach((d) => {
        const key = keyFunction(d);
        if (!(key in rowsByKey)) {
          rowsByKey[key] = {
            ...emptyRow,
          };
        }
        rowsByKey[key] = {
          ...rowsByKey[key],
          ...d,
        };
      });
  });

  return Object.keys(rowsByKey).map((k) => rowsByKey[k]);
};

export const dateSorter = (a, b) => ascending(getDate(a), getDate(b));

export const rowHasMetric = (activeMetric, row) =>
  !isNaN(row[activeMetric.column]) && row[activeMetric.column] !== null;

// Looks up a dataset from stat name and countryCodeType
export const getDataset = ({ statName, countryCodeType }) => {
  switch (statName) {
    case STAT_NAMES.MOVEMENT:
      return DATASETS_BY_ID[DATASET_ID.MOVEMENT_FB];
    case STAT_NAMES.CASES:
      return DATASETS_BY_ID[DATASET_ID.CASES_JHU];
    case STAT_NAMES.SYMPTOM:
      if (countryCodeType === COUNTRY_CODE_TYPES.FIPS) {
        return DATASETS_BY_ID[DATASET_ID.SYMPTOM_CMU];
      } else {
        return DATASETS_BY_ID[DATASET_ID.SYMPTOM_UMD];
      }
    default:
      break;
  }
};

export const getDateExtent = (rows) => extent(rows, getDate);
