import lookup from 'country-code-lookup';

import {
  WORLD,
  COUNTRY_CODE_TYPES,
  REGION_TYPES,
  STAT_NAMES,
  DATASET_ID,
} from 'common/constants';
import { getDataset } from 'common/DataContext/util';

const adminDigit = (adminLevel) => adminLevel[adminLevel.length - 1];

const getDataFileBasename = (countryCodeType, adminLevel, regionId) => {
  switch (countryCodeType) {
    case COUNTRY_CODE_TYPES.GADM:
      // Special-case USA as parent is GADM but children are FIPS.
      if (regionId === 'USA') return 'fips1';

      const adminLevelString =
        adminLevel === REGION_TYPES.ADMIN2 ? '12' : adminDigit(adminLevel);
      return `${countryCodeType}${adminLevelString}${
        regionId === WORLD ? '' : '_' + regionId
      }`;

    case COUNTRY_CODE_TYPES.FIPS:
      return `${countryCodeType}${adminDigit(adminLevel)}${
        adminLevel === REGION_TYPES.ADMIN2 ? `_${regionId}` : ''
      }`;

    default:
      break;
  }
};

// Possible values for adminLevel are:
//   'admin0'
//   'admin1'
//   'admin2'
// Possible values for statName are:
//   'mobility_statistics'
//   'symptom_survey'
export const path = ({
  adminLevel,
  statName,
  regionId,
  metadata,
  countryCodeType = COUNTRY_CODE_TYPES.GADM,
  usePipelinePaths = true,
  usePipelineEndpoints = true,
}) => {
  const urlRoot = usePipelineEndpoints
    ? process.env.REACT_APP_DATA_ENDPOINT
    : process.env.PUBLIC_URL;

  const dataset = getDataset({ statName, countryCodeType });
  const latestDateString =
    metadata.byDatasetId[dataset.datasetId].latestDateString;

  let urlComponents = [urlRoot, '/'];
  if (usePipelinePaths) {
    switch (statName) {
      case STAT_NAMES.CASES:
        if (regionId === WORLD) {
          urlComponents = urlComponents.concat([
            statName,
            '/',
            latestDateString,
            '/national.csv',
          ]);
        } else {
          const lookupResult = lookup.byIso(regionId);
          let iso2Code = null;
          if (lookupResult) {
            iso2Code = lookupResult.iso2;
          }
          if (!iso2Code) {
            console.log(`ERROR finding iso2 code for ${regionId}`);
          }
          urlComponents = urlComponents.concat([
            statName,
            '/',
            latestDateString,
            countryCodeType === COUNTRY_CODE_TYPES.GADM
              ? '/cumulative_'
              : '/daily_',
            iso2Code,
            '.csv',
          ]);
        }
        break;

      default:
        urlComponents = urlComponents.concat([
          (dataset.datasetId === DATASET_ID.SYMPTOM_CMU ? 'cmu_' : '') +
            statName,
          '/',
          latestDateString,
          '/',
          getDataFileBasename(countryCodeType, adminLevel, regionId),
          '.csv',
        ]);
        break;
    }
  } else {
    urlComponents = urlComponents.concat([
      // Different values for mobility data vs. symptom survey data.
      statName,

      '/',

      // Ignore region Id if at the admin0 level (countries).
      adminLevel === REGION_TYPES.ADMIN0 ? '' : regionId + '_',

      adminLevel,
      '_',
      statName,
      '.',
      process.env.REACT_APP_DATA_DATE,
      '.csv',
    ]);
  }
  return urlComponents.join('');
};
