import React from 'react';
import parse from 'html-react-parser';
import Const from '../const';
import { DisclaimerWrapper, DisclaimerContent } from './styles';

const { DISCLAIMER_HTML } = Const;

export default function MapDisclaimer({ isEmbed }) {
  return (
    <DisclaimerWrapper isEmbed={isEmbed}>
      <DisclaimerContent>{parse(DISCLAIMER_HTML)}</DisclaimerContent>
    </DisclaimerWrapper>
  );
}
