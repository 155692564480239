// @flow
import { format } from 'd3-format';
import { timeWeek, timeMonth, timeDay, timeSaturday } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import type {
  Dataset,
  Interval,
  DemographicMetric,
  TimeResolution,
} from 'types';
import { formatBigNumber } from 'common/util/formatBigNumber';
import locale from 'common/locale';

import { PRODUCTS, CURRENT_PRODUCT, SYMPTOM_SURVEY } from './product';
export { PRODUCTS, CURRENT_PRODUCT, SYMPTOM_SURVEY };

export { formatBigNumber };
export const formatTwoDecimalPercent = format('.2~%');
export const formatReadableDate = timeFormat('%B %d, %Y');

export const GOVT_ACTION_TYPES = {
  SCHOOL_CLOSED: 'SCHOOL_CLOSED',
  EMERGENCY: 'EMERGENCY',
  SHELTER_IN_PLACE: 'SHELTER_IN_PLACE',
  OTHER: 'OTHER',
};

export const GOVT_ACTION_DATASETS = {
  WHO_PHSM: 'WHO_PHSM',
  US_STATES: 'US_STATES',
};

export const GOVT_ACTION_TEXT = {
  [GOVT_ACTION_DATASETS.US_STATES]: {
    [GOVT_ACTION_TYPES.SCHOOL_CLOSED]: locale('i18n_schoolsClosure'),
    [GOVT_ACTION_TYPES.EMERGENCY]: locale('i18n_stateOfEmergency'),
    [GOVT_ACTION_TYPES.SHELTER_IN_PLACE]: locale('i18n_shelterInPlace'),
  },
  [GOVT_ACTION_DATASETS.WHO_PHSM]: {
    [GOVT_ACTION_TYPES.SCHOOL_CLOSED]: locale('i18n_schoolsClosure'),
    [GOVT_ACTION_TYPES.SHELTER_IN_PLACE]: locale('i18n_stayAtHomeOrder'),
  },
};

export const WORLD = 'WORLD';

export const USA = 'USA';
export const REGION_TYPES = {
  ADMIN0: 'admin0',
  ADMIN1: 'admin1',
  ADMIN2: 'admin2',
};

export const COUNTRY_CODE_TYPES = {
  GADM: 'gadm',
  FIPS: 'fips',
};

export const COUNTRY_CODE_TYPES_LIST: Array<string> = Object.keys(
  COUNTRY_CODE_TYPES
).map((k) => COUNTRY_CODE_TYPES[k]);

export const STAT_NAMES = {
  MOVEMENT: 'movement',
  SYMPTOM: 'symptom_survey',
  CASES: 'cases',
};

export const STAT_NAMES_LIST: Array<string> = Object.keys(STAT_NAMES).map(
  (k) => STAT_NAMES[k]
);

export const DATASET_ID = {
  SYMPTOM_CMU: 'SYMPTOM_CMU',
  SYMPTOM_UMD: 'SYMPTOM_UMD',
  MOVEMENT_FB: 'MOVEMENT_FB',
  CASES_JHU: 'CASES_JHU',
};

export const DATASETS_BY_ID = {
  // U.S.-only symptom survey results from CMU Delphi API (https://delphi.cmu.edu).
  [DATASET_ID.SYMPTOM_CMU]: {
    datasetId: DATASET_ID.SYMPTOM_CMU,
    apiPath: 'cmu_symptom_survey',
    statName: STAT_NAMES.SYMPTOM,
  },
  // COVID-19 World Survey Data API from URM (https://covidmap.umd.edu/api.html).
  [DATASET_ID.SYMPTOM_UMD]: {
    datasetId: DATASET_ID.SYMPTOM_UMD,
    apiPath: 'symptom_survey',
    statName: STAT_NAMES.SYMPTOM,
  },
  // https://data.humdata.org/dataset/movement-range-maps
  [DATASET_ID.MOVEMENT_FB]: {
    datasetId: DATASET_ID.MOVEMENT_FB,
    apiPath: 'movement',
    statName: STAT_NAMES.MOVEMENT,
  },
  // COVID-19 Data Repository by the Center for Systems Science and Engineering (CSSE) at Johns Hopkins University (https://github.com/CSSEGISandData/COVID-19)
  [DATASET_ID.CASES_JHU]: {
    datasetId: DATASET_ID.CASES_JHU,
    apiPath: 'cases',
    statName: STAT_NAMES.CASES,
  },
};

export const DATASETS_LIST: Array<Dataset> = Object.keys(DATASETS_BY_ID).map(
  (datasetId): Dataset => DATASETS_BY_ID[datasetId]
);

export const DEMOGRAPHIC_METRIC_IDS = {
  POPULATION: 'POPULATION',
  DENSITY: 'DENSITY',
  OVER_65: 'OVER_65',
};

export const DEMOGRAPHIC_METRICS: Array<DemographicMetric> = [
  {
    id: DEMOGRAPHIC_METRIC_IDS.POPULATION,
    label: locale('i18n_totalPopulation'),
    labelForMapTooltip: locale('i18n_totalPopulation'),
    labelForLegend: locale('i18n_totalPopulation'),
    column: 'Total population',
    display: (popNum: number) => {
      if (popNum < 100) return format('.2s')(popNum);
      else return formatBigNumber(popNum);
    },
    bivariateBreakpoints: [15000000, 30000000],
    lessIndicator: 'Fewer People',
    moreIndicator: 'More People',
    dataSource: () => 'US Census Bureau (US) & Data For Good (non-US)',
  },
  {
    id: DEMOGRAPHIC_METRIC_IDS.DENSITY,
    label: locale('i18n_densityPerSq'),
    labelForMapTooltip: locale('i18n_densityPerSq'),
    labelForLegend: locale('i18n_densityPerSq'),
    column: 'Density (per sq km)',
    display: formatBigNumber,
    bivariateBreakpoints: [500, 1000],
    lessIndicator: 'Less Dense',
    moreIndicator: 'More Dense',
    dataSource: () => 'US Census Bureau (US) & Data For Good (non-US)',
  },
  {
    id: DEMOGRAPHIC_METRIC_IDS.OVER_65,
    label: locale('i18n_65Percent'),
    labelForMapTooltip: locale('i18n_65PercentTooltipLabel'),
    labelForLegend: locale('i18n_65Percent'),
    column: '65+ percent',
    display: format('.2~%'),
    bivariateBreakpoints: [0.14, 0.175],
    lessIndicator: 'Fewer 65+',
    moreIndicator: 'More 65+',
    dataSource: () => 'US Census Bureau (US) & Data For Good (non-US)',
  },
];
export const DEMOGRAPHIC_METRICS_BY_ID = DEMOGRAPHIC_METRICS.reduce(
  (acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  },
  {}
);

export const NO_DATA_MESSAGE_BY_PRODUCT = {
  [PRODUCTS.MOBILITY]: locale('i18n_noMobility'),
  [PRODUCTS.SYMPTOM]: locale('i18n_data_not_avail'),
};

export const INTERVAL_ID = {
  ONE_W: 'ONE_W',
  ONE_M: 'ONE_M',
  THREE_M: 'THREE_M',
  ALL: 'ALL',
  EIGHT_W: 'EIGHT_W',
  EIGHT_D: 'EIGHT_D',
};

export const INTERVALS: Array<Interval> = [
  {
    intervalId: INTERVAL_ID.ONE_W,
    timeFunction: timeWeek,
    timeOffset: 1,
    label: '1W',
    isDefault: false,
  },
  {
    intervalId: INTERVAL_ID.ONE_M,
    timeFunction: timeMonth,
    timeOffset: 1,
    label: '1M',
  },
  {
    intervalId: INTERVAL_ID.EIGHT_W,
    timeFunction: timeWeek,
    timeOffset: 8,
    label: '8W',
    isDefault: CURRENT_PRODUCT === PRODUCTS.SYMPTOM,
  },
  {
    intervalId: INTERVAL_ID.EIGHT_D,
    timeFunction: timeDay,
    timeOffset: 8,
    label: '8D',
  },
  {
    intervalId: INTERVAL_ID.THREE_M,
    timeFunction: timeMonth,
    timeOffset: 3,
    label: '3M',
    isDefault: CURRENT_PRODUCT === PRODUCTS.MOBILITY,
  },
  {
    intervalId: INTERVAL_ID.ALL,
    timeFunction: null,
    label: 'ALL',
    isDefault: false,
  },
];

export const INTERVALS_BY_ID = INTERVALS.reduce((acc, curr) => {
  acc[curr.intervalId] = curr;
  return acc;
}, {});

export const TIME_RESOLUTION_IDS = {
  DAY: 'day',
  WEEK: 'week',
};

export const TIME_RESOLUTIONS: Array<TimeResolution> = [
  {
    timeResolutionId: TIME_RESOLUTION_IDS.DAY,
    timeFunction: timeDay,
    isDefault: CURRENT_PRODUCT === PRODUCTS.MOBILITY,
  },
  {
    timeResolutionId: TIME_RESOLUTION_IDS.WEEK,
    timeFunction: timeSaturday,
    isDefault: CURRENT_PRODUCT === PRODUCTS.SYMPTOM,
  },
];
export const TIME_RESOLUTIONS_BY_ID = TIME_RESOLUTIONS.reduce((acc, curr) => {
  acc[curr.timeResolutionId] = curr;
  return acc;
}, {});

export const METRIC_IDS = {
  SYMPTOMS_COVID: 'covid',
  SYMPTOMS_FLU: 'flu',
  MOBILITY: 'mobility',
  STAY_PUT: 'stay-put',
  TOTAL_CONFIRMED: 'totalconfirmed',
  NEW_CONFIRMED: 'newconfirmed',
};

export const MAP_ALLOWED_DEMOGRAPHIC_IDS = [
  DEMOGRAPHIC_METRIC_IDS.POPULATION,
  DEMOGRAPHIC_METRIC_IDS.OVER_65,
  DEMOGRAPHIC_METRIC_IDS.DENSITY,
];

export const MAP_ALLOWED_METRIC_IDS = [
  METRIC_IDS.SYMPTOMS_COVID,
  METRIC_IDS.SYMPTOMS_FLU,
  METRIC_IDS.TOTAL_CONFIRMED,
  METRIC_IDS.NEW_CONFIRMED,
  METRIC_IDS.STAY_PUT,
  METRIC_IDS.MOBILITY,
];

export const LINE_CHARTS_CONFIG = [
  {
    id: 'symptoms-chart',
    allowedMetricIDs: [METRIC_IDS.SYMPTOMS_COVID, METRIC_IDS.SYMPTOMS_FLU],
    activeLineColor: '#C22734',
    defaultSelectedMetricID: METRIC_IDS.SYMPTOMS_COVID,
    statName: STAT_NAMES.SYMPTOM,
  },
  {
    id: 'mobility-chart',
    allowedMetricIDs: [METRIC_IDS.STAY_PUT, METRIC_IDS.MOBILITY],
    activeLineColor: '#007C82',
    defaultSelectedMetricID: METRIC_IDS.STAY_PUT,
    statName: STAT_NAMES.MOVEMENT,
  },
  {
    id: 'cases-chart',
    allowedMetricIDs: [METRIC_IDS.TOTAL_CONFIRMED, METRIC_IDS.NEW_CONFIRMED],
    activeLineColor: '#6269AE',
    defaultSelectedMetricID: METRIC_IDS.TOTAL_CONFIRMED,
    statName: STAT_NAMES.CASES,
  },
];

export const biVariatePallette = [
  ['#D6F3FF', '#A0E1DC', '#68C9C1'],
  ['#F6E6FB', '#A5D2EB', '#26A49A'],
  ['#C8A2D3', '#7E518B', '#004872'],
];

export const MAP_PROJECTION = {
  MERCATOR: 'mercator',
  PATTERSON: 'patterson',
};
