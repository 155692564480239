import React, { useContext } from 'react';
import { AttributionText, LineChartAttribution } from './styles';
import { margin } from 'components/common/LineChart';
import { InteractionContext } from 'common/InteractionContext';

export const InterventionAttribution = ({ asterisk = true }) => {
  const { selectedRegion } = useContext(InteractionContext);

  if (selectedRegion === 'USA')
    return (
      <LineChartAttribution marginLeft={margin.left}>
        <AttributionText>
          {asterisk ? '*' : ''}Intervention data taken from{' '}
          <a href="https://github.com/COVID19StatePolicy/SocialDistancing/blob/master/data/USstatesCov19distancingpolicy.csv">
            COVID19StatePolicy’s SocialDistancing repository
          </a>{' '}
          on GitHub{' '}
        </AttributionText>
      </LineChartAttribution>
    );
  else
    return (
      <LineChartAttribution marginLeft={margin.left}>
        <AttributionText>
          {asterisk ? '*' : ''}For international government intervention data,
          visit the{' '}
          <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/phsm">
            WHO Public health and social measures global dataset.
          </a>
        </AttributionText>
      </LineChartAttribution>
    );
};

export default InterventionAttribution;
