import React from 'react';
import FBNav from 'components/symptom/FBNav';
import { FBHeaderContainer, FBHeaderText } from './styles';
import locale from 'common/locale';

const FBHeader = () => {
  return (
    <>
      <FBNav />
      <FBHeaderContainer className="section-inner">
        <FBHeaderText>{locale('i18n_covidHeader')}</FBHeaderText>
      </FBHeaderContainer>
    </>
  );
};

export default FBHeader;
