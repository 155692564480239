import React, { useCallback, useContext } from 'react';

import { InteractionContext } from 'common/InteractionContext';
import './styles.scss';
import locale from 'common/locale';

export default function GlossaryButton() {
  const { setIsGlossaryOpen } = useContext(InteractionContext);
  const onOpenGlossary = useCallback(() => {
    setIsGlossaryOpen(true);
  }, [setIsGlossaryOpen]);
  return (
    <div className="glossary-button" onClick={onOpenGlossary}>
      {locale('i18n_glossary')}
      {
        // <div className="icon">
      }
      <img alt="" src="svg/chevron.svg" />
      {
        // </div>
      }
    </div>
  );
}
