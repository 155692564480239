import { max } from 'd3-array';

import { formatDashlessDate, parseDate } from './util';

export const createRegionStatRow = () => ({
  regionId: null,
  date: null,
  denominator: null,
  pctCLI: null,
  pctILI: null,
  all_day_bing_tiles_visited_relative_change_day_of_week: null,
  all_day_ratio_single_tile_users: null,
});

export const regionStatRowJoinKey = (d) =>
  d.regionId + formatDashlessDate(d.date);

export const createDatasetMetadata = (latestDateRaw) => {
  const latestDateString = latestDateRaw.trim();
  return {
    latestDate: parseDate(latestDateString),
    latestDateString,
  };
};

export const createStatMetadata = (datasetsForStat, byDatasetId) => {
  const latestDates = datasetsForStat.map(
    (dataset) => byDatasetId[dataset.datasetId].latestDate
  );
  return {
    latestDate: max(latestDates),
  };
};

export const createScatterplotFilter = ({
  activeMetricMin,
  activeMetricMax,
  activeDemographicMetricMin,
  activeDemographicMetricMax,
}) => ({
  activeMetricBounds: {
    min: activeMetricMin,
    max: activeMetricMax,
  },
  activeDemographicMetricBounds: {
    min: activeDemographicMetricMin,
    max: activeDemographicMetricMax,
  },
});
