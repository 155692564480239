// @flow
// A boolean, true if we are working inside the "symptom survey build".
// Activate this in development with npm run start-symptom-survey,
// or set the environment variable to "true".
export const SYMPTOM_SURVEY = process.env.REACT_APP_SYMPTOM_SURVEY === 'true';

export const PRODUCTS = {
  MOBILITY: 'MOBILITY',
  SYMPTOM: 'SYMPTOM',
};

export const CURRENT_PRODUCT = SYMPTOM_SURVEY
  ? PRODUCTS.SYMPTOM
  : PRODUCTS.MOBILITY;
