import React from 'react';
// TODO: change this to use glob and lazy
import intro from 'components/symptom/intro';
import getLanguage from 'common/language';
// import { CloseSVG } from '../svg/CloseSVG';
// import { Wrapper, Box, CloseIcon, Message, Dialog, Title } from './styles';

export const IntroContent = () => {
  const content = intro[getLanguage()] || intro['en'];
  return <div className="section-outer community">{content()}</div>;
};
