import React from 'react';

export default function intro() {
  // prettier-ignore
  return (
<div className="section-inner">
      <div className="story">
        <div className="section section-partnering">
          <div className="content"><h4 className="eyebrow">Données des sondages et de la carte</h4>
            <h3>Soutenir la recherche sur le COVID-19</h3>
            <p>Avec plus de 2 milliards de personnes sur Facebook, nous avons la
             possibilité unique de soutenir la recherche de santé publique. Depuis 2017, nous formons des partenariats
                  avec des institutions académiques et des organisations à but non
                  lucratif reconnues, par l’intermédiaire de l’initiative Data For Good,
              pour créer un réseau de recherche visant à répondre aux plus grands problèmes humanitaires auxquels le monde doit faire face. Récemment, nous
              avons collaboré avec<span> </span>
                  <a className="bold-link" href="https://delphi.cmu.edu/" target="_blank" rel="noopener noreferrer">
                    l’université Carnegie Mellon University (CMU)
                  </a><span> </span>
                  et<span> </span>
                  <a className="bold-link" href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data" target="_blank" rel="noopener noreferrer">
                    l’université du Maryland (UMD)
                  </a><span> </span>
                  pour inviter les gens à participer à des sondages sur le COVID-19,
                  ses symptômes et ses facteurs de risque. Nous transcrivons ensuite les données de ces sondages sur notre carte
                  et nous y ajoutons d’autres données agrégées publiques
                  pour offrir une vue plus holistique de la pandémie de COVID-19.</p>
            <p>Dès le début, les initiatives de nos partenaires sont conçues dans un souci de
                  respect de la confidentialité de chaque individu.</p>
            <p className="link"><a href="./survey_and_map_data.html" rel="noopener noreferrer">
                <img className="link" alt="" src={`${process.env.PUBLIC_URL}/svg/ic-grey-link-arrow.svg`}/>
                En savoir plus sur les données des sondages et des cartes
              </a></p></div>
          <div className="partner-logos">
            <a className="cmu" href="https://www.cmu.edu" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/svg/cmu-wordmark-square-w-on-70k.svg`} alt="Carnegie Mellon University logo" aria-label="Carnegie Mellon University logo" />
            </a>
            <a className="umd" href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/svg/university-of-maryland-1-1.svg`} aria-label="University of Maryland logo" alt="University of Maryland logo" />
            </a>
          </div>
        </div>
      </div>

      <div className="story">
        <div className="section tools-to-help">
          <div className="content"><h4 className="eyebrow">Autres outils</h4><div className="more-info">
              <div className="request-access-section info">
                <a href="https://dataforgood.fb.com/docs/covid-19-symptom-survey-request-for-data-access/" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>Les données agrégées des sondages sur les symptômes sont publiques et
                      accessibles sur demande auprès des universités Carnegie Mellon University et
                      du Maryland. Facebook et ses partenaires universitaires
                      autorisent également les chercheurs académiques et d’organisations
                      à but non lucratif à demander accès aux données non agrégées.</p>
                    <p className="call-to-action">En savoir plus sur l’accès aux recherches</p></div>
                </a>
              </div>

              <div className="facebook-ai-section info">
                <a href="https://ai.facebook.com/blog/using-ai-to-help-health-experts-address-the-covid-19-pandemic" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>Nous proposons une intelligence artificielle pour prévoir la propagation
                      de la maladie et l’impact sur les hôpitaux, à l’échelle nationale
                      dans certaines zones, pour aider les autorités sanitaires locales à
                      anticiper le nombre de lits, masques et respirateurs nécessaires.</p>
                    <p className="call-to-action">En savoir plus sur l’intelligence artificielle (AI) Facebook</p></div>
                </a>
              </div>

              <div className="follow-covid-info-section info">
                <a href="https://www.facebook.com/coronavirus_info/" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>Suivez les dernières actualités et conseils de sécurité des autorités sanitaires
                      et des organes de presse sur Facebook.</p>
                    <p className="call-to-action">Suivre les informations relatives au COVID-19</p></div>
                </a>
              </div>

              <div className="data-for-good-section info">
                <a href="https://dataforgood.fb.com/" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>Dans le cadre de l’initiative Data for Good, nous fournissons des cartes de
                      prévention des maladies pour aider les chercheurs à comprendre
                      comment la dynamique des populations influence la propagation. Ces cartes utilisent des données agrégées pour
                      protéger la confidentialité des individus.</p>
                    <p className="call-to-action">En savoir plus sur l’initiative Data for Good</p></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
