import React, { useCallback } from 'react';

import './MetricSelector.scss';

const MetricSelector = ({
  allowedMetrics,
  value,
  onChange,
  className = 'layer-select',
}) => {
  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;
      onChange(allowedMetrics[value]);
    },
    [onChange, allowedMetrics]
  );
  const currentValue = allowedMetrics.indexOf(value);
  return (
    <div className={className}>
      <form>
        <select value={currentValue} onChange={handleChange}>
          {allowedMetrics.map((metric, i) => (
            <option key={metric.id} value={i}>
              {metric.label}
            </option>
          ))}
        </select>
      </form>
    </div>
  );
};

export default MetricSelector;
