import React, { useRef } from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
//import L from "leaflet";
import Const from '../const';
import GlossaryButton from 'components/symptom/GlossaryButton';
import { formatReadableDate } from 'common/constants';
import './styles.scss';
import locale from 'common/locale';

const { DISCLAIMER_HTML } = Const;

export class LegendBody extends React.Component {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();
  }

  render() {
    const {
      activeDate,
      isEmbed,
      activeMetric,
      selectedRegion,
      thresholds,
    } = this.props;
    const date = activeDate;
    const source = activeMetric.dataSource(selectedRegion);

    const dateString = formatReadableDate(date);

    let mobileDisclaimer =
      window.innerWidth <= Const.MOBILE_BREAK ? (
        <div className="disclaimer-content">{parse(DISCLAIMER_HTML)}</div>
      ) : null;

    let mobileInfoText =
      window.innerWidth <= Const.MOBILE_BREAK ? (
        <div className={'info legend-text mobile'}>
          {locale('i18n_source')} {source}, {dateString}
        </div>
      ) : null;

    let desktopInfoText =
      window.innerWidth >= Const.MID_BREAK ? (
        <div className={'info legend-text'}>
          <p>
            {locale('i18n_source')} {source}, {dateString}
          </p>
        </div>
      ) : null;

    return (
      <div
        className={classNames('info legend-container', {
          embedded: isEmbed,
        })}
        ref={this.divRef}
      >
        <div className={'info legend-title'}>
          <div className={'info legend-title text'}>
            {activeMetric.labelForLegend}
          </div>
        </div>
        {desktopInfoText}
        <Key metric={activeMetric} thresholds={thresholds} />
        {mobileInfoText}
        {mobileDisclaimer}
        {isEmbed ? null : <GlossaryButton />}
      </div>
    );
  }

  renderSymptomInfoToolTip(text) {
    return `<div class="legend-symptom-tooltip tooltip">
       ${text}
    </div>`;
  }
}

// TODO improve these to match original product.
const defaultUnit = '';

function createBoundaryLabels(metric, thresholds) {
  const { unit = defaultUnit } = metric;
  const tickFormatter = metric.display;

  const display = (value) =>
    tickFormatter != null ? tickFormatter(value) + unit : value + unit;
  let labels = [];
  if (thresholds) {
    if (thresholds[0] > 0) {
      labels.push(`${display(0)}`);
    }

    for (var i = 0; i < thresholds.length - 1; i++) {
      labels.push(`${display(thresholds[i])}`);
    }

    labels.push(`>${display(thresholds[i])}`);
  }

  return labels;
}

const Key = (props) => {
  const { metric, thresholds } = props;
  const colorPalette = metric.colors;

  const boundaryLabels = createBoundaryLabels(metric, thresholds);
  const divRef = useRef(null);

  //useEffect(() => {
  //  L.DomEvent.disableClickPropagation(divRef.current);
  //});

  return (
    <div className={'info legend'} ref={divRef}>
      {boundaryLabels.map((label, i) => {
        const color = colorPalette[i];

        return (
          <div className={'legend-item'} key={`${label}-${color}`}>
            <i style={{ background: `${color}` }}></i>
            <div className={'legend-label'}>{label}</div>
          </div>
        );
      })}
    </div>
  );
};
