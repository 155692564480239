import React, { useContext, useState, useCallback } from 'react';

import { VizContainer } from 'styles/styles';
import { InteractionContext } from 'common/InteractionContext';
import { FetchingContext } from 'common/DataContext/FetchingContext';
import { isMobile } from 'styles/styles';
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';
import { EmbedLayout } from './EmbedLayout';
import { EmbedDrawer } from './EmbedDrawer';
import { SmallMultiples } from './SmallMultiples';
import LandingModal from 'components/mobility/Modal/LandingModal';
import LoadingModal from 'components/mobility/Modal/LoadingModal';

const MovementTrendsViz = ({ isEmbed }) => {
  const { isEmbedDrawerOpen, isSmallMultiplesOpen } = useContext(
    InteractionContext
  );
  const { selectedRegionStatsAreFetching } = useContext(FetchingContext);

  // Defines state for showing the landing modal on first load.
  const [showLandingModal, setShowLandingModal] = useState(!isEmbed);
  const dismissLandingModal = useCallback(() => setShowLandingModal(false), [
    setShowLandingModal,
  ]);

  // Defines state for showing loading modal.
  const showLoadingModal = !showLandingModal && selectedRegionStatsAreFetching;

  const mainVizComponent = isSmallMultiplesOpen ? (
    <SmallMultiples />
  ) : isEmbed ? (
    <EmbedLayout />
  ) : isMobile ? (
    <MobileLayout />
  ) : isEmbedDrawerOpen ? (
    <EmbedDrawer />
  ) : (
    <DesktopLayout />
  );

  return (
    <VizContainer isEmbed={isEmbed}>
      {mainVizComponent}
      <LandingModal
        isEmbed={isEmbed}
        showLandingModal={showLandingModal}
        dismissModal={dismissLandingModal}
      />
      {showLoadingModal ? <LoadingModal /> : null}
    </VizContainer>
  );
};

export default MovementTrendsViz;
