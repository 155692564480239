// @flow
import React from 'react';
import ReactDOM from 'react-dom';

import 'styles/clear.css';

import { App } from './App';
import { AppMobility } from './AppMobility';

import { CURRENT_PRODUCT, PRODUCTS } from 'common/constants';

const appComponentsForProduct = {
  [PRODUCTS.SYMPTOM]: App,
  [PRODUCTS.MOBILITY]: AppMobility,
};

const ProductApp = appComponentsForProduct[CURRENT_PRODUCT];

ReactDOM.render(
  <React.StrictMode>
    <ProductApp />
  </React.StrictMode>,
  document.getElementById('root')
);
