import React from 'react';
import './index.scss';
import FBHeader from 'components/symptom/FBHeader/index.js';
import FBFooter from 'components/symptom/FBFooter/index.js';

export default class SurveyMapData extends React.PureComponent {
  render() {
    return (
      <div>
        <FBHeader parent="data_info" />
        <div className="section-outer data-info">
          <div className="section-inner">
            <div className="story">
              <div className="section">
                <div
                  className="content"
                  id="i18n-1713164943"
                  style={{ marginTop: 40, marginBottom: 80 }}
                >
                  <h3>This page isn’t available</h3>
                  <p>
                    The link you followed may be broken, or the page may have
                    been removed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20 }} />
        <FBFooter />
      </div>
    );
  }
}
