import React from 'react';
import '../styles.scss';

export default function mapExplainer(handleClick) {
  // prettier-ignore
  return (
<div className="map-explainer">
      <div className="explainer-header"><h3>À propos de cette carte</h3><div id="r2"/>
      </div><ul className="explainer-body">
        <li>Cette carte utilise des données publiques agrégées pour protéger la
          confidentialité des individus, et n’est pas conçue à des fins de diagnostic ou de conseils de voyage.</li>
        <li>Pour en savoir plus sur les données, veuillez vous reporter à la page <span> </span>
          <a href="./survey_and_map_data.html">Sondage et données</a>.</li>
        <li>Les frontières des zones administratives litigieuses sont fournies par<span> </span>
          <a href="https://www.mapbox.com/" target="_blank" rel="noopener noreferrer">
            Mapbox
          </a>
          . Toutes les autres zones administratives représentées sur cette carte sont fournies par le<span> </span>
          <a href="https://www.census.gov/geographies/mapping-files/time-series/geo/cartographic-boundary.html" target="_blank" rel="noopener noreferrer">
            Bureau du recensement des États-Unis
          </a>
          <span> et extraites de la </span>
          <a href="https://gadm.org/" target="_blank" rel="noopener noreferrer">
            Base de données des zones administratives mondiales
          </a>
          .</li>
      </ul>
    </div>
);
}
