import styled, { css } from 'styled-components';

const DropdownBase = css`
  z-index: 10000;
`;

export const FloatingDropdownContainer = styled.div`
  ${DropdownBase}
  width: 200px;
  position: absolute;
  top: 10px;
  left: 10px;
`;

export const FullWidthDropdownContainer = styled.div`
  ${DropdownBase}
  width: 100%;
  margin-bottom: 6px;
`;

export const CenteredDropdownContainer = styled.div`
  ${DropdownBase}
  width: 200px;
  margin: auto;
`;
