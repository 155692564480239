import React from 'react';
export const DescendingNumeralSVG = ({
  height = 13,
  fill = 'currentcolor',
}) => (
  <path
    fill={fill}
    d="M6.161 4.605c.117.117.276.183.442.183.166 0 .325-.066.442-.183l.808-.808v7.242c0 .345.28.625.625.625s.625-.28.625-.625V3.797l.808.808c.245.242.639.24.882-.003s.244-.636.002-.88L8.92 1.845c-.244-.243-.64-.243-.884 0L6.161 3.721c-.117.118-.183.277-.183.442 0 .166.066.325.183.442zM2.777 5.664V0h-.925c0 .05-.015.125-.043.227-.05.169-.123.304-.22.406-.14.148-.322.247-.546.297C.903.96.656.984.305 1v.758h1.332v3.906h1.14zm1.102 7v-.98H1.406c.07-.118.157-.22.26-.309.103-.089.294-.232.572-.43l.446-.316c.375-.266.644-.507.808-.723.25-.325.375-.698.375-1.117 0-.547-.177-.986-.531-1.318-.354-.332-.83-.498-1.43-.498-.758 0-1.285.282-1.582.847-.156.297-.243.687-.262 1.168h1.083c.013-.317.054-.55.125-.695.122-.253.352-.379.69-.379.248 0 .438.08.571.238.133.16.2.358.2.598 0 .294-.116.565-.348.813-.151.158-.483.421-.996.789-.591.421-.966.821-1.125 1.199-.164.336-.252.707-.262 1.113h3.879z"
  />
);
//  <svg height={height} viewBox="0 0 11 13">
//  </svg>
