import React, { useContext } from 'react';

import { breadcrumbsRegionId } from 'common/breadcrumbs';
import { DataContext } from 'common/DataContext';
import { InteractionContext } from 'common/InteractionContext';
import { DispatcherContext } from 'common/DispatcherContext';
import DataDateText from './DataDateText';

import { isMobile } from 'styles/styles';
import {
  Top,
  TopLeft,
  TopRight,
  Breadcrumbs,
  Breadcrumb,
  EmbedLink,
  HeaderTitle,
} from './styles';
import { InternationalBreadcrumbs } from './InternationalBreadcrumbs';

const Header = () => {
  const { getRegionName } = useContext(DataContext);
  const { dispatchDeselectRegion, dispatchToggleEmbedDrawer } = useContext(
    DispatcherContext
  );
  const { selectedRegion, activeRow, international } = useContext(
    InteractionContext
  );

  return (
    <Top isActive={true} isMobile={isMobile}>
      <TopLeft isMobile={isMobile}>
        <HeaderTitle>Movement Trends</HeaderTitle>{' '}
        {international ? (
          <Breadcrumbs isMobile={isMobile}>
            {isMobile ? null : (
              <InternationalBreadcrumbs
                regionId={breadcrumbsRegionId({ activeRow, selectedRegion })}
              />
            )}
          </Breadcrumbs>
        ) : (
          <Breadcrumbs isMobile={isMobile}>
            {selectedRegion ? (
              activeRow && activeRow.regionId !== selectedRegion ? (
                <>
                  <Breadcrumb onClick={dispatchDeselectRegion}>
                    United States
                  </Breadcrumb>
                  {' > '}
                  {getRegionName(selectedRegion)}
                  {' > '}
                  {getRegionName(activeRow.regionId)}
                </>
              ) : (
                <>
                  <Breadcrumb onClick={dispatchDeselectRegion}>
                    United States
                  </Breadcrumb>
                  {' > '}
                  {getRegionName(selectedRegion)}
                </>
              )
            ) : (
              'United States'
            )}
          </Breadcrumbs>
        )}
      </TopLeft>
      <TopRight>
        <Breadcrumbs>
          {isMobile ? null : (
            <EmbedLink onClick={dispatchToggleEmbedDrawer}>
              Get Embed Code
            </EmbedLink>
          )}
          Updated <DataDateText />
        </Breadcrumbs>
      </TopRight>
    </Top>
  );
};

export default Header;
