import React, { useContext, useCallback, useMemo } from 'react';
import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import { rowHasMetric } from 'common/DataContext/util';
import { DispatcherContext } from 'common/DispatcherContext';
import { DateSelector } from '../SymptomSurveyViz/SymptomSurveyLayout/DateSelector';
import { metricsById } from 'common/MetricComplex/metrics';
import { MeasuredLineChart } from 'components/common/LineChart';
import MetricSelector from '../MetricSelector';
import { isMobile } from 'styles/styles';
import {
  LineChartContainer,
  Chart,
  LineChartHeader,
  DataDateFooter,
} from './styles';
import SymptomSurveyLineChartLegend from 'components/common/LineChart/SymptomSurveyLineChartLegend';

const LineChartWithMetricSelector = ({
  lineChartIndex,
  activeLineColor,
  statName,
  allowedMetricIDs,
  isEmbed,
}) => {
  const { lineChartSelectedMetrics, visibleDateInterval } = useContext(
    InteractionContext
  );
  const { dispatchUpdateLineChartMetric } = useContext(DispatcherContext);
  const allowedMetrics = allowedMetricIDs.map(
    (metricId) => metricsById[metricId]
  );
  const currentMetric = lineChartSelectedMetrics[lineChartIndex];

  const onChange = useCallback(
    (newMetric) => {
      dispatchUpdateLineChartMetric(lineChartIndex, newMetric);
    },
    [dispatchUpdateLineChartMetric, lineChartIndex]
  );

  // Filter mobility stats by visible date range.
  const { getVisibleDataForMetric } = useContext(DataContext);
  const filteredData = useMemo(() => getVisibleDataForMetric(currentMetric), [
    currentMetric,
    getVisibleDataForMetric,
  ]);

  const { parentTimeseries } = useContext(DataContext);

  // Filter parent timeseries by visible date range.
  const parentTimeseriesFiltered = useMemo(() => {
    if (!parentTimeseries || !visibleDateInterval) return null;
    const [minDate, maxDate] = visibleDateInterval;
    return parentTimeseries.filter(
      (row) =>
        row.date >= minDate &&
        row.date <= maxDate &&
        rowHasMetric(currentMetric, row)
    );
  }, [parentTimeseries, visibleDateInterval, currentMetric]);

  const hasParent =
    parentTimeseriesFiltered && parentTimeseriesFiltered.length > 0;

  return (
    <Chart isEmbed={isEmbed}>
      <LineChartHeader>
        <MetricSelector
          allowedMetrics={allowedMetrics}
          value={currentMetric}
          onChange={onChange}
        />
        <SymptomSurveyLineChartLegend
          activeLineColor={currentMetric.activeLineColor}
          filteredData={filteredData}
          hasParent={hasParent}
        />
      </LineChartHeader>
      <LineChartContainer>
        <MeasuredLineChart
          filteredData={filteredData}
          color={currentMetric.activeLineColor}
          currentMetric={currentMetric}
          parentTimeseriesFiltered={parentTimeseriesFiltered}
          hasParent={hasParent}
        />
      </LineChartContainer>
      <DataDateFooter>{currentMetric.dataDateBlurb}</DataDateFooter>
      {isMobile ? <DateSelector /> : null}
    </Chart>
  );
};

export default LineChartWithMetricSelector;
