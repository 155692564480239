import React from 'react';

import { Modal } from 'components/common/Modal';

const LoadingModal = () => (
  <Modal canClose={false} noBox={true}>
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </Modal>
);

export default LoadingModal;
