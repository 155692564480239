import React, { useContext } from 'react';

import { DataContext } from 'common/DataContext';
import { InteractionContext } from 'common/InteractionContext';
import { MeasuredLineChart } from 'components/common/LineChart';
import { LineChartContainer } from './styles';

const MovementLineChart = () => {
  const { getVisibleDataForMetric } = useContext(DataContext);
  const { activeMetric } = useContext(InteractionContext);
  return (
    <LineChartContainer>
      <MeasuredLineChart
        filteredData={getVisibleDataForMetric(activeMetric)}
        color={'black'}
        currentMetric={activeMetric}
      />
    </LineChartContainer>
  );
};

export default MovementLineChart;
