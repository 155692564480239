import styled from 'styled-components';

export const Button = styled.div`
  height: 26px;
  border-radius: 5px;
  border: solid 1px #ffffff;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 0 12px 0 12px;
`;
