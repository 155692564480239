import React from 'react';
import En from './survey';
import Fr from './translations/fr';
import Ar from './translations/ar';
import ZhCn from './translations/zh_cn';

export default function surveyData(lang) {
  switch (lang) {
    case 'fr':
      return <Fr />;
    case 'ar':
      return <Ar />;
    case 'zh_cn':
      return <ZhCn />;
    default:
      return <En />;
  }
}
