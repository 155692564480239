import { max, min } from 'd3-array';
import { join, dateSorter } from 'common/DataContext/util';
import {
  createRegionStatRow,
  regionStatRowJoinKey,
} from 'common/DataContext/model';
import { resultHasData } from './model';

export const filterResultsByMinExtents = (results) => {
  const resultsWithData = results.filter((r) => resultHasData(r));
  const minExtents = [
    max(resultsWithData.map((r) => r.dateExtent[0])),
    min(resultsWithData.map((r) => r.dateExtent[1])),
  ];

  // Filters rows by extents
  results.forEach((r) => {
    r.rows = resultHasData(r)
      ? r.rows.filter(
          (d) =>
            d.date.getTime() >= minExtents[0].getTime() &&
            d.date.getTime() <= minExtents[1].getTime()
        )
      : [];
  });

  return results;
};

const joinResultsByRegionId = (results) =>
  join(
    regionStatRowJoinKey,
    createRegionStatRow(),
    results.map((r) => r.rows)
  );

const addNameToRows = (rows, regionIdToName) =>
  rows.map((row) => ({
    ...row,
    name: regionIdToName[row.regionId],
  }));

// Converts the fetched results to rows for the app to use.
export const processFetchedResults = (results, regionIdToName) => {
  // This ensures we filter the data by the earliest available date from
  // each pipeline.
  filterResultsByMinExtents(results);

  let rows = joinResultsByRegionId(results).sort(dateSorter);
  rows = addNameToRows(rows, regionIdToName);
  return rows;
};
