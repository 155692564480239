import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  flex: 1 0 auto;
  background: none;
`;

export const SVG = styled.svg`
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
`;

export const SymptomLineChartSVG = styled.svg`
  font-size: 12px;
  line-height: 20px;
  color: #232323;
  position: absolute;

  ${(props) =>
    css`
      z-index: ${props.zIndex};
    `}
`;

// Invisible rect over line chart, to capture hover
// and trigger voronoi generation.
export const HoverDetectorRect = styled.rect`
  fill: none;

  /* Uncomment the line below to see this rect appear and disappear. */
  /* fill: rgba(255, 0, 0, 0.4); */

  pointer-events: all;
`;
