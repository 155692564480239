import styled, { css } from 'styled-components';

import { PRODUCTS, CURRENT_PRODUCT } from 'common/constants';
import './styles.scss';

const PopupHeadingByProduct = {
  [PRODUCTS.MOBILITY]: styled.div`
    font-weight: bold;
  `,
  [PRODUCTS.SYMPTOM]: styled.div`
    font-family: 'Facebook Reader';
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #232323;
    flex: 0 1 auto;
    padding: 4px 0px;
  `,
};

const StyledPopupByProduct = {
  [PRODUCTS.MOBILITY]: styled.div`
    color: #4a585e;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: none;

    display: flex;
    flex-direction: column;
  `,
  [PRODUCTS.SYMPTOM]: styled.div`
    background: white;
    border-radius: 6px !important; // override mapbox-gl.css
    animation: 0.3s cubic-bezier(0.17, 0.67, 0.68, 1.3) tooltip-pop;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-around;
  `,
};

const PopupEntryByProduct = {
  [PRODUCTS.MOBILITY]: styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
  `,
  [PRODUCTS.SYMPTOM]: styled.div`
    font-family: 'Facebook Reader';
    font-size: 12px;
    line-height: 14px;
    display: flex;
    flex: 0 1 auto;
    align-items: flex-start;
    padding: 2px 0px;
    width: 100%;
    justify-content: space-between;

    .hrr-tooltip-label.stay-at-home {
      font-style: italic;
    }

    .hrr-tooltip-value {
    }

    .hrr-tooltip-value-normal {
    }
  `,
};

const PopupEntryLabelByProduct = {
  [PRODUCTS.MOBILITY]: styled.div`
    padding-right: 20px;
  `,
  [PRODUCTS.SYMPTOM]: styled.div`
    ${(props) =>
      props.highlight
        ? css`
            color: #677b8c;
            font-weight: bold;
            line-height: 20px;
          `
        : css`
            color: #9ca8b1;
          `}
  `,
};

const PopupEntryValueByProduct = {
  [PRODUCTS.MOBILITY]: styled.div`
    font-weight: bold;
  `,
  [PRODUCTS.SYMPTOM]: styled.div`
    ${(props) =>
      props.normal
        ? css`
            background: white;
            color: #677b8c;
            border-radius: 2px;
            align-self: flex-end;
            padding: 1px 6px;
            font-style: normal;
            line-height: 12px;
            margin-left: 12px;
            text-align: right;
            min-width: 32px;
          `
        : css`
            background: #677b8c;
            color: white;
            border-radius: 4px;
            align-self: flex-end;
            padding: 4px 6px;
            font-style: normal;
            font-weight: bold;
            line-height: 12px;
            // height: 20px;
            margin-left: 12px;
            text-align: right;
            min-width: 32px;
          `}
  `,
};

export const PopupHeading = PopupHeadingByProduct[CURRENT_PRODUCT];
export const StyledPopup = StyledPopupByProduct[CURRENT_PRODUCT];
export const PopupEntry = PopupEntryByProduct[CURRENT_PRODUCT];
export const PopupEntryLabel = PopupEntryLabelByProduct[CURRENT_PRODUCT];
export const PopupEntryValue = PopupEntryValueByProduct[CURRENT_PRODUCT];

export const PopupSeparator = styled.div`
  margin-top: 0.4em;
  margin-bottom: 0.4em;
  border-top: 1px solid #e7eef2;
`;

export const PopupTitle = styled.strong`
  text-transform: uppercase;
  font-size: 0.9em;
  color: '#77868B';
  margin-top: 0.5em;
`;

export const PopupLeft = styled.div`
  padding-right: 20px;
`;

export const ViewNextLevel = styled.div`
  text-align: center;
  background: #f5f6f6;
  border-radius: 6px;
  margin-top: 12px;
`;

const NextLevelButtonByProduct = {
  [PRODUCTS.MOBILITY]: styled.button`
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    width: 100%;
    padding: 7px;
  `,
  [PRODUCTS.SYMPTOM]: styled.button`
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    width: 100%;
    padding: 7px;
    font-size: 11px;
    font-family: 'Facebook Reader';
  `,
};

export const NextLevelButton = NextLevelButtonByProduct[CURRENT_PRODUCT];
