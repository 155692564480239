import React from 'react';
import './map-fb-callback.scss';
import FB_logo from 'assets/svg/FACEBOOK_logo_BlueGray_RGB.svg';

const FBCallbackPill = () => {
  return (
    <div className={'map-fb-callback'}>
      <a
        href="https://dataforgood.fb.com/covid-symptoms-map/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={FB_logo}
          aria-label="Go to Map Source"
          alt="Go to Map Source"
        />
      </a>
    </div>
  );
};

export default FBCallbackPill;
