import React from 'react';

const YAxisLabel = ({ height, label }) => (
  <text
    className="axis-label"
    transform={`translate(-40,${height / 2}) rotate(-90)`}
    textAnchor="middle"
  >
    {label}
  </text>
);

export default YAxisLabel;
