import React, { useContext } from 'react';
import { DispatcherContext } from 'common/DispatcherContext';
import { DataContext } from 'common/DataContext';
import { Breadcrumb } from './styles';
import { breadcrumbsAncestors } from 'common/breadcrumbs';

export const InternationalBreadcrumbs = ({ regionId }) => {
  const { getRegionName } = useContext(DataContext);
  const { dispatchSelectRegion } = useContext(DispatcherContext);
  const regionIds = breadcrumbsAncestors(regionId);
  return regionIds.map((regionId, i) => {
    const handleClick = () => {
      dispatchSelectRegion(regionId);
    };
    return i === regionIds.length - 1 ? (
      getRegionName(regionId)
    ) : (
      <React.Fragment key={regionId}>
        <Breadcrumb onClick={handleClick}>{getRegionName(regionId)}</Breadcrumb>
        {' > '}
      </React.Fragment>
    );
  });
};
