import React, { useContext } from 'react';

import { formatDate } from 'components/common/LineChart/util';
import { DataContext } from 'common/DataContext';
import { InteractionContext } from 'common/InteractionContext';

const DataDateText = () => {
  const { getDataDateForStat } = useContext(DataContext);
  const {
    activeMetric: { statName },
  } = useContext(InteractionContext);

  const date = getDataDateForStat(statName);

  return <span>{formatDate(date)}</span>;
};

export default DataDateText;
