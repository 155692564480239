import React from 'react';
import { DateSelector } from './DateSelector';
import { FloatingCharts, MobileChart, EmbedChart } from '../styles';
import LineChartWithMetricSelector from '../LineChartWithMetricSelector';
import { LINE_CHARTS_CONFIG, METRIC_IDS } from 'common/constants';
import { isMobile } from 'styles/styles';
import { GovInterventions } from 'components/symptom/GovInterventions/index.js';

const RightPanel = ({ isEmbed }) => {
  return isEmbed ? (
    <EmbedChart>
      <LineChartWithMetricSelector
        key={'embed-chart'}
        lineChartIndex={0}
        allowedMetricIDs={Object.values(METRIC_IDS)}
        isEmbed={true}
      />
      <GovInterventions isEmbed={isEmbed} />
    </EmbedChart>
  ) : isMobile ? (
    <MobileChart>
      <LineChartWithMetricSelector
        key={'mobile-chart'}
        lineChartIndex={0}
        allowedMetricIDs={Object.values(METRIC_IDS)}
      />
      <GovInterventions />
    </MobileChart>
  ) : (
    <FloatingCharts>
      <DateSelector />
      {LINE_CHARTS_CONFIG.map((lineChartConfig, lineChartIndex) => (
        <LineChartWithMetricSelector
          key={lineChartConfig.id}
          lineChartIndex={lineChartIndex}
          {...lineChartConfig}
        />
      ))}
      <GovInterventions />
    </FloatingCharts>
  );
};

export default RightPanel;
