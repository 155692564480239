import React, { useContext, useCallback } from 'react';
import { InteractionContext } from 'common/InteractionContext';
import { DispatcherContext } from 'common/DispatcherContext';
import { yFormat } from 'components/common/LineChart/util';
import { breadcrumbsAncestors } from 'common/breadcrumbs';

export const Marks = ({
  data,
  xScale,
  yScale,
  colorScale,
  xValue,
  yValue,
  colorValue,
  tooltipFormat,
  innerWidth,
  margin,
}) => {
  const { activeRow, activeMetric, selectedRegion } = useContext(
    InteractionContext
  );

  const {
    dispatchActivateRegion,
    dispatchDeactivateRegion,
    dispatchUserSelectedAdmin0Region,
    dispatchUserSelectedAdmin1Region,
    dispatchUserSelectedAdmin2Region,
  } = useContext(DispatcherContext);

  const onClick = useCallback(
    (regionId) => {
      const breadcrumbRegionIds = breadcrumbsAncestors(selectedRegion);
      let handler;
      if (breadcrumbRegionIds.length === 1) {
        handler = dispatchUserSelectedAdmin0Region;
      } else if (breadcrumbRegionIds.length === 2) {
        handler = dispatchUserSelectedAdmin1Region;
      } else if (breadcrumbRegionIds.length === 3) {
        handler = dispatchUserSelectedAdmin2Region;
      } else {
        throw new Error('Cannot determine region hierarchy');
      }
      handler(regionId);
    },
    [
      dispatchUserSelectedAdmin0Region,
      dispatchUserSelectedAdmin1Region,
      dispatchUserSelectedAdmin2Region,
      selectedRegion,
    ]
  );

  return data.map((d) => {
    const isActive = activeRow && yValue(d) === yValue(activeRow);
    const isNegative = xValue(d) < 0;
    const markColor = colorScale(colorValue(d));
    const yTranslate = yScale(yValue(d)) + yScale.bandwidth() / 2;

    // The color of the "handle" or "stalk" of the lollipop.
    const handleColor = activeRow
      ? isActive
        ? '#374c67'
        : '#f4f6f5'
      : '#e5ecf0';

    // The color of the thing at the end of the lollipop.
    const circleColor = activeRow
      ? isActive
        ? markColor
        : '#e8eef2'
      : markColor;

    return (
      <g
        key={d.regionId}
        transform={`translate(0,${yTranslate})`}
        onMouseOver={() => {
          dispatchActivateRegion(d.regionId);
        }}
        onMouseOut={() => {
          dispatchDeactivateRegion(d.regionId);
        }}
        style={{ cursor: 'pointer' }}
        onClick={() => onClick(d.regionId)}
      >
        <line
          x1={xScale(0)}
          x2={xScale(xValue(d))}
          stroke={handleColor}
          strokeWidth={2}
        />
        <circle
          cx={xScale(xValue(d))}
          fill={circleColor}
          r={5}
          stroke={`rgba(0,0,0,${isActive ? 0.5 : 0.1})`}
        />
        <text
          x={xScale(0) - 5 * (isNegative ? -1 : 1)}
          className="lollipop-label"
          textAnchor={isNegative ? 'start' : 'end'}
          style={{
            fontWeight: isActive ? 'bold' : 'normal',
            userSelect: 'none',
            pointerEvents: 'none',
          }}
        >
          {yValue(d)}
        </text>
        {isActive ? (
          <text
            x={xScale(xValue(d)) + 7 * (isNegative ? -1 : 1)}
            className="lollipop-label"
            textAnchor={isNegative ? 'end' : 'start'}
            style={{
              userSelect: 'none',
              pointerEvents: 'none',
            }}
          >
            {yFormat(activeMetric)(xValue(d))}
          </text>
        ) : null}
        <rect
          y={-yScale.bandwidth() / 2}
          x={-margin.left}
          width={innerWidth + margin.left + margin.right}
          height={yScale.bandwidth()}
          fill="none"
          style={{ pointerEvents: 'all' }}
          opacity={isActive ? 1 : 0.25}
        />
      </g>
    );
  });
};
