import React from 'react';
import './styles.scss';
import classNames from 'classnames';

export default function glossary(showGlossary, onCloseGlossary) {
  // prettier-ignore
  return (
    <div className={classNames('map-glossary', {hidden: !showGlossary,})}>
      <div className="glossary-header">
        <h3>Glossary of Map Terms</h3>
        <div className='close' onClick={onCloseGlossary}>
          <img alt="" src="svg/icon-close.svg" />
        </div>
      </div>
      <ul className="glossary-body">
        <li>
          <b className="term">COVID-19 symptoms</b> - COVID-19
          symptoms include a fever and either a cough, shortness of breath or
          difficulty breathing as defined in the CMU and UMD surveys.
        </li>
        <li>
          <b className="term">Flu symptoms</b> - Flu symptoms include
          a fever and either a sore throat or cough as defined in the CMU and
          UMD surveys.
        </li>
        <li>
          <b className="term">Percentage of people with symptoms</b> -
          The estimated percentage of people who have experienced symptoms based
          on a 7-day rolling average.
        </li>
        <li>
          <b className="term">Total population</b> - The total number
          of people in a location.
        </li>
        <li>
          <b className="term">Population density per sq km</b> - The
          number of people per square kilometer of land area.
        </li>
        <li>
          <b className="term">Percentage of people age 65+</b> - The
          percentage of people age 65 or over.
        </li>
        <li>
          <b className="term">Total confirmed COVID-19 cases</b> - The
          total number of confirmed cases of COVID-19.
        </li>
        <li>
          <b className="term">Change in movement</b> - The percentage
          of change in people's movement compared to the average for the same
          day of the week in February 2020.
        </li>
        <li>
          <b className="term">
            Percentage of people staying in place
          </b><span> </span>
          - The percentage of people who remained in one location for 24 hours.
        </li>
        <li>
          <b className="term">School closure</b> - The date the
          location closed public schools.
        </li>
        <li>
          <b className="term">State of emergency</b> - The date the
          location was declared in a state of emergency.
        </li>
        <li>
          <b className="term">Physical distancing</b> - The date the
          location started physical distancing. Physical distancing is the
          practice of keeping a safe, 6-foot distance between people to reduce
          the spread of COVID-19. Physical distancing data include policies put
          in place by governments to stay at home and shelter in place. For
          non-US locations, researchers can see a more comprehensive dataset
          that includes physical distancing data and other public health
          measures from the<span> </span>
          <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/phsm">
            World Health Organization
          </a>
          .
        </li>
      </ul>
    </div>
  );
}
