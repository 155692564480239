import { useState, useEffect } from 'react';

export const useDebounced = (value, milliseconds = 200) => {
  const [debouncedValue, setDebouncedValue] = useState();
  useEffect(() => {
    //console.log('value changed upstream');
    const timeout = setTimeout(() => {
      //console.log('value changed downstream');
      setDebouncedValue(value);
    }, milliseconds);
    return () => clearTimeout(timeout);
  }, [value, milliseconds]);
  return debouncedValue;
};
