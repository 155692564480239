import styled from 'styled-components';
//import { LargeIcon } from '../pages/styles';

const hoverBackground = 'rgba(0, 0, 0, 0.05)';
const activeBackground = 'rgba(0, 0, 0, 0.1)';
export const Clickable = styled.div`
  &:hover {
    background-color: ${hoverBackground};
  }
  &:active {
    background-color: ${activeBackground};
  }
  cursor: pointer;
  user-select: none;
`;

export const Icon = styled(Clickable)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LargeIcon = styled(Icon)`
  height: 40px;
  padding-right: ${(props) => (props.rightmost ? 10 : 7)}px;
  padding-left: ${(props) => (props.leftmost ? 10 : 7)}px;
`;

export const Z_WAY_WAY_ABOVE = 10;
export const Z_WAY_ABOVE = 2;
export const Z_ABOVE = 1;
export const Z_BELOW = -1;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: ${Z_WAY_WAY_ABOVE};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4a585e;
  font-size: 13px;
`;

export const Box = styled.div`
  flex: 1;
  max-width: 570px;
  ${(props) =>
    `${
      props.noBox
        ? ``
        : `
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  `
    }`}
  padding: 15px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const CloseIcon = styled(LargeIcon)`
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: ${(props) => props.theme.borderRadiusLarge}px;
`;

export const Message = styled.div``;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: normal;
  color: #0972a6;
`;

export const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
