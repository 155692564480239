import React, { useContext } from 'react';
import { Popup } from 'react-mapbox-gl';

import { DataContext } from 'common/DataContext';
import { InteractionContext } from 'common/InteractionContext';
import { StyledPopup } from './styles';
import { isMobile } from 'styles/styles';
import MapPopupHeading from './MapPopupHeading';
import MapPopupContents from './MapPopupContents';
import MapPopupMobileButton from './MapPopupMobileButton';
import { PRODUCTS, CURRENT_PRODUCT } from 'common/constants';

const ActiveDataMapPopup = () => {
  const {
    activeRow: { regionId },
  } = useContext(InteractionContext);
  const {
    data: { getCoordinates },
  } = useContext(DataContext);
  const showMobileButton = isMobile && CURRENT_PRODUCT === PRODUCTS.MOBILITY;
  return (
    <Popup coordinates={getCoordinates(regionId)} offset={5}>
      <StyledPopup>
        <MapPopupHeading />
        <MapPopupContents />
        {showMobileButton ? (
          <MapPopupMobileButton activeRow={regionId} />
        ) : null}
      </StyledPopup>
    </Popup>
  );
};

export default ActiveDataMapPopup;
