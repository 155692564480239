import React from 'react';

export const PageRightSVG = () => (
  <g transform="translate(-3, -5)">
    <path
      fill="#0972A6"
      stroke="#0972A6"
      d="M.872.629l.5-.5L5.613 4.37 1.37 8.614l-.5-.5L4.615 4.37.872.63z"
    />
  </g>
);
