import styled, { css } from 'styled-components';

const Heading = css`
  font-size: 2.25rem;
  font-family: lora, sans-serif;
  font-weight: 400;
`;

export const Heading1 = styled.h1`
  ${Heading}
  margin: 0;
  line-height: 1.25;
  color: #313131;
  text-rendering: optimizeLegibility;
  margin-bottom: 20px;
`;

export const Heading2 = styled.h2`
  ${Heading}
  font-size: 1.5rem;
  margin-top: 1rem;
`;

export const EmphasizedParagraph = styled.p`
  font-style: italic;
`;

export const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
`;

const TextBlockBase = css`
  width: 90%;
  margin: auto;
`;

export const TextBlock = styled.div`
  ${TextBlockBase}
`;

export const ConstrainedTextBlock = styled.div`
  ${TextBlockBase}
  max-width: 950px;
`;
