import React, { useEffect, useContext } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import lookup from 'country-code-lookup';
import './mapbox-gl-geocoder.scss';
import { accessToken } from 'components/common/Map/mapConfig';
import { isMobile } from 'styles/styles';
import { DataContext } from 'common/DataContext';
import { DispatcherContext } from 'common/DispatcherContext';
import { supportedCountries } from 'data/supportedCountries';
import { GeocoderContainer } from './styles';
import { USA } from 'common/constants';
import { isAdmin1, isAdmin0 } from 'common/DataContext/accessors';

export const Geocoder = () => {
  const { getRegionId } = useContext(DataContext);

  const {
    dispatchUserSelectedAdmin0Region,
    dispatchUserSelectedAdmin1Region,
  } = useContext(DispatcherContext);

  function renderResult(item) {
    return (
      "<div class='geocoder-dropdown-item'><span class='geocoder-dropdown-text'>" +
      item.place_name +
      '</span></div>'
    );
  }

  const collapsed = !isMobile;
  const placeholder = 'Enter a ZIP code, city or county';
  const types = 'country,region,place,district,postcode';
  const clearAndBlurOnEsc = true;
  const countries = Object.keys(supportedCountries)
    .map((regionId) => {
      const lookupResult = lookup.byIso(regionId);
      if (!lookupResult) {
        console.error(`Could not look up country code for ${regionId}`);
        return null;
      } else {
        return lookupResult.iso2;
      }
    })
    .filter((c) => c !== null)
    .join(',');

  // Documentation is [here](https://github.com/mapbox/mapbox-sdk-js/blob/main/docs/services.md#forwardgeocode)
  const geocoder = new MapboxGeocoder({
    accessToken: accessToken,
    collapsed: collapsed,
    placeholder: placeholder,
    types: types,
    clearAndBlurOnEsc: clearAndBlurOnEsc,
    countries: countries,
    render: renderResult,
  });

  const getRegionIdFromShortCode = (short_code) => {
    const iso2 = short_code;
    const lookupResult = lookup.byIso(iso2);
    if (!lookupResult) {
      console.error(
        `Could not find country code for short code: ${JSON.stringify(iso2)}`
      );
    }
    return lookupResult.iso3;
  };

  const handleCountrySearchResult = (result) => {
    const regionId = getRegionIdFromShortCode(result.properties.short_code);
    dispatchUserSelectedAdmin0Region(regionId, true);
  };

  const handleRegionSearchResult = (result) => {
    let countryId, regionId;
    result.context.forEach((currContext) => {
      if (currContext.id.startsWith('country')) {
        countryId = getRegionIdFromShortCode(currContext.short_code);
      } else {
        //console.log("unhandled context:");
        //console.log("currContext");
        //console.log(currContext);
      }
    });
    if (countryId === USA) {
      // Attempts to construct the regionId with a name lookup
      regionId = getRegionId(result.text);
      console.log(`Trying ${result.text}`);
      if (!regionId) {
        console.warn(
          `Could not determine regionId for result: ${JSON.stringify(result)}`
        );
        regionId = countryId;
      }
    } else {
      regionId = countryId;
    }

    if (isAdmin1(regionId)) {
      dispatchUserSelectedAdmin1Region(regionId, true);
    } else if (isAdmin0(regionId)) {
      dispatchUserSelectedAdmin0Region(regionId, true);
    }
  };

  const handlePostcodeSearchResult = (result) => {
    let countryId, regionId;
    result.context.forEach((currContext) => {
      if (currContext.id.startsWith('country')) {
        countryId = getRegionIdFromShortCode(currContext.short_code);
      }
    });

    if (countryId === USA) {
      // Then `region` will be a US state
      result.context.forEach((currContext) => {
        if (currContext.id.startsWith('region')) {
          regionId = getRegionId(currContext.text);
          if (!regionId) {
            console.error(`Could not find regionId from ${currContext.text}`);
            regionId = countryId;
          }
        }
      });
    } else {
      regionId = countryId;
    }

    if (isAdmin1(regionId)) {
      dispatchUserSelectedAdmin1Region(regionId, true);
    } else if (isAdmin0(regionId)) {
      dispatchUserSelectedAdmin0Region(regionId, true);
    }
  };

  // Siphon results to determine what level the feature is
  // Also determines the hierarchy so we ensure we select the correct feature in the case of dupe names
  // Results come in as outlined here: https://docs.mapbox.com/api/search/#geocoding-response-object
  geocoder.on('result', function ({ result }) {
    if (result.place_type.includes('country')) {
      handleCountrySearchResult(result);
    } else if (result.place_type.includes('postcode')) {
      handlePostcodeSearchResult(result);
    } else {
      handleRegionSearchResult(result);
    }
  });

  useEffect(() => {
    geocoder.addTo('#geocoderContainer');
    // Haxx to only call the useEffect on the inital render
    // eslint-disable-next-line
  }, []);

  return <GeocoderContainer id="geocoderContainer" />;
};
