import React from 'react';
import 'jquery';
import 'bootstrap/js/dist/collapse.js';

import HamburgerIcon from 'assets/svg/noun_menu_627958.svg';

import './styles.scss';

const mainSiteURL = 'https://www.covid19mobility.org/';

const NavigationHeader = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light compensate-for-scrollbar"
      id="navbar-main"
    >
      <div className="container">
        <div className="d-none d-lg-inline-flex">
          <a className="navbar-brand" href={mainSiteURL}>
            COVID-19 Mobility Data Network
          </a>
        </div>
        <button
          type="button"
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbar-content"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span>
            <img src={HamburgerIcon} alt="open menu" />
          </span>
        </button>
        <div className="navbar-brand-mobile-wrapper d-inline-flex d-lg-none">
          <a className="navbar-brand" href={mainSiteURL}>
            COVID-19 Mobility Data Network
          </a>
        </div>
        <div
          className="navbar-collapse main-menu-item collapse justify-content-start"
          id="navbar-content"
        >
          <ul className="navbar-nav d-md-inline-flex">
            <li className="nav-item">
              <a className="nav-link" href={`${mainSiteURL}/#dashboards`}>
                <span>Visualization Tools</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={`${mainSiteURL}/#technical_resources`}
              >
                <span>Resources</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`${mainSiteURL}/#about01`}>
                <span>About</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`${mainSiteURL}/partners/`}>
                <span>Partners</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`${mainSiteURL}/#contact`}>
                <span>Contact</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://ccdd.hsph.harvard.edu/covid-19-research/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>CCDD</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://3.basecamp.com/4418925/projects"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Member Login</span>
              </a>
            </li>
          </ul>
        </div>
        <ul className="nav-icons navbar-nav flex-row ml-auto d-flex pl-md-2"></ul>
      </div>
    </nav>
  );
};

export default NavigationHeader;
