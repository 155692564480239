import React, { useContext } from 'react';
import NoDataMapPopup from './NoDataMapPopup';
import ActiveDataMapPopup from './ActiveDataMapPopup';
import { InteractionContext } from 'common/InteractionContext';

const MapPopup = () => {
  const { activeRow, activeNoDataRow } = useContext(InteractionContext);

  return activeRow || activeNoDataRow ? (
    activeRow ? (
      <ActiveDataMapPopup />
    ) : (
      <NoDataMapPopup />
    )
  ) : null;
};

export default MapPopup;
