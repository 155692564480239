import styled from 'styled-components';

export const ScatterplotContainer = styled.div`
  ${(props) =>
    props.isMobile
      ? `
    width: 100%;
    height: 311px;
  `
      : `
  `}
`;
