import React from 'react';
import '../styles.scss';
import classNames from 'classnames';

export default function glossary(showGlossary, onCloseGlossary) {
  // prettier-ignore
  return (
<div className={classNames('map-glossary', {hidden: !showGlossary,})}>
      <div className="glossary-header"><h3>地图术语表</h3><div className='close' onClick={onCloseGlossary}>
          <img alt="" src="svg/icon-close.svg" />
        </div>
      </div><ul className="glossary-body">
        <li><b className="term">新冠病毒感染症状</b> - 新冠病毒感染
          症状包括：发烧、咳嗽、呼吸急促或
          呼吸困难（详见卡耐基·梅隆大学和马里兰大学所提供问卷中的定义）。</li>
        <li><b className="term">流感症状</b> - 流感症状包括：
          发热、咽喉痛、咳嗽（详见卡耐基·梅隆大学和
          马里兰大学所提供问卷中的定义）。</li>
        <li><b className="term">有症状的人口百分比</b> -
          采用 7 日滚动平均值预估的
          有症状人口百分比。</li>
        <li><b className="term">总人口数</b> - 指定地区的人口
          总数。</li>
        <li><b className="term">每平方公里人口密度</b> - 每平方公里
          土地面积的人口数量。</li>
        <li><b className="term">65+ 岁人口百分比</b> - 年满 65 岁的
          人口所占百分比。</li>
        <li><b className="term">新冠确诊病例总数</b> - 确诊感染
          新冠病毒的总人数。</li>
        <li><b className="term">人口流动变化</b> - 与 2020 年 2 月
          同一周同一天的平均人口数相比，
          统计日当天人口流动的百分比。</li>
        <li><b className="term">
            未流动人口百分比
          </b><span> </span>
          - 指定地区 24 小时内的未流动人口百分比。</li>
        <li><b className="term">学校关闭日期</b> - 指定地区关闭公立学校
          的日期。</li>
        <li><b className="term">紧急状态发布日期</b> - 指定地区宣布
          进入紧急状态的日期。</li>
        <li><b className="term">安全距离实施日期</b> - 指定地区开始实施
          “保持安全距离”措施的日期。保持安全距离是指
          人与人之间保持 6 英尺的安全距离，以此减缓
          新冠疫情蔓延的措施。这一数据还包括政府
          发布的居家隔离令和就地避难令。对于
          美国以外的地区，研究人员从世界卫生组织了解到更为全面的数据集，
          其中包括各地实施安全距离的相关数据以及其他
          公共卫生措施<span> </span>
          <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/phsm">
            
          </a>
          。</li>
      </ul>
    </div>
  
);
}
