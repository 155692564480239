import React, { useContext } from 'react';

import { InteractionContext } from 'common/InteractionContext';
import MetricEntry from './MetricEntry';

const MapPopupCurrentMetricEntry = () => {
  const { activeRow, activeMetric } = useContext(InteractionContext);
  return (
    <MetricEntry
      highlight={true}
      normal={false}
      metric={activeMetric}
      row={activeRow}
    />
  );
};

export default MapPopupCurrentMetricEntry;
