import { useContext, useRef, useCallback } from 'react';

import { InteractionContext } from 'common/InteractionContext';
import { DispatcherContext } from 'common/DispatcherContext';
import { isAdmin0, isAdmin1 } from 'common/DataContext/accessors';
import { getRegionIdFromFeature } from './util';
import { WORLD } from 'common/constants';
import { addPostfix } from 'common/DataContext/util';
import { proj, tileAdminKeys } from './mapConfig';

// Implements a timeout for calling `onOutsideClick` (i.e. detecting a click
// in the ocean) because Mapbox does not provide it for us.  The Mapbox
// container reports clicks with no `type` everytime, so just need to clear
// the timeout when a layer click happens, and if not then the `onOutsideClick`
// handler will be called.
export const useDetectOutsideClick = function (onOutsideClick) {
  // useRef stores a reference to the timer, necessary to detect when outside
  // the map was clicked, because the Mapbox component does not fire a
  // unique click event when clicking outside a map layer.
  const clickOutsideTimeout = useRef(null);

  const onEveryClick = (e) => {
    if (e.type && e.type === 'click') {
      clearTimeout(clickOutsideTimeout.current);
    } else {
      // Called each time the map is clicked, regardless of a map layer or
      // outside a layer, because map container component sends a null
      // click event always
      clickOutsideTimeout.current = setTimeout(onOutsideClick, 100);
    }
  };

  return {
    onEveryClick,
  };
};

const useMapRegionOnClick = function () {
  const {
    dispatchUserSelectedAdmin0Region,
    dispatchUserSelectedAdmin1Region,
    dispatchUserSelectedAdmin2Region,
    dispatchUserSelectedParentRegion,
  } = useContext(DispatcherContext);
  const { onEveryClick } = useDetectOutsideClick(
    dispatchUserSelectedParentRegion
  );
  const onClick = useCallback(
    (e) => {
      onEveryClick(e);
      if (e.type && e.type === 'click') {
        if (e.features) {
          const feature = e.features[0];
          const regionId = getRegionIdFromFeature(feature);
          //console.log(
          //'onClick',
          //feature.properties[tileAdminKeys[proj].typekey],
          //feature
          //);
          switch (feature.properties[tileAdminKeys[proj].typekey]) {
            case tileAdminKeys[proj].admin0type:
              dispatchUserSelectedAdmin0Region(regionId);
              break;
            case tileAdminKeys[proj].admin1type:
              dispatchUserSelectedAdmin1Region(regionId);
              break;
            case tileAdminKeys[proj].admin2type:
              dispatchUserSelectedAdmin2Region(regionId);
              break;

            default:
              break;
          }
        }
      }
    },
    [
      dispatchUserSelectedAdmin0Region,
      dispatchUserSelectedAdmin1Region,
      dispatchUserSelectedAdmin2Region,
      onEveryClick,
    ]
  );
  return onClick;
};

export const useMobileMapInteractions = function () {
  const onClick = useMapRegionOnClick();

  return { onClick };
};

export const useDesktopMapInteractions = function () {
  const { selectedRegion } = useContext(InteractionContext);
  const { dispatchActivateRegion, dispatchDeactivateRegion } = useContext(
    DispatcherContext
  );

  const onClick = useMapRegionOnClick();

  const onMouseMove = useCallback(
    (e) => {
      // mouseMove will trigger on all levels of the admin hierarchy,
      // even ones not currently visible on the map
      e.features.forEach((feature) => {
        const regionId = getRegionIdFromFeature(feature);
        if (selectedRegion) {
          if (
            // If hover was over admin0 and currently selected is world
            selectedRegion === WORLD &&
            feature.properties[tileAdminKeys[proj].typekey] ===
              tileAdminKeys[proj].admin0type
          ) {
            // Activates admin0 region
            dispatchActivateRegion(regionId);
          } else if (
            // If hover was over admin1 and currently selected is admin0
            isAdmin0(selectedRegion) &&
            feature.properties[tileAdminKeys[proj].typekey] ===
              tileAdminKeys[proj].admin1type
          ) {
            if (
              // If currently hovered is child of currently selected admin0
              feature.properties[tileAdminKeys[proj].admin1_parent] ===
              addPostfix(selectedRegion)
            ) {
              // Activates admin1 region
              dispatchActivateRegion(regionId);
            } else {
              dispatchDeactivateRegion();
            }
          } else if (
            // If currently selected is admin1 and hovered admin2
            isAdmin1(selectedRegion) &&
            feature.properties[tileAdminKeys[proj].typekey] ===
              tileAdminKeys[proj].admin2type
          ) {
            // if hovered admin2 is child of current admin1
            if (
              feature.properties[tileAdminKeys[proj].admin2_parent] ===
              addPostfix(selectedRegion)
            ) {
              // activate admin1
              dispatchActivateRegion(regionId);
            } else {
              dispatchDeactivateRegion();
            }
          }
        } else if (!selectedRegion) {
          //} else if (!selectedRegion && feature.properties.type === 'state') {
          dispatchActivateRegion(regionId);
        }
      });
    },
    [selectedRegion, dispatchDeactivateRegion, dispatchActivateRegion]
  );
  // Tricky naming, not the usual 'mouseOut'. See Layer section in:
  // http://alex3165.github.io/react-mapbox-gl/documentation
  const onMouseLeave = useCallback(
    (e) => {
      dispatchDeactivateRegion();
    },
    [dispatchDeactivateRegion]
  );

  return { onClick, onMouseMove, onMouseLeave };
};
