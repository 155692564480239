import React from 'react';
import { Heading2, EmphasizedParagraph } from 'components/mobility/Text/styles';
import { Modal } from 'components/common/Modal';
import locale from 'common/locale';

const LandingModal = ({ isEmbed, showLandingModal, dismissModal }) => {
  return showLandingModal ? (
    <Modal onClose={dismissModal}>
      <Modal.Dialog>
        <Heading2>{locale('i18n_landingModalHeading_exclude')}</Heading2>
        <EmphasizedParagraph>
          {locale('i18n_landingModalPara_exclude')}{' '}
          <a
            href="https://www.directrelief.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            {locale('i18n_directRelief_exclude')}
          </a>{' '}
          {locale('i18n_andResearcherFrom_exclude')}{' '}
          <a
            href="https://www.hsph.harvard.edu"
            target="_blank"
            rel="noopener noreferrer"
          >
            {locale('i18n_harvardChan_exclude')}
          </a>
          {locale('i18n_usingAggregated_exclude')}{' '}
          <a
            href="https://dataforgood.fb.com/docs/covid19/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {locale('i18n_fbD4GProg_exclude')}
          </a>
          .
        </EmphasizedParagraph>
        <EmphasizedParagraph>
          Boundaries for disputed administrative areas are from{' '}
          <a
            href="https://www.naturalearthdata.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Natural Earth
          </a>
          . All other administrative areas represented in this map are from the{' '}
          <a
            href="https://www.census.gov/geographies/mapping-files/time-series/geo/cartographic-boundary.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            US Census Bureau
          </a>{' '}
          and the{' '}
          <a href="https://gadm.org" target="_blank" rel="noopener noreferrer">
            Database of Global Administrative Areas
          </a>
          .
        </EmphasizedParagraph>
      </Modal.Dialog>
    </Modal>
  ) : null;
};

export default LandingModal;
