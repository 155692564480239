import React from 'react';
import '../styles.scss';

export default function mapExplainer(handleClick) {
  // prettier-ignore
  return (
<div className="map-explainer">
      <div className="explainer-header"><h3>关于此地图</h3><div id="r2"/>
      </div><ul className="explainer-body">
        <li>此地图使用的公开数据经过汇总处理，以此保护用户隐私，
          其目的并非用于诊断，或作为指导人们出行的依据。</li>
        <li>如需详细了解这些数据，请参阅我们的<span> </span>
          <a href="./survey_and_map_data.html">问卷调查和地图数据</a>页面。</li>
        <li>有争议的行政区域边界数据来源于<span> </span>
          <a href="https://www.mapbox.com/" target="_blank" rel="noopener noreferrer">
            Mapbox
          </a>
          。此地图中列出的所有其他行政区域数据均来源于<span> </span>
          <a href="https://www.census.gov/geographies/mapping-files/time-series/geo/cartographic-boundary.html" target="_blank" rel="noopener noreferrer">
            美国人口普查局 (US Census Bureau)
          </a>
          <span>和</span>
          <a href="https://gadm.org/" target="_blank" rel="noopener noreferrer">
            全球行政区域数据库 (Database of Global Administrative Areas)
          </a>
          。</li>
      </ul>
    </div>
);
}
