// @flow
import { isSymptomSurvey } from 'styles/styles';
import locale from 'common/locale';
import {
  METRIC_IDS,
  formatTwoDecimalPercent,
  formatBigNumber,
  CURRENT_PRODUCT,
  PRODUCTS,
  STAT_NAMES,
} from 'common/constants';
import type { Metric } from 'types';
import { isUSStateFIPS } from 'common/DataContext/accessors';

const mobilityDataSource = () => 'Data For Good';
const symptomSurveyDataSource = (regionId: string) =>
  regionId === 'WORLD'
    ? 'Carnegie Mellon University (US) & University of Maryland (non-US)'
    : regionId === 'USA' || isUSStateFIPS(regionId)
    ? 'Carnegie Mellon University'
    : 'University of Maryland';

const blueColors = [
  '#FDAE6B',
  '#FEE6CE',
  '#EFF3FF',
  '#C6DBEF',
  '#9ECAE1',
  '#6BAED6',
  '#4292C6',
  '#2171B5',
  '#084594',
].reverse();

const greenColors = [
  '#D8F5F3',
  '#A0E1DC',
  '#68C9C1',
  '#26A49A',
  '#077B72',
  '#02514B',
  '#023733',
];

const shortForm = (str) => str && str.replace('Percentage', '%');

const mobilityMetrics: Array<Metric> = [
  {
    id: METRIC_IDS.MOBILITY,
    label: locale('i18n_change_mov'),
    labelForMapTooltip: locale('i18n_change_mov'),
    labelForLegend: locale('i18n_change_mov'),
    urlKey: 'mobility',
    column: 'all_day_bing_tiles_visited_relative_change_day_of_week',
    breakpoints:
      CURRENT_PRODUCT === PRODUCTS.SYMPTOM
        ? [-100, -75, -50, -25, 0, 25, 50].map((n) => n / 100)
        : //: [0.1, 0, -0.1, -0.2, -0.3, -0.4, -0.5, -0.6],
          [0.1, 0, -0.1, -0.2, -0.3, -0.4, -0.5, -0.6].reverse(),
    bivariateBreakpoints: [-0.19, 0],
    colors:
      CURRENT_PRODUCT === PRODUCTS.SYMPTOM
        ? [
            '#D8F5F3',
            '#A0E1DC',
            '#68C9C1',
            '#26A49A',
            '#077B72',
            '#02514B',
            '#023733',
          ]
        : blueColors,
    blurb:
      'This chart measures the change in frequency of travel (relative to the day of the week) between the last two weeks of February and today. The longer the bar, the less frequently people in that region have traveled outside their home area between February 29 and today.',
    display: formatTwoDecimalPercent,
    dataNotAvailableBlurb: 'Movement data not available',
    dataSource: mobilityDataSource,
    lessIndicator: 'Less Movement',
    moreIndicator: 'More Movement',
    dataDateBlurb: locale('i18n_date_blurb4'),
    statName: STAT_NAMES.MOVEMENT,
  },
  {
    id: METRIC_IDS.STAY_PUT,
    label: locale('i18n_per_in_pl'),
    labelForMapTooltip: shortForm(locale('i18n_per_in_pl')),
    labelForLegend: locale('i18n_per_in_pl'),
    urlKey: 'stay-put',
    column: 'all_day_ratio_single_tile_users',
    breakpoints:
      CURRENT_PRODUCT === PRODUCTS.SYMPTOM
        ? [27, 30, 32, 33, 34, 35].map((n) => n / 100)
        : [0.1, 0.15, 0.2, 0.25, 0.3, 0.35],
    bivariateBreakpoints: [0.182, 0.24],
    colors:
      CURRENT_PRODUCT === PRODUCTS.SYMPTOM
        ? [
            '#D8F5F3',
            '#A0E1DC',
            '#68C9C1',
            '#26A49A',
            '#077B72',
            '#02514B',
            '#023733',
          ]
        : greenColors,
    flipSort: true,
    blurb:
      'This chart measures the percentage of people who remained in one location for 24 hours.',
    display: formatTwoDecimalPercent,
    dataNotAvailableBlurb: 'Movement data not available',
    dataSource: mobilityDataSource,
    lessIndicator: 'Fewer Staying in Place',
    moreIndicator: 'More Staying in Place',
    dataDateBlurb: locale('i18n_date_blurb3'),
    statName: STAT_NAMES.MOVEMENT,
  },
  //  {label:'Location history users',column:'num_location_history_people' }
];

const symptomSurveyMetrics: Array<Metric> = [
  {
    id: METRIC_IDS.SYMPTOMS_COVID,
    label: locale('i18n_covid_symp'),
    labelForMapTooltip: locale('i18n_covid_like_tip'),
    labelForLegend: locale('i18n_percent_with_s'),
    urlKey: 'covid',
    column: 'pctCLI',
    breakpoints: [0.02, 0.05, 0.07, 0.1, 0.12, 0.15],
    bivariateBreakpoints: [0.0025, 0.0052],
    colors: [
      '#FFE2E5',
      '#FFC5CA',
      '#FEA9B0',
      '#F58E97',
      '#EB6570',
      '#D93E4B',
      '#C22734',
    ],
    flipSort: true,
    blurb: locale('i18n_blurb1_exclude'),
    display: formatTwoDecimalPercent,
    dataNotAvailableBlurb: 'Symptom Data not availalbe',
    dataSource: symptomSurveyDataSource,
    lessIndicator: 'Fewer Symptoms',
    moreIndicator: 'More Symptoms',
    dataDateBlurb: locale('i18n_date_blurb1'),
    statName: STAT_NAMES.SYMPTOM,
  },
  {
    id: METRIC_IDS.SYMPTOMS_FLU,
    label: locale('i18n_fluSymptoms'),
    labelForMapTooltip: locale('i18n_covid_like_tip'),
    labelForLegend: locale('i18n_percent_with_s'),
    urlKey: 'flu',
    column: 'pctILI',
    breakpoints: [0.02, 0.05, 0.07, 0.1, 0.12, 0.15],
    bivariateBreakpoints: [0.0025, 0.0052],
    colors: [
      '#FFE2E5',
      '#FFC5CA',
      '#FEA9B0',
      '#F58E97',
      '#EB6570',
      '#D93E4B',
      '#C22734',
    ],
    flipSort: true,
    blurb: locale('i18n_blurb2'),
    display: formatTwoDecimalPercent,
    dataNotAvailableBlurb: 'Symptom Data not available',
    dataSource: symptomSurveyDataSource,
    lessIndicator: 'Fewer Symptoms',
    moreIndicator: 'More Symptoms',
    dataDateBlurb: locale('i18n_date_blurb2'),
    statName: STAT_NAMES.SYMPTOM,
  },
  {
    id: METRIC_IDS.TOTAL_CONFIRMED,
    label: locale('i18n_total_confirmed'),
    labelForMapTooltip: locale('i18n_total_confirmed'),
    urlKey: 'total_confirmed',
    column: 'total_confirmed',
    breakpoints: [10000, 30000, 50000, 55000, 100000, 200000],
    bivariateBreakpoints: [30000, 100000],
    colors: [
      '#EDE7FF',
      '#D2CDF0',
      '#ADA5E0',
      '#9186CF',
      '#6A5EB1',
      '#4E4391',
      '#312770',
    ],
    flipSort: true,
    blurb: '',
    display: (d: number) => formatBigNumber(d),
    dataNotAvailableBlurb: 'Cases Data not available',
    dataSource: () => 'Johns Hopkins University',
    lessIndicator: 'Fewer Cases',
    moreIndicator: 'More Cases',
    dataDateBlurb: locale('i18n_total_confirmed2'),
    statName: STAT_NAMES.CASES,
  },
  {
    id: METRIC_IDS.NEW_CONFIRMED,
    label: 'New Confirmed COVID-19 Cases',
    labelForMapTooltip: 'New Confirmed COVID-19 Cases',
    column: 'new_confirmed',
    urlKey: 'new_confirmed',
    breakpoints: [500, 1000, 1800, 2100, 2500, 3500],
    bivariateBreakpoints: [1000, 2500],
    colors: [
      '#EDE7FF',
      '#D2CDF0',
      '#ADA5E0',
      '#9186CF',
      '#6A5EB1',
      '#4E4391',
      '#312770',
    ],
    flipSort: true,
    blurb: '',
    display: (d) => formatBigNumber(d),
    dataNotAvailableBlurb: 'Cases Data not available',
    dataSource: () => 'Johns Hopkins University',
    lessIndicator: 'Fewer Cases',
    moreIndicator: 'More Cases',
    dataDateBlurb: locale('i18n_total_confirmed2'),
    statName: STAT_NAMES.CASES,
  },
  //{
  //id: METRIC_IDS.DENOMINATOR,
  //label: 'Denominator',
  //urlKey: 'denominator',
  //column: 'denominator',
  //breakpoints: [10000, 20000, 30000, 40000, 50000, 60000],
  //colors: blueColors.map(redify),
  //flipSort: true,
  //blurb: locale('i18n_blurb3'),
  //display: (d) => d,
  //dataSource: () => 'Johns Hopkins University',
  //},
];

export const metrics: Array<Metric> = isSymptomSurvey
  ? mobilityMetrics.concat(symptomSurveyMetrics)
  : mobilityMetrics;

// To get at the hex codes if needed...
//console.log(JSON.stringify(metrics, null, 2));

export const defaultMetric =
  CURRENT_PRODUCT === PRODUCTS.SYMPTOM
    ? symptomSurveyMetrics[0]
    : mobilityMetrics[0];

export const metricsById: { [string]: Metric } = metrics.reduce((acc, curr) => {
  acc[curr.id] = curr;
  return acc;
}, {});

// Look up a metric object by URL key.
export const getMetric = (urlKey: string): ?Metric =>
  metrics.find((metric) => metric.urlKey === urlKey);
