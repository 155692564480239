import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  font-family: Facebook Reader;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #bac2c8;
  border: 1px solid #f5f5f6;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
  border-radius: 36px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
`;

export const NoDataText = styled.span`
  padding: 4px 8px;
`;
