import styled from 'styled-components';

export const FBHeaderContainer = styled.div`
  color: $c-dark-blue-font;
  margin: 32px 40px;
  font-family: 'Optimistic Display', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 43px;
  color: #677b8c;
`;

export const FBHeaderText = styled.h1`
  text-align: center;
`;
