import { text } from 'd3-fetch';
import { csvParseRows } from 'd3-dsv';

import { path } from './path';
import { createFetchedData } from './model';
import { getDateExtent } from 'common/DataContext/util';

export const fetchData = ({
  adminLevel,
  statName,
  parseRows,
  countryCodeType,
}) => async (regionId, metadata) => {
  try {
    const rowsRaw = await text(
      path({ adminLevel, statName, regionId, metadata, countryCodeType })
    );
    const rows = parseRows(csvParseRows(rowsRaw));
    return createFetchedData({
      rows,
      statName,
      dateExtent: getDateExtent(rows),
      countryCodeType,
    });
  } catch (e) {
    return createFetchedData({
      statName,
      countryCodeType,
    });
  }
};
