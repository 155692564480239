// A place for common breadcrumbs-related utilities.
// Used for both mobility map and symptom survey breadcrumbs.
import { getParentRegionId } from 'common/DataContext/accessors';

export const breadcrumbsRegionId = ({ activeRow, selectedRegion }) =>
  activeRow && activeRow.regionId !== selectedRegion
    ? activeRow.regionId
    : selectedRegion;

export const breadcrumbsAncestors = (regionId) => {
  const regionIds = [regionId];
  let i = 0;
  while (getParentRegionId(regionIds[i]) && i < 5) {
    regionIds.push(getParentRegionId(regionIds[i]));
    i++;
  }
  return regionIds.reverse();
};
