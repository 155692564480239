import React, { useContext } from 'react';
import { DispatcherContext } from 'common/DispatcherContext';
import { Legend } from 'components/common/LineChart/Legend';
import { MetricComplex } from 'components/mobility/MetricComplex';
import { Map } from 'components/common/Map';
import { MeasuredLollipopChart } from 'components/mobility/LollipopChart';
import BelowVizTextBlock from 'components/mobility/Text/BelowVizTextBlock';
import { Credits } from './Credits';
import InterventionAttribution from './InterventionAttribution';
import ColorLegend from './ColorLegend';
import Header from './Header';
import { FloatingCountryDropdown } from 'components/mobility/CountryDropdown';
import { TimelineRangeButtons } from './TimelineRangeButtons';
import MovementLineChart from './MovementLineChart';
import {
  Bottom,
  Left,
  Right,
  RightTop,
  RightBottom,
  LegendContainer,
  Open,
} from './styles';

export const DesktopLayout = () => {
  const { dispatchToggleSmallMultiples } = useContext(DispatcherContext);
  return (
    <div>
      <Header />
      <Bottom>
        <Left>
          <MetricComplex />
          <MeasuredLollipopChart />
          <Credits />
        </Left>
        <Right>
          <RightTop>
            <Map />
            <ColorLegend />
            <FloatingCountryDropdown />
          </RightTop>
          <RightBottom>
            <LegendContainer>
              <Legend />
              <TimelineRangeButtons />
              <Open onClick={dispatchToggleSmallMultiples}>
                View as separate charts
              </Open>
            </LegendContainer>
            <MovementLineChart />
            <InterventionAttribution asterisk={true} />
          </RightBottom>
        </Right>
      </Bottom>
      <BelowVizTextBlock />
    </div>
  );
};
