import React from 'react';
import { PopupEntry, PopupEntryLabel, PopupEntryValue } from './styles';

const MetricEntry = ({ metric, row, highlight = true, normal = true }) => (
  <PopupEntry>
    <PopupEntryLabel highlight={highlight}>
      {metric.labelForMapTooltip}
    </PopupEntryLabel>
    <PopupEntryValue normal={normal}>
      {metric.display(row[metric.column])}
    </PopupEntryValue>
  </PopupEntry>
);

export default MetricEntry;
