import React, { useMemo, useContext, useCallback } from 'react';
import { withContentRect } from 'react-measure';
import { scaleBand, scaleLinear } from 'd3-scale';
import { min, max, ascending, descending } from 'd3-array';

import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import { regionDescriptor } from 'common/DataContext/accessors';
import { AscendingNumeralSVG } from 'assets/svg/AscendingNumeralSVG';
import { DescendingNumeralSVG } from 'assets/svg/DescendingNumeralSVG';
import { XAxis } from './XAxis';
import { Wrapper, SVG, Scroller } from './styles';
import { Marks } from './Marks';
import { yFormat } from 'components/common/LineChart/util';
import { isMobile } from 'styles/styles';

const parethesize = (str) => '(' + str + ')';

const yValue = (d) => d.name;
const numTicks = isMobile ? 2 : 4;

// Leave room for tooltips.
const minMarginX = 40;

const entryHeight = 15;

// Number of counties to show.
//const n = 20;
//const maxLabelWidth = 110;

const horizontalWithLabel = 150;
const horizontalWithoutLabel = 100;
//const horizontalWithoutLabel = 70;
const margin = { top: 45, bottom: 10 };

const LollipopChart = ({ width }) => {
  const {
    activeMetric,
    lollipopSortOrder,
    setLollipopSortOrder,
    selectedRegion,
  } = useContext(InteractionContext);

  const { getRegionName, activeDateData } = useContext(DataContext);

  const colorScale = scaleLinear()
    .domain(activeMetric.breakpoints)
    .range(activeMetric.colors);

  const xLabel =
    activeMetric.label +
    ' ' +
    parethesize(regionDescriptor(selectedRegion, getRegionName));
  const xValue = useCallback((d) => d[activeMetric.column], [activeMetric]);

  const sortedData = useMemo(() => {
    const comparator =
      lollipopSortOrder === 'descending' ? descending : ascending;

    // Immutable array sort to ensure React catches the update in rendering.
    return [...activeDateData].sort((a, b) => comparator(xValue(a), xValue(b)));
  }, [activeDateData, lollipopSortOrder, xValue]);

  // Expand vertically to fit all counties,
  // allow the user to scroll.
  const height = margin.top + margin.bottom + entryHeight * sortedData.length;

  margin.left = horizontalWithLabel;
  margin.right = horizontalWithLabel;

  const xMin = Math.min(min(sortedData, xValue), 0);
  const xMax = Math.max(max(sortedData, xValue), 0);
  const straddles = xMin < 0 && xMax > 0;
  const hasLeftLabels = straddles || xMin === 0;
  const hasRightLabels = straddles || xMax === 0;

  if (!hasLeftLabels) {
    margin.left = minMarginX;
  }

  if (!hasRightLabels) {
    margin.right = minMarginX;
  }

  // TODO one day... there could be more correct math here,
  // for precision margins depending on the location of the zero line.
  // This works fairly well for now: if the domain straddles zero,
  // then make the margins slightly less than if one side or the
  // other had to support a full-sized label.
  if (straddles) {
    margin.right = horizontalWithoutLabel;
    margin.left = horizontalWithoutLabel;
  }

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const xScale = useMemo(
    () => scaleLinear().domain([xMin, xMax]).range([0, innerWidth]),
    [xMin, xMax, innerWidth]
  );

  const yScale = useMemo(
    () =>
      scaleBand()
        .domain(sortedData.map(yValue))
        .range([0, innerHeight])
        .padding(0),
    [innerHeight, sortedData]
  );

  const colorValue = useCallback((d) => d[activeMetric.column], [activeMetric]);

  const handleNumericSortClick = useCallback(() => {
    setLollipopSortOrder(
      lollipopSortOrder === 'ascending' ? 'descending' : 'ascending'
    );
  }, [lollipopSortOrder, setLollipopSortOrder]);

  if (!width || !height) return null;

  return (
    <SVG width={width} height={height}>
      <g transform="translate(16, 10)">
        <text
          className="axis-label"
          textAnchor="start"
          alignmentBaseline="hanging"
          style={{ userSelect: 'none', pointerEvents: 'none' }}
        >
          {xLabel}
        </text>
        <g transform={`translate(${width - 60},0)`}>
          {lollipopSortOrder === 'ascending' ? (
            <AscendingNumeralSVG fill={'#0972A6'} />
          ) : (
            <DescendingNumeralSVG fill={'#0972A6'} />
          )}
          <rect
            onClick={handleNumericSortClick}
            fill="none"
            style={{ pointerEvents: 'all', cursor: 'pointer' }}
            width="20"
            height="20"
            x="-3"
            y="-3"
          />
        </g>
      </g>
      <g transform={`translate(${margin.left},${margin.top})`}>
        <XAxis
          xScale={xScale}
          innerHeight={innerHeight}
          tickFormat={yFormat(activeMetric)}
          numTicks={numTicks}
        />
        <line
          x1={xScale(0)}
          x2={xScale(0)}
          y0={0}
          y1={innerHeight}
          stroke="#e5ecf0"
          strokeWidth={1}
        />
        <Marks
          data={sortedData}
          xScale={xScale}
          yScale={yScale}
          colorScale={colorScale}
          xValue={xValue}
          yValue={yValue}
          colorValue={colorValue}
          tooltipFormat={yFormat(activeMetric)}
          innerWidth={innerWidth}
          margin={margin}
        />
      </g>
    </SVG>
  );
};

// Inspired by https://www.npmjs.com/package/react-measure
export const MeasuredLollipopChart = withContentRect('bounds')(
  ({
    measureRef,
    measure,
    contentRect: {
      bounds: { width, height },
    },
  }) => (
    <Wrapper ref={measureRef}>
      <Scroller>
        <LollipopChart width={width} height={height} />
      </Scroller>
    </Wrapper>
  )
);
