import React from 'react';

import { EmbedContentLink, EmbedParagraph, EmbedIntroHeader } from '../styles';

const EmbedIntro = () => {
  // prettier-ignore
  return (
    <div>
      <EmbedIntroHeader>Embed Customized Map On Your Site</EmbedIntroHeader>
      <EmbedParagraph>
        By embedding this map, you agree to Facebook’s<span> </span>
        <EmbedContentLink href="https://www.facebook.com/legal/terms" style={{ textDecoration: 'none' }}>
          Terms of Service
        </EmbedContentLink><span> </span>
        and<span> </span>
        <EmbedContentLink href="https://developers.facebook.com/policy/" style={{ textDecoration: 'none' }}>
          Developer Policy
        </EmbedContentLink>
        .
      </EmbedParagraph>
    </div>
  );
};

export default EmbedIntro;
