import React from 'react';
import '../index.scss';
import FBHeader from 'components/symptom/FBHeader/index.js';
import FBFooter from 'components/symptom/FBFooter/index.js';

export default function survey() {
  // prettier-ignore
  return (
<div>
      <FBHeader parent="data_info" />
      <div className="section-outer data-info">
        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164943"><h4 className="eyebrow">Données des sondages et de la carte</h4>
                <h3>Soutenir la recherche sur le COVID-19</h3>
                <p>Avec plus de 2 milliards de personnes sur Facebook, nous avons la possibilité unique
                  de soutenir la recherche de santé publique. Depuis 2017, nous formons des partenariats
                  avec des institutions académiques et des organisations à but non
                  lucratif reconnues, par l’intermédiaire de l’initiative Data For Good,
                  pour créer un réseau de recherche visant à répondre aux plus grands problèmes humanitaires auxquels le monde doit faire face.
                  Récemment, nous avons collaboré avec <span> </span>
                  <a className="bold-link" href="https://delphi.cmu.edu/" target="_blank" rel="noopener noreferrer">
                   l’université Carnegie Mellon University (CMU)
                  </a><span> </span>
                  et<span> </span>
                  <a className="bold-link" href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data" target="_blank" rel="noopener noreferrer">
                   l’université du Maryland (UMD)
                  </a><span> </span>
                  pour inviter les gens à participer à des sondages sur le COVID-19,
                  ses symptômes et ses facteurs de risque. Nous transcrivons ensuite les données de ces sondages sur notre carte
                  et nous y ajoutons d’autres données agrégées publiques
                  pour offrir une vue plus holistique de la pandémie de COVID-19. Dès le début,
                  les initiatives de nos partenaires sont conçues dans un souci de
                  respect de la confidentialité de chaque individu.</p></div>
            </div>
          </div>
        </div>

        <div className="data-image-section">
          <img alt="" src={`${process.env.PUBLIC_URL}/images/image-survey-methodology.jpg`} className="survey-image"/>
        </div>

        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164945"><h4 className="sub-header">La méthodologie appliquée aux sondages</h4>
                <p>En partenariat avec les universités de Carnegie Mellon University et
                  du Maryland, nous invitons les gens sur Facebook à
                  participer à des sondages qui les interrogent sur leur état de santé,
                  notamment si eux ou des membres de leur foyer ont eu des
                  symptômes du COVID-19, ainsi que sur leurs facteurs de
                  risques de contamination. Ces sondages ont pour objectif de fournir
                  de précieuses informations permettant de surveiller et prévoir la
                  propagation du COVID-19, sans compromettre la confidentialité des
                  participants. Plus d’un million de personnes y ont répondu
                  dans les deux premières semaines.</p><div className="survey-grid">
                  <div className="survey-grid-item col-2"><h4>À propos des sondages</h4>
                    <p>Les sondages menés par l’université Carnegie Mellon University et
                      l’université du Maryland demandent aux participants de signaler
                      les symptômes associés au COVID-19 ou à la grippe qu’eux-mêmes
                      ou des membres de leur foyer auraient pu avoir dans les dernières 24 heures.
                      Des recherches de santé publiques similaires ont été conduites
                      partout dans le monde et utilisées pour prévoir la propagation
                      de la grippe et autres maladies par le passé.</p></div>

                  <div className="survey-grid-item col-2"><h4>Qui participe aux sondages</h4>
                    <p>Facebook touche de larges segments de la population, ce qui permet
                      une représentation significative des âges, des genres et des zones
                      géographiques. Chaque jour, un nouveau panel d’utilisateurs de Facebook de plus de
                      18 ans est invité à participer à un sondage. Aux États-unis,
                      les utilisateurs participent à un sondage de l’université Carnegie
                      Mellon University, tandis que dans le reste du monde, ils
                      participent à celui de l’université du Maryland.
                      Facebook ne reçoit, collecte ou stocke aucune réponse individuelle aux
                      sondages. Les universités Carnegie Mellon University et
                      du Maryland ne savent pas qui a participé aux sondages.</p></div>

                  <div className="survey-grid-item col-2"><h4>Ajustement des biais des échantillons</h4>
                    <p>Facebook partage une statistique unique, appelée valeur de
                      pondération, pour corriger les biais des échantillons dans les sondages. La valeur de pondération
                      n’identifie pas le participant au sondage. Cet ajustement
                      permet de garantir que l’échantillon reflète au plus juste
                      les caractéristiques de la population représentée.
 La valeur de pondération pour une personne peut
                      être appréhendée comme le nombre de personnes dans une
                      population adulte qu’elles représentent, en fonction de leur âge,
                      genre et zone géographique. Nous ne montrons aucune donnée pour les pays qui ne reflètent
                      pas les ajustements des biais des échantillons.</p></div>

                  <div className="survey-grid-item col-2"><h4>Utilisation des données des sondages</h4>
                    <p>Les données des sondages peuvent aider les décideurs politiques et les
                      chercheurs à prévoir les épidémies de COVID-19 et répondre à la
                      pandémie dans leurs communautés. Les partenaires de recherche de Facebook se sont engagés à n’utiliser
                      les résultats des sondages sur les symptômes qu’à des fins d’étude
                      et de limitation du COVID-19.<span> </span>
                      <a className="bold-link" href="https://dataforgood.fb.com/docs/covid-19-symptom-survey-request-for-data-access/" target="_blank" rel="noopener noreferrer">
                        Apprenez-en davantage
                      </a><span> </span>
                      sur la demande d’accès aux données des sondages en tant que
                      chercheur d’un établissement académique ou d’une organisation à but non lucratif.</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="data-image-section">
          <img alt="" src={`${process.env.PUBLIC_URL}/images/image-map-data.jpg`} className="survey-image"/>
        </div>

        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164947"><h4 className="sub-header">Données sur notre carte</h4>
                <p>Les données de notre carte peuvent aider à mieux comprendre les symptômes
                  du COVID-19 et les cas confirmés signalés, ainsi que la manière dont
                  les mesures et consignes préventives ou les caractéristiques d’une
                  population, telles que l’âge et la densité, affectent la propagation de la
                  maladie. Utilisées ensemble, ces données peuvent aider les décideurs et les
                  autorités sanitaires publiques à mettre en place des stratégies de
                  réponse plus efficaces pour leurs communautés. Notre carte affiche trois catégories de
                  données : les symptômes et les cas, la distanciation
                  physique et les facteurs de risque.</p><div className="survey-grid">
                  <div className="survey-grid-item col-3"><h4>Symptômes et cas</h4>
                    <p>Les signalements des symptômes et des cas confirmés sont essentiels pour
                      comprendre la pandémie, car ils donnent une indication de la
                      manière dont les mesures et consignes préventives, telles que la
                      distanciation physique, affectent la propagation du COVID-19.</p>
                    <ul>
                      <li>
                        <p>Données sur les symptômes de COVID-19 et de grippe fournies par <span> </span>
                          <a className="bold-link" href="https://cmu-delphi.github.io/delphi-epidata/api/" target="_blank" rel="noopener noreferrer">
                            l’université Carnegie Mellon University
                          </a><span> </span>
                          et<span> </span>
                          <a className="bold-link" href="https://covidmap.umd.edu/api.html" target="_blank" rel="noopener noreferrer">
                            l’université du Maryland
                          </a></p>
                      </li>
                      <li>
                        <p>Données du total des cas confirmés fournies par<span> </span>
                          <a className="bold-link" href="https://github.com/CSSEGISandData/COVID-19" target="_blank" rel="noopener noreferrer">
                            Johns Hopkins University
                          </a></p>
                      </li>
                    </ul></div>

                  <div className="survey-grid-item col-3"><h4>Distanciation physique</h4>
                    <p>La distanciation physique, la fermeture des écoles et l’état d’urgence
                      sanitaire, ainsi que les données de mobilité, donnent une indication
                      de la manière dont les mesures de protection et de confinement
                      diminuent la mobilité pour limiter la propagation du COVID-19.</p>
                    <ul>
                      <li>
                        <p>Données sur la distanciation physique, la fermeture des écoles et l’état
                          d’urgence sanitaire fournies par le<span> </span>
                          <a className="bold-link" href="https://github.com/COVID19StatePolicy/SocialDistancing/blob/master/data/USstatesCov19distancingpolicy.csv" target="_blank" rel="noopener noreferrer">
                            COVID-19 State Policy Repository
                          </a></p>
                      </li>
                      <li>
                        <p>Pourcentage des personnes restant chez elles et données sur
                          l’évolution des déplacements fournies par les<span> </span>
                          <a className="bold-link" href="https://data.humdata.org/dataset/movement-range-maps" target="_blank" rel="noopener noreferrer">
                            cartes des déplacements Data For Good de Facebook
                          </a></p>
                      </li>
                    </ul></div>

                  <div className="survey-grid-item col-3"><h4>Facteurs de risque</h4>
                    <p>Les facteurs de risque sont les caractéristiques d’une population associées
                      à un risque accru de propagation du COVID-19 ou de
                      développement de maladie sérieuse, notamment la population
                      totale, sa densité et les personnes de 65 ans et plus. Ces trois
                      caractéristiques peuvent aider à mieux comprendre les autres
                      catégories de données.</p>
                    <ul>
                      <li>
                        <p>Les données sur la population totale, la densité de population au km² et
                          le pourcentage de personnes âgées de 65 ans et plus sont fournies par le<span> </span>
                          <a className="bold-link" href="https://censusreporter.org/" target="_blank" rel="noopener noreferrer">
                            Bureau du recensement des États-Unis
                          </a><span> </span>
                          et les cartes de population<span> </span>
                          <a className="bold-link" href="https://data.humdata.org/organization/facebook" target="_blank" rel="noopener noreferrer">
                            Data For Good de Facebook
                          </a></p>
                      </li>
                    </ul></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 20 }} />
      <FBFooter />
    </div>
  );
}
