import React from 'react';
import './index.scss';
import FBHeader from 'components/symptom/FBHeader/index.js';
import FBFooter from 'components/symptom/FBFooter/index.js';

export default class SurveyMapData extends React.PureComponent {
  render() {
    return (
      <div>
        <FBHeader parent="data_info" />
        <div className="section-outer data-info">
          <div className="section-inner">
            <div className="story">
              <div className="section">
                <div className="content" id="i18n-1713164943">
                  <h4 className="eyebrow">ERROR 403</h4>
                  <h3>ERROR 403</h3>
                  <p>ERROR 403</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20 }} />
        <FBFooter />
      </div>
    );
  }
}
