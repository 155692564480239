import { text } from 'd3-fetch';

import { STAT_NAMES_LIST, DATASETS_LIST } from 'common/constants';

import { createStatMetadata, createDatasetMetadata } from './model';

const createLatestURLForDataset = (dataset) =>
  `${process.env.REACT_APP_DATA_ENDPOINT}/${dataset.apiPath}/LATEST.txt`;

export const parseLatestDates = (latestDatasetDates) => {
  const byDatasetId = DATASETS_LIST.reduce((acc, dataset, i) => {
    const latestDateString = latestDatasetDates[i];
    acc[dataset.datasetId] = createDatasetMetadata(latestDateString);
    return acc;
  }, {});
  const byStatName = STAT_NAMES_LIST.reduce((acc, statName) => {
    const datasetsForStat = DATASETS_LIST.filter(
      (d) => d.statName === statName
    );
    acc[statName] = createStatMetadata(datasetsForStat, byDatasetId);
    return acc;
  }, {});

  return {
    byDatasetId,
    byStatName,
  };
};

export const fetchMetadata = function () {
  return new Promise((res, rej) =>
    Promise.all(
      DATASETS_LIST.map((dataset) => text(createLatestURLForDataset(dataset)))
    ).then((latestDateList) => {
      const byDatasetId = DATASETS_LIST.reduce((acc, dataset, i) => {
        const latestDateString = latestDateList[i];
        acc[dataset.datasetId] = createDatasetMetadata(latestDateString);
        return acc;
      }, {});
      const byStatName = STAT_NAMES_LIST.reduce((acc, statName, i) => {
        const datasetsForStat = DATASETS_LIST.filter(
          (d) => d.statName === statName
        );
        acc[statName] = createStatMetadata(datasetsForStat, byDatasetId);
        return acc;
      }, {});

      res({
        byStatName,
        byDatasetId,
      });
    })
  );
};
