import { CURRENT_PRODUCT, PRODUCTS, MAP_PROJECTION } from 'common/constants';

// for crisisready
export const accessToken =
  'pk.eyJ1IjoiY3Jpc2lzcmVhZHkiLCJhIjoiY2twNGgzemg1MDIybjJ1cDJxZGZpMW0xciJ9.1nZpvn5J-ELpMGpHjdsk7A';

// for fbmaps
//export const accessToken =
//  'pk.eyJ1IjoiZmJtYXBzIiwiYSI6ImNrOHJ5dWx6bzAyNDgzbXMxZ2hkazIwOTUifQ.6FVFvcOjQ7VhjFxdUTBjKA';

// for stamen
//const accessToken =
//  'pk.eyJ1Ijoic3RhbWVuIiwiYSI6IlpkZEtuS1EifQ.jiH_c9ShtBwtqH9RdG40mw';

//const proj = 'patterson';
export const proj =
  CURRENT_PRODUCT === PRODUCTS.SYMPTOM
    ? MAP_PROJECTION.MERCATOR
    : MAP_PROJECTION.PATTERSON;
//const proj = 'albers';

// For World in Mercator
//const worldBounds = [
//  [-170, -80],
//  [170, 80],
//];
// For World in Patterson
export const worldBounds = {
  mercator: [
    [-150, -50],
    [150, 50],
  ],
  patterson: [
    [-175, -60],
    [175, 60],
  ],
};

const mercatorUsername =
  CURRENT_PRODUCT === PRODUCTS.SYMPTOM ? 'fbmaps' : 'crisisready';
const pattersonUsername =
  CURRENT_PRODUCT === PRODUCTS.SYMPTOM ? 'fbmaps' : 'crisisready';

export const mapboxStyle = {
  albers: 'mapbox://styles/stamen/ck9c1hvo204ey1it12no3llni',
  //mercator: 'mapbox://styles/stamen/ck9c1hvo204ey1it12no3llni',
  mercator: 'mapbox://styles/fbmaps/ckayqqptb1llw1io5omufvnlz',
  miller: 'mapbox://styles/stamen/ck9ecng230q0r1ip6mc4fxz2y',
  patterson: 'mapbox://styles/crisisready/ckphau10h1jhv18qz7lsvha70',
  //patterson: 'mapbox://styles/stamen/ckah3ri1y0fy01iplmlhwlpnz',
};
export const mapboxSources = {
  albers: {
    labelpoints: {
      id: 'label-points-source',
      key: 'label-points-source',
      tileJsonSource: {
        type: 'vector',
        url: 'mapbox://fbmaps.covid19-albers-label-points',
      },
    },
    admin2: {
      id: 'counties-source',
      key: 'counties-source',
      tileJsonSource: {
        type: 'vector',
        url: 'mapbox://fbmaps.covid19-albers-counties',
      },
    },
    admin1: {
      id: 'states-source',
      key: 'states-source',
      tileJsonSource: {
        type: 'vector',
        url: 'mapbox://fbmaps.covid19-albers-states',
      },
    },
    admin0: {},
  },
  mercator: {
    labelpoints: {
      id: 'label-points-source',
      key: 'label-points-source',
      tileJsonSource: {
        type: 'vector',
        url: `mapbox://${mercatorUsername}.covid19-label-points`,
      },
    },
    admin2: {
      id: 'admin2-source',
      key: 'admin2-source',
      tileJsonSource: {
        type: 'vector',
        url: `mapbox://${mercatorUsername}.covid19-admin2`,
      },
    },
    admin1: {
      id: 'admin1-source',
      key: 'admin1-source',
      tileJsonSource: {
        type: 'vector',
        url: `mapbox://${mercatorUsername}.covid19-admin1`,
      },
    },
    admin0: {
      id: 'admin0-source',
      key: 'admin0-source',
      tileJsonSource: {
        type: 'vector',
        url: `mapbox://${mercatorUsername}.covid19-admin0`,
      },
    },
  },
  patterson: {
    labelpoints: {
      id: 'label-points-source',
      key: 'label-points-source',
      tileJsonSource: {
        type: 'vector',
        url: `mapbox://${pattersonUsername}.covid19-label-points-patterson`,
      },
    },
    admin2: {
      id: 'admin2-source',
      key: 'admin2-source',
      tileJsonSource: {
        type: 'vector',
        url: `mapbox://${pattersonUsername}.covid19-admin2-patterson`,
      },
    },
    admin1: {
      id: 'admin1-source',
      key: 'admin1-source',
      tileJsonSource: {
        type: 'vector',
        url: `mapbox://${pattersonUsername}.covid19-admin1-patterson`,
      },
    },
    admin0: {
      id: 'admin0-source',
      key: 'admin0-source',
      tileJsonSource: {
        type: 'vector',
        url: `mapbox://${pattersonUsername}.covid19-admin0-patt-new`,
      },
    },
  },
};
export const mapboxLayers = {
  albers: {
    admin2choropleth: {
      id: 'admin2-choropleth',
      key: 'admin2-choropleth',
      sourceId: 'counties-source',
      sourceLayer: 'albers-counties',
      before: 'albers-states',
    },
    admin1choropleth: {
      id: 'admin1-choropleth',
      key: 'admin1-choropleth',
      sourceId: 'states-source',
      sourceLayer: 'albers-states',
      before: 'albers-states',
    },
    admin0choropleth: {
      id: 'admin0-choropleth',
      key: 'admin0-choropleth',
      sourceId: 'admin0-source',
      sourceLayer: 'covid19-admin0',
      before: 'albers-states',
    },
    admin2outline: {
      id: 'admin2-outline',
      key: 'admin2-outline',
      sourceId: 'counties-source',
      sourceLayer: 'albers-counties',
      before: 'smallest-populated-places',
    },
    admin2interaction: {
      id: 'admin2-interaction',
      key: 'admin2-interaction',
      sourceId: 'counties-source',
      sourceLayer: 'albers-counties',
      before: 'smallest-populated-places',
    },
    admin1outline: {},
    admin1interaction: {
      id: 'admin1-interaction',
      key: 'admin1-interaction',
      sourceId: 'states-source',
      sourceLayer: 'albers-states',
      before: 'smallest-populated-places',
    },
    admin0outline: {},
    admin0interaction: {},
  },
  mercator: {
    admin2choropleth: {
      id: 'admin2-choropleth',
      key: 'admin2-choropleth',
      sourceId: 'admin2-source',
      sourceLayer: 'covid19-admin2',
      before: 'covid19-lakes',
      //before: 'land-structure-polygon',
    },
    admin1choropleth: {
      id: 'admin1-choropleth',
      key: 'admin1-choropleth',
      sourceId: 'admin1-source',
      sourceLayer: 'covid19-admin1',
      before: 'covid19-lakes',
      //before: 'land-structure-polygon',
    },
    admin0choropleth: {
      id: 'admin0-choropleth',
      key: 'admin0-choropleth',
      sourceId: 'admin0-source',
      sourceLayer: 'covid19-admin0',
      before: 'covid19-lakes',
      //before: 'land-structure-polygon',
    },
    admin2outline: {
      id: 'admin2-outline',
      key: 'admin2-outline',
      sourceId: 'admin2-source',
      sourceLayer: 'covid19-admin2',
      before: 'settlement-minor-label',
    },
    admin2interaction: {
      id: 'admin2-interaction',
      key: 'admin2-interaction',
      sourceId: 'admin2-source',
      sourceLayer: 'covid19-admin2',
      before: 'settlement-minor-label',
    },
    admin1outline: {
      id: 'admin1-outline',
      key: 'admin1-outline',
      sourceId: 'admin1-source',
      sourceLayer: 'covid19-admin1',
      before: 'settlement-minor-label',
    },
    admin1interaction: {
      id: 'admin1-interaction',
      key: 'admin1-interaction',
      sourceId: 'admin1-source',
      sourceLayer: 'covid19-admin1',
      before: 'settlement-minor-label',
    },
    admin0outline: {
      id: 'admin0-outline',
      key: 'admin0-outline',
      sourceId: 'admin0-source',
      sourceLayer: 'covid19-admin0',
      before: 'settlement-minor-label',
    },
    admin0interaction: {
      id: 'admin0-interaction',
      key: 'admin0-interaction',
      sourceId: 'admin0-source',
      sourceLayer: 'covid19-admin0',
      before: 'settlement-minor-label',
    },
    adminLabels: {
      id: 'label-points',
      key: 'label-points',
      sourceId: 'label-points-source',
      sourceLayer: 'label-points',
      before: 'covid19-lakes-large',
    },
  },
  patterson: {
    admin2choropleth: {
      id: 'admin2-choropleth',
      key: 'admin2-choropleth',
      sourceId: 'admin2-source',
      sourceLayer: 'covid19-admin2',
      before: 'covid19-lakes-large',
    },
    admin1choropleth: {
      id: 'admin1-choropleth',
      key: 'admin1-choropleth',
      sourceId: 'admin1-source',
      sourceLayer: 'covid19-admin1',
      before: 'covid19-lakes-large',
    },
    admin0choropleth: {
      id: 'admin0-choropleth',
      key: 'admin0-choropleth',
      sourceId: 'admin0-source',
      sourceLayer: 'covid19-admin0',
      before: 'covid19-lakes-large',
    },
    admin2outline: {
      id: 'admin2-outline',
      key: 'admin2-outline',
      sourceId: 'admin2-source',
      sourceLayer: 'covid19-admin2',
      before: 'covid19-lakes-large',
    },
    admin2interaction: {
      id: 'admin2-interaction',
      key: 'admin2-interaction',
      sourceId: 'admin2-source',
      sourceLayer: 'covid19-admin2',
      before: 'covid19-lakes-large',
    },
    admin1outline: {
      id: 'admin1-outline',
      key: 'admin1-outline',
      sourceId: 'admin1-source',
      sourceLayer: 'covid19-admin1',
      before: 'covid19-lakes-large',
    },
    admin1interaction: {
      id: 'admin1-interaction',
      key: 'admin1-interaction',
      sourceId: 'admin1-source',
      sourceLayer: 'covid19-admin1',
      before: 'covid19-lakes-large',
    },
    admin0outline: {
      id: 'admin0-outline',
      key: 'admin0-outline',
      sourceId: 'admin0-source',
      sourceLayer: 'covid19-admin0',
      before: 'covid19-lakes-large',
    },
    admin0interaction: {
      id: 'admin0-interaction',
      key: 'admin0-interaction',
      sourceId: 'admin0-source',
      sourceLayer: 'covid19-admin0',
      before: 'covid19-lakes-large',
    },
    adminLabels: {
      id: 'label-points',
      key: 'label-points',
      sourceId: 'label-points-source',
      sourceLayer: 'label-points',
      before: 'covid19-lakes-large',
    },
  },
};
export const tileAdminKeys = {
  albers: {
    admin2: 'county_fips',
    admin2_parent: 'state_fips',
    admin1: 'state_fips',
    admin1_parent: 'country_code',
    admin0: 'country_code',
    typekey: 'type',
    admin0type: 'country',
    admin1type: 'state',
    admin2type: 'county',
  },
  mercator: {
    admin2: 'id',
    admin2_parent: 'id_1',
    admin1: 'id',
    admin1_parent: 'id_0',
    admin0: 'id',
    typekey: 'level',
    admin0type: 0,
    admin1type: 1,
    admin2type: 2,
  },
  patterson: {
    admin2: 'id',
    admin2_parent: 'id_1',
    admin1: 'id',
    admin1_parent: 'id_0',
    admin0: 'id',
    typekey: 'level',
    admin0type: 0,
    admin1type: 1,
    admin2type: 2,
  },
};
