import React from 'react';

const ActiveLine = ({ lineGenerator, lineData, color = '#374c67' }) =>
  lineData ? (
    <path
      className="active-line"
      d={lineGenerator(lineData)}
      fill="none"
      stroke={color}
    />
  ) : null;

export default ActiveLine;
