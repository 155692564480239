import React from 'react';

import { EmbedParagraph, EmbedSection } from './styles';
import EmbedCopyButton from './EmbedCopyButton';
import locale from 'common/locale';

const hiddenStyle = {
  opacity: 0,
};

const CodeSnippetSection = React.forwardRef(
  ({ generatedSnippet, onCopyClicked }, ref) => {
    return (
      <EmbedSection ref={ref} style={!generatedSnippet ? hiddenStyle : {}}>
        <h2>{locale('i18n_snippet1')}</h2>
        <EmbedParagraph>{locale('i18n_snippet2')}</EmbedParagraph>
        <div className="code-snippet">
          <pre>{generatedSnippet}</pre>
          <EmbedCopyButton onClick={onCopyClicked} />
        </div>
      </EmbedSection>
    );
  }
);

export default CodeSnippetSection;
