import React, { useCallback, useState } from 'react';
import copy from 'copy-to-clipboard';
import { Button } from './styles';

export const CopyButton = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = useCallback(() => {
    copy(textToCopy);
    setCopied(true);
  }, [textToCopy]);

  return <Button onClick={handleClick}>{copied ? 'Copied!' : 'Copy'}</Button>;
};
