import React from 'react';
import { Layer } from 'react-mapbox-gl';

const LineLayer = ({ layerProps, paint }) => (
  <Layer
    type="line"
    layout={{
      'line-join': 'round',
      'line-cap': 'round',
    }}
    paint={paint}
    {...layerProps}
  />
);

export default LineLayer;
