import React from 'react';

import {
  EmbedContentLink,
  EmbedParagraph,
  EmbedIntroHeader,
} from '../../styles';

const EmbedIntro = () => {
  return (
    // prettier-ignore

    <div>
      <EmbedIntroHeader>在您网站内嵌入自定义地图</EmbedIntroHeader>
      <EmbedParagraph>
        嵌入此地图，即表示您同意 Facebook 的<span> </span>
        <EmbedContentLink href="https://www.facebook.com/legal/terms" style={{ textDecoration: 'none' }}>
          服务条款
        </EmbedContentLink><span> </span>
        和<span> </span>
        <EmbedContentLink href="https://developers.facebook.com/policy/" style={{ textDecoration: 'none' }}>
          开放平台政策
        </EmbedContentLink>
        .
      </EmbedParagraph>
    </div>
  );
};

export default EmbedIntro;
