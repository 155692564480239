import React from 'react';
import '../index.scss';
import FBHeader from 'components/symptom/FBHeader/index.js';
import FBFooter from 'components/symptom/FBFooter/index.js';

export default function survey() {
  // prettier-ignore
  return (
<div>
      <FBHeader parent="data_info" />
      <div className="section-outer data-info">
        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164943"><h4 className="eyebrow">问卷调查和地图数据</h4>
                <h3>全力支持新冠疫情调研的开展</h3>
                <p>Facebook 拥有超过 20 亿用户，
                  在支持开展公共卫生研究方面占有举足轻重的地位。自 2017 年以来，我们推出了 Data For Good 项目，
                  与值得信赖的学术机构和非营利组织合作
                  搭建起一个研究网络，
                  专门解决全球范围内的一些人道主义问题。
                  最近，我们与<span> </span>
                  <a className="bold-link" href="https://delphi.cmu.edu/" target="_blank" rel="noopener noreferrer">
                    卡耐基·梅隆大学
                  </a><span> </span>
                  以及<span> </span>
                  <a className="bold-link" href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data" target="_blank" rel="noopener noreferrer">
                    马里兰大学
                  </a><span> </span>
                  合作，邀请人们参与新冠病毒感染
                  症状与风险因素相关的问卷调查。问卷收集到的数据将被用于我们的地图功能，
                  结合经过汇总处理的其他公开数据，
                  让人们对新冠大流行有一个更为全面的认识。我们的
                  合作调研项目在设计之初就将用户的隐私保护
                  纳入考量。</p></div>
            </div>
          </div>
        </div>

        <div className="data-image-section">
          <img alt="" src={`${process.env.PUBLIC_URL}/images/image-survey-methodology.jpg`} className="survey-image"/>
        </div>

        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164945"><h4 className="sub-header">调研方法</h4>
                <p>我们与卡耐基·梅隆大学以及马里兰大学
                  合作，邀请 Facebook 用户
                  参与问卷。问卷涉及
                  用户健康状况相关的问题，
                  例如，用户或其家庭成员曾出现过的
                  新冠病毒感染症状，以及患病的风险因素。这些问卷旨在
                  提供有价值的信息，以帮助我们
                  监控和预测新冠疫情的蔓延情况，
                  同时保护问卷参与者的隐私。在最初的两周内，已有
                  一百多万用户参与了问卷。</p><div className="survey-grid">
                  <div className="survey-grid-item col-2"><h4>问卷说明</h4>
                    <p>该问卷调研项目是由卡耐基·梅隆大学和马里兰大学主导的，
                  目的是让人们报告自己或其家庭成员在
                  过去 24 小时内出现的
                  新冠病毒感染或流感相关症状。
                      类似的公共卫生调研问卷
                  亦曾在全球范围内开展，用以
                  预测流感和其他疾病的传播。</p></div>

                  <div className="survey-grid-item col-2"><h4>问卷调查对象</h4>
                    <p>Facebook 平台拥有广泛的用户群，
                  这一优势保证了平台用户在年龄、性别和地区上
                  具有显著代表性。Facebook 每天会抽取一批年满 18 岁的用户作为样本
                  受邀参与问卷调查。如果用户位于美国境内，
                  他们将参与由卡耐基·梅隆大学主导的问卷调查，
                  而美国境外的用户则参与
                  马里兰大学主导的问卷调查。
                      Facebook 不会接收、收集或
                  存储任何参与者的回复。卡耐基·梅隆大学和马里兰大学
                  则不了解问卷参与者的身份。</p></div>

                  <div className="survey-grid-item col-2"><h4>样本偏差调整</h4>
                    <p>为了校准问卷样本存在的偏差问题，
                  Facebook 会分享一个独立的统计学数字（也称之为权数）。权数中不包含
                  问卷对象的身份识别信息。通过权数进行
                  样本偏差调整，可确保
                  样本更准确地反映
                  其所代表人群的特征。我们可以把权数想象成
                  与被调查者
                  同龄、同性别和同地区
                  的所有成人人数。对于无法反映样本偏差调整的国家/地区，
                  我们不会显示其数据。</p></div>

                  <div className="survey-grid-item col-2"><h4>问卷数据的用途</h4>
                    <p>问卷数据有助于
                  决策者和研究人员预测新冠疫情的暴发情况，
                  以及应对本地新冠大流行。新冠病毒感染症状问卷调查的结果将仅供
                      Facebook 的合作研究机构用于开展研究
                      以及协助遏制新冠病毒蔓延。<span> </span>
                      <a className="bold-link" href="https://dataforgood.fb.com/docs/covid-19-symptom-survey-request-for-data-access/" target="_blank" rel="noopener noreferrer">
                        详细了解
                      </a><span> </span>
                      学术机构或非营利研究机构
                        如何申请访问问卷数据。</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="data-image-section">
          <img alt="" src={`${process.env.PUBLIC_URL}/images/image-map-data.jpg`} className="survey-image"/>
        </div>

        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164947"><h4 className="sub-header">关于地图数据</h4>
                <p>此地图中的数据有助于增进公众对以下几类信息的了解：
                  新冠病毒感染用户自报症状、确诊病例，以及
                  预防措施、政府命令和人口特征
                  （如年龄和人口密度）对于
                  疾病蔓延有何影响。通过结合分析以上数据，
                  可帮助决策者和公共卫生系统为本地民众制定
                  更有效的抗疫计划。本地图总共分为
                  三大类数据：有症状人群与确诊病例、安全距离实施日期
                  和风险因素。</p><div className="survey-grid">
                  <div className="survey-grid-item col-3"><h4>有症状人群与确诊病例</h4>
                    <p>用户自报症状和确诊病例这一类数据对于
                      分析疫情大流行最为关键，因为它们反映了
                      预防措施和政府命令（例如保持安全距离）的落实
                      对于新冠疫情蔓延产生的影响。</p>
                    <ul>
                      <li>
                        <p>新冠病毒感染和流感症状自报数据来源于<span> </span>
                          <a className="bold-link" href="https://cmu-delphi.github.io/delphi-epidata/api/" target="_blank" rel="noopener noreferrer">
                            卡耐基·梅隆大学
                          </a><span> </span>
                          和<span> </span>
                          <a className="bold-link" href="https://covidmap.umd.edu/api.html" target="_blank" rel="noopener noreferrer">
                            马里兰大学
                          </a></p>
                      </li>
                      <li>
                        <p>确诊病例总数来源于<span> </span>
                          <a className="bold-link" href="https://github.com/CSSEGISandData/COVID-19" target="_blank" rel="noopener noreferrer">
                            约翰斯·霍普金斯大学
                          </a></p>
                      </li>
                    </ul></div>

                  <div className="survey-grid-item col-3"><h4>安全距离实施日期</h4>
                    <p>安全距离实施日期、学校关闭日期、紧急
                      状态发布日期以及人口流动数据
                      可反映就地避难令和居家隔离令
                      在减少人口流动进而减缓新冠疫情蔓延方面的成效。</p>
                    <ul>
                      <li>
                        <p>安全距离实施日期、学校关闭日期
                          以及紧急状态发布日期的数据来源于<span> </span>
                          <a className="bold-link" href="https://github.com/COVID19StatePolicy/SocialDistancing/blob/master/data/USstatesCov19distancingpolicy.csv" target="_blank" rel="noopener noreferrer">
                            新冠疫情国家政策库 (COVID-19 State Policy Repository)
                          </a></p>
                      </li>
                      <li>
                        <p>未流动人口百分比与人口流动
                          变化数据来源于 Facebook <span> </span>
                          <a className="bold-link" href="https://data.humdata.org/dataset/movement-range-maps" target="_blank" rel="noopener noreferrer">
                            Data For Good 人口流动范围图
                          </a></p>
                      </li>
                    </ul></div>

                  <div className="survey-grid-item col-3"><h4>风险因素</h4>
                    <p>风险因素是指造成新冠疫情传播
                      或人们感染重疾风险增加
                      的相关人口特征，包括总人口数、
                      人口密度以及年满 65 岁人口数。这三类
                      特征在人们理解其他
                      数据时颇有帮助。</p>
                    <ul>
                      <li>
                        <p>总人口数、每平方公里人口密度
                          和年满 65 岁人口百分比数据来源于<span> </span>
                          <a className="bold-link" href="https://censusreporter.org/" target="_blank" rel="noopener noreferrer">
                            美国人口普查局 (US Census Bureau)
                          </a><span> </span>
                          和 Facebook <span> </span>
                          <a className="bold-link" href="https://data.humdata.org/organization/facebook" target="_blank" rel="noopener noreferrer">
                            Data For Good 人口地图
                          </a></p>
                      </li>
                    </ul></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 20 }} />
      <FBFooter />
    </div>
  );
}
