import React from 'react';
import LineChartSVGTooltip from './LineChartSVGTooltip';

import { xValue, lineValue, yFormat, formatDate } from './util';

const TooltipText = ({
  activeRow,
  className,
  lineGenerator,
  yValue,
  currentMetric,
}) => (
  <text
    className={className}
    x={-10}
    y={-10}
    textAnchor={lineGenerator.x()(activeRow) < 300 ? 'start' : 'end'}
    style={{ userSelect: 'none', pointerEvents: 'none' }}
  >
    {lineValue(activeRow)}: {yFormat(currentMetric)(yValue(activeRow))} as of{' '}
    {formatDate(xValue(activeRow))}
  </text>
);

const TextTooltipWithDot = (props) => {
  const { activeRow, currentMetric, lineGenerator, yValue } = props;
  return (
    <LineChartSVGTooltip {...props}>
      <circle r={5} />
      <TooltipText
        activeRow={activeRow}
        currentMetric={currentMetric}
        className="svg-tooltip-stroke"
        lineGenerator={lineGenerator}
        yValue={yValue}
      />
      <TooltipText
        activeRow={activeRow}
        currentMetric={currentMetric}
        className="svg-tooltip"
        lineGenerator={lineGenerator}
        yValue={yValue}
      />
    </LineChartSVGTooltip>
  );
};

export default TextTooltipWithDot;
