import React, { useCallback, useMemo, useContext } from 'react';
import { withContentRect } from 'react-measure';
import { scaleTime, scaleLinear } from 'd3-scale';
import { extent, group } from 'd3-array';
import { line } from 'd3-shape';

import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import { DispatcherContext } from 'common/DispatcherContext';
import { Wrapper } from './styles';
import { xValue, getLineKey } from './util';
import MobilityLineChart from './MobilityLineChart';
import SymptomsLineChart from './SymptomsLineChart';
import { PRODUCTS, CURRENT_PRODUCT } from 'common/constants';
import NoDataPill from './NoDataPill';
import { isMobile } from 'styles/styles';

const lineChartComponentByProduct = {
  [PRODUCTS.MOBILITY]: MobilityLineChart,
  [PRODUCTS.SYMPTOM]: SymptomsLineChart,
};

const darkness = 175;
export const lineColor = `rgb(${darkness},${darkness},${darkness},0.4)`;
const xAxisHeight = 43;

const govtGanttHeight = CURRENT_PRODUCT === PRODUCTS.MOBILITY ? 42 : 0;

export const margin =
  CURRENT_PRODUCT === PRODUCTS.MOBILITY
    ? { top: 0, right: 18, bottom: 28, left: 60 }
    : isMobile
    ? { top: 12, right: 12, bottom: 20, left: 32 }
    : { top: 12, right: 58, bottom: 20, left: 56 };

export const LineChart = ({
  width,
  height,
  color,
  currentMetric,
  filteredData,
  parentTimeseriesFiltered,
  hasParent,
}) => {
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom - govtGanttHeight;

  const { dateExtent } = useContext(DataContext);
  const {
    activeRow,
    activeDate,
    activeMetric,
    visibleDateInterval,
  } = useContext(InteractionContext);

  currentMetric = currentMetric || activeMetric;

  const [minDate, maxDate] = dateExtent;
  const showPageLeft = minDate < visibleDateInterval[0];
  const showPageRight = maxDate > visibleDateInterval[1];

  const {
    dispatchSelectDate,
    dispatchActivateRegionAndDate,
    dispatchDeactivateRegion,
  } = useContext(DispatcherContext);

  const yValue = useCallback((d) => d[currentMetric.column], [currentMetric]);

  const xScale = useMemo(
    () => scaleTime().domain(visibleDateInterval).range([0, innerWidth]),
    [visibleDateInterval, innerWidth]
  );

  const allData = useMemo(() => {
    let allData = [];
    if (filteredData) {
      allData = allData.concat(filteredData);
    }
    if (parentTimeseriesFiltered) {
      allData = allData.concat(parentTimeseriesFiltered);
    }
    return allData;
  }, [filteredData, parentTimeseriesFiltered]);
  // This is how you can debug the useMemo optimizations getting lost.
  // useMemo(() => { console.log('innerHeight changed'); }, [innerHeight]);
  // useMemo(() => { console.log('allData changed'); }, [allData]);
  // useMemo(() => { console.log('yValue changed'); }, [yValue]);
  //
  const yScale = useMemo(
    () => scaleLinear().domain(extent(allData, yValue)).range([innerHeight, 0]),
    [innerHeight, allData, yValue]
  );

  const lineGenerator = useMemo(
    () =>
      line()
        .x((d) => xScale(xValue(d)))
        .y((d) => yScale(yValue(d)))
        .defined((d) => !isNaN(yValue(d))),
    [xScale, yScale, yValue]
  );
  // This is how you can debug the useMemo optimizations getting lost.
  // useMemo(() => { console.log('xScale changed'); }, [xScale]);
  // useMemo(() => { console.log('yScale changed'); }, [yScale]);
  // useMemo(() => { console.log('yValue changed'); }, [yValue]);

  const handleVoronoiHover = useCallback(
    (d) => {
      dispatchActivateRegionAndDate(d);
    },
    [dispatchActivateRegionAndDate]
  );
  const handleVoronoiClick = useCallback(
    (d) => {
      dispatchSelectDate(xValue(d));
    },
    [dispatchSelectDate]
  );
  const handleMouseOut = useCallback(() => {
    dispatchDeactivateRegion();
  }, [dispatchDeactivateRegion]);

  // Derive separate arrays, one for each line.
  const linesData = useMemo(() => {
    const groupedMap = group(filteredData, getLineKey);
    return Array.from(groupedMap.entries());
  }, [filteredData]);

  // Isolate the data for the active line.
  let activeLineData;
  if (activeRow) {
    activeLineData = linesData.find(
      ([lineKey]) => lineKey === getLineKey(activeRow)
    );
    if (activeLineData && activeLineData.length > 1) {
      activeLineData = activeLineData[1];
    } else {
      activeLineData = null;
    }
  } else {
    activeLineData = null;
  }

  const lineChartProps = {
    xScale,
    yScale,
    margin,
    innerWidth,
    innerHeight,
    govtGanttHeight,
    width,
    height,
    handleMouseOut,
    linesData,
    lineGenerator,
    lineColor,
    activeDate,
    currentMetric,
    xAxisHeight,
    showPageLeft,
    showPageRight,
    parentTimeseriesFiltered,
    activeLineData,
    activeRow,
    yValue,
    handleVoronoiHover,
    handleVoronoiClick,
    filteredData,
    color,
  };

  const LineChartComponent = lineChartComponentByProduct[CURRENT_PRODUCT];

  const hasChildren = filteredData.length > 0;

  // Show the pill if there is no data at all for the active metric
  const showNoDataPill = !hasChildren && !hasParent;

  return (
    <div>
      <LineChartComponent {...lineChartProps} />
      {showNoDataPill ? <NoDataPill /> : null}
    </div>
  );
};

// Inspired by https://www.npmjs.com/package/react-measure
export const MeasuredLineChart = withContentRect('bounds')(
  ({
    measureRef,
    measure,
    contentRect: {
      bounds: { width, height },
    },
    color,
    currentMetric,
    containerRef,
    filteredData,
    parentTimeseriesFiltered,
    hasParent,
  }) => {
    const { data } = useContext(DataContext);

    // Guard against case where data is loading,
    // for the first time (on page load only).
    if (!data) {
      return null;
    }

    // Waits for dimensions measurement.
    const hasMeasured = width && height;
    return (
      <Wrapper ref={measureRef}>
        {hasMeasured ? (
          <LineChart
            width={width}
            height={height}
            color={color}
            currentMetric={currentMetric}
            filteredData={filteredData}
            parentTimeseriesFiltered={parentTimeseriesFiltered}
            hasParent={hasParent}
          />
        ) : null}
      </Wrapper>
    );
  }
);
