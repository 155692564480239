import { useState, useCallback } from 'react';
import mapExplainer from './explainerIndex';
import getLanguage from 'common/language';

export const MapExplainer = (props) => {
  const [showDescription, setShowDescription] = useState(true);

  const handleClick = useCallback(() => {
    setShowDescription(!showDescription);
  }, [showDescription, setShowDescription]);
  const explainer = mapExplainer[getLanguage()] || mapExplainer['en'];
  return showDescription ? explainer(handleClick) : null;
};
