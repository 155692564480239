import React from 'react';
import { XAxis } from './XAxis';
import { YAxis } from './YAxis';
import { VoronoiOverlay } from './VoronoiOverlay';
import { SVG } from './styles';
import { PaginationButtons } from './PaginationButtons';
import GovtGantt from './GovtGantt';
import { yFormat } from './util';
import LineSeries from './LineSeries';
import ParentLine from './ParentLine';
import ActiveDateLine from './ActiveDateLine';
import ActiveLine from './ActiveLine';
import TextTooltipWithDot from './TextTooltipWithDot';
import YAxisLabel from './YAxisLabel';

const MobilityLineChart = ({
  xScale,
  yScale,
  margin,
  innerWidth,
  innerHeight,
  govtGanttHeight,
  width,
  height,
  handleMouseOut,
  linesData,
  lineGenerator,
  lineColor,
  activeDate,
  currentMetric,
  xAxisHeight,
  showPageLeft,
  showPageRight,
  parentTimeseriesFiltered,
  activeLineData,
  activeRow,
  yValue,
  handleVoronoiHover,
  handleVoronoiClick,
  filteredData,
}) => {
  return (
    <>
      <GovtGantt
        xScale={xScale}
        margin={margin}
        width={innerWidth}
        height={govtGanttHeight}
      />
      <SVG width={width} height={height} onMouseOut={handleMouseOut}>
        <g
          transform={`translate(${margin.left},${
            margin.top + govtGanttHeight
          })`}
        >
          <XAxis
            xScale={xScale}
            innerHeight={innerHeight}
            height={xAxisHeight}
            showPageLeft={showPageLeft}
            showPageRight={showPageRight}
          />
          <YAxis
            yScale={yScale}
            innerWidth={innerWidth}
            yFormat={yFormat(currentMetric)}
          />

          <LineSeries
            linesData={linesData}
            lineGenerator={lineGenerator}
            lineColor={lineColor}
          />
          <ParentLine
            lineData={parentTimeseriesFiltered}
            lineGenerator={lineGenerator}
          />
          <ActiveDateLine
            date={activeDate}
            metric={currentMetric}
            xScale={xScale}
            height={innerHeight}
          />

          <YAxisLabel height={height} label={currentMetric.label} />
          <ActiveLine lineGenerator={lineGenerator} lineData={activeLineData} />
          <TextTooltipWithDot
            lineGenerator={lineGenerator}
            activeRow={activeRow}
            currentMetric={currentMetric}
            yValue={yValue}
            activeLineData={activeLineData}
          />

          <g transform={`translate(0, ${innerHeight})`}>
            <PaginationButtons
              innerWidth={innerWidth}
              showPageLeft={showPageLeft}
              showPageRight={showPageRight}
            />
          </g>
        </g>
        <VoronoiOverlay
          margin={{ ...margin, top: margin.top + govtGanttHeight }}
          onHover={handleVoronoiHover}
          onClick={handleVoronoiClick}
          height={height - xAxisHeight}
          width={width}
          allData={filteredData}
          lineGenerator={lineGenerator}
        />
      </SVG>
    </>
  );
};

export default MobilityLineChart;
