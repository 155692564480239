import React from 'react';

import { GanttEntryContainer, GanttEntryLabel } from './styles';

const GovtGanttEntry = ({ labelText, ...props }) => {
  return (
    <>
      <GanttEntryContainer {...props}></GanttEntryContainer>
      <GanttEntryLabel {...props}>{labelText}</GanttEntryLabel>
    </>
  );
};

export default GovtGanttEntry;
