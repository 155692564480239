import styled, { css } from 'styled-components';

import { govtActionDotsColorScale } from '../scales';

export const GovtGanttContainer = styled.div`
  position: absolute;
  overflow: hidden;
  ${(props) => css`
    top: ${props.margin.top}px;
    left: ${props.margin.left}px;
    height: ${props.height}px;
    width: ${props.width}px;
  `}
`;

export const GovtGanttRowContainer = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  margin-bottom: 1px;
`;

const labelAndBackground = css`
  position: absolute;
  height: 10px;

  // TODO: This is untested
  ${(props) =>
    props.isEntirelyOffscreen
      ? css`
          visibility: hidden;
        `
      : ``}
`;

export const GanttEntryLabel = styled.label`
  z-index: 110;
  ${labelAndBackground}
  white-space: nowrap;
  font-size: 8px;
  line-height: 10px;
  font-weight: bold;
  ${({ xStart, xEnd }) =>
    xEnd - xStart < 100
      ? css`
          visibility: hidden;
        `
      : css``}
  ${({ isOffscreenLeft, xStart }) =>
    isOffscreenLeft
      ? css`
          left: 8px;
        `
      : css`
          left: ${xStart + 8}px;
        `}
`;

export const GanttEntryContainer = styled.div`
  z-index: 100;
  ${labelAndBackground}
  border-left: 1px solid white;

  ${(props) => css`
    left: ${props.xStart}px;
    width: ${props.xEnd - props.xStart}px;
    background-color: ${govtActionDotsColorScale(props.govtActionType)};
  `}
`;
