import React, { useContext } from 'react';

import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';

import './ScatterplotTooltipContent.scss';

const ScatterplotTooltipContent = () => {
  const { getRegionName, getDemographicsForRegion } = useContext(DataContext);
  const { activeRow, activeMetric, activeDemographicMetric } = useContext(
    InteractionContext
  );
  const xMetricValue = activeRow ? activeRow[activeMetric.column] : '';
  const rowDemographics = activeRow
    ? getDemographicsForRegion(activeRow.regionId)
    : null;
  const yMetricValue =
    activeRow && rowDemographics
      ? rowDemographics[activeDemographicMetric.column]
      : '';
  const locationName = activeRow ? getRegionName(activeRow.regionId) : '';
  return activeRow ? (
    <div>
      <div className="hrr-tooltip-item">
        <div className="hrr-tooltip-label">Location</div>
        <div className="hrr-tooltip-value-normal">{locationName}</div>
      </div>
      <div className="hrr-tooltip-item">
        <div className="hrr-tooltip-label">{activeMetric.label}</div>
        <div className="hrr-tooltip-value-normal">{xMetricValue}</div>
      </div>
      <div className="hrr-tooltip-item">
        <div className="hrr-tooltip-label">{activeDemographicMetric.label}</div>
        <div className="hrr-tooltip-value-normal">{yMetricValue}</div>
      </div>
    </div>
  ) : null;
};

export default ScatterplotTooltipContent;
