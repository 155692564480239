import React from 'react';
import { PopupEntry, PopupEntryLabel, PopupEntryValue } from './styles';
import { DEMOGRAPHIC_METRICS } from 'common/constants';

const MapPopupDemographicEntries = ({ demographics }) => (
  <div>
    {DEMOGRAPHIC_METRICS.map((metric) => {
      const value = demographics[metric.column];
      return !isNaN(value) ? (
        <PopupEntry key={metric.id}>
          <PopupEntryLabel highlight={false}>
            {metric.labelForMapTooltip}:
          </PopupEntryLabel>
          <PopupEntryValue normal={true}>
            {metric.display(value)}
          </PopupEntryValue>
        </PopupEntry>
      ) : null;
    })}
  </div>
);

export default MapPopupDemographicEntries;
