import React, { useContext, useState, useCallback } from 'react';
import { InfoSVG } from 'assets/svg/InfoSVG';
import { InteractionContext } from 'common/InteractionContext';
import { Wrapper, Tabs, Tab, Tooltip, TooltipBackground } from './styles';
import { metrics } from 'common/MetricComplex/metrics';
import { Icon } from 'components/common/Modal/styles';
import { isMobile } from 'styles/styles';

export const MetricComplex = ({ isEmbed }) => {
  const { activeMetric, setActiveMetric } = useContext(InteractionContext);

  const [tooltipMetric, setTooltipMetric] = useState();

  const closeTooltip = useCallback(
    (e) => {
      e.preventDefault();
      setTooltipMetric(null);
    },
    [setTooltipMetric]
  );

  return (
    <Wrapper isEmbed={isEmbed} isMobile={isMobile}>
      {tooltipMetric ? (
        <TooltipBackground onClick={closeTooltip}></TooltipBackground>
      ) : null}
      <Tabs isEmbed={isEmbed}>
        {metrics.map((metric, i) => (
          <Tab
            key={metric.column}
            onClick={() => {
              setActiveMetric(metric);
            }}
            isLast={i === metrics.length - 1}
            isActive={activeMetric === metric}
          >
            {metric.label}
            <Icon
              style={{ marginLeft: '5px' }}
              onClick={(event) => {
                event.preventDefault();
                tooltipMetric ? closeTooltip() : setTooltipMetric(metric);
              }}
            >
              <InfoSVG />
            </Icon>
            {tooltipMetric === metric ? (
              <Tooltip isMobile={isMobile} onClick={closeTooltip}>
                {metric.blurb}
              </Tooltip>
            ) : null}
          </Tab>
        ))}
      </Tabs>
    </Wrapper>
  );
};
