import React, { useMemo } from 'react';
import { Delaunay } from 'd3-delaunay';
export const VoronoiOverlay = ({
  margin,
  width,
  height,
  allData,
  lineGenerator,
  onHover,
  onClick,
  handleMouseOut,
}) => {
  // This is how you can debug the useMemo optimizations getting lost.
  //useMemo(() => { console.log('allData changed'); }, [allData]);
  //useMemo(() => { console.log('lineGenerator changed'); }, [lineGenerator]);
  //useMemo(() => { console.log('width changed'); }, [width]);
  //useMemo(() => { console.log('height changed'); }, [height]);
  //useMemo(() => { console.log('onHover changed'); }, [onHover]);
  //useMemo(() => { console.log('onClick changed'); }, [onClick]);
  //useMemo(() => { console.log('margin changed'); }, [margin]);

  //useEffect(() => {
  //  console.log('something changed');
  //}, [allData, lineGenerator, width, height, onHover, onClick, margin]);

  return useMemo(() => {
    // Let's leave this in - so devs can clearly see if/when
    // we lose the useMemo optimization again.

    const points = allData.map((d) => [
      lineGenerator.x()(d) + margin.left,
      lineGenerator.y()(d) + margin.top,
    ]);
    const delaunay = Delaunay.from(points);

    const bounds = [0, 0, width, height];

    const voronoi = delaunay.voronoi(bounds);
    return (
      <g className="voronoi" onMouseOut={handleMouseOut || (() => {})}>
        {points.map((point, i) => (
          <path
            key={i}
            onMouseEnter={() => onHover(allData[i])}
            onClick={() => onClick(allData[i])}
            d={voronoi.renderCell(i)}
          />
        ))}
      </g>
    );
  }, [
    allData,
    lineGenerator,
    width,
    height,
    onHover,
    onClick,
    margin,
    handleMouseOut,
  ]);
};
