import styled, { css } from 'styled-components';

import {
  mobileBreakpoint,
  mobileGap,
  Content,
  crispness,
  isMobile,
} from 'styles/styles';

export const Top = styled(Content)`
  min-height: ${(props) => (props.isMobile ? 100 : 50)}px;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isMobile ? '8px' : '0 20px 0 20px')};
  margin-bottom: 10px;
  @media (max-width: ${mobileBreakpoint}px) {
    margin-bottom: 1px;
  }
  font-size: 14px;
  justify-content: space-between;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  background-color: white;
  color: #4a585e;
  ${crispness}
`;

export const TopLeft = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  font-weight: bold;
`;

export const TopRight = styled.div`
  display: flex;
  align-items: center;
`;

export const Breadcrumbs = styled.div`
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: ${(props) => (props.isMobile ? 5 : 0)}px;
`;

export const Breadcrumb = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const Bottom = styled.div`
  flex: 1;
  display: flex;
  @media (max-width: ${mobileBreakpoint}px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  flex: 1;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${mobileBreakpoint}px) {
    margin-right: 10px;
    min-width: ${mobileBreakpoint}px;
  }
`;

export const Right = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

export const RightTop = styled.div`
  flex: 1;
  position: relative;
  border-radius: 3px;
  ${crispness}
`;

export const Legend = styled.img`
  position: absolute;
  bottom: 8px;
  left: 8px;
`;

export const RightBottom = styled.div`
  flex: 0.66;
  ${(props) =>
    props.isEmbed
      ? `margin-top: 1px;`
      : `
          margin-top: 10px;
          @media (max-width: ${mobileBreakpoint}px) {
            margin-top: 5px;
          }
        `}
  min-height: 230px;

  display: flex;
  flex-direction: column;
  border-radius: 3px;
  ${crispness}
`;

export const LineChartContainer = styled.div`
  ${() =>
    isMobile
      ? css`flex 1 0 200px;`
      : css`
          flex: 1 0 auto;
        `}

  display: flex;
  align-items: stretch;

  path.active-line {
    stroke: #374c67;
    stroke-width: 2;
  }
  path.parent-line {
    stroke: "#0972a6"
    stroke-width: 2;
  }
  line.active-date-line {
    stroke: #4a585e;
    stroke-width: 2;
  }
`;

export const LegendContainer = styled.div`
  background: white;
  flex: 0 0 2.5rem;
  display: flex;
  justify-content: space-between;
`;

export const AttributionWrapper = styled(Content)`
  padding: 10px 32px 10px 32px;
  margin-top: ${(props) => (props.isEmbed ? 1 : 10)}px;
  @media (max-width: ${mobileBreakpoint}px) {
    margin-top: ${mobileGap}px;
    padding: 5px;
  }
  display: flex;
  align-items: center;
  ${crispness}
`;

export const AttributionText = styled.div`
  line-height: 1.33;
  a {
    color: #0972a6;
    text-decoration: underline;
  }
  a:hover {
    color: #053f5d;
  }
`;

export const Mobile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileRow = styled.div`
  flex: 1;
`;

export const MobileMapContainer = styled.div`
  position: relative;
`;

export const LineChartAttribution = styled(Content)`
  padding-left: ${(props) => props.marginLeft}px;
  padding-bottom: 10px;
  padding-top: 5px;
`;

export const EmbedLink = styled.span`
  margin-right: 30px;
  cursor: pointer;
`;

export const EmbedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #4a585e;
  background-color: white;
  padding: 5px 10px 5px 10px;
  margin-bottom: 1px;
`;

export const EmbedBackground = styled.div`
  background-color: #e5ecf0;
`;

export const Open = styled.div`
  color: #00a4eb;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 0.5em;

  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-stretch: condensed;
  color: #0972a6;
`;

export const TimelineRangeButtonsContainer = styled.div`
  flex: 1 0 auto;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  background-color: white;
`;

export const TimelineRangeButton = styled.div`
  ${(props) =>
    props.active
      ? `
    background-color: #738695;
    color: #ffffff;
  `
      : `
    background-color: #e5ecf0;
    color: #738695;
  `}

  ${isMobile
    ? `
    height: 32px;
    min-width: 32px;
  `
    : `
    height: 16px;
    width: auto;
  `}
  cursor: pointer;
  font-size: 10px;
  padding: 1px 4px;
  border: 0;
  // background-color: #e5ecf0;
  border-radius: 3px;
  padding-top: 0;
  margin: 0;
  margin-top: 2px;
  margin-right: 2px;
  font-weight: bold;
  font-stretch: condensed;
  text-transform: uppercase;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;
`;
