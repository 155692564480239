import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { extent } from 'd3-array';
import { regionStatsCacheKey, useCache } from './useCache';
import { InteractionContext } from '../InteractionContext';
import { fetchDemographicsData } from './fetchDemographicsData';
import { fetchCentroidsData } from './fetchCentroidsData';
import { getParentRegionId } from './accessors';
import parseGovtActionsData from './parseGovtActionsData';
import { fetchMetadata } from './fetchMetadata';
import { fetchWorldStats, fetchCountryStats } from './fetchData';
import { getCountryCodeTypeFromRegionId } from './accessors';
import { rowHasMetric } from './util';

import { DEMOGRAPHIC_METRICS, WORLD, USA, INTERVAL_ID } from 'common/constants';

const postProcess = (asyncFunction, process) =>
  async function () {
    return process(await asyncFunction(arguments));
  };

export const FetchingContext = createContext({});

export const FetchingProvider = ({ children }) => {
  const cache = useCache();
  const { requestData, cacheHasKey, isRequestPending } = cache;
  const {
    selectedRegion,
    activeDate,
    setActiveDate,
    activeMetric,
    activeDemographicMetric,
    visibleDateInterval,
    setVisibleDateInterval,
    selectedTimeInterval,
    activeScatterplotFilter,
    selectedTimeResolution,
  } = useContext(InteractionContext);

  const metadata = requestData({
    cacheKey: 'metadata',
    goFetch: fetchMetadata,
  });

  const demographicsData = requestData({
    cacheKey: 'demographics',
    goFetch: fetchDemographicsData,
  });

  const {
    fipsToName,
    fipsToDemographics,
    getDemographicsForRegion,
    nameToFips,
  } = useMemo(() => {
    const nameLookup = {
      WORLD: 'World',
      USA: 'US',
    };
    const fipsLookup = {};
    const demographicsLookup = {};
    if (demographicsData) {
      demographicsData.forEach((d) => {
        const regionId = d.regionId === '' ? USA : d.regionId;
        nameLookup[regionId] = d.Name;
        fipsLookup[d.Name] = regionId;
        // Retreives only the properties we need from the demographic object
        demographicsLookup[regionId] = DEMOGRAPHIC_METRICS.reduce(
          (acc, demographicMetric) => {
            acc[demographicMetric.column] = d[demographicMetric.column];
            return acc;
          },
          {}
        );
      });
      // Creates a helper method to lookup demographics data given a regionId,
      // handling missing data gracefully
      const getDemographicsForRegion = (regionId) => {
        const demoDataForRegion = fipsToDemographics[regionId];
        const hasDemographics =
          demoDataForRegion &&
          DEMOGRAPHIC_METRICS.find(
            (m) => !isNaN(demoDataForRegion[m.column])
          ) !== undefined;
        return hasDemographics ? demoDataForRegion : null;
      };

      return {
        fipsToName: nameLookup,
        fipsToDemographics: demographicsLookup,
        getDemographicsForRegion,
        nameToFips: fipsLookup,
      };
    }
    return {
      fipsToName: null,
      fipsToDemographics: null,
      getDemographicsForRegion: null,
      nameToFips: null,
    };
  }, [demographicsData]);

  // Fetches the world stats
  const worldStats =
    metadata && fipsToName
      ? requestData({
          cacheKey: regionStatsCacheKey(WORLD),
          goFetch: fetchWorldStats({ metadata, fipsToName }),
        })
      : null;

  // Currently, a value of null signals that we look at the US.
  // TODO move to using country identifier instead of null.
  const isUS = selectedRegion === null;
  const selectedRegionId = isUS ? 'USA' : selectedRegion;

  const readyToFetchCountry = worldStats && metadata && fipsToName;

  let selectedRegionStats = null;
  if (readyToFetchCountry) {
    if (selectedRegionId === WORLD) {
      selectedRegionStats = worldStats;
    } else {
      selectedRegionStats = requestData({
        cacheKey: regionStatsCacheKey(selectedRegionId),

        // post-process the response to ensure that it doesn't contain entries for
        // the parent country itself (selectedRegionId).
        // This is because it is expected in downstream logic that this data only contains
        // entries for the children of selectedRegionId.
        // While most pipeline data adheres to this convention, the Confirmed COVID cases
        // data does not, so we need to strip out those entries here in the client.
        goFetch: postProcess(
          fetchCountryStats({
            regionId: selectedRegionId,
            fipsToName,
            metadata,
            countryCodeType: getCountryCodeTypeFromRegionId(selectedRegionId),
          }),
          (data) => data.filter((d) => d.regionId !== selectedRegionId)
        ),
      });
    }
  }

  // Derive the parent region data from selected region.
  const parentRegionId = getParentRegionId(selectedRegionId);
  let parentRegionStats = null;
  if (readyToFetchCountry) {
    if (parentRegionId === WORLD) {
      parentRegionStats = worldStats;
    } else {
      parentRegionStats = requestData({
        cacheKey: regionStatsCacheKey(parentRegionId),
        goFetch: fetchCountryStats({
          regionId: parentRegionId,
          fipsToName,
          metadata,
          countryCodeType: getCountryCodeTypeFromRegionId(parentRegionId),
        }),
      });
    }
  }

  const centroidsData = requestData({
    cacheKey: 'centroids',
    goFetch: fetchCentroidsData,
  });

  const {
    fipsToCentroid,
    getCoordinates,
    getBoundsFromRegion,
  } = useMemo(() => {
    let fipsToCentroid = null;
    if (centroidsData) {
      fipsToCentroid = {};
      centroidsData.forEach((d) => {
        fipsToCentroid[d.regionId] = d;
      });
    }

    const getCoordinates = (regionId) => {
      const centroid = fipsToCentroid[regionId];
      return centroid ? [centroid.x, centroid.y] : [0, 0];
    };

    const getBoundsFromRegion = (regionId) => {
      const c = fipsToCentroid[regionId];
      //console.log('centroidData', c);
      if (c && c.minx !== c.maxx && c.miny !== c.maxy) {
        // sanity check
        return [
          [+c.minx, +c.miny],
          [+c.maxx, +c.maxy],
        ];
      }
    };

    return { fipsToCentroid, getCoordinates, getBoundsFromRegion };
  }, [centroidsData]);

  const dateExtent = useMemo(() => {
    if (selectedRegionStats) {
      const result = extent(
        selectedRegionStats.filter((d) => rowHasMetric(activeMetric, d)),
        (d) => d.date
      );

      // When a selected region has no data for this metric, just default
      // to a dateExtent that covers the whole region dataset.
      if (result[0] === undefined) {
        return extent(selectedRegionStats, (d) => d.date);
      } else {
        return result;
      }
    }
  }, [selectedRegionStats, activeMetric]);

  useEffect(() => {
    if (!selectedRegionStats) return;
    let [minDate, maxDate] = dateExtent;

    // Initialize active date to most recent date after first data loads.
    if (!activeDate || activeDate > maxDate) {
      setActiveDate(maxDate);
    } else {
      if (activeDate < minDate) {
        setActiveDate(minDate);
      }
    }

    // Initialize visibleDateInterval.
    if (!visibleDateInterval) {
      if (selectedTimeInterval.intervalId !== INTERVAL_ID.ALL) {
        minDate = selectedTimeInterval.timeFunction.offset(
          maxDate,
          -selectedTimeInterval.timeOffset
        );
      }
    } else {
      // Adjust visible date interval when selectedTimeInterval changes
      if (selectedTimeInterval.intervalId !== INTERVAL_ID.ALL) {
        // Hinge on the max visible date,
        // snapped to the floored natural interval (e.g. the most recent Saturday, for weeks).
        maxDate = selectedTimeResolution.timeFunction.floor(
          visibleDateInterval[1]
        );
        minDate = selectedTimeInterval.timeFunction.offset(
          maxDate,
          -selectedTimeInterval.timeOffset
        );
      }
    }
    if (
      !visibleDateInterval ||
      visibleDateInterval[0].getTime() !== minDate.getTime() ||
      visibleDateInterval[1].getTime() !== maxDate.getTime()
    ) {
      setVisibleDateInterval([minDate, maxDate]);
    }
    //// Initialize visibleDateInterval.
    //if (selectedRegionStats && !visibleDateInterval) {
    //const minDate = timeWeek.offset(maxDate, -numWeeksToShow);
    //setVisibleDateInterval([minDate, maxDate]);
    //}
  }, [
    selectedRegionStats,
    activeDate,
    setActiveDate,
    visibleDateInterval,
    setVisibleDateInterval,
    dateExtent,
    selectedTimeInterval,
    activeMetric,
    selectedTimeResolution,
  ]);

  const govtActionsDataProvider = requestData({
    cacheKey: 'govtActions',
    goFetch: parseGovtActionsData,
  });

  // Derive the data filtered by the currently active date.
  // This value will be null as data is loading.
  const { activeDateData, activeDateDataWithDemographics } = useMemo(() => {
    if (selectedRegionStats && activeDate && getDemographicsForRegion) {
      const activeDateData = selectedRegionStats
        .filter((d) => d.date && d.date.getTime() === activeDate.getTime())
        .filter((d) => rowHasMetric(activeMetric, d));

      let activeDateDataWithDemographics = activeDateData
        .map((d) => {
          const rowDemographics = getDemographicsForRegion(d.regionId);
          return {
            ...d,
            hasDemographics: rowDemographics !== null,
            ...rowDemographics,
            isInScatterplotFilter: true,
          };
        })
        .filter((d) => d.hasDemographics);

      if (activeScatterplotFilter) {
        const {
          activeMetricBounds,
          activeDemographicMetricBounds,
        } = activeScatterplotFilter;
        activeDateDataWithDemographics = activeDateDataWithDemographics.map(
          (d) => {
            const activeMetricValue = d[activeMetric.column] || null;
            const activeDemographicMetricValue =
              d[activeDemographicMetric.column] || null;
            const isInScatterplotFilter =
              activeMetricValue !== null &&
              activeDemographicMetricValue !== null &&
              activeMetricValue > activeMetricBounds.min &&
              activeMetricValue < activeMetricBounds.max &&
              activeDemographicMetricValue >
                activeDemographicMetricBounds.min &&
              activeDemographicMetricValue < activeDemographicMetricBounds.max;
            return {
              ...d,
              isInScatterplotFilter,
            };
          }
        );
      }

      return { activeDateData, activeDateDataWithDemographics };
    }
    return { activeDateData: null, activeDateDataWithDemographics: null };
  }, [
    activeDate,
    activeMetric,
    activeDemographicMetric,
    activeScatterplotFilter,
    selectedRegionStats,
    getDemographicsForRegion,
  ]);

  const parentTimeseries = useMemo(() => {
    if (!parentRegionStats) return null;
    return parentRegionStats
      .filter((d) => d.regionId === selectedRegionId)
      .filter((d) => rowHasMetric(activeMetric, d));
  }, [parentRegionStats, selectedRegionId, activeMetric]);

  const cacheKey = regionStatsCacheKey(selectedRegion);
  const selectedRegionStatsAreFetching =
    !cacheHasKey(cacheKey) || isRequestPending(cacheKey);

  const value = {
    cache,
    visibleDateInterval,
    selectedRegionStats,
    activeDateData,
    activeDateDataWithDemographics,
    parentTimeseries,
    govtActionsDataProvider,
    dateExtent,
    fipsToName,
    fipsToDemographics,
    fipsToCentroid,
    getCoordinates,
    getBoundsFromRegion,
    getDemographicsForRegion,
    metadata,
    nameToFips,
    activeMetric,
    worldStats,
    selectedRegionStatsAreFetching,
  };

  return (
    <FetchingContext.Provider value={value}>
      {children}
    </FetchingContext.Provider>
  );
};
