import React, { useContext } from 'react';

import { InteractionContext } from 'common/InteractionContext';
import { metrics } from 'common/MetricComplex/metrics';
import { Legend } from './styles';

const ColorLegend = () => {
  const { activeMetric } = useContext(InteractionContext);
  return (
    <Legend
      src={
        activeMetric === metrics[0]
          ? 'images/relative_mobility_change.svg'
          : 'images/stayput.svg'
      }
    ></Legend>
  );
};

export default ColorLegend;
