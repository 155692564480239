import React, { useRef, useEffect } from 'react';
import { select } from 'd3-selection';
import { axisLeft } from 'd3-axis';

export const YAxis = ({ yScale, innerWidth, yFormat }) => {
  const ref = useRef();
  useEffect(() => {
    const yAxisG = select(ref.current);
    const yAxis = axisLeft(yScale)
      .tickSize(-innerWidth)
      .tickPadding(3)
      .ticks(5)
      .tickFormat(yFormat);
    yAxisG.call(yAxis);
  }, [innerWidth, yScale, yFormat]);
  return <g className="y-axis" ref={ref} />;
};
