import React from 'react';

import VerticalLine from './VerticalLine';

import './HoveredDateLine.scss';

const HoveredDateLine = (props) => (
  <VerticalLine className="hovered-date-line" {...props} />
);

export default HoveredDateLine;
