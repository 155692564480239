import React from 'react';
import '../styles.scss';
import classNames from 'classnames';

export default function glossary(showGlossary, onCloseGlossary) {
  // prettier-ignore
  return (
<div className={classNames('map-glossary', {hidden: !showGlossary,})}>
      <div className="glossary-header"><h3>Glossaire des termes utilisés sur la carte</h3><div className='close' onClick={onCloseGlossary}>
          <img alt="" src="svg/icon-close.svg" />
        </div>
      </div><ul className="glossary-body">
        <li><b className="term">Symptômes du COVID-19</b> - Les symptômes du COVID-19
          incluent la fièvre, accompagnée soit de toux, d’insuffisances ou de
          difficultés respiratoires, telles que définies dans les sondages de la CMU et de l’UMD.</li>
        <li><b className="term">Symptômes de la grippe</b> - Les symptômes de la grippe incluent
          la fièvre, accompagnée soit de maux de gorge ou de toux, tels que définis
          dans les sondages de la CMU et de l’UMD.</li>
        <li><b className="term">Pourcentage de personnes présentant des symptômes</b> -
          Le pourcentage estimé de personnes ayant eu des symptômes sur la base
          d’une moyenne sur 7 jours glissants.</li>
        <li><b className="term">Population totale</b> - Le nombre total
          d’habitants d’une zone géographique.</li>
        <li><b className="term">Densité de la population au km²</b> - Le nombre
          d’habitants au km² d’une zone géographique.</li>
        <li><b className="term">Pourcentage de personnes de 65 ans et +</b> - Le
          pourcentage de personnes âgées de 65 ans ou plus.</li>
        <li><b className="term">Total des cas de COVID-19 confirmés</b> - Le
          nombre total de cas de COVID-19 confirmés.</li>
        <li><b className="term">Évolution des déplacements</b> - Le pourcentage
          d’évolution des déplacements de la population par rapport à la moyenne
          pour le même jour de la semaine en février 2020.</li>
        <li><b className="term">
            Pourcentage de personnes en confinement
          </b><span> </span>
          - Le pourcentage de personnes d’une zone géographique qui ne se sont pas déplacées pendant 24 heures.</li>
        <li><b className="term">Fermeture des écoles</b> - La date de
          fermeture des écoles publiques dans la zone géographique en question.</li>
        <li><b className="term">État d’urgence</b> - La date du début de l’état
          d’urgence dans la zone géographique en question.</li>
        <li><b className="term">Distanciation physique</b> - La date de la mise en œuvre
          de la distanciation physique dans la zone géographique en question. La distanciation physique consiste à
          garder une distance de sécurité de 6 pieds (entre 1,5 et 2 mètres) entre les
          individus afin de limiter la propagation du COVID-19. Les données de distanciation physiques incluent
          les politiques de confinement mises en place par les gouvernements. Pour les
          zones en dehors des États-Unis, les chercheurs peuvent voir des
          données plus complètes qui incluent les données de distanciation physique
          et d’autres mesures de santé publiques de<span> </span>
          <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/phsm">
           l’Organisation mondiale de la Santé
          </a>
          .</li>
      </ul>
    </div>
  
);
}
