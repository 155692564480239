import React, { useContext, useEffect, useState } from 'react';
import { ascending } from 'd3-array';

import { DataContext } from 'common/DataContext';
import {
  getAdmin0FromAdmin1,
  isAdmin0,
  isAdmin1,
} from 'common/DataContext/accessors';
import { InteractionContext } from 'common/InteractionContext';
import { GOVT_ACTION_TEXT, GOVT_ACTION_TYPES, WORLD } from 'common/constants';
import { dateLabelFormat } from '../util';

import GovtGanttRow from './GovtGanttRow';
import { GovtGanttContainer } from './styles';

const createLabelText = ({ govtActionType, govtActionDataset, startDate }) =>
  `${GOVT_ACTION_TEXT[govtActionDataset][govtActionType]}, ${dateLabelFormat(
    startDate
  )}`;

const createGanttRowEntry = ({
  startDate,
  endDate,
  govtActionType,
  govtActionDataset,
  xScale,
  visibleXStart,
}) => {
  const xStart = xScale(startDate);
  const xEnd = xScale(endDate);
  const isOffscreenLeft = xStart < visibleXStart;
  const isEntirelyOffscreen = xEnd < visibleXStart;
  return {
    xStart,
    xEnd,
    labelText: createLabelText({
      govtActionType,
      govtActionDataset,
      startDate,
    }),
    isOffscreenLeft,
    isEntirelyOffscreen,
  };
};

const createGanttRowData = ({ govtActionType }) => ({
  govtActionType,
  entries: [],
});

const GovtGantt = ({ xScale, margin, height, width }) => {
  const [chartData, setChartData] = useState({ ganttRows: [] });
  const { govtActionsDataProvider } = useContext(DataContext);
  const { activeRow, selectedRegion, visibleDateInterval } = useContext(
    InteractionContext
  );

  useEffect(() => {
    const ganttRows = [];
    let data;
    if (selectedRegion === WORLD) {
      // Shows the hovered country govt actions
      data = govtActionsDataProvider.getActionsByTypeForRegion(
        activeRow ? activeRow.regionId : null
      );
    } else if (isAdmin0(selectedRegion)) {
      // Shows the currently selected country govt actions or country's
      // admin1 govt actions if hovered
      data =
        govtActionsDataProvider.getActionsByTypeForRegion(
          selectedRegion,
          activeRow ? activeRow.regionId : null
        ) ||
        govtActionsDataProvider.getActionsByTypeForRegion(selectedRegion, null);
    } else if (isAdmin1(selectedRegion)) {
      // Shows the current country's admin1 govt actions when admin1 is
      // hovered, or just the country's govt actions if there is no admin1
      // data.
      data =
        govtActionsDataProvider.getActionsByTypeForRegion(
          getAdmin0FromAdmin1(selectedRegion),
          selectedRegion
        ) ||
        govtActionsDataProvider.getActionsByTypeForRegion(
          getAdmin0FromAdmin1(selectedRegion),
          null
        );
    }
    data = data || [];
    const [visibleXStart] = visibleDateInterval.map(xScale);
    data.forEach((points, govtActionType) => {
      if (govtActionType === GOVT_ACTION_TYPES.OTHER) {
        return;
      }

      const ganttRow = createGanttRowData({ govtActionType });
      ganttRows.push(ganttRow);

      const sortedPoints = points.sort((a, b) =>
        ascending(a.dateEnacted, b.dateEnacted)
      );
      let i;
      for (i = 0; i < sortedPoints.length; i++) {
        const p = sortedPoints[i];
        let startDate = p.dateEnacted;
        let endDate = p.dateEnded || xScale.domain()[1];
        if (i < points.length - 1) {
          endDate = points[i + 1].dateEnacted;
        }
        const { govtActionDataset } = p;
        ganttRow.entries.push(
          createGanttRowEntry({
            startDate,
            endDate,
            govtActionType,
            govtActionDataset,
            xScale,
            visibleXStart,
          })
        );
      }
    });

    setChartData({
      ganttRows,
    });
  }, [
    govtActionsDataProvider,
    selectedRegion,
    activeRow,
    xScale,
    setChartData,
    visibleDateInterval,
  ]);

  // Prepares data for gantt chart.
  return (
    <GovtGanttContainer margin={margin} height={height} width={width}>
      {chartData.ganttRows.map((r) => (
        <GovtGanttRow key={r.govtActionType} {...r} />
      ))}
    </GovtGanttContainer>
  );
};

export default GovtGantt;
