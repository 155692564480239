import React, { useContext } from 'react';
import { DataContext } from 'common/DataContext';
import { InteractionContext } from 'common/InteractionContext';
import { PopupHeading } from './styles';

const MapPopupHeading = () => {
  const {
    activeRow: { regionId },
  } = useContext(InteractionContext);
  const {
    data: { fipsToName },
  } = useContext(DataContext);
  return (
    <div>
      <PopupHeading>{fipsToName[regionId]}</PopupHeading>
    </div>
  );
};

export default MapPopupHeading;
