import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { stringify, parse } from 'query-string';
import { DispatcherContext } from 'common/DispatcherContext';
import { EmbedLayout } from '../EmbedLayout';
import { CopyButton } from './CopyButton';
import {
  Wrapper,
  Content,
  Title,
  TitleArea,
  Close,
  Heading,
  Copy,
  Snippet,
  Top,
  SectionTitle,
  SnippetCode,
  SnippetCopyButton,
  EmbedPreview,
} from './styles';
import locale from 'common/locale';

const embedRootURL = 'https://visualization.covid19mobility.org/';

export const EmbedDrawer = () => {
  const { dispatchToggleEmbedDrawer } = useContext(DispatcherContext);
  const { search } = useLocation();
  const query = parse(search);

  // Keep current params.
  // Turn on embed flag.
  const embedQuery = { ...query, embed: null };
  const embedSearch = stringify(embedQuery);
  const srcURL = embedRootURL + '?' + embedSearch;
  const snippet = `<iframe src="${srcURL}" width="100%" height="736px" style="border-radius: 5px; border: solid 1px #e5ecf0; background-color: #ffffff;"> </iframe>`;

  return (
    <Wrapper>
      <Content>
        <Top>
          <TitleArea>
            <Title>{locale('i18n_drawer1_exclude')}</Title>
            <Close onClick={dispatchToggleEmbedDrawer}>
              {locale('i18n_drawer2_exclude')}
            </Close>
          </TitleArea>
          <Heading>{locale('i18n_drawer3_exclude')}</Heading>
          <Copy>{locale('i18n_drawer4_exclude')}</Copy>
          <Snippet>
            <SnippetCode value={snippet} readOnly autoSelect></SnippetCode>
            <SnippetCopyButton>
              <CopyButton textToCopy={snippet} />
            </SnippetCopyButton>
          </Snippet>
        </Top>
        <SectionTitle>{locale('i18n_drawer5_exclude')}</SectionTitle>
        <EmbedPreview>
          <EmbedLayout />
        </EmbedPreview>
      </Content>
    </Wrapper>
  );
};
