import React, { useContext, useCallback, useMemo } from 'react';
import Select from 'react-select';

import { DataContext } from 'common/DataContext';
import { InteractionContext } from 'common/InteractionContext';
import { DispatcherContext } from 'common/DispatcherContext';
import { supportedCountries } from 'data/supportedCountries';
import { breadcrumbsAncestors } from 'common/breadcrumbs';
import { rowHasMetric } from 'common/DataContext/util';

import {
  FloatingDropdownContainer,
  FullWidthDropdownContainer,
  CenteredDropdownContainer,
} from './styles';
import { WORLD, CURRENT_PRODUCT, PRODUCTS } from 'common/constants';

const CountryDropdown = () => {
  // This ensures the dropdown menu items are on top of the map popups
  const selectStyles = {
    menu: () => ({
      zIndex: '10000',
    }),
  };

  const { getRegionName, worldStats } = useContext(DataContext);
  const { selectedRegion, activeMetric } = useContext(InteractionContext);
  const { dispatchChangeSelectedRegion } = useContext(DispatcherContext);

  // Generates a list of countries for dropdown.
  let supportedCountryCodes;
  supportedCountryCodes = useMemo(() => {
    if (CURRENT_PRODUCT === PRODUCTS.MOBILITY) {
      // On mobility app, uses world data to cache a list of countries that have
      // data for the current metric.
      if (worldStats) {
        return Array.from(
          // Filters data from worldStats with metric, reduces into a unique
          // set of regionId values.
          worldStats
            .filter((d) => rowHasMetric(activeMetric, d))
            .reduce((acc, curr) => {
              acc.add(curr.regionId);
              return acc;
            }, new Set())
        );
      }
      return [];
    } else if (CURRENT_PRODUCT === PRODUCTS.SYMPTOM) {
      // On symptom app, uses hard-coded list of countries always for dropdown.
      return Object.keys(supportedCountries).filter(
        (countryCode) => supportedCountries[countryCode]
      );
    }
  }, [worldStats, activeMetric]);

  const menuItems = [
    {
      value: WORLD,
      label: 'World',
    },
  ].concat(
    supportedCountryCodes.map((regionId) => {
      return {
        value: regionId,
        label: getRegionName(regionId),
      };
    })
  );
  const currentMenuItem = menuItems.find(({ value }) =>
    selectedRegion === WORLD
      ? value === WORLD
      : value === breadcrumbsAncestors(selectedRegion)[1]
  );
  const onChange = useCallback(
    ({ value }) => dispatchChangeSelectedRegion(value),
    [dispatchChangeSelectedRegion]
  );
  return (
    <Select
      onChange={onChange}
      value={currentMenuItem}
      options={menuItems}
      styles={selectStyles}
    />
  );
};

export const FloatingCountryDropdown = () => (
  <FloatingDropdownContainer>
    <CountryDropdown />
  </FloatingDropdownContainer>
);

export const FullWidthCountryDropdown = () => (
  <FullWidthDropdownContainer>
    <CountryDropdown />
  </FullWidthDropdownContainer>
);

export const CenteredCountryDropdown = () => (
  <CenteredDropdownContainer>
    <CountryDropdown />
  </CenteredDropdownContainer>
);

export default CountryDropdown;
