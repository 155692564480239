import React, { useContext } from 'react';
import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import { regionDescriptor } from 'common/DataContext/accessors';
import {
  Wrapper,
  Line,
  GovtLegendItem,
  LegendLabel,
  LineLegendContainer,
  GovtActionsLegendContainer,
} from './styles';
import { isMobile } from 'styles/styles';

import { GOVT_ACTION_TYPES, GOVT_ACTION_TEXT } from 'common/constants';
import { LegendActionDot } from 'components/common/LineChart/DailyActionDotXAxis/ActionDot';
import { margin } from 'components/common/LineChart';

export const LineLegend = ({ lineColor }) => {
  const { selectedRegion, activeRow } = useContext(InteractionContext);

  const { getRegionName, parentTimeseries } = useContext(DataContext);

  const title = regionDescriptor(selectedRegion, getRegionName);

  const parentTitle =
    parentTimeseries &&
    parentTimeseries[0] &&
    getRegionName(parentTimeseries[0].regionId);

  return (
    <LineLegendContainer isMobile={isMobile}>
      <LegendLabel>{title}</LegendLabel>
      <Line color={lineColor} />
      {parentTitle ? (
        <>
          <LegendLabel>{parentTitle}</LegendLabel>
          <Line color="#0972a6" />
        </>
      ) : null}
      {activeRow ? (
        <>
          <LegendLabel>{getRegionName(activeRow.regionId)}</LegendLabel>
          <Line color="#013149" />
        </>
      ) : null}
    </LineLegendContainer>
  );
};

const GovtActionsLegendContents = ({ asterisk = true }) => (
  <>
    {[
      GOVT_ACTION_TYPES.SCHOOL_CLOSED,
      GOVT_ACTION_TYPES.EMERGENCY,
      GOVT_ACTION_TYPES.SHELTER_IN_PLACE,
    ].map((a) => (
      <GovtLegendItem key={a}>
        <LegendLabel>{`${asterisk ? '*' : ''}${
          GOVT_ACTION_TEXT[a]
        }`}</LegendLabel>
        <svg height={10} width={10}>
          <LegendActionDot hasGovtActions={true} govtActionTypes={[a]} />
        </svg>
      </GovtLegendItem>
    ))}
  </>
);

export const GovtActionsLegend = () => (
  <GovtActionsLegendContainer isMobile={isMobile} margin={margin}>
    <GovtActionsLegendContents asterisk={!isMobile} />
  </GovtActionsLegendContainer>
);

export const Legend = (props) => (
  <Wrapper {...props} isMobile={isMobile} margin={margin}>
    <LineLegend {...props} />
  </Wrapper>
);
