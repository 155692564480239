import React, { useContext, useMemo, useCallback } from 'react';
import { group, extent } from 'd3-array';
import { line } from 'd3-shape';
import { scaleTime, scaleLinear } from 'd3-scale';
import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
// import { curveCatmullRom, curveBasis } from 'd3-shape';
import { DataContext } from 'common/DataContext';
import { rowHasMetric } from 'common/DataContext/util';
import { InteractionContext } from 'common/InteractionContext';
import { metrics } from 'common/MetricComplex/metrics';
import {
  SVG,
  SmallMultipleHeader,
  SmallMultiplePercentage,
  DateLabel,
  SmallMultipleContainer,
} from './styles.js';

const lineValue = (d) => d.name;

const xValue = (d) => d.date;

// export const formatDate = timeFormat('%m/%d');
export const formatDate = timeFormat('%B %d');

export const margin = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const SmallMultiple = ({
  lineKey,
  lineData,
  width,
  height,
  parentTimeSeries,
  yScale,
  yValue,
}) => {
  const { activeMetric, visibleDateInterval } = useContext(InteractionContext);

  const lineColor = activeMetric.colors[1];

  const innerWidth = width - margin.left - margin.right;

  const percentFormat = format('.0%');
  const yFormat = (activeMetric) => (d) =>
    (d > 0 && activeMetric === metrics[0] ? '+' : '') + percentFormat(d);

  const xScale = useMemo(
    () => scaleTime().domain(extent(lineData, xValue)).range([0, innerWidth]),
    [lineData, innerWidth]
  );

  // Moving this upwards in the component tree so yScale is shared between elements
  // If you compute the scale here, it is independent for each small multiple
  // const yScale = useMemo(
  //   () =>
  //     scaleLinear().domain(extent(lineData, yValue)).range([innerHeight, 0]),
  //   [innerHeight, lineData, yValue]
  // );

  const lineGenerator = useMemo(
    () =>
      line()
        .x((d) => xScale(xValue(d)))
        .y((d) => yScale(yValue(d)))
        // .curve(curveCatmullRom.alpha(0.5))
        // .curve(curveBasis)
        .defined((d) => !isNaN(yValue(d))),
    [xScale, yScale, yValue]
  );

  return (
    <SmallMultipleContainer style={{ color: lineColor }}>
      <SmallMultipleHeader>{lineKey}</SmallMultipleHeader>
      <SmallMultiplePercentage>
        {yFormat(activeMetric)(yValue(lineData[lineData.length - 1]))} as of{' '}
        {formatDate(visibleDateInterval[1])}
      </SmallMultiplePercentage>
      <SVG width={width} height={height}>
        {/* 
        SVG axis labels
        <XAxisLabelText id="x-axis-start-label" y={height - 2} x={0}>
          {formatDate(visibleDateInterval[0])}
        </XAxisLabelText>

        <XAxisLabelText
          id="x-axis-end-label"
          text-anchor="end"
          y={height - 2}
          x={width - 40}
        >
          {formatDate(visibleDateInterval[1])}
        </XAxisLabelText> */}
        {parentTimeSeries ? (
          <path
            d={lineGenerator(parentTimeSeries)}
            fill="none"
            stroke="#CCC"
            strokeWidth={2}
          />
        ) : null}
        <path
          d={lineGenerator(lineData)}
          fill="none"
          stroke={lineColor}
          strokeWidth={2}
        />
      </SVG>
      <div>
        <DateLabel>{formatDate(visibleDateInterval[0])}</DateLabel>
        <DateLabel style={{ textAlign: 'right' }}>
          {formatDate(visibleDateInterval[1])}
        </DateLabel>
      </div>
    </SmallMultipleContainer>
  );
};

export const SmallMultiplesViz = () => {
  const { data, parentTimeSeries } = useContext(DataContext);
  const { visibleDateInterval, activeMetric } = useContext(InteractionContext);
  const allData = data.mobilityStats;

  const width = 165;
  const height = 165;

  // Filter mobility stats by visible date range.
  const filteredData = useMemo(() => {
    if (!allData || !visibleDateInterval) return null;
    const [minDate, maxDate] = visibleDateInterval;
    return allData
      .filter(({ date }) => date >= minDate && date <= maxDate)
      .filter((r) => rowHasMetric(activeMetric, r));
  }, [allData, visibleDateInterval, activeMetric]);

  // Derive separate arrays, one for each line.
  const grouped = useMemo(() => {
    const groupedMap = group(filteredData, lineValue);
    return Array.from(groupedMap.entries());
  }, [filteredData]);

  const innerHeight = height - margin.top - margin.bottom;

  const yValue = useCallback((d) => d[activeMetric.column], [activeMetric]);

  const yScale = useMemo(
    () => scaleLinear().domain(extent(allData, yValue)).range([innerHeight, 0]),
    [innerHeight, yValue, allData]
  );

  return grouped.map(([lineKey, lineData]) => (
    <SmallMultiple
      key={lineKey}
      lineKey={lineKey}
      lineData={lineData}
      parentTimeSeries={parentTimeSeries}
      visibleDateInterval={visibleDateInterval}
      width={width}
      height={height}
      yScale={yScale}
      yValue={yValue}
    />
  ));
};
