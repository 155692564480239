// @flow
import React, { createContext, useState, useCallback, useEffect } from 'react';
import type { Context, Node } from 'react';
import type {
  InteractionContextType,
  Interval,
  LineChartSelectedMetric,
} from 'types';
import { useURLState } from 'common/useURLState';
import {
  TIME_RESOLUTIONS,
  LINE_CHARTS_CONFIG,
  INTERVALS,
} from 'common/constants';
import { metricsById } from 'common/MetricComplex/metrics';

// This context gives deep children access to the interaction state.
//  * Active row (hover)
//  * Selected rows (click)
export const InteractionContext: Context<InteractionContextType> = createContext(
  {}
);

export const InteractionProvider = (props: { children: Node }) => {
  const { children } = props;
  const [activeRow, setActiveRow] = useState(null);
  const [activeNoDataRow, setActiveNoDataRow] = useState(null);
  const [activeRowIsPinned, setActiveRowIsPinned] = useState(false);
  const [isEmbedDrawerOpen, setIsEmbedDrawerOpen] = useState(false);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState(
    INTERVALS.find(({ isDefault }: Interval) => isDefault)
  );
  const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);

  const {
    selectedRegion,
    setSelectedRegion,

    activeMetric,
    setActiveMetric,

    activeDate,
    setActiveDate,

    international,

    visibleDateInterval,
    setVisibleDateInterval,

    isSmallMultiplesOpen,
    setIsSmallMultiplesOpen,
  } = useURLState();

  // Possible values 'ascending', 'descending'
  const [lollipopSortOrder, setLollipopSortOrder] = useState('ascending');

  // Second selected metric in the bivariate map is a demographic metric or null
  const [activeDemographicMetric, setActiveDemographicMetric] = useState(null);

  // Selected metrics for each line chart in the right panel
  const [
    lineChartSelectedMetrics: Array<LineChartSelectedMetric>,
    setLineChartSelectedMetrics,
  ] = useState(
    LINE_CHARTS_CONFIG.map(({ defaultSelectedMetricID, activeLineColor }) => {
      return {
        ...metricsById[defaultSelectedMetricID],
        activeLineColor: activeLineColor,
      };
    })
  );

  const [activeScatterplotFilter, setActiveScatterplotFilter] = useState(null);

  // TODO data loading will depend on this
  // (different endpoints for weekly vs. daily data)
  // See https://github.com/stamen/covid19mobility/issues/461
  const [selectedTimeResolution, setSelectedTimeResolution] = useState(
    TIME_RESOLUTIONS.find(({ isDefault }) => isDefault)
  );

  // Use a default sort for each metric.
  useEffect(() => {
    setLollipopSortOrder(activeMetric.flipSort ? 'descending' : 'ascending');
  }, [activeMetric]);

  const [bounds, setBounds] = useState(null);

  const value = {
    activeRow,
    activeNoDataRow,
    setActiveNoDataRow,
    selectedRegion,

    // Navigating to a new selected region
    // also clears the active row, which if present
    // is no longer valid in any case.
    setSelectedRegion: useCallback(
      (regionId) => {
        setActiveRow(null);
        setSelectedRegion(regionId);
      },
      [setActiveRow, setSelectedRegion]
    ),

    activeMetric,
    setActiveMetric,
    activeRowIsPinned,
    setActiveRowIsPinned,
    lollipopSortOrder,
    setLollipopSortOrder,
    activeDate,
    setActiveDate,
    setActiveRow,
    international,
    visibleDateInterval,
    setVisibleDateInterval,
    isEmbedDrawerOpen,
    setIsEmbedDrawerOpen,
    isSmallMultiplesOpen,
    setIsSmallMultiplesOpen,
    selectedTimeInterval,
    setSelectedTimeInterval,
    activeDemographicMetric,
    setActiveDemographicMetric,
    lineChartSelectedMetrics,
    setLineChartSelectedMetrics,
    selectedTimeResolution,
    setSelectedTimeResolution,
    isGlossaryOpen,
    setIsGlossaryOpen,
    activeScatterplotFilter,
    setActiveScatterplotFilter,
    bounds,
    setBounds,
  };

  return (
    <InteractionContext.Provider value={value}>
      {children}
    </InteractionContext.Provider>
  );
};
