import React from 'react';
import styled, { css } from 'styled-components';

import { govtActionDotsColorScale } from '../scales';
//import { isMobile } from '../../styles';

const Circle = styled.circle`
  stroke: #4a585e;
  stroke-width: 1px;

  fill: white;

  ${({ govtActionType }) =>
    govtActionType &&
    css`
      fill: ${govtActionDotsColorScale(govtActionType)};
    `}
`;

const r = 2.5;
const outerR = 4.5;
const ActionDot = ({ x, hasGovtActions, govtActionTypes, t }) => {
  //let isFirstDayOfWeek = true;
  //if (t) {
  //isFirstDayOfWeek = t.getDay() === 0;
  //}
  if (hasGovtActions) {
    if (govtActionTypes.length === 2) {
      return (
        <>
          <Circle
            r={outerR}
            cx={x}
            cy={0}
            govtActionType={govtActionTypes[1]}
          ></Circle>
          <Circle
            r={r}
            cx={x}
            cy={0}
            govtActionType={govtActionTypes[0]}
          ></Circle>
        </>
      );
    } else {
      return (
        <Circle
          r={r}
          cx={x}
          cy={0}
          govtActionType={govtActionTypes[0]}
        ></Circle>
      );
    }
  } else {
    return <Circle r={r} cx={x} cy={0}></Circle>;
    //if (isMobile && isFirstDayOfWeek || !isMobile) {
    //return <Circle r={r} cx={x} cy={0}></Circle>;
    //} else {
    //return null;
    //}
  }
};

export const LegendActionDot = (props) => (
  <g transform={`translate(${5},${5})`}>
    <ActionDot x={0} {...props} />
  </g>
);

export default ActionDot;
