import styled from 'styled-components';

export const Snippet = styled.div`
  background-color: #738695;
  color: white;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  align-items: center;
`;

export const SnippetCode = styled.input`
  overflow: hidden;
  border: none;
  background-color: #738695;
  flex: 1;
  padding-right: 10px;
  color: white;
`;

export const Eyebrow = styled.h4`
  color: rgba(80, 94, 107, 0.7);
  text-transform: uppercase;
  letter-spacing: 3.6px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
`;

export const EmbedParagraph = styled.p`
  font-size: 18px;
  line-height: 27px;
  color: #505e6b;
`;

export const EmbedContentLink = styled.a`
  color: #344854;
  font-weight: bold;
`;

export const EmbedIntroHeader = styled.h3`
  font-family: 'Optimistic Display';
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  color: #505e6b;
  padding-bottom: 20px;
`;

export const EmbedControlsHeader = styled.h2`
  font-family: 'Facebook Reader';
  font-size: 18px;
  line-height: 27px;
  color: #505e6b;
  border-bottom: 2px solid #677b8c;
  width: fit-content;
  margin-bottom: 20px;
`;

export const EmbedSection = styled.div`
  max-width: 1216px;
  margin: 0 auto;
  padding: 24px 64px;
`;
