import { timeFormat } from 'd3-time-format';
import { format } from 'd3-format';

import { metrics } from 'common/MetricComplex/metrics';

const percentFormat = format('.2~%');
export const dateLabelFormat = timeFormat('%b %d');
export const xValue = (d) => d.date;
export const lineValue = (d) => d.name;
export const getLineKey = (d) => d.regionId;
export const formatDate = timeFormat('%m/%d');
export const yFormat = (activeMetric) => (d) =>
  (d > 0 && activeMetric === metrics[0] ? '+' : '') + percentFormat(d);
