import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  color: #4a585e
  font-size: 18px;
`;

export const Content = styled.div`
  max-width: 1060px;
  flex: 1;
`;

export const Top = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const Title = styled.div`
  font-size: 25px;
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
`;

export const Close = styled.div`
  color: #00a4eb;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
`;

export const Heading = styled.div`
  font-weight: bold;
`;

export const Copy = styled.p``;

export const SectionTitle = styled.div`
  font-size: 12px;
  color: #738695;
`;

export const Snippet = styled.div`
  background-color: #738695;
  color: white;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  align-items: center;
`;

export const SnippetCode = styled.input`
  overflow: hidden;
  border: none;
  background-color: #738695;
  flex: 1;
  padding-right: 10px;
  color: white;
`;

export const SnippetCopyButton = styled.div``;

export const EmbedPreview = styled.div`
  border: solid 1px #e5ecf0;
  box-shadow: 1px 1px 0px #e5ecf0, -1px 1px 0px #e5ecf0, 1px -1px 0px #e5ecf0,
    -1px -1px 0px #e5ecf0;
  border-radius: 5px;
`;
