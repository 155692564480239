import { useCallback, useMemo } from 'react';
import { stringify as stringifyQueryString } from 'query-string';

const identity = (d) => d;

export const useQueryParameter = ({
  name,
  stringify = identity,
  parse = identity,
  defaultValue = null,
  query,
  push,
}) => {
  const queryValue = query[name];

  const parameter = useMemo(() => parse(queryValue) || defaultValue, [
    queryValue,
    defaultValue,
    parse,
  ]);

  const setParameter = useCallback(
    (value) => {
      push({
        search: stringifyQueryString({
          ...query,
          [name]: value === defaultValue ? undefined : stringify(value),
        }),
      });
    },
    [name, stringify, push, query, defaultValue]
  );

  return [parameter, setParameter];
};
