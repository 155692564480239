import React, { useContext, useCallback, useMemo } from 'react';
import ReactMapboxGl, { Source, ZoomControl } from 'react-mapbox-gl';
import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import { isMobile } from 'styles/styles';
import {
  WORLD,
  REGION_TYPES,
  biVariatePallette,
  CURRENT_PRODUCT,
  PRODUCTS,
} from 'common/constants';
import { MapPopup } from 'components/common/MapPopup';
import LineLayer from './LineLayer';
import FillLayer from './FillLayer';
import {
  useMobileMapInteractions,
  useDesktopMapInteractions,
} from './interactionHooks';
import { useDebounced } from './useDebounced';

import {
  isAdmin0,
  isUSStateFIPS,
  getParentRegionId,
  isDigit,
} from 'common/DataContext/accessors';
import {
  transparentFillCondition,
  createBucketsForData,
  createBivariateBucketsForData,
  bucketFillCondition,
  bivariateBucketFillCondition,
} from './util';
import { addPostfix } from 'common/DataContext/util';
import {
  proj,
  mapboxStyle,
  tileAdminKeys,
  mapboxSources,
  accessToken,
  mapboxLayers,
} from './mapConfig';
import SymbolLayer from './SymbolLayer';

import './styles.scss';

const dragRotate = false;
const pitchWithRotate = false;
const scrollZoom = false;
const dragPan = !isMobile;
const touchAction = isMobile ? 'pan-y' : 'none';
const logoPosition = 'bottom-right';
const Mapbox = ReactMapboxGl({
  accessToken,
  dragRotate,
  pitchWithRotate,
  scrollZoom,
  dragPan,
  touchAction,
  logoPosition,
});

// TODO: This remains different than `isAdmin1` elsewhere.
export const isAdmin1 = (regionId) =>
  regionId &&
  (isUSStateFIPS(regionId) ||
  (regionId.length === 3 &&
    isAdmin0(regionId.substring(0, 2)) &&
    !isDigit(regionId[0]) &&
    !isDigit(regionId[1])) || // note, sometimes the third char is not a digit
    (regionId.length > 3 &&
      !isDigit(regionId[0]) &&
      !isDigit(regionId[1]) &&
      !isDigit(regionId[2]) &&
      '.' === regionId[3] &&
      regionId.substring(4).indexOf('.') === -1)); // confirm it does not have another dot

// This was the only functional difference from `getParentRegionId` - WORLD instead of null at the top layer
const getParent = (regionId) => getParentRegionId(regionId) || WORLD;

const makeGetValue = (key) => (d) => d[key];

export const Map = ({ isEmbed }) => {
  const {
    activeRow: activeRowNotDebounced,
    activeNoDataRow,
    selectedRegion,
    activeMetric,
    activeDemographicMetric,
    bounds,

    // Feature flag for enabling international features.
    // To turn on, put "?international" in the URL.

    //international,
  } = useContext(InteractionContext);

  const activeRow = useDebounced(activeRowNotDebounced);

  //if (international !== undefined) {
  //  console.log('TODO enable international features based on this.');
  //}

  const {
    activeDateData,
    activeDateDataWithDemographics,
    breakpoints,
  } = useContext(DataContext);

  //console.log('centroids in map:');
  //console.log(centroids);
  //console.log(fipsToCentroid['36']);

  //console.log('activeRow in Map: ', activeRow);
  //console.log('activeNoDataRow in Map: ', activeNoDataRow);
  //if (activeRow) console.log(activeRow.FIPS, activeRow.county_name);

  //console.log('activeDateData:');
  //console.log(activeDateData);

  // The activeRow object looks like this:
  // {
  //   "regionId": "36119",
  //   "county_name": "Westchester",
  //   "ds": "2020-03-11",
  //   "all_day_bing_tiles_visited_relative_change": -0.045234733921021,
  //   "all_day_ratio_single_tile_users": "0.12231930103257",
  //   "date": "2020-03-11T04:00:00.000Z"
  // }
  // So we could say activeRow.regionId for example.
  //

  const generateChoroplethPaint = useMemo(() => {
    const getValue = makeGetValue(activeMetric.column);
    const getDemographicValue = activeDemographicMetric
      ? makeGetValue(activeDemographicMetric.column)
      : null;
    //console.log('recomputing styles');
    return (type) => {
      //console.log("generateChoroplethPaint", selectedRegion, type, isAdmin0(selectedRegion), isAdmin1(selectedRegion));
      if (
        (!selectedRegion || selectedRegion === WORLD) &&
        (type === REGION_TYPES.ADMIN1 || type === REGION_TYPES.ADMIN2)
      ) {
        // if no selected region, then this is a world map.
        // Return no fill if we are an admin1 or admin2,
        // otherwise fall through to style a country choropleth
        return {
          'fill-color': 'hsla(0, 0%, 0%, 0)',
          'fill-outline-color': 'hsla(0, 0%, 0%, 0)',
        };
      } else if (selectedRegion && isAdmin0(selectedRegion)) {
        // in this case, there is a selected country.
        // Then we style all other countries gray, leaving this one transparent
        if (type === REGION_TYPES.ADMIN0) {
          // if admin1 map, show gray admin0 fills, and none for this admin0
          // ...so the admin1 choropleth shows through
          return transparentFillCondition(
            tileAdminKeys[proj][REGION_TYPES.ADMIN0],
            addPostfix(selectedRegion)
          );
        } else if (type === REGION_TYPES.ADMIN2) {
          // if a country is selected, all admin2s are invisible
          return {
            'fill-color': 'hsla(0, 0%, 0%, 0)',
            'fill-outline-color': 'hsla(0, 0%, 0%, 0)',
          };
        }
        // else, go ahead and fall through and style all the admin1.
        // it's ok because the irrelevant ones will be covered up by admin0 gray
      } else if (selectedRegion && isAdmin1(selectedRegion)) {
        // in this case, there is a selected state/province (admin1).
        // Then we style all other admin1 gray, leaving this one transparent
        if (type === REGION_TYPES.ADMIN0) {
          // if admin2 map, show gray admin0 fills, and none for this admin0
          // ...so the admin1 gray and admin2 choropleth shows through
          return transparentFillCondition(
            tileAdminKeys[proj][REGION_TYPES.ADMIN0],
            addPostfix(getParent(selectedRegion))
          );
        } else if (type === REGION_TYPES.ADMIN1) {
          // if admin2 map, show gray admin1 fills, and none for this admin1
          // ...so the admin2 choropleth shows through
          return {
            'fill-color': [
              'case',
              [
                'all',
                [
                  '==',
                  ['get', tileAdminKeys[proj]['admin1_parent']],
                  addPostfix(getParent(selectedRegion)),
                ],
                [
                  '!=',
                  ['get', tileAdminKeys[proj][REGION_TYPES.ADMIN1]],
                  addPostfix(selectedRegion),
                ],
              ],
              //non-selected state (only relevant for the US since that is the only country with county-level data)
              //only for non-bivariate map (not available for bivariate)
              //'#e5e5e5',
              //to match bivariate
              '#ededed',
              'rgba(0, 0, 0, 0)',
            ],
            'fill-outline-color': 'rgba(0, 0, 0, 0)',
          };
        }
        // else, go ahead and fall through and style all the admin2.
        // it's ok because the irrelevant ones will be covered up by admin0&1 gray
      }

      // we only get to this point if we're styling a choropleth that
      // is supposed to be visible under the current selection.

      const { colors } = activeMetric;

      if (activeMetric) {
        if (activeDemographicMetric) {
          const filteredData = activeDateDataWithDemographics.filter(
            (d) => d.isInScatterplotFilter
          );
          // bivariate mode
          const demographicBuckets = createBivariateBucketsForData({
            unfilteredData: activeDateDataWithDemographics,
            data: filteredData,
            getValue: getDemographicValue,
          });
          const buckets = createBivariateBucketsForData({
            unfilteredData: activeDateDataWithDemographics,
            data: filteredData,
            getValue,
          });
          return bivariateBucketFillCondition({
            key: tileAdminKeys[proj][type],
            parentKey:
              tileAdminKeys[proj][
                type === REGION_TYPES.ADMIN1 ? 'admin1_parent' : 'admin2_parent'
              ],
            parentValue: addPostfix(selectedRegion),
            buckets,
            demographicBuckets,
            biVariatePallette,
          });
        } else {
          const buckets = createBucketsForData({
            data: activeDateData,
            breakpoints:
              CURRENT_PRODUCT === PRODUCTS.MOBILITY
                ? activeMetric.breakpoints
                : breakpoints,
            getValue,
          });

          return bucketFillCondition({
            key: tileAdminKeys[proj][type],
            parentKey:
              tileAdminKeys[proj][
                type === REGION_TYPES.ADMIN1 ? 'admin1_parent' : 'admin2_parent'
              ],
            parentValue: addPostfix(selectedRegion),
            buckets,
            colors,
          });
        }
      } else {
        return {
          'fill-color': 'purple',
          'fill-outline-color': 'hsla(0, 0%, 0%, 0)',
        };
      }
    };
  }, [
    selectedRegion,
    activeMetric,
    activeDemographicMetric,
    activeDateData,
    activeDateDataWithDemographics,
    breakpoints,
  ]);

  const generateHighlightPaint = useCallback(
    (type) => {
      // TODO: some of this might need more updating for our three-level hierarchy
      if (!selectedRegion && type === REGION_TYPES.ADMIN2) {
        // if admin1-level map, hide all admin2 highlights
        return {
          'line-opacity': 0,
        };
      }

      var highlightFeatures = [];
      if (activeRow && activeRow.hasOwnProperty('regionId')) {
        highlightFeatures.push(addPostfix(activeRow.regionId));
      }
      if (activeNoDataRow && activeNoDataRow.hasOwnProperty('regionId')) {
        highlightFeatures.push(addPostfix(activeNoDataRow.regionId));
      }
      return {
        'line-opacity': [
          'case',
          [
            'in',
            ['get', tileAdminKeys[proj][type]],
            ['literal', highlightFeatures],
          ],
          1,
          0,
        ],
        'line-width': ['interpolate', ['linear'], ['zoom'], 1, 1, 7, 3],
        'line-color': '#5BC4F1',
        //'line-width': 2,
        //'line-color': '#374c67',
      };
    },
    [selectedRegion, activeRow, activeNoDataRow]
  );

  const generateOutlinePaint = useCallback(
    (type) => {
      // TODO: some of this might need more updating for our three-level hierarchy

      // for now, always draw country outlines (we may adjust this later)
      if (type === REGION_TYPES.ADMIN0) {
        return {
          'line-opacity': 1,
          'line-width': 0,
          'line-color': 'rgba(247,248,249,1)',
        };
        //return {
        //'line-opacity': 1,
        //'line-width': 0.5,
        //'line-color': 'hsl(0, 0%, 87%)',
        //};
      }

      if (
        (!selectedRegion || selectedRegion === WORLD) &&
        (type === REGION_TYPES.ADMIN1 || type === REGION_TYPES.ADMIN2)
      ) {
        // if no selected region it's a world map, hide all admin1 & admin2 outlines
        return {
          'line-opacity': 0,
        };
      }

      if (selectedRegion && isAdmin0(selectedRegion)) {
        if (type === REGION_TYPES.ADMIN2) {
          return {
            'line-opacity': 0,
          };
        } else {
          // if (type === REGION_TYPES.ADMIN1)
          // We only show the admin1 outlines if they are children of the current admin0
          return {
            'line-opacity': [
              'case',
              [
                '==',
                ['get', tileAdminKeys[proj]['admin1_parent']],
                addPostfix(selectedRegion),
              ],
              1,
              0,
            ],
            'line-width': CURRENT_PRODUCT === PRODUCTS.MOBILITY ? 1 : 0,
            'line-color': 'rgba(247,248,249,0.6)',
            //'line-width': 1,
            //'line-color': 'rgba(247,248,249,0.4)',
          };
        }
      }

      if (selectedRegion && isAdmin1(selectedRegion)) {
        if (type === REGION_TYPES.ADMIN2) {
          // We only show the admin2 outlines if they are children of the current admin1
          return {
            'line-opacity': [
              'case',
              [
                '==',
                ['get', tileAdminKeys[proj]['admin2_parent']],
                addPostfix(selectedRegion),
              ],
              1,
              0,
            ],
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              8,
              0.5,
              14,
              1.2,
            ],
            'line-color': 'rgba(247,248,249,0.6)',
          };
        } else {
          // if (type === REGION_TYPES.ADMIN1)
          // We only show the admin1 outlines if they are siblings of the current admin1
          return {
            'line-opacity': [
              'case',
              [
                '==',
                ['get', tileAdminKeys[proj]['admin1_parent']],
                addPostfix(getParent(selectedRegion)),
              ],
              1,
              0,
            ],
            'line-width': 1.2,
            'line-color': '#dbdbdb',
            //'line-width': 1,
            //'line-color': 'rgba(225,225,225,0.5)',
          };
        }
      }
    },
    [selectedRegion]
  );

  const desktopMapInteractions = useDesktopMapInteractions();

  const mobileMapInteractions = useMobileMapInteractions();

  const mapboxInteractionProps = isMobile
    ? mobileMapInteractions
    : {
        onClick: desktopMapInteractions.onClick,
      };

  const fillLayerInteractionProps = isMobile
    ? mobileMapInteractions
    : desktopMapInteractions;

  return !bounds ? null : (
    <Mapbox
      style={mapboxStyle[proj]}
      fitBounds={bounds}
      fitBoundsOptions={{
        padding: 20,
        linear: true,
      }}
      containerStyle={{
        height: '100%',
        minHeight: '400px',
        width: '100%',
        borderRadius: '3px',
      }}
      {...mapboxInteractionProps}
    >
      <ZoomControl
        className={`nav-control
          ${
            isEmbed
              ? 'embed'
              : selectedRegion === 'WORLD'
              ? 'international'
              : ''
          }`}
      />
      <Source
        id={mapboxSources[proj].admin2.id}
        key={mapboxSources[proj].admin2.key}
        tileJsonSource={mapboxSources[proj].admin2.tileJsonSource}
      />
      <Source
        id={mapboxSources[proj].admin1.id}
        key={mapboxSources[proj].admin1.key}
        tileJsonSource={mapboxSources[proj].admin1.tileJsonSource}
      />
      <Source
        id={mapboxSources[proj].admin0.id}
        key={mapboxSources[proj].admin0.key}
        tileJsonSource={mapboxSources[proj].admin0.tileJsonSource}
      />
      <Source
        id={mapboxSources[proj].labelpoints.id}
        key={mapboxSources[proj].labelpoints.key}
        tileJsonSource={mapboxSources[proj].labelpoints.tileJsonSource}
      />
      <FillLayer
        layerProps={mapboxLayers[proj].admin0choropleth}
        paint={generateChoroplethPaint(REGION_TYPES.ADMIN0)}
        {...fillLayerInteractionProps}
      />
      <FillLayer
        layerProps={mapboxLayers[proj].admin1choropleth}
        paint={generateChoroplethPaint(REGION_TYPES.ADMIN1)}
        {...fillLayerInteractionProps}
      />
      <FillLayer
        layerProps={mapboxLayers[proj].admin2choropleth}
        paint={generateChoroplethPaint(REGION_TYPES.ADMIN2)}
        {...fillLayerInteractionProps}
      />
      <LineLayer
        layerProps={mapboxLayers[proj].admin2outline}
        paint={generateOutlinePaint(REGION_TYPES.ADMIN2)}
      />
      <LineLayer
        layerProps={mapboxLayers[proj].admin1outline}
        paint={generateOutlinePaint(REGION_TYPES.ADMIN1)}
      />
      <LineLayer
        layerProps={mapboxLayers[proj].admin0outline}
        paint={generateOutlinePaint(REGION_TYPES.ADMIN0)}
      />
      <LineLayer
        layerProps={mapboxLayers[proj].admin2interaction}
        paint={generateHighlightPaint(REGION_TYPES.ADMIN2)}
      />
      <LineLayer
        layerProps={mapboxLayers[proj].admin1interaction}
        paint={generateHighlightPaint(REGION_TYPES.ADMIN1)}
      />
      <LineLayer
        layerProps={mapboxLayers[proj].admin0interaction}
        paint={generateHighlightPaint(REGION_TYPES.ADMIN0)}
      />
      {CURRENT_PRODUCT === PRODUCTS.MOBILITY ? <SymbolLayer /> : null}
      <MapPopup />
    </Mapbox>
  );
};
