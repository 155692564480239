import React from 'react';

import { EmbedControlsHeader, EmbedParagraph, EmbedSection } from './styles';
import './EmbedControls.scss';
import iconCode from 'assets/svg/icon_code.svg';
import locale from 'common/locale';

const SizeSelector = (props) => {
  return (
    <select value={props.width} onChange={props.calcHeight}>
      <option value="800">{locale('i18n_7_embed')}</option>
      <option value="600">{locale('i18n_8_embed')}</option>
      <option value="400">{locale('i18n_9_embed')}</option>
    </select>
  );
};
/*<div className="geocoder">
<p>
location
</p>
<Geocoder
accessToken={accessToken}
onSelect={this.onSelect.bind(this)}
inputPlaceholder="Enter a ZIP code, city or county"
/>

</div>
*/

const EmbedControls = ({ width, calcHeight, generateCode }) => {
  return (
    <EmbedSection>
      <EmbedControlsHeader>{locale('i18n_5_embed')}</EmbedControlsHeader>
      <EmbedParagraph>{locale('i18n_2_embed')}</EmbedParagraph>
      <div className="code-generator">
        <div className="code-generator-options">
          <div className="size-select">
            <p>{locale('i18n_3_embed')}</p>
            <SizeSelector width={width} calcHeight={calcHeight} />
          </div>

          <div className="code-generate-button">
            <button onClick={generateCode}>
              <div>
                <img src={iconCode} alt="Generate Embed Code" />
                {locale('i18n_4_embed')}
              </div>
            </button>
          </div>
        </div>
      </div>
    </EmbedSection>
  );
};

export default EmbedControls;
