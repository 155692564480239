import { scaleOrdinal } from 'd3-scale';

import { GOVT_ACTION_TYPES } from 'common/constants';

export const govtActionDotsColorScale = scaleOrdinal()
  .domain([
    GOVT_ACTION_TYPES.SCHOOL_CLOSED,
    GOVT_ACTION_TYPES.EMERGENCY,
    GOVT_ACTION_TYPES.SHELTER_IN_PLACE,
  ])
  .range(['#cbeaff', '#ffd5c8', '#d7f1c0']);
