import React from 'react';
import '../index.scss';
import FBHeader from 'components/symptom/FBHeader/index.js';
import FBFooter from 'components/symptom/FBFooter/index.js';

export default function survey() {
  // prettier-ignore
  return (
<div>
      <FBHeader parent="data_info" />
      <div className="section-outer data-info">
        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164943"><h4 className="eyebrow">الاستبيان وبيانات الخريطة</h4>
                <h3>دعم البحث المتعلق بفيروس كورونا (كوفيد-١٩)</h3>
                <p>مع وجود ما يزيد عن 2 مليار شخص على فيسبوك، نتمتع بوضع مميز
                  لدعم أبحاث الصحة العامة. لقد عقدنا شراكات
                  مع مؤسسات أكاديمية ومنظمات غير هادفة للربح موثوق بها منذ 2017،
                  من خلال برنامج Data For Good (بيانات من أجل الأعمال الخيرية)، لبناء شبكة بحثية
                  للتعامل مع بعض أكبر المشكلات الإنسانية في العالم.
                  مؤخرًا، عقدنا شراكة مع<span> </span>
                  <a className="bold-link" href="https://delphi.cmu.edu/" target="_blank" rel="noopener noreferrer">
                    جامعة كارنيجي ميلون
                  </a><span> </span>
                  و<span> </span>
                  <a className="bold-link" href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data" target="_blank" rel="noopener noreferrer">
                    جامعة ميريلاند
                  </a><span> </span>
                  لدعوة الأشخاص إلى المشاركة في استبيانات عن أعراض
                  فيروس كورونا (كوفيد-١٩) وعوامل الخطر المرتبطة به. ونستخدم بيانات الاستبيان في خريطتنا،
                  ونعرض أيضًا البيانات المجمعة الأخرى المتاحة للاطلاع العام بهدف توفير
                  نظرة أكثر شمولاً عن جائحة فيروس كورونا (كوفيد-١٩). وقد وضعنا
                  مراعاة الخصوصية نصب أعيننا أثناء تصميم المبادرات مع شركائنا
                  من البداية.</p></div>
            </div>
          </div>
        </div>

        <div className="data-image-section">
          <img alt="" src={`${process.env.PUBLIC_URL}/images/image-survey-methodology.jpg`} className="survey-image"/>
        </div>

        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164945"><h4 className="sub-header">منهجية الاستبيان</h4>
                <p>بالشراكة مع
                  جامعة كارنيجي ميلون وجامعة ميريلاند، ندعو الأشخاص على فيسبوك إلى
                  المشاركة في الاستبيانات التي تسأل عن ما يشعرون به،
                  بما في ذلك أي من أعراض فيروس كورونا (كوفيد-١٩) التي عانوا منها هم أو
                  أعضاء أسرهم، وحول عوامل الخطر المتعلقة
                  بالإصابة بفيروس كورونا. تم تصميم الاستبيانات لتوفير
                  معلومات ذات قيمة للمساعدة في مراقبة كيفية انتشار فيروس كورونا (كوفيد-١٩)
                  والتنبؤ بها، دون التنازل عن الحفاظ على خصوصية المشاركين
                  في الاستبيان. ولقد شارك في الاستبيانات ما يزيد عن مليون شخص
                  خلال أول أسبوعين.</p><div className="survey-grid">
                  <div className="survey-grid-item col-2"><h4>حول الاستبيانات</h4>
                    <p>تطلب الاستبيانات التي أجرتها جامعة كارنيجي ميلون و
                      جامعة ميريلاند من الأشخاص الإبلاغ عن الأعراض المرتبطة
                      بالإصابة بفيروس كورونا (كوفيد-١٩) أو الإنفلونزا التي عانوا منها هم أو
                      أفراد أسرهم خلال آخر 24 ساعة.
                      ولقد تم إجراء استبيانات حول أبحاث الصحة العامة
                      مشابهة لهذه الاستبيانات على مستوى العالم، وتم استخدام نتائجها للتنبؤ بانتشار
                      الإنفلونزا والأمراض الأخرى في الماضي.</p></div>

                  <div className="survey-grid-item col-2"><h4>مَن الذين يشاركون في الاستبيان</h4>
                    <p>يصل فيسبوك إلى شرائح كبيرة من السكان مما يسمح
                      بتمثيل عناصر العمر والجنس والموقع
                      بشكل كبير. كل يوم، يتم إرسال دعوة إلى عينة جديدة من مستخدمي فيسبوك الذين تزيد أعمارهم عن
                      18 عامًا للمشاركة في استبيان. يشارك المستخدمون
                      في الولايات المتحدة في الاستبيان الذي أعدته
                      جامعة كارنيجي ميلون، بينما يشارك المستخدمون من خارج الولايات المتحدة
                      في الاستبيان الذي أعدته جامعة ميريلاند.
                      ولا يقوم فيسبوك بتلقي أو تجميع أو تخزين إجابات الأفراد
                      على الاستبيانات. ولا تعرف جامعة كارنيجي ميلون وجامعة
                      ميريلاند هوية الأشخاص الذين شملتهم الاستبيانات.</p></div>

                  <div className="survey-grid-item col-2"><h4>تصحيح التحيّز في اختيار العيّنة</h4>
                    <p>يشارك فيسبوك رقمًا إحصائيًا واحدًا، يُعرف باسم القيمة الترجيحية،
                      لتصحيح التحيّز في اختيار عيّنة الاستبيان. لا تحدد القيمة الترجيحية
                      هوية المشارك في الاستبيان. ويؤدي استخدام القيم الترجيحية لتصحيح التحيّز
                      في اختيار العيّنة إلى ضمان أن
                      العينة تعكس خصائص السكان
                      الممثلين بشكل أكثر دقة. ويمكن النظر إلى القيمة الترجيحية لشخص ما
                      على أنها عدد الأشخاص البالغين
                      الذين يمثلهم هذا الشخص، استنادًا إلى العمر والجنس
                      والموقع. ولا نعرض أي بيانات للبلدان التي لا تعكس
                      تصحيحات التحيّز في اختيار عيّنات الاستبيان.</p></div>

                  <div className="survey-grid-item col-2"><h4>استخدام بيانات الاستبيان</h4>
                    <p>يمكن أن تساعد بيانات الاستبيان واضعي السياسات والباحثين على
                      التنبؤ ببؤر انتشار فيروس كورونا (كوفيد-١٩) والتعامل مع الجائحة
                      في مجتمعاتهم. يلتزم شركاء الأبحاث الذين
                      يتعاونون مع فيسبوك باستخدام نتائج استبيان الأعراض لأغراض الدراسة فقط
                      والمساعدة على احتواء فيروس كورونا (كوفيد-١٩).<span> </span>
                      <a className="bold-link" href="https://dataforgood.fb.com/docs/covid-19-symptom-survey-request-for-data-access/" target="_blank" rel="noopener noreferrer">
                        تعرف على المزيد
                      </a><span> </span>
                        عن طلب صلاحية الوصول إلى بيانات الاستبيان كجهة بحث أكاديمي أو
                      منظمة غير هادفة للربح.</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="data-image-section">
          <img alt="" src={`${process.env.PUBLIC_URL}/images/image-map-data.jpg`} className="survey-image"/>
        </div>

        <div className="section-inner">
          <div className="story">
            <div className="section">
              <div className="content" id="i18n-1713164947"><h4 className="sub-header">البيانات الموجودة على الخريطة</h4>
                <p>يمكن استخدام البيانات الموجودة على الخريطة للحصول
                  على فهم أفضل فيما يتعلق بأعراض فيروس كورونا (كوفيد-١٩)
                  التي تم الإبلاغ عنها وحالات الإصابة المؤكدة، بالإضافة إلى معرفة
                  كيفية تأثير التدابير والسياسات الوقائية أو الخصائص السكانية، مثل العمر
                  والكثافة السكانية، في معدل انتشار المرض. وعند استخدام كل هذه البيانات معًا، يمكن أن تساعد
                  واضعي السياسات وأنظمة الصحة العامة في وضع الخطط التي تناسب
                  مجتمعاتهم بشكل أكثر فعّالية. وهناك ثلاث فئات من
                  البيانات التي نعرضها على الخريطة: الأعراض والحالات، والتباعد
                  البدني وعوامل الخطر.</p><div className="survey-grid">
                  <div className="survey-grid-item col-3"><h4>الأعراض والحالات</h4>
                    <p>تُعد الأعراض التي تم الإبلاغ عنها وحالات الإصابة المؤكدة من العوامل الأساسية في
                      فهم الجائحة لأنها تعتبر مؤشر إلى
                      مدى تأثير التدابير والسياسات الوقائية، مثل
                      التباعد البدني، في انتشار فيروس كورونا (كوفيد-١٩).</p>
                    <ul>
                      <li>
                        <p>مصدر بيانات أعراض فيروس كورونا (كوفيد-١٩) والإنفلونزا هو<span> </span>
                          <a className="bold-link" href="https://cmu-delphi.github.io/delphi-epidata/api/" target="_blank" rel="noopener noreferrer">
                             جامعة كارنيجي ميلون
                          </a><span> </span>
                          و<span> </span>
                          <a className="bold-link" href="https://covidmap.umd.edu/api.html" target="_blank" rel="noopener noreferrer">
                            جامعة ميريلاند
                          </a></p>
                      </li>
                      <li>
                        <p>مصدر بيانات إجمالي حالات الإصابة المؤكدة هو<span> </span>
                          <a className="bold-link" href="https://github.com/CSSEGISandData/COVID-19" target="_blank" rel="noopener noreferrer">
                            جامعة جونز هوبكنز
                          </a></p>
                      </li>
                    </ul></div>

                  <div className="survey-grid-item col-3"><h4>التباعد البدني</h4>
                    <p>تعطي البيانات المتعلقة بالتباعد البدني وتواريخ
                      إغلاق المدارس وإعلان حالات الطوارئ، إلى جانب البيانات المتعلقة بحركة الأشخاص، إشارة إلى كيفية تقليل سياسات الإيواء في الموقع
                      والبقاء في المنازل من حركة تنقل
                      الأشخاص للحد من انتشار فيروس كورونا (كوفيد-١٩).</p>
                    <ul>
                      <li>
                        <p>مصدر بيانات التباعد البدني وتواريخ إغلاق المدارس
                          وإعلان حالات الطوارئ هو<span> </span>
                          <a className="bold-link" href="https://github.com/COVID19StatePolicy/SocialDistancing/blob/master/data/USstatesCov19distancingpolicy.csv" target="_blank" rel="noopener noreferrer">
                            COVID-19 State Policy Repository
                          </a></p>
                      </li>
                      <li>
                        <p>مصدر بيانات النسبة المئوية للأشخاص الذين بقوا في مكانهم ومعدل التغير في
                          التحركات هو<span> </span>
                          <a className="bold-link" href="https://data.humdata.org/dataset/movement-range-maps" target="_blank" rel="noopener noreferrer">
                            خرائط نطاق التحركات في برنامج Data For Good (بيانات من أجل الأعمال الخيرية)
                          </a> التابع لفيسبوك</p>
                      </li>
                    </ul></div>

                  <div className="survey-grid-item col-3"><h4>عوامل الخطر</h4>
                    <p>عوامل الخطر هي الخصائص السكانية المرتبطة
                      بزيادة خطر انتشار فيروس كورونا (كوفيد-١٩) أو
                      تطور مرض خطير، بما في ذلك إجمالي عدد السكان
                      والكثافة السكانية وعدد الأشخاص الذين يبلغون 65 عامًا فما فوق. ويمكن استخدام هذه
                      الخصائص الثلاث لفهم فئات البيانات
                      الأخرى بشكل أكثر فعّالية.</p>
                    <ul>
                      <li>
                        <p>مصدر بيانات إجمالي عدد السكان، والكثافة السكانية لكل كيلومتر مربع
                          والنسبة المئوية لعدد الأشخاص بسن 65 عامًا فما فوق هو<span> </span>
                          <a className="bold-link" href="https://censusreporter.org/" target="_blank" rel="noopener noreferrer">
                            مكتب الإحصاء الأمريكي
                          </a><span> </span>
                          و<span> </span>
                          <a className="bold-link" href="https://data.humdata.org/organization/facebook" target="_blank" rel="noopener noreferrer">
                            خرائط السكان برنامج Data For Good (بيانات من أجل الأعمال الخيرية)
                          </a> التابع لفيسبوك</p>
                      </li>
                    </ul></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 20 }} />
      <FBFooter />
    </div>
  );
}
