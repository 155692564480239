import React, { useContext } from 'react';
import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import { LegendBody } from './LegendBody';

export const Legend = ({ isEmbed = false }) => {
  const {
    activeDate,
    activeMetric,
    selectedRegion,
    activeDemographicMetric,
  } = useContext(InteractionContext);

  const { breakpoints } = useContext(DataContext);

  return activeDemographicMetric ? null : (
    <LegendBody
      activeDate={activeDate}
      isEmbed={isEmbed}
      activeMetric={activeMetric}
      selectedRegion={selectedRegion}
      thresholds={breakpoints}
    />
  );
};
