import React from 'react';

const LineChartSVGTooltip = ({
  lineGenerator,
  activeRow,
  activeLineData,
  children,
}) =>
  activeLineData ? (
    <g
      transform={`translate(${lineGenerator.x()(activeRow)},${lineGenerator.y()(
        activeRow
      )})`}
    >
      {children}
    </g>
  ) : null;

export default LineChartSVGTooltip;
