import { useMemo, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { timeFormat, timeParse } from 'd3-time-format';
import { useQueryParameter } from './useQueryParameter';
import { defaultMetric, getMetric } from 'common/MetricComplex/metrics';

import { WORLD } from 'common/constants';

const urlDateFormat = '%Y-%m-%d';
const stringifyDate = timeFormat(urlDateFormat);
const parseDate = timeParse(urlDateFormat);

const stringifyDateInterval = (array) => array.map(stringifyDate).join('_');
const parseDateInterval = (string) =>
  string && string.split('_').map(parseDate);

const parseBoolean = (value) => value !== undefined;

export const useURLState = () => {
  const { search } = useLocation();

  const query = useMemo(() => parse(search), [search]);

  const { push } = useHistory();

  const [selectedRegion, setSelectedRegion] = useQueryParameter({
    name: 'region',
    query,
    push,
  });

  const [activeMetric, setActiveMetric] = useQueryParameter({
    name: 'metric',
    query,
    push,
    defaultValue: defaultMetric,
    stringify: (metric) => metric.urlKey,
    parse: getMetric,
  });

  const [activeDate, setActiveDate] = useQueryParameter({
    name: 'date',
    query,
    push,
    stringify: stringifyDate,
    parse: parseDate,
  });

  const [visibleDateInterval, setVisibleDateInterval] = useQueryParameter({
    name: 'dates',
    query,
    push,
    stringify: stringifyDateInterval,
    parse: parseDateInterval,
  });

  // True if international flag is enabled.
  // False otherwise.
  const [international] = useQueryParameter({
    name: 'international',
    query,
    push,
    parse: parseBoolean,
    defaultValue: true,
  });

  const [isSmallMultiplesOpen, setIsSmallMultiplesOpen] = useQueryParameter({
    name: 'small-multiples',
    query,
    push,
    parse: parseBoolean,
    defaultValue: false,
  });

  // If the international feature flag is enabled,
  // auto-navigate to region=US if region not specified,
  // to make the US the default view.
  useEffect(() => {
    if (international && !selectedRegion) {
      setSelectedRegion(WORLD);
    }
  }, [international, selectedRegion, setSelectedRegion]);

  return {
    selectedRegion,
    setSelectedRegion,
    activeMetric,
    setActiveMetric,
    activeDate,
    setActiveDate,
    international,
    visibleDateInterval,
    setVisibleDateInterval,
    isSmallMultiplesOpen,
    setIsSmallMultiplesOpen,
  };
};
