import React, { useContext, useCallback } from 'react';

import {
  isAdmin0,
  isAdmin1,
  isAdmin2,
  getParentRegionId,
} from 'common/DataContext/accessors';
import { USA } from 'common/constants';
import { ViewNextLevel, NextLevelButton } from './styles';
import { DispatcherContext } from 'common/DispatcherContext';
import { InteractionContext } from 'common/InteractionContext';

const MapPopupMobileButton = () => {
  const { dispatchSelectRegion } = useContext(DispatcherContext);
  const {
    activeRow: { regionId },
  } = useContext(InteractionContext);

  const onClick = useCallback(() => {
    // for some reason this triggers when this component renders, not when the button below is clicked.
    dispatchSelectRegion(regionId);
  }, [dispatchSelectRegion, regionId]);

  let regionsSelectable = true;
  if (isAdmin0(regionId)) {
    regionsSelectable = true;
  } else if (isAdmin1(regionId)) {
    if (getParentRegionId(regionId) !== USA) {
      regionsSelectable = false;
    }
  } else if (isAdmin2(regionId)) {
    regionsSelectable = false;
  }

  return regionsSelectable ? (
    <ViewNextLevel onClick={onClick}>
      <NextLevelButton>
        {getParentRegionId(regionId) !== USA
          ? 'View More Locations in Country'
          : 'View Counties'}
      </NextLevelButton>
    </ViewNextLevel>
  ) : null;
};

export default MapPopupMobileButton;
