import React from 'react';

import LineChartDOMTooltip from './LineChartDOMTooltip';
import { lineValue } from './util';

import './BubbleTooltip.scss';

const BubbleTooltip = (props) => {
  const { activeRow, currentMetric } = props;
  const dataIsAvailable = activeRow && activeRow[currentMetric.column];
  const regionText = activeRow ? lineValue(activeRow) : '';
  const valueText = dataIsAvailable
    ? currentMetric.display(activeRow[currentMetric.column])
    : '';
  return (
    <LineChartDOMTooltip {...props}>
      <div className="chart-tooltip">
        <div className="region">{regionText}</div>
        <div className="entry">
          <div className="legend">
            {currentMetric.labelForMapTooltip
              ? currentMetric.labelForMapTooltip
              : currentMetric.label}
          </div>
          <div className="value">{valueText}</div>
        </div>
      </div>
    </LineChartDOMTooltip>
  );
};

export default BubbleTooltip;
