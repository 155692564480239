import React from 'react';
import '../styles.scss';

export default function mapExplainer(handleClick) {
  // prettier-ignore
  return (
<div className="map-explainer">
      <div className="explainer-header"><h3>حول هذه الخريطة</h3><div id="r2"/>
      </div><ul className="explainer-body">
        <li>هذه الخريطة تستخدم بيانات عامة مجمعة لحماية خصوصية الأشخاص و
           غير مخصصة لأغراض التشخيص أو الاسترشاد بها لرحلات السفر.</li>
        <li>للتعرف على المزيد عن البيانات، يرجى الاطلاع على صفحة <span> </span>
          <a href="./survey_and_map_data.html">الاستبيان والبيانات</a>.</li>
        <li>حدود المناطق الإدارية المتنازع عليها مصدرها هو<span> </span>
          <a href="https://www.mapbox.com/" target="_blank" rel="noopener noreferrer">
            Mapbox
          </a>
          . جميع المناطق الإدارية الأخرى الممثلة في هذه الخريطة مصدرها <span> </span>
           <a href="https://www.census.gov/geographies/mapping-files/time-series/geo/cartographic-boundary.html" target="_blank" rel="noopener noreferrer">
             مكتب الإحصاء الأمريكي
           </a>
           <span> و</span>
           <a href="https://gadm.org/" target="_blank" rel="noopener noreferrer">
             قاعدة بيانات المناطق الإدارية العالمية
           </a>
           .</li>
      </ul>
    </div>
);
}
