import React, { useContext } from 'react';
import { regionDescriptor } from 'common/DataContext/accessors';
import { DispatcherContext } from 'common/DispatcherContext';
import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import {
  Wrapper,
  Content,
  TitleArea,
  Close,
  Heading,
  Copy,
  Top,
} from './styles';
import { SmallMultiplesViz } from './SmallMultiplesViz';

export const SmallMultiples = () => {
  const { dispatchToggleSmallMultiples } = useContext(DispatcherContext);
  const { activeMetric, selectedRegion } = useContext(InteractionContext);

  const {
    data: { fipsToName },
  } = useContext(DataContext);

  // TODO refactor: unify with definition in src/LollipopChart/index.js &/src/LineChart/Legend/index.js
  const getName = (regionId) => fipsToName[regionId || 'US'];

  const title = regionDescriptor(selectedRegion, getName);

  return (
    <Wrapper>
      <Content>
        <Top>
          <TitleArea>
            <Close onClick={dispatchToggleSmallMultiples}>Return to map</Close>
          </TitleArea>
          <Heading>
            {activeMetric.label} — {title}{' '}
          </Heading>
          <Copy>{activeMetric.blurb}</Copy>
        </Top>
        <SmallMultiplesViz />
      </Content>
    </Wrapper>
  );
};
