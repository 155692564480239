import React from 'react';
import { metrics } from 'common/MetricComplex/metrics';
import MetricEntry from './MetricEntry';

const MapPopupMetricsEntries = ({ activeRow }) => (
  <div>
    {metrics.map((metric) => (
      <MetricEntry key={metric.id} metric={metric} row={activeRow} />
    ))}
  </div>
);

export default MapPopupMetricsEntries;
