import React from 'react';

const LineSeries = ({ linesData, lineGenerator, lineColor }) => (
  <>
    {linesData.map(([lineKey, lineData]) => (
      <path
        key={lineKey}
        fill="none"
        stroke={lineColor}
        d={lineGenerator(lineData)}
      />
    ))}
  </>
);

export default LineSeries;
