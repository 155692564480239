import React, { useContext, useState, useCallback, useRef } from 'react';

import iconPlusSVGURL from 'assets/svg/icon-plus.svg';
import iconCloseSVGURL from 'assets/svg/icon-close-no-bg.svg';

import { InteractionContext } from 'common/InteractionContext';
import { DispatcherContext } from 'common/DispatcherContext';
import { isMobile } from 'styles/styles';
import {
  MAP_ALLOWED_DEMOGRAPHIC_IDS,
  DEMOGRAPHIC_METRICS_BY_ID,
} from 'common/constants';

import './AddLayerSelector.scss';

const AddLayerSelector = () => {
  const { activeDemographicMetric } = useContext(InteractionContext);
  const {
    dispatchSetActiveDemographicMetric,
    dispatchClearActiveDemographicMetric,
  } = useContext(DispatcherContext);
  // timer for timeOut that will reset the selector to its unexpanded form
  const timerRef = useRef();

  // If secondary metric panel is expanded
  const [isExpanded, setIsExpanded] = useState(false);

  const onMouseEnter = useCallback(() => {
    if (timerRef.current != null) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setIsExpanded(true);
  }, [setIsExpanded]);

  const onMouseLeave = useCallback(() => {
    if (timerRef.current != null) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    timerRef.current = setTimeout(() => {
      setIsExpanded(false);
      timerRef.current != null && clearTimeout(timerRef.current);
      timerRef.current = null;
    }, 500);
  }, [setIsExpanded]);

  const clearValue = useCallback(() => {
    dispatchClearActiveDemographicMetric();
    // user has 0.5 second to interact with the no selector otherwise it
    // will revert back to not expanded form
    timerRef.current = setTimeout(() => {
      setIsExpanded(false);
      timerRef.current != null && clearTimeout(timerRef.current);
      timerRef.current = null;
    }, 500);
  }, [dispatchClearActiveDemographicMetric]);

  const updateValue = useCallback(
    (e) => {
      const newDemographicMetricId = e.target.value;
      dispatchSetActiveDemographicMetric(
        DEMOGRAPHIC_METRICS_BY_ID[newDemographicMetricId]
      );
    },
    [dispatchSetActiveDemographicMetric]
  );

  const allowedMetrics = MAP_ALLOWED_DEMOGRAPHIC_IDS.map(
    (id) => DEMOGRAPHIC_METRICS_BY_ID[id]
  );

  //<option value={''} disabled>Add Map Layer</option>
  return (
    <div className="add-layer-selector">
      {!activeDemographicMetric ? (
        <div
          className={isExpanded ? 'layer-select expanded' : 'layer-select'}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {!isMobile ? (
            <img
              className="layer-select-add-icon"
              src={iconPlusSVGURL}
              alt="add demographic layer"
            />
          ) : null}
          <select value={''} onChange={updateValue}>
            <option value={''} disabled>
              Add Map Layer
            </option>
            {allowedMetrics.map((m) => (
              <option key={m.id} value={m.id}>
                {m.label}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="layer-select selected">
          <span>{activeDemographicMetric.label}</span>
          <img
            onClick={clearValue}
            className="layer-select-remove-icon"
            src={iconCloseSVGURL}
            alt="remove demographic layer"
          />
        </div>
      )}
    </div>
  );
};

export default AddLayerSelector;
