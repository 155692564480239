import { useState } from 'react';

const useAsyncStyles = (loadStyles) => {
  const [stylesLoading, setStylesLoading] = useState(false);
  const [stylesLoaded, setStylesLoaded] = useState(false);
  if (!stylesLoading) {
    setStylesLoading(true);
    loadStyles().then(() => setStylesLoaded(true));
  }
  return stylesLoaded;
};

export default useAsyncStyles;
