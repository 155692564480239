export const createFetchedData = ({
  statName,
  countryCodeType,
  rows = [],
  dateExtent = null,
}) => ({
  rows,
  statName,
  dateExtent,
  countryCodeType,
});

export const resultHasData = (r) => r.dateExtent && r.rows;
