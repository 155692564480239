import styled, { css } from 'styled-components';
import { Content, mobileBreakpoint, mobileGap, crispness } from 'styles/styles';
export const Wrapper = styled.div`
  padding-bottom: ${(props) => (props.isEmbed ? 5 : props.isMobile ? 0 : 10)}px;
  padding-top: ${(props) => (props.isEmbed ? 5 : 0)}px;
  @media (max-width: ${mobileBreakpoint}px) {
    margin-top: ${mobileGap}px;
    margin-bottom: ${mobileGap}px;
  }
  display: flex;
  justify-content: center;
  ${(props) => (props.isEmbed ? 'background-color: white;' : '')}
`;

export const Tabs = styled.div`
  display: flex;
  ${(props) => (props.isEmbed ? 'max-width: 450px;' : '')}
  flex: 1;
`;

export const Tab = styled(Content)`
  display: flex;
  flex: 1;
  border-radius: 3px;
  height: 30px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: ${(props) => (props.isLast ? 0 : 5)}px;
  position: relative;
  ${crispness}
`;

export const TooltipBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
`;

export const Tooltip = styled.div`
  font-weight: normal;

  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 10px;
          font-size: 14px;
          line-height: 17px;
          background-color: #738695;
          height: 105px;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          color: white;
        `
      : css`
          padding: 8px;
          font-size: 12px;
          line-height: 14px;
          border-radius: 3px;
          background-color: #ffffff;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          color: #4a585e;
          position: absolute;
          max-width: 255px;
          top: 25px;
          left: 60px;
        `}
  z-index: 2;
`;
