import React from 'react';
import { SymptomSurveyLayout } from './SymptomSurveyLayout';
import { SymptomSurveyEmbedLayout } from './SymptomSurveyLayout/SymptomSurveyEmbedLayout';
import { SymptomSurveyMobileLayout } from './SymptomSurveyLayout/SymptomSurveyMobileLayout';
import { isMobile } from 'styles/styles';

const SymptomSurveyViz = ({ isEmbed }) =>
  isEmbed ? (
    <SymptomSurveyEmbedLayout />
  ) : isMobile ? (
    <SymptomSurveyMobileLayout />
  ) : (
    <SymptomSurveyLayout />
  );

export default SymptomSurveyViz;
