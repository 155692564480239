import React, { useContext, useEffect, useState } from 'react';
import { timeFormat } from 'd3-time-format';
import parse from 'html-react-parser';

import { InteractionContext } from 'common/InteractionContext';
import {
  getAdmin0FromAdmin1,
  isAdmin0,
  isAdmin1,
} from 'common/DataContext/accessors';
import { IntGovInterventions } from './styles';

import {
  // GOVT_ACTION_TEXT,
  // GOVT_ACTION_TYPES,
  WORLD,
} from 'common/constants';

import { DataContext } from 'common/DataContext';

import infoGlyph from 'assets/svg/info-glyph-small.svg';
import locale from 'common/locale';

// const isMobile = () => {
//     return window.innerWidth <= 578;
// };

const formatDate = (date) => {
  const convertedDate =
    new Date(date).getTime() + new Date().getTimezoneOffset() * 60000;
  const formatter = timeFormat('%b %d');
  return formatter(convertedDate);
};

const initialDisplayData = {
  schoolClosure: {
    show: false,
    key: 'SCHOOL_CLOSED',
    startDate: null,
    endDate: null,
  },
  physicalDistancing: {
    show: false,
    key: 'SHELTER_IN_PLACE',
    startDate: null,
    endDate: null,
  },
};

export const GovInterventions = ({ isEmbed = false }) => {
  // const [chartData, setChartData] = useState({ ganttRows: [] });

  const [displayData, setDisplayData] = useState(initialDisplayData);
  const { govtActionsDataProvider } = useContext(DataContext);
  const { activeRow, selectedRegion, visibleDateInterval } = useContext(
    InteractionContext
  );

  useEffect(() => {
    let data;
    if (selectedRegion === WORLD) {
      // TODO make sure we show nothing for international data
      // data = govtActionsDataProvider.getActionsByTypeForRegion(
      //   activeRow ? activeRow.regionId : null
      // );
      data = [];
    } else if (isAdmin0(selectedRegion)) {
      // If we're in the US and we SHOULD be showing government actions

      // Shows the currently selected country govt actions or country's
      // admin1 govt actions if hove#F0AFB5
      data =
        govtActionsDataProvider.getActionsByTypeForRegion(
          selectedRegion,
          activeRow ? activeRow.regionId : null
        ) ||
        govtActionsDataProvider.getActionsByTypeForRegion(selectedRegion, null);
    } else if (isAdmin1(selectedRegion)) {
      // Shows the current country's admin1 govt actions when admin1 is
      // hove#F0AFB5, or just the country's govt actions if there is no admin1
      // data.
      data =
        govtActionsDataProvider.getActionsByTypeForRegion(
          getAdmin0FromAdmin1(selectedRegion),
          selectedRegion
        ) ||
        govtActionsDataProvider.getActionsByTypeForRegion(
          getAdmin0FromAdmin1(selectedRegion),
          null
        );
    }
    data = data || [];

    const newDisplayData = { ...initialDisplayData };

    if (data.length === 0) {
      // console.log('No data found')
      newDisplayData.schoolClosure.show = false;
      newDisplayData.physicalDistancing.show = false;
      setDisplayData(newDisplayData);
    } else {
      data.forEach((d, i) => {
        if (i === 'SCHOOL_CLOSED') {
          newDisplayData.schoolClosure.show = true;
          newDisplayData.schoolClosure.startDate = d[0].dateIssued;
          newDisplayData.schoolClosure.endDate =
            d[0].dateEnded || d[0].dateExpiry || 'Present';
        }

        if (i === 'SHELTER_IN_PLACE') {
          newDisplayData.physicalDistancing.show = true;
          newDisplayData.physicalDistancing.startDate = d[0].dateIssued;
          newDisplayData.physicalDistancing.endDate =
            d[0].dateEnded || d[0].dateExpiry || 'Present';
        }
      });

      setDisplayData(newDisplayData);
    }
    // return displayData;
    // console.log({displayData})
  }, [
    govtActionsDataProvider,
    selectedRegion,
    activeRow,
    visibleDateInterval,
    setDisplayData,
  ]);

  // console.log('isEmbed');
  // console.log(isEmbed);
  if (displayData.schoolClosure.show || displayData.physicalDistancing.show)
    return (
      <div className="timeline">
        <div className="intervention-timeline-container">
          {displayData.schoolClosure.show ? (
            <div class="school-closure-container">
              <div
                class="date-bar"
                style={{
                  backgroundColor: '#BEE7F9',
                  height: '12px',
                  width: '100%',
                  borderRadius: '6px',
                }}
              ></div>
              <div class="date-bar-label">
                {formatDate(displayData.schoolClosure.startDate)} -{' '}
                {displayData.schoolClosure.endDate === 'Present'
                  ? ' Present'
                  : formatDate(displayData.schoolClosure.endDate)}
              </div>
            </div>
          ) : null}

          {displayData.physicalDistancing.show ? (
            <div class="physical-distance-container">
              <div
                class="date-bar"
                style={{
                  backgroundColor: '#F0AFB5',
                  height: '12px',
                  width: '100%',
                  borderRadius: '6px',
                }}
              ></div>
              <div class="date-bar-label">
                {formatDate(displayData.physicalDistancing.startDate)} -{' '}
                {displayData.physicalDistancing.endDate === 'Present'
                  ? ' Present'
                  : formatDate(displayData.physicalDistancing.endDate)}
              </div>
            </div>
          ) : null}
        </div>
        <div class="legend" style={{ textAlign: 'center' }}>
          {displayData.schoolClosure.show ? (
            <div
              class="label-container"
              style={{
                display: 'inline-block',
                marginRight: '0 0.5em',
              }}
            >
              <div
                class="label-circle"
                style={{
                  backgroundColor: '#BEE7F9',
                  height: '0.8em',
                  width: '0.8em',
                  borderRadius: '6px',
                  display: 'inline-block',
                  margin: '0 0.5em',
                }}
              ></div>
              <span class="label">{locale('i18n_school')}</span>
            </div>
          ) : null}

          {displayData.physicalDistancing.show ? (
            <div
              class="label-container"
              style={{
                display: 'inline-block',
                marginRight: '0 0.5em',
              }}
            >
              <div
                class="label-circle"
                style={{
                  backgroundColor: '#F0AFB5',
                  height: '0.8em',
                  width: '0.8em',
                  borderRadius: '6px',
                  display: 'inline-block',
                  margin: '0 0.5em',
                }}
              ></div>
              <span class="label">{locale('i18n_physical')}</span>
            </div>
          ) : null}
        </div>
      </div>
    );
  else if (selectedRegion !== 'USA')
    return (
      <IntGovInterventions isEmbed={isEmbed}>
        <img src={infoGlyph} alt="International Government Intervention Info" />
        {parse(locale('i18n_physical_distance_block'))}
      </IntGovInterventions>
    );
  else return <span></span>;
};
