// @flow
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { parse } from 'query-string';

import { DataProvider } from 'common/DataContext';
import { InteractionProvider } from 'common/InteractionContext';
import { DispatcherProvider } from 'common/DispatcherContext';
import { Container, VizContainer } from 'styles/styles';
import useAsyncStyles from 'common/useAsyncStyles';

import SymptomSurveyViz from 'components/symptom/SymptomSurveyViz';
import FBHeader from 'components/symptom/FBHeader/index.js';
import FBFooter from 'components/symptom/FBFooter/index.js';

import SurveyMapData from 'components/symptom/SurveyMapData';
import Error403 from 'components/symptom/Error403';
import ErrorPage from 'components/symptom/ErrorPage';
import Embed from 'components/symptom/Embed';
import { IntroContent } from 'components/symptom/IntroContent';

const isEmbed = parse(window.location.search).embed !== undefined;

const Home = () => (
  <>
    {!isEmbed ? <FBHeader parent="app" /> : null}
    <InteractionProvider>
      <DataProvider>
        <DispatcherProvider>
          <Container isEmbed={isEmbed}>
            <VizContainer isEmbed={isEmbed}>
              <SymptomSurveyViz isEmbed={isEmbed} />
            </VizContainer>
            {!isEmbed ? <IntroContent /> : null}
            {!isEmbed ? <FBFooter /> : null}
          </Container>
        </DispatcherProvider>
      </DataProvider>
    </InteractionProvider>
  </>
);

const loadStyles = () => Promise.all([import('styles/index_symptom.scss')]);

export const App = () => {
  const stylesLoaded = useAsyncStyles(loadStyles);
  return stylesLoaded ? (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/embed" component={Embed} />
        <Route path="/embed.html" component={Embed} />
        <Route path="/survey_and_map_data" component={SurveyMapData} />
        <Route path="/survey_and_map_data.html" component={SurveyMapData} />
        <Route path="/error-dynamic-403.html" component={Error403} />
        <Route path="/error.html" component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  ) : null;
};
