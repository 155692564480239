import React, { useContext, useCallback } from 'react';
import { InteractionContext } from 'common/InteractionContext';
import { MapGlossaryBody } from './MapGlossaryBody';

export const MapGlossary = ({ isEmbed = false }) => {
  const { isGlossaryOpen, setIsGlossaryOpen } = useContext(InteractionContext);

  const handleCloseGlossary = useCallback(() => {
    setIsGlossaryOpen(false);
  }, [setIsGlossaryOpen]);

  return isGlossaryOpen ? (
    <MapGlossaryBody onCloseGlossary={handleCloseGlossary} />
  ) : null;
};
