import React, { useCallback, useState, useEffect } from 'react';
import { YAxis } from './YAxis';
import { VoronoiOverlay } from './VoronoiOverlay';
import { SymptomLineChartSVG } from './styles';
import LineSeries from './LineSeries';
import ParentLine from './ParentLine';
import ActiveDateLine from './ActiveDateLine';
import ActiveLine from './ActiveLine';
import BubbleTooltip from './BubbleTooltip';
import HoveredDateLine from './HoveredDateLine';

import { HoverDetector } from './HoverDetector';

const SymptomsLineChart = ({
  xScale,
  yScale,
  margin,
  innerWidth,
  innerHeight,
  width,
  height,
  handleMouseOut,
  linesData,
  lineGenerator,
  lineColor,
  activeDate,
  currentMetric,
  xAxisHeight,
  showPageLeft,
  showPageRight,
  parentTimeseriesFiltered,
  activeLineData,
  activeRow,
  yValue,
  handleVoronoiHover,
  handleVoronoiClick,
  filteredData,
  color,
  bounds,
}) => {
  // Hold off on computing the voronoi until the user hovers over the line chart.
  // (because it's quite expensive).
  const [isVoronoiEnabled, setIsVoronoiEnabled] = useState(false);
  const enableVoronoi = useCallback(() => {
    //console.log('enable voronoi');
    setIsVoronoiEnabled(true);
  }, [setIsVoronoiEnabled]);
  const disableVoronoi = useCallback(() => {
    //console.log('disable voronoi');
    setIsVoronoiEnabled(false);
  }, [setIsVoronoiEnabled]);

  // Disable voronoi when any of the voronoi's dependencies change,
  // do avoid expensive computation until mouse hover happens.
  useEffect(() => {
    //console.log('filteredData changed');
    disableVoronoi();
  }, [
    margin,

    // It turns out this gets redefined when active metric changes.
    // Ideally it shouldn't, but for now active metric changes
    // disable the voronoi (acceptable behavior).
    handleVoronoiHover,

    handleVoronoiClick,
    width,
    height,
    filteredData,
    lineGenerator,
    disableVoronoi,
  ]);
  // This is how you can debug the useMemo optimizations getting lost.
  // useMemo(() => { console.log('margin changed'); }, [margin]);
  // useMemo(() => { console.log('handleVoronoiHover changed'); }, [handleVoronoiHover]);
  // useMemo(() => { console.log('handleVoronoiClick changed'); }, [handleVoronoiClick]);
  // useMemo(() => { console.log('width changed'); }, [width]);
  // useMemo(() => { console.log('height changed'); }, [height]);
  // useMemo(() => { console.log('filteredData changed'); }, [filteredData]);
  // useMemo(() => { console.log('lineGenerator changed'); }, [lineGenerator]);
  // useMemo(() => { console.log('disableVoronoi changed'); }, [disableVoronoi]);

  const hoveredDate = activeRow ? activeRow.date : null;
  const showTooltip =
    activeLineData && activeRow && activeRow[currentMetric.column];

  return (
    <>
      {showTooltip ? (
        <BubbleTooltip
          lineGenerator={lineGenerator}
          activeRow={activeRow}
          currentMetric={currentMetric}
          yValue={yValue}
          activeLineData={activeLineData}
          margin={margin}
          showTooltip={showTooltip}
        />
      ) : null}
      <SymptomLineChartSVG width={width} height={height}>
        <g transform={`translate(${margin.left},${margin.top})`}>
          <YAxis
            yScale={yScale}
            innerWidth={innerWidth}
            yFormat={currentMetric.display}
          />
          <LineSeries
            linesData={linesData}
            lineGenerator={lineGenerator}
            lineColor={lineColor}
          />
          <ParentLine
            lineData={parentTimeseriesFiltered}
            lineGenerator={lineGenerator}
          />
          <ActiveDateLine
            date={activeDate}
            xScale={xScale}
            height={innerHeight}
          />
          {hoveredDate ? (
            <HoveredDateLine
              date={hoveredDate}
              xScale={xScale}
              height={innerHeight}
            />
          ) : null}
          <ActiveLine
            lineGenerator={lineGenerator}
            lineData={activeLineData}
            color={color}
          />
        </g>
        {isVoronoiEnabled ? (
          <VoronoiOverlay
            margin={margin}
            onHover={handleVoronoiHover}
            onClick={handleVoronoiClick}
            handleMouseOut={handleMouseOut}
            height={height}
            width={width}
            allData={filteredData}
            lineGenerator={lineGenerator}
          />
        ) : (
          <HoverDetector
            onHover={enableVoronoi}
            width={width}
            height={height}
          />
        )}
      </SymptomLineChartSVG>
    </>
  );
};

export default SymptomsLineChart;
