import React from 'react';

import { Heading2, Paragraph, ConstrainedTextBlock } from './styles';

const BelowVizTextBlock = () => {
  return (
    <ConstrainedTextBlock>
      <Heading2 id="population-mobility-data-and-physical-distancing-efforts-related-to-stopping-the-spread-of-covid-19">
        Population Mobility Data and Physical Distancing Efforts Related to
        Stopping the Spread of COVID-19
      </Heading2>
      <Paragraph>
        In the absence of a vaccine or an effective treatment, communities
        throughout the world have relied upon “non-pharmaceutical interventions”
        (NPIs) such as physical distancing to slow the spread of the COVID-19
        virus and reduce the impact of acute cases on medical systems. By
        reducing their rates of mobility and avoiding large gatherings, people
        are reducing the likelihood that the virus is transmitted from person to
        person, and that large numbers of acute cases that require intensive
        care overburden hospitals.
      </Paragraph>
      <Paragraph>
        When policymakers at all levels began instituting physical distancing
        measures ranging from school and restaurant closures to stay-at-home
        orders, one of the most important pieces of information they needed was
        evidence that rates of mobility were in fact decreasing and that rates
        of distancing were increasing. Aggregated and privacy preserving data
        from Facebook, which shows rates at which people have been moving around
        or generally staying put, has been vital to providing guidance on how
        distancing efforts are being followed and where communities may require
        additional messaging and support to enable them to stay home and stay
        safe.
      </Paragraph>
      <Paragraph>
        The data contained in this map displays county level changes in the
        rates of mobility and staying put, which can allow people to understand
        the degree to which their communities may be adopting physical
        distancing efforts. Variations in mobility rates, displayed in time
        series on the graphs, are measured against a baseline from the end of
        February. These should not be interpreted as absolute numbers of people
        avoiding public spaces or large gatherings, but relative rates at which
        people chose to reduce their mobility or remain near home.
      </Paragraph>
      <Paragraph>
        The information provided by Facebook on population mobility always needs
        to be contextualized with a range of additional data sources on local
        demographics, infrastructure and socioeconomic indicators, in order to
        understand the underlying reasons for the observed variations in
        mobility, and how they may impact the risks associated with COVID-19.
        Researchers can access these additional insights through public data
        sources like census information, as well as through other commercially
        available statistics.
      </Paragraph>
      <Paragraph>
        There are many reasons why some areas may demonstrate greater relative
        reduction in mobility than others. Areas with higher densities of
        population and infrastructure, including grocery stores and pharmacies,
        tend to be characterized by shorter trips and easier access to essential
        services. Areas with lower densities of population and infrastructure
        tend to demonstrate higher mobility before stay at home measures were
        put in place at baseline simply based upon the need to travel for access
        to jobs and services. Finally, these data represent a slice of Facebook
        users that have opted into the Location History setting, and may
        therefore be overrepresented in certain populations and underrepresented
        in others.
      </Paragraph>
      <Paragraph>
        For more information about these data sets from Facebook, please visit:
      </Paragraph>
      <Paragraph>
        <a href="https://dataforgood.fb.com/tools/disease-prevention-maps/">
          https://dataforgood.fb.com/tools/disease-prevention-maps/
        </a>
      </Paragraph>
    </ConstrainedTextBlock>
  );
};

export default BelowVizTextBlock;
