import React, { useContext, useCallback } from 'react';
import { timeFormat } from 'd3-time-format';
import {
  LabelList,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceDot,
} from 'recharts';

import './timeline.scss';
import './chart.scss';
import './styles.scss';

import { isMobile } from 'styles/styles';
import { TIME_RESOLUTION_IDS } from 'common/constants';
import { DispatcherContext } from 'common/DispatcherContext';
import { InteractionContext } from 'common/InteractionContext';

export const formatDate = timeFormat('%b %d');

const datesEqual = (date1, date2) =>
  date1 && date2 && date1.getTime() === date2.getTime();

export function DateSelectorBody({ datesData }) {
  const {
    selectedTimeResolution,
    activeDate,
    visibleDateInterval,
  } = useContext(InteractionContext);
  const {
    dispatchToggleTimeResolution,
    dispatchSelectDate,
    dispatchTimeIncrementLeft,
    dispatchTimeIncrementRight,
    canTimeIncrementLeft,
    canTimeIncrementRight,
  } = useContext(DispatcherContext);

  const onDateChange = useCallback(
    (index) => {
      if (isNaN(index)) return;
      dispatchSelectDate(datesData[index].dateObject);
    },
    [datesData, dispatchSelectDate]
  );
  const onClick = (datesData1, index) => onDateChange(datesData1.index);

  const dateRangeSelector = (
    <div className={'radio-group'}>
      <input
        type="radio"
        id="day"
        name="interval"
        value={TIME_RESOLUTION_IDS.DAY}
        onChange={dispatchToggleTimeResolution}
        checked={
          selectedTimeResolution.timeResolutionId === TIME_RESOLUTION_IDS.DAY
        }
      />
      <label htmlFor="day">Day</label>
      <input
        type="radio"
        id="week"
        name="interval"
        value={TIME_RESOLUTION_IDS.WEEK}
        onChange={dispatchToggleTimeResolution}
        checked={
          selectedTimeResolution.timeResolutionId === TIME_RESOLUTION_IDS.WEEK
        }
      />
      <label htmlFor="week">Week</label>
      {/* waiting for monthly aggregates from CMU
        <input type="radio" id="month" name="interval" value="month" onChange={dispatchToggleTimeResolution} checked={selectedTimeResolution === "month"}/>
        <label htmlFor="month">Month</label>
        */}
    </div>
  );

  const plot = (
    <div className={'date-plot'}>
      <button
        onClick={dispatchTimeIncrementLeft}
        className="left"
        disabled={!canTimeIncrementLeft()}
      >
        <img
          alt=""
          src={
            datesEqual(activeDate, visibleDateInterval[0])
              ? 'svg/arrow-scrubber-disabled-left.svg'
              : 'svg/arrow-scrubber-left.svg'
          }
        />
      </button>
      <ResponsiveContainer width={isMobile ? '105%' : '99%'} height="110%">
        <LineChart
          data={datesData}
          syncId={'someId'}
          margin={{ top: 0, left: 0, right: 2, bottom: 0 }}
        >
          {activeDate && !isMobile ? (
            <ReferenceDot
              x={formatDate(activeDate)}
              y={0}
              r={11}
              fill="#D0D6DB"
            />
          ) : null}
          <CartesianGrid horizontal={false} vertical={false} />
          <XAxis
            dataKey="date"
            tick={false}
            axisLine={false}
            padding={{ right: 20 }}
          />
          <YAxis tick={false} axisLine={false} />
          {!isMobile ? <Tooltip content={() => null} cursor={false} /> : null}
          <Line
            type="monotone"
            dataKey={() => 0}
            stroke="#D0D6DB"
            strokeWidth={isMobile ? 0 : 2}
            isAnimationActive={false}
            dot={isMobile ? false : { r: 6, stroke: '#677B8C', cursor: 'auto' }}
            activeDot={
              !isMobile ? { r: 10, onClick: onClick, cursor: 'pointer' } : null
            }
          >
            <LabelList dataKey="date" position="top" offset={15} />
          </Line>
        </LineChart>
      </ResponsiveContainer>
      <button
        onClick={dispatchTimeIncrementRight}
        className="right"
        disabled={!canTimeIncrementRight()}
      >
        <img
          alt=""
          src={
            datesEqual(activeDate, visibleDateInterval[1])
              ? 'svg/arrow-scrubber-disabled-right.svg'
              : 'svg/arrow-scrubber-right.svg'
          }
        />
      </button>
    </div>
  );
  return (
    <div className={'date-selector'}>
      {
        // TODO mobile support
        //   isMobile ? (
        //   <div>
        //     {plot}
        //     <div className="source-date">
        //       {activeMetric.label} Data available since {activeMetric.datesDataStartDate}
        //     </div>
        //     <div className={'interval'}>{dateRangeSelector}</div>
        //   </div>
        // ) : (
        <div>
          <div className={'interval'}>{dateRangeSelector}</div>
          {plot}
        </div>
        // )
      }
    </div>
  );
}
