import React, {
  useCallback,
  //useEffect,
  //useMemo,
  useState,
  useContext,
} from 'react';
import _ from 'lodash';
import {
  ScatterChart,
  Scatter,
  Cell,
  Label,
  //LineChart,
  //Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  //ResponsiveContainer,
  //ReferenceLine,
  //ReferenceArea,
  //Text,
} from 'recharts';
import { scaleThreshold } from 'd3-scale';
import { getNiceTickValues } from 'recharts-scale';
import classNames from 'classnames';

import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import { DispatcherContext } from 'common/DispatcherContext';
import { biVariatePallette, formatReadableDate } from 'common/constants';
import { isMobile } from 'styles/styles';
import ScatterplotTooltipContent from './ScatterplotTooltipContent';
import GlossaryButton from 'components/symptom/GlossaryButton';
import { ScatterplotContainer } from './styles';

import './Scatterplot.scss';

function getScatterPlotTicks(data, metric) {
  const key = metric.column;
  const flattenData = _.flatMap(data, (datum) =>
    datum != null ? datum[key] : null
  );
  let domain = flattenData.filter(
    (entry) => (_.isNumber(entry) && !_.isNaN(entry)) || parseFloat(entry, 10)
  );
  domain = domain.length
    ? [_.min(domain), _.max(domain)]
    : [Infinity, -Infinity];
  let niceTicks = getNiceTickValues(
    domain, // min/max of the data
    4, // number of ticks (includes the start and ending of the grid)
    true // allow decimals
  );
  return niceTicks;
  //if (breakpoints && breakpoints.length === 2) {
  //return [niceTicks[0], ...breakpoints, niceTicks[-1]];
  //} else {
  //return niceTicks;
  //}
}

const Scatterplot = ({ isEmbed = false }) => {
  const { activeDateDataWithDemographics } = useContext(DataContext);

  const {
    activeMetric,
    activeDemographicMetric,
    selectedRegion,
    activeDate,
    activeScatterplotFilter,
  } = useContext(InteractionContext);

  const {
    dispatchActivateRegion,
    dispatchDeactivateRegion,
    dispatchSetScatterplotFilter,
    dispatchClearScatterplotFilter,
  } = useContext(DispatcherContext);

  const [isHoveringChart, setIsHoveringChart] = useState(false);
  const onMouseMove = useCallback(
    (chartHoverInfo) => {
      // chartInfo is not null whenever the mouse if hovering over the chart portion and not the legends
      if (chartHoverInfo != null && !isHoveringChart) {
        setIsHoveringChart(true);
      } else if (chartHoverInfo == null) {
        setIsHoveringChart(false);
      }
    },
    [isHoveringChart]
  );

  const onHoverDot = useCallback(
    (d) => {
      if (d) {
        dispatchActivateRegion(d.regionId);
      } else {
        dispatchDeactivateRegion();
      }
    },
    [dispatchActivateRegion, dispatchDeactivateRegion]
  );

  const getXValue = (d) => d[activeMetric.column];
  const getYValue = (d) => d[activeDemographicMetric.column];

  let chartData = activeDateDataWithDemographics;

  const xAxisTicks = getScatterPlotTicks(chartData, activeMetric);
  const yAxisTicks = getScatterPlotTicks(chartData, activeDemographicMetric);

  const getScatterplotBoxFromCoords = useCallback(
    (xValue, yValue) => {
      let xIdx;
      let yIdx;
      const xAxisTicksWithoutStart = xAxisTicks.slice(1);
      const yAxisTicksWithoutStart = yAxisTicks.slice(1);
      for (xIdx = 0; xIdx < xAxisTicksWithoutStart.length; xIdx++) {
        if (xValue <= xAxisTicksWithoutStart[xIdx]) {
          break;
        }
      }

      for (yIdx = 0; yIdx < yAxisTicksWithoutStart.length; yIdx++) {
        if (yValue <= yAxisTicksWithoutStart[yIdx]) {
          break;
        }
      }
      return { xIdx, yIdx };
    },
    [xAxisTicks, yAxisTicks]
  );

  // Of the four ticks for each axis, we only use the middle two for scaleThreshold
  const xColorScales = biVariatePallette.map((bivarPalletteRow) =>
    scaleThreshold().domain(xAxisTicks.slice(1, -1)).range(bivarPalletteRow)
  );
  const yColorScale = scaleThreshold()
    .domain(yAxisTicks.slice(1, -1))
    .range(xColorScales);

  chartData = chartData.map((d) => ({
    ...d,
    xValue: getXValue(d),
    yValue: getYValue(d),
    color: d.isInScatterplotFilter
      ? yColorScale(getYValue(d))(getXValue(d))
      : '#F5F6F6',
  }));

  const dateString = formatReadableDate(activeDate);

  const onClick = useCallback(
    ({ xValue, yValue }) => {
      const { xIdx, yIdx } = getScatterplotBoxFromCoords(xValue, yValue);
      if (activeScatterplotFilter) {
        const {
          activeMetricBounds,
          activeDemographicMetricBounds,
        } = activeScatterplotFilter;
        const activeScatterplotFilterBox = getScatterplotBoxFromCoords(
          activeMetricBounds.max,
          activeDemographicMetricBounds.max
        );
        if (
          xIdx === activeScatterplotFilterBox.xIdx &&
          yIdx === activeScatterplotFilterBox.yIdx
        ) {
          dispatchClearScatterplotFilter();
          return;
        }
      }

      const activeMetricMin = xAxisTicks[xIdx];
      const activeMetricMax = xAxisTicks[xIdx + 1];
      const activeDemographicMetricMin = yAxisTicks[yIdx];
      const activeDemographicMetricMax = yAxisTicks[yIdx + 1];

      dispatchSetScatterplotFilter({
        activeMetricMin,
        activeMetricMax,
        activeDemographicMetricMin,
        activeDemographicMetricMax,
      });
    },
    [
      xAxisTicks,
      yAxisTicks,
      dispatchSetScatterplotFilter,
      dispatchClearScatterplotFilter,
      getScatterplotBoxFromCoords,
      activeScatterplotFilter,
    ]
  );

  const margin = {
    top: 0,
    right: 0,
    bottom: 0,
    left: -30,
  };

  const desktopSize = 248;

  const scatterChartProps = !isMobile
    ? {
        onClick,
        onMouseMove,
        width: desktopSize,
        height: desktopSize,
        margin,
      }
    : {
        onClick,
        width: window.innerWidth - 50,
        height: 211,
        margin,
      };

  return (
    <div className="scatterplot">
      <ScatterplotContainer isMobile={isMobile}>
        <div
          className={classNames('scatterplot-chart', {
            'scatterplot-hovered': isHoveringChart,
          })}
        >
          <ScatterChart {...scatterChartProps}>
            <CartesianGrid cursor="pointer" stroke="#E1E4E5" />
            <XAxis
              type="number"
              tickLine={false}
              dataKey={activeMetric.column}
              name={activeMetric.label}
              tickFormatter={(tick) => ''}
              axisLine={{ stroke: '#E1E4E5' }}
              ticks={xAxisTicks}
              // domain is the starting and end point of the ticks
              domain={[xAxisTicks[0], xAxisTicks[3]]}
            >
              <Label
                value={activeMetric.label}
                position="bottom"
                offset={-10}
                style={{
                  textAnchor: 'middle',
                  fontSize: 10,
                  fill: '#232323',
                }}
              />
              <Label
                value={activeMetric.lessIndicator}
                position="insideBottomLeft"
                dy={-15}
                offset={0}
                style={{ textAnchor: 'start', fontSize: 10, fill: '#979797' }}
              />
              <Label
                value={activeMetric.moreIndicator}
                position="insideBottomRight"
                dy={-15}
                offset={0}
                style={{ textAnchor: 'end', fontSize: 10, fill: '#979797' }}
              />
            </XAxis>
            <YAxis
              type="number"
              tickLine={false}
              dataKey={activeDemographicMetric.column}
              name={activeDemographicMetric.label}
              tickFormatter={(tick) => ''}
              axisLine={{ stroke: '#E1E4E5' }}
              ticks={yAxisTicks}
              // domain is the starting and end point of the ticks
              domain={[yAxisTicks[0], yAxisTicks[3]]}
            >
              <Label
                value={activeDemographicMetric.label}
                position="left"
                offset={-35}
                angle={-90}
                style={{
                  textAnchor: 'middle',
                  fontSize: 10,
                  fill: '#232323',
                }}
              />
              <Label
                value={activeDemographicMetric.lessIndicator}
                position="insideBottomLeft"
                dx={50}
                offset={0}
                angle={-90}
                style={{ textAnchor: 'start', fontSize: 10, fill: '#979797' }}
              />
              <Label
                value={activeDemographicMetric.moreIndicator}
                position="insideTopLeft"
                dx={45}
                offset={0}
                angle={-90}
                style={{ textAnchor: 'end', fontSize: 10, fill: '#979797' }}
              />
            </YAxis>
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={() => <ScatterplotTooltipContent />}
            />
            <Scatter
              isAnimationActive={false}
              name="Symptom Scatterplot"
              data={chartData}
            >
              {chartData.map((entry, index) => {
                return (
                  <Cell
                    className="scatterplot-cell"
                    onMouseEnter={() => onHoverDot(entry)}
                    onMouseOut={() => onHoverDot(null)}
                    key={`cell-${entry.regionId}`}
                    fill={entry.color}
                  />
                );
              })}
            </Scatter>
          </ScatterChart>
        </div>
        <div className="scatterplot-sources">
          <div className={'legend-text'}>
            {activeMetric.label} source:{' '}
            {activeMetric.dataSource(selectedRegion)}, {dateString}
          </div>
          <div className={'legend-text'}>
            {activeDemographicMetric.label} source:{' '}
            {activeDemographicMetric.dataSource(selectedRegion)}, {dateString}
          </div>
          {isEmbed ? null : <GlossaryButton />}
        </div>
      </ScatterplotContainer>
    </div>
  );
};

export default Scatterplot;
//<ResponsiveContainer width="100%" height={311}>
//<>
//<Scatter name="Symptom Scatterplot" data={fakeData}>
//{fakeData.map((entry, index) => {
//return <Cell key={`cell-${index}`} fill={entry['color']} />;
//})}
//</Scatter>
//{biVariatePallette
//.map((entry, indexX) => {
//return entry.map((color, indexY) => {
//const x1 = indexX * 0.34;
//const x2 = x1 + 0.32;
//const y1 = indexY * 0.34;
//const y2 = y1 + 0.32;
//return (
//<ReferenceArea
//className="scater-plot-ref-area"
//onClick={() =>
//selectedColor === color
//? onSelectColor(null)
//: onSelectColor(color)
//}
//key={color}
//x1={x1}
//x2={x2}
//y1={y1}
//y2={y2}
//fill={
//selectedColor == null || selectedColor === color
//? color
//: '#F5F6F6'
//}
//fillOpacity="1"
//isFront={true}
///>
//);
//});
//})
//.flat()}
//</>
//</ResponsiveContainer>
