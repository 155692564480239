import React from 'react';

export const PageLeftSVG = () => (
  <g transform="translate(-5, -5)">
    <path
      fill="#0972A6"
      stroke="#0972A6"
      d="M6.614 8.114l-.5.5L1.872 4.37 6.114.13l.5.5L2.872 4.37l3.742 3.743z"
    />
  </g>
);
