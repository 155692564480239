import { groups } from 'd3-array';

import { CMU_SIGNALS } from 'common/DataContext/constants';
import { fetchData } from 'common/DataContext/fetchData';
import {
  REGION_TYPES,
  WORLD,
  STAT_NAMES,
  STAT_NAMES_LIST,
  COUNTRY_CODE_TYPES,
  USA,
} from 'common/constants';
import {
  parseDashlessDate,
  parseDate,
  stripGADMSuffix,
} from 'common/DataContext/util';
import {
  filterResultsByMinExtents,
  processFetchedResults,
} from './processData';

const parseWorldStats = {
  [COUNTRY_CODE_TYPES.GADM]: {
    [STAT_NAMES.SYMPTOM]: (rows) => {
      return rows.slice(1).map((row) => ({
        regionId: row[0],
        date: parseDashlessDate(row[1]),
        denominator: parseFloat(row[2]),
        pctCLI: parseFloat(row[3]),
        pctILI: parseFloat(row[4]),
      }));
    },
    [STAT_NAMES.MOVEMENT]: (rows) => {
      return rows.slice(1).map((row) => ({
        regionId: row[1],
        date: parseDate(row[2]),
        all_day_bing_tiles_visited_relative_change_day_of_week: parseFloat(
          row[3]
        ),
        all_day_ratio_single_tile_users: parseFloat(row[4]),
      }));
    },
    [STAT_NAMES.CASES]: (rows) => {
      // 0: "country_code"
      // 1: "gadm_code"
      // 2: "case_date"
      // 3: "new_confirmed"
      // 4: "new_death"
      // 5: "total_confirmed"
      // 6: "total_death"
      // 7: "total_recovered"
      // 8: "total_test"
      // 9: "total_hospitalized"

      return rows.slice(1).map((row) => ({
        regionId: stripGADMSuffix(row[1]),
        date: parseDate(row[2]),
        new_confirmed: parseFloat(row[3]),
        new_death: parseFloat(row[4]),
        total_confirmed: parseFloat(row[5]),
        total_death: parseFloat(row[6]),
        total_recovered: parseFloat(row[7]),
        total_test: parseFloat(row[8]),
        total_hospitalized: parseFloat(row[9]),
      }));
    },
  },
  [COUNTRY_CODE_TYPES.FIPS]: {
    [STAT_NAMES.SYMPTOM]: (rows) => {
      const parsedRows = [];
      const grouped = groups(rows.slice(1), (d) => d[0]);
      grouped.forEach(([dateString, dateGroup]) => {
        const date = parseDashlessDate(dateString);
        const denominator = parseFloat(dateGroup[0][4]);
        const pctCLI =
          parseFloat(dateGroup.find((d) => d[5] === CMU_SIGNALS.CLI)[2]) /
          100.0;
        const pctILI =
          parseFloat(dateGroup.find((d) => d[5] === CMU_SIGNALS.ILI)[2]) /
          100.0;
        parsedRows.push({
          regionId: USA,
          date,
          denominator,
          pctCLI,
          pctILI,
        });
      });
      return parsedRows;
    },
    [STAT_NAMES.MOVEMENT]: (rows) => {
      return rows.slice(1).map((row) => ({
        regionId: USA,
        date: parseDate(row[0]),
        all_day_bing_tiles_visited_relative_change_day_of_week: parseFloat(
          row[1]
        ),
        all_day_ratio_single_tile_users: parseFloat(row[2]),
      }));
    },
    [STAT_NAMES.CASES]: (rows) => {
      //console.log("rows");
      //console.log(rows);
      return [];
    },
  },
};

const createFetchersForCountryCodeType = (countryCodeType) =>
  // Creates a data fetcher for each stat
  STAT_NAMES_LIST.map((statName) =>
    fetchData({
      adminLevel: REGION_TYPES.ADMIN0,
      statName,
      parseRows: parseWorldStats[countryCodeType][statName],
      countryCodeType,
    })
  );

export const fetchWorldStats = ({ metadata, fipsToName }) => {
  // Creates data fetchers for each stat, for each country code type
  const worldStatsFetchers = []
    .concat(createFetchersForCountryCodeType(COUNTRY_CODE_TYPES.GADM))
    .concat(createFetchersForCountryCodeType(COUNTRY_CODE_TYPES.FIPS));

  return async () => {
    const fetcherResults = await Promise.all(
      worldStatsFetchers.map((doFetch) => doFetch(WORLD, metadata))
    );

    // For each stat, ensures GADM and FIPS data is aligned, discarding
    // points where either pipeline has lagged
    STAT_NAMES_LIST.forEach((statName) => {
      // Gets results for this stat
      const fetcherResultsForStat = fetcherResults.filter(
        (r) => r.statName === statName
      );

      // Ensures data from US and other countries is aligned, since they come
      // from different pipelines.
      filterResultsByMinExtents(fetcherResultsForStat);
    });

    return processFetchedResults(fetcherResults, fipsToName);
  };
};
