import { csv } from 'd3-fetch';

import pattersonCentroidsDataURL from 'data/centroids_patterson.csv';
import mercatorCentroidsDataURL from 'data/centroids_mercator.csv';
import { MAP_PROJECTION } from 'common/constants';
import { proj } from 'components/common/Map/mapConfig';

const CENTROID_URL_BY_PROJECTION = {
  [MAP_PROJECTION.MERCATOR]: mercatorCentroidsDataURL,
  [MAP_PROJECTION.PATTERSON]: pattersonCentroidsDataURL,
};

let cachedCentroidsData;

export const fetchCentroidsData = function () {
  const centroidsDataURL = CENTROID_URL_BY_PROJECTION[proj];
  if (cachedCentroidsData) {
    return Promise.resolve(cachedCentroidsData);
  } else {
    return new Promise((res, rej) => {
      csv(centroidsDataURL).then((centroidsData) => {
        cachedCentroidsData = centroidsData.map(
          ({ id, x, y, minx, miny, maxx, maxy }) => ({
            // TODO: remove FIPS key when all references are removed
            FIPS: id,
            regionId: id,
            x: +x,
            y: +y,
            minx: +minx,
            miny: +miny,
            maxx: +maxx,
            maxy: +maxy,
          })
        );
        res(cachedCentroidsData);
      });
    });
  }
};
