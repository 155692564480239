import React from 'react';

export default function intro() {
  // prettier-ignore
  return (
<div className="section-inner">
      <div className="story">
        <div className="section section-partnering">
          <div className="content"><h4 className="eyebrow">الاستبيان وبيانات الخريطة</h4>
            <h3>دعم البحث المتعلق بفيروس كورونا (كوفيد-١٩)</h3>
            <p>مع وجود ما يزيد عن 2 مليار شخص على فيسبوك، نتمتع بوضع مميز
               لدعم أبحاث الصحة العامة. لقد عقدنا شراكات مع
              مؤسسات أكاديمية ومنظمات غير هادفة للربح موثوق بها منذ 2017، من خلال
              برنامج Data For Good (بيانات من أجل الأعمال الخيرية)، لبناء شبكة بحثية للتعامل
              مع بعض أكبر المشكلات الإنسانية في العالم. مؤخرًا، عقدنا
              شراكة مع<span> </span>
              <a className="bold-link" href="https://delphi.cmu.edu/" target="_blank" rel="noopener noreferrer">
                جامعة كارنيجي ميلون
              </a><span> </span>
              و<span> </span>
              <a className="bold-link" href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data" target="_blank" rel="noopener noreferrer">
                جامعة ميريلاند
              </a><span> </span>
              لدعوة الأشخاص إلى المشاركة في استبيانات عن أعراض فيروس كورونا (كوفيد-١٩)
              وعوامل الخطر المرتبطة به. ونستخدم بيانات الاستبيان في خريطتنا، ونعرض أيضًا
              البيانات المجمعة الأخرى المتاحة للاطلاع العام لتوفير
              نظرة أكثر شمولاً عن جائحة فيروس كورونا (كوفيد-١٩).</p>
            <p>وقد وضعنا مراعاة الخصوصية نصب أعيننا أثناء تصميم المبادرات مع شركائنا
              من البداية.</p>
            <p className="link"><a href="./survey_and_map_data.html" rel="noopener noreferrer">
                <img className="link" alt="" src={`${process.env.PUBLIC_URL}/svg/ic-grey-link-arrow.svg`}/>
                تعرف على المزيد عن الاستبيانات وبيانات الخريطة
              </a></p></div>
          <div className="partner-logos">
            <a className="cmu" href="https://www.cmu.edu" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/svg/cmu-wordmark-square-w-on-70k.svg`} alt="Carnegie Mellon University logo" aria-label="Carnegie Mellon University logo" />
            </a>
            <a className="umd" href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/svg/university-of-maryland-1-1.svg`} aria-label="University of Maryland logo" alt="University of Maryland logo" />
            </a>
          </div>
        </div>
      </div>

      <div className="story">
        <div className="section tools-to-help">
          <div className="content"><h4 className="eyebrow">أدوات إضافية للمساعدة</h4><div className="more-info">
              <div className="request-access-section info">
                <a href="https://dataforgood.fb.com/docs/covid-19-symptom-survey-request-for-data-access/" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>تتوفر البيانات المجمعة للأعراض للاطلاع العام
                      ويمكن الوصول إليها من جامعة كارنيجي ميلون و
                      جامعة ميريلاند. يسمح فيسبوك والجامعات الشريكة
                      أيضًا للمؤسسات البحثية المؤهلة وغير الهادفة للربح
                      بطلب الوصول إلى البيانات غير المجمعة.</p>
                    <p className="call-to-action">تعرف على المزيد عن الوصول للأغراض البحثية</p></div>
                </a>
              </div>

              <div className="facebook-ai-section info">
                <a href="https://ai.facebook.com/blog/using-ai-to-help-health-experts-address-the-covid-19-pandemic" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>وفر تقنيات الذكاء الاصطناعي للتنبؤ بانتشار المرض
                      ومعدل التأثير على المستشفيات، على مستوى البلد
                      في مناطق معينة لمساعدة الأنظمة الصحية المحلية على فهم الحاجة
                      للأسِّرة والكمامات وأجهزة التنفس.</p>
                    <p className="call-to-action">تعرَّف على المزيد عن الذكاء الاصطناعي</p></div>
                </a>
              </div>

              <div className="follow-covid-info-section info">
                <a href="https://www.facebook.com/coronavirus_info/" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>احصل على آخر التحديثات والنصائح بشأن السلامة من المؤسسات الصحية
                      والإخبارية على فيسبوك.</p>
                    <p className="call-to-action">متابعة معلومات فيروس كورونا (كوفيد-١٩)</p></div>
                </a>
              </div>

              <div className="data-for-good-section info">
                <a href="https://dataforgood.fb.com/" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>كجزء من برنامج Data For Good (بيانات من أجل الأعمال الخيرية)، نوفر خرائط الوقاية من المرض
                      لمساعدة الباحثين في المجال الصحي على فهم كيفية تأثير
                      ديناميكيات السكان في انتشار المرض. تستخدم الخرائط بيانات مجمعة بهدف
                      حماية الخصوصية.</p>
                    <p className="call-to-action">تعرف على المزيد عن برنامج Data For Good (بيانات من أجل الأعمال الخيرية)</p></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
