import styled from 'styled-components';
import { mobileBreakpoint, crispness } from 'styles/styles';

export const Wrapper = styled.div`
  height: 100%;
  background-color: white;
  border-radius: 3px;
  ${crispness}
`;

export const Scroller = styled.div`
  @media (min-width: ${mobileBreakpoint}px) {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
  }
`;

export const SVG = styled.svg`
  @media (min-width: ${mobileBreakpoint}px) {
    position: absolute;
  }
`;
