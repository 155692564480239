import { csv } from 'd3-fetch';
import demographicsDataURL from 'data/demographics.csv';

let cachedDemographicMetrics;

export const fetchDemographicsData = function () {
  if (cachedDemographicMetrics) {
    return Promise.resolve(cachedDemographicMetrics);
  } else {
    const demographicFields = [
      'FIPS',
      'Name',
      'Total population',
      '65+ population',
      '65+ percent',
      'Density (per sq km)',
      'Land Area (sq km)',
    ];
    const demographicMetricIndexes = [2, 3, 4, 5, 6];
    return new Promise((res, rej) => {
      csv(demographicsDataURL).then((demographicsData) => {
        cachedDemographicMetrics = demographicsData.map((d) => {
          demographicFields.forEach((f, i) => {
            if (demographicMetricIndexes.includes(i)) {
              // Using parseFloat here instead of unary plus
              // so that empty strings are parsed as NaN, not 0.
              d[f] = parseFloat(d[f]);
            }
          });
          d.regionId = d.FIPS;
          return d;
        });
        res(cachedDemographicMetrics);
      });
    });
  }
};
