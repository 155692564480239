import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { parse } from 'query-string';

import { DataProvider } from 'common/DataContext';
import { InteractionProvider } from 'common/InteractionContext';
import { DispatcherProvider } from 'common/DispatcherContext';
import { FetchingProvider } from 'common/DataContext/FetchingContext';
import { Container } from 'styles/styles';
import useAsyncStyles from 'common/useAsyncStyles';

import MovementTrendsViz from 'components/mobility/MovementTrendsViz';
import NavigationHeader from 'components/mobility/NavigationHeader';

const isEmbed = parse(window.location.search).embed !== undefined;

// TODO: This would not be needed if less conflicting global styles
const loadStyles = () =>
  Promise.all([
    import('bootstrap/dist/css/bootstrap.css'),
    import('styles/index_mobility.scss'),
  ]);
export const AppMobility = () => {
  const stylesLoaded = useAsyncStyles(loadStyles);
  return stylesLoaded ? (
    <BrowserRouter>
      {!isEmbed ? <NavigationHeader /> : null}
      <Container isEmbed={isEmbed}>
        <InteractionProvider>
          <FetchingProvider>
            <DataProvider>
              <DispatcherProvider>
                <MovementTrendsViz isEmbed={isEmbed} />
              </DispatcherProvider>
            </DataProvider>
          </FetchingProvider>
        </InteractionProvider>
      </Container>
    </BrowserRouter>
  ) : null;
};
