import React from 'react';

import {
  EmbedContentLink,
  EmbedParagraph,
  EmbedIntroHeader,
} from '../../styles';

const EmbedIntro = () => {
  return (
    // prettier-ignore

    <div>
      <EmbedIntroHeader>تضمين الخريطة المخصصة في موقعك</EmbedIntroHeader>
      <EmbedParagraph>
        بتضمين هذه الخريطة، فإنك توافق على <span> </span>
        <EmbedContentLink href="https://www.facebook.com/legal/terms" style={{ textDecoration: 'none' }}>
          شروط الخدمة
        </EmbedContentLink><span> </span>
        و<span> </span>
        <EmbedContentLink href="https://developers.facebook.com/policy/" style={{ textDecoration: 'none' }}>
          سياسة المطوِّرين
        </EmbedContentLink>
         لفيسبوك.
      </EmbedParagraph>
    </div>
  );
};

export default EmbedIntro;
