import React from 'react';
import Fr from './translations/fr';
import En from './embedIntro';
import ZhCn from './translations/zh_cn';
import Ar from './translations/ar';

export default function embedIntro(lang) {
  switch (lang) {
    case 'fr':
      return <Fr />;
    case 'zh_cn':
      return <ZhCn />;
    case 'ar':
      return <Ar />;
    default:
      return <En />;
  }
}
