import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import {
  //hasChildren,
  getParentRegionId,
  isAdmin0,
  isAdmin1,
  isSupportedCountry,
} from 'common/DataContext/accessors';
import {
  TIME_RESOLUTION_IDS,
  TIME_RESOLUTIONS_BY_ID,
  WORLD,
  USA,
  INTERVAL_ID,
  INTERVALS_BY_ID,
  METRIC_IDS,
} from 'common/constants';
import { createScatterplotFilter } from 'common/DataContext/model';
import { worldBounds, proj } from 'components/common/Map/mapConfig';
import { isMobile } from 'styles/styles';

// This was the only functional difference from `getParentRegionId` - WORLD instead of null at the top layer
// TODO: remove this or move to accessors
const getParent = (regionId) => getParentRegionId(regionId) || WORLD;

export const DispatcherContext = createContext({});

export const DispatcherProvider = ({ children }) => {
  const {
    setActiveNoDataRow,
    setSelectedRegion,
    activeRowIsPinned,
    setActiveRowIsPinned,
    activeRow,
    setActiveRow,
    activeDate,
    setActiveDate,
    activeMetric,
    setActiveMetric,
    isEmbedDrawerOpen,
    setIsEmbedDrawerOpen,
    isSmallMultiplesOpen,
    setIsSmallMultiplesOpen,
    selectedTimeInterval,
    setSelectedTimeInterval,
    lineChartSelectedMetrics,
    setLineChartSelectedMetrics,
    selectedTimeResolution,
    setSelectedTimeResolution,
    setActiveScatterplotFilter,
    setActiveDemographicMetric,
    selectedRegion,
    bounds,
    setBounds,
    visibleDateInterval,
    setVisibleDateInterval,
  } = useContext(InteractionContext);

  const { data, activeDateData, getBoundsFromRegion, dateExtent } = useContext(
    DataContext
  );

  const dispatchZoomMapToRegion = useCallback(
    (regionId) => {
      if (!getBoundsFromRegion) {
        return;
      }
      const newBounds = getBoundsFromRegion(regionId);
      if (newBounds) {
        setBounds(newBounds);
      }
    },
    [getBoundsFromRegion, setBounds]
  );

  // Initializes map bounds if a selectedRegion on startup
  useEffect(() => {
    if (!bounds) {
      if (selectedRegion && selectedRegion !== WORLD) {
        dispatchZoomMapToRegion(selectedRegion);
      } else {
        setBounds(worldBounds[proj]);
      }
    }
  }, [bounds, setBounds, selectedRegion, dispatchZoomMapToRegion]);

  // Sets the new map bounds based on the currently-selected region
  useEffect(() => {
    if (selectedRegion && selectedRegion !== WORLD) {
      dispatchZoomMapToRegion(selectedRegion);
    } else {
      setBounds(worldBounds[proj]);
    }
  }, [selectedRegion, setBounds, dispatchZoomMapToRegion]);

  const dispatchActivateRegion = useCallback(
    (regionId) => {
      // Cannot activate new regions when in pinned state
      if (activeRowIsPinned) {
        return;
      }
      let newActiveRow = activeDateData.find(
        (row) => row.regionId === regionId
      );
      // selected county or state does not matter because both use the regionId
      // property, activeDateData assumed loaded with rows of the proper type.
      if (newActiveRow) {
        setActiveRow(newActiveRow);
        setActiveNoDataRow(null);
      } else {
        setActiveRow(null);
        setActiveNoDataRow({
          regionId,
        });
      }

      if (isMobile) {
        dispatchZoomMapToRegion(regionId);
      }
    },
    [
      setActiveRow,
      setActiveNoDataRow,
      activeRowIsPinned,
      activeDateData,
      dispatchZoomMapToRegion,
    ]
  );

  const dispatchSelectRegion = useCallback(
    (regionId) => {
      console.log('dispatchSelectRegion ' + regionId);
      //console.log(hasChildren(regionId));
      // If we're at the leaf level (e.g. clicking US counties),
      // we are toggling the pinned state.
      //if (!hasChildren(regionId)) {
      //let newActiveRow = activeDateData.find(
      //  (row) => row.regionId === regionId
      //);
      //if (activeRowIsPinned) {
      //  if (newActiveRow === activeRow) {
      //    setActiveRowIsPinned(false);
      //  } else {
      //    setActiveRow(newActiveRow);
      //  }
      //} else {
      //  setActiveRowIsPinned(true);
      //  setActiveRow(newActiveRow);
      //}
      //} else {
      // Otherwise we are selecting the region
      //setActiveRowIsPinned(false);
      setActiveRow(null);
      setSelectedRegion(regionId);
      //}
    },
    [setSelectedRegion, setActiveRow]
  );

  const allData = data.mobilityStats;

  const dispatchSelectDate = setActiveDate;

  // Handle the case that the active row is pinned,
  // and the user changes the selected date.
  // Idea: update the active row to match the selected date.
  // Without this logic, the active row would be for a date
  // other than the active date after the user changes the active date.
  useEffect(() => {
    // If active row is pinned, keep it pinned and select the closest point as
    // the active row.
    if (
      activeRow &&
      activeRowIsPinned &&
      activeRow.date.getTime() !== activeDate.getTime()
    ) {
      const newActiveRow = allData.find(
        (d) =>
          d.date.getTime() === activeDate.getTime() &&
          !isNaN(d[activeMetric.column]) &&
          d.regionId === activeRow.regionId
      );
      if (newActiveRow) {
        setActiveRow(newActiveRow);
      } else {
        // User navigated to undefined data, so unpin
        setActiveRowIsPinned(false);
        setActiveRow(null);
      }
    }
  }, [
    activeRowIsPinned,
    setActiveRowIsPinned,
    allData,
    activeDate,
    activeRow,
    setActiveRow,
    activeMetric,
  ]);

  const dispatchActivateRegionAndDate = useCallback(
    (row) => {
      if (!activeRowIsPinned) {
        let newActiveRow = row;
        if (newActiveRow) {
          setActiveRow(newActiveRow);
        } else {
          setActiveNoDataRow({
            regionId: row.regionId,
          });
        }
      }
    },
    [setActiveRow, setActiveNoDataRow, activeRowIsPinned]
  );

  const dispatchActivateDate = setActiveDate;

  const dispatchDeactivateRegion = useCallback(() => {
    if (!activeRowIsPinned) {
      setActiveRow(null);
      setActiveNoDataRow(null);
    }
  }, [activeRowIsPinned, setActiveRow, setActiveNoDataRow]);

  const dispatchChangeSelectedRegion = useCallback(
    (regionId) => {
      setActiveRowIsPinned(false);
      setActiveRow(null);
      setSelectedRegion(regionId);
    },
    [setActiveRowIsPinned, setActiveRow, setSelectedRegion]
  );

  const dispatchDeselectRegion = useCallback(() => {
    setActiveRowIsPinned(false);
    setActiveRow(null);
    setSelectedRegion(null);
  }, [setActiveRowIsPinned, setActiveRow, setSelectedRegion]);

  const dispatchToggleEmbedDrawer = useCallback(() => {
    setIsEmbedDrawerOpen(!isEmbedDrawerOpen);
  }, [isEmbedDrawerOpen, setIsEmbedDrawerOpen]);

  const dispatchToggleSmallMultiples = useCallback(() => {
    setIsSmallMultiplesOpen(!isSmallMultiplesOpen);
  }, [isSmallMultiplesOpen, setIsSmallMultiplesOpen]);

  const dispatchSelectTimeInterval = useCallback(
    (interval) => {
      setSelectedTimeInterval(interval);
    },
    [setSelectedTimeInterval]
  );

  const dispatchToggleTimeResolution = useCallback(() => {
    // If switching from day to week,
    if (selectedTimeResolution.timeResolutionId === TIME_RESOLUTION_IDS.DAY) {
      setSelectedTimeResolution(
        TIME_RESOLUTIONS_BY_ID[TIME_RESOLUTION_IDS.WEEK]
      );

      // show eight weeks worth of data.
      setSelectedTimeInterval(INTERVALS_BY_ID[INTERVAL_ID.EIGHT_W]);
    } else {
      // If switching from week to day,
      setSelectedTimeResolution(
        TIME_RESOLUTIONS_BY_ID[TIME_RESOLUTION_IDS.DAY]
      );

      // show eight days worth of data.
      setSelectedTimeInterval(INTERVALS_BY_ID[INTERVAL_ID.EIGHT_D]);
    }
  }, [
    selectedTimeResolution,
    setSelectedTimeResolution,
    setSelectedTimeInterval,
  ]);

  const getNewVisibleDateInterval = useCallback(
    (timeFunction, offset) =>
      visibleDateInterval.map((d) => timeFunction.offset(d, offset)),
    [visibleDateInterval]
  );

  const getDateIsInBounds = useCallback(
    (date) =>
      date &&
      dateExtent &&
      dateExtent.length === 2 &&
      dateExtent[0] &&
      dateExtent[1] &&
      date.getTime() >= dateExtent[0].getTime() &&
      date.getTime() <= dateExtent[1].getTime(),
    [dateExtent]
  );

  const dispatchTimePage = useCallback(
    (direction) => {
      const newVisibleDateInterval = getNewVisibleDateInterval(
        selectedTimeInterval.timeFunction,
        selectedTimeInterval.timeOffset * direction
      );
      setVisibleDateInterval(newVisibleDateInterval);
    },
    [selectedTimeInterval, setVisibleDateInterval, getNewVisibleDateInterval]
  );

  const dispatchTimePageLeft = useCallback(() => dispatchTimePage(-1), [
    dispatchTimePage,
  ]);

  const dispatchTimePageRight = useCallback(() => dispatchTimePage(1), [
    dispatchTimePage,
  ]);

  const canTimePage = useCallback(
    (direction) => {
      const newVisibleDateInterval = getNewVisibleDateInterval(
        selectedTimeInterval.timeFunction,
        selectedTimeInterval.timeOffset * direction
      );
      if (direction < 0) {
        return getDateIsInBounds(newVisibleDateInterval[0]);
      } else {
        return getDateIsInBounds(newVisibleDateInterval[1]);
      }
    },
    [getNewVisibleDateInterval, selectedTimeInterval, getDateIsInBounds]
  );

  const canTimePageLeft = useCallback(() => canTimePage(-1), [canTimePage]);
  const canTimePageRight = useCallback(() => canTimePage(1), [canTimePage]);

  const dispatchTimeIncrement = useCallback(
    (direction) => {
      const newVisibleDateInterval = visibleDateInterval.map((d) =>
        selectedTimeResolution.timeFunction.offset(d, direction)
      );
      setVisibleDateInterval(newVisibleDateInterval);
    },
    [visibleDateInterval, selectedTimeResolution, setVisibleDateInterval]
  );

  const dispatchTimeIncrementLeft = useCallback(
    () => dispatchTimeIncrement(-1),
    [dispatchTimeIncrement]
  );
  const dispatchTimeIncrementRight = useCallback(
    () => dispatchTimeIncrement(1),
    [dispatchTimeIncrement]
  );

  const canTimeIncrement = useCallback(
    (direction) => {
      const newVisibleDateInterval = getNewVisibleDateInterval(
        selectedTimeResolution.timeFunction,
        direction
      );
      if (direction < 0) {
        return getDateIsInBounds(newVisibleDateInterval[0]);
      } else {
        return getDateIsInBounds(newVisibleDateInterval[1]);
      }
    },
    [getNewVisibleDateInterval, selectedTimeResolution, getDateIsInBounds]
  );

  const canTimeIncrementLeft = useCallback(() => canTimeIncrement(-1), [
    canTimeIncrement,
  ]);
  const canTimeIncrementRight = useCallback(() => canTimeIncrement(1), [
    canTimeIncrement,
  ]);

  const dispatchUpdateLineChartMetric = useCallback(
    (lineChartIndex, newMetric) => {
      const newLineChartSelectedMetrics = [...lineChartSelectedMetrics];
      newLineChartSelectedMetrics[lineChartIndex] = newMetric;
      setLineChartSelectedMetrics(newLineChartSelectedMetrics);
      setActiveMetric(newMetric);
    },
    [lineChartSelectedMetrics, setLineChartSelectedMetrics, setActiveMetric]
  );

  const dispatchSetScatterplotFilter = useCallback(
    (props) => setActiveScatterplotFilter(createScatterplotFilter(props)),
    [setActiveScatterplotFilter]
  );
  const dispatchClearScatterplotFilter = useCallback(
    () => setActiveScatterplotFilter(null),
    [setActiveScatterplotFilter]
  );

  const dispatchSetActiveDemographicMetric = useCallback(
    (newActiveDemographicMetric) => {
      setActiveDemographicMetric(newActiveDemographicMetric);
    },
    [setActiveDemographicMetric]
  );

  const dispatchClearActiveDemographicMetric = useCallback(() => {
    setActiveScatterplotFilter(null);
    setActiveDemographicMetric(null);
  }, [setActiveScatterplotFilter, setActiveDemographicMetric]);

  const isAllowedAdmin2Selection = useCallback(
    (regionId) =>
      selectedRegion &&
      getParent(getParent(regionId)) === USA &&
      selectedRegion === getParentRegionId(regionId),
    [selectedRegion]
  );

  // Interactions to select various regions, as intended on the map
  const dispatchUserSelectedAdmin2Region = useCallback(
    (regionId) => {
      //console.log(
      //'dispatchUserSelectedAdmin2Region',
      //regionId,
      //getParent(regionId),
      //selectedRegion
      //);
      // If zoomed in on an admin1 and selection was for a subregion in the same admin1
      if (isAllowedAdmin2Selection(regionId)) {
        // Don't do this unless it's USA
        if (isMobile) {
          dispatchActivateRegion(regionId);
        }
      }
    },
    [isAllowedAdmin2Selection, dispatchActivateRegion]
  );

  // Checks whether the selected region has data available for a given regionId
  // (assumed to be a regionId within the selected region).
  // If not, drilling down into it is not allowed.
  const childRegionHasData = useCallback(
    (regionId) => {
      const regionDataEntry =
        activeDateData && activeDateData.find((d) => d.regionId === regionId);
      if (!regionDataEntry) return false;
      return !isNaN(regionDataEntry[activeMetric.column]);
    },
    [activeDateData, activeMetric]
  );

  const isAllowedAdmin1Selection = useCallback(
    (regionId, fromGeocoder = false) =>
      selectedRegion &&
      selectedRegion !== regionId &&
      (fromGeocoder || isAdmin0(selectedRegion)) &&
      (fromGeocoder ||
        isMobile ||
        (getParent(regionId) === selectedRegion &&
          (childRegionHasData(regionId) ||
            [METRIC_IDS.MOBILITY, METRIC_IDS.STAY_PUT].includes(
              activeMetric.id
            )))),
    [selectedRegion, childRegionHasData, activeMetric.id]
  );

  const isAllowedAdmin1SelectionChange = useCallback(
    (regionId) =>
      selectedRegion &&
      selectedRegion !== regionId &&
      isAdmin1(selectedRegion) &&
      getParent(regionId) === USA &&
      getParent(regionId) === getParent(selectedRegion) &&
      childRegionHasData(regionId),
    [selectedRegion, childRegionHasData]
  );

  const dispatchUserSelectedAdmin1Region = useCallback(
    (regionId, fromGeocoder = false) => {
      // if !selectedRegion, it's a world map.
      // Only admin0 should react to clicks in that case, so do nothing here.
      //
      // if selectedRegion exists, admin1s should only respond to clicks if
      // selected Region is the USA or if it's a neighboring state.
      if (isAllowedAdmin1Selection(regionId, fromGeocoder)) {
        isMobile
          ? dispatchActivateRegion(regionId)
          : dispatchChangeSelectedRegion(regionId);
      } else if (isAllowedAdmin1SelectionChange(regionId)) {
        isMobile
          ? dispatchActivateRegion(regionId)
          : dispatchChangeSelectedRegion(regionId);
      }
    },
    [
      dispatchChangeSelectedRegion,
      isAllowedAdmin1Selection,
      isAllowedAdmin1SelectionChange,
      dispatchActivateRegion,
    ]
  );

  const isAllowedAdmin0Selection = useCallback(
    (regionId, fromGeocoder = false) =>
      (!selectedRegion || selectedRegion === WORLD || fromGeocoder) &&
      isAdmin0(regionId) &&
      isSupportedCountry(regionId) &&
      (fromGeocoder || childRegionHasData(regionId)),
    [selectedRegion, childRegionHasData]
  );

  const isAllowedAdmin0SelectionChange = useCallback(
    (regionId) =>
      isSupportedCountry(regionId) &&
      isAdmin0(regionId) &&
      selectedRegion !== regionId &&
      (isAdmin0(selectedRegion) ||
        (isAdmin1(selectedRegion) && getParent(selectedRegion) !== regionId)) &&
      childRegionHasData(regionId),
    [selectedRegion, childRegionHasData]
  );

  const dispatchUserSelectedAdmin0Region = useCallback(
    (regionId, fromGeocoder = false) => {
      // checking isAdmin0 on regionId seems redundant here but it also protects us
      // from selecting country polygons that don't have data attached
      //console.log(
      //  'dispatchUserSelectedAdmin0Region',
      //  regionId,
      //  getParent(regionId),
      //  selectedRegion
      //);
      // Only respond to country clicks if this country is not in the blacklist
      if (isAllowedAdmin0Selection(regionId, fromGeocoder)) {
        isMobile
          ? dispatchActivateRegion(regionId)
          : dispatchSelectRegion(regionId);
      } else if (isAllowedAdmin0SelectionChange(regionId)) {
        isMobile
          ? dispatchActivateRegion(regionId)
          : dispatchChangeSelectedRegion(regionId);
      }
    },
    [
      dispatchSelectRegion,
      dispatchChangeSelectedRegion,
      isAllowedAdmin0Selection,
      isAllowedAdmin0SelectionChange,
      dispatchActivateRegion,
    ]
  );
  const dispatchUserSelectedParentRegion = useCallback(() => {
    // If there's a currently selected region, just go one step up in the hierarchy if we click in the ocean
    //console.log("Clicked in water. Current region:", selectedRegion, "Current parent:", getParent(selectedRegion));
    if (
      selectedRegion &&
      selectedRegion !== WORLD &&
      getParent(selectedRegion)
    ) {
      dispatchChangeSelectedRegion(getParent(selectedRegion));
    } else {
      dispatchDeselectRegion();
    }
  }, [dispatchChangeSelectedRegion, dispatchDeselectRegion, selectedRegion]);

  const value = {
    dispatchActivateRegion,
    dispatchSelectRegion,
    dispatchSelectDate,
    dispatchDeactivateRegion,
    dispatchDeselectRegion,
    dispatchActivateRegionAndDate,
    dispatchChangeSelectedRegion,
    dispatchActivateDate,
    dispatchToggleEmbedDrawer,
    dispatchToggleSmallMultiples,
    dispatchSelectTimeInterval,
    dispatchToggleTimeResolution,
    dispatchUpdateLineChartMetric,
    dispatchSetScatterplotFilter,
    dispatchClearScatterplotFilter,
    dispatchSetActiveDemographicMetric,
    dispatchClearActiveDemographicMetric,
    dispatchUserSelectedAdmin0Region,
    dispatchUserSelectedAdmin1Region,
    dispatchUserSelectedAdmin2Region,
    dispatchUserSelectedParentRegion,
    dispatchZoomMapToRegion,
    dispatchTimePageLeft,
    dispatchTimePageRight,
    dispatchTimeIncrementLeft,
    dispatchTimeIncrementRight,
    canTimePageLeft,
    canTimePageRight,
    canTimeIncrementLeft,
    canTimeIncrementRight,
  };

  return value ? (
    <DispatcherContext.Provider value={value}>
      {children}
    </DispatcherContext.Provider>
  ) : null;
};
