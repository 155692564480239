import React from 'react';
import { Legend } from 'components/common/LineChart/Legend';
import { MetricComplex } from 'components/mobility/MetricComplex';
import { Map } from 'components/common/Map';
import InterventionAttribution from './InterventionAttribution';
import { Credits } from './Credits';
import ColorLegend from './ColorLegend';
import { CenteredCountryDropdown } from 'components/mobility/CountryDropdown';
import MovementLineChart from './MovementLineChart';
import DataDateText from './DataDateText';
import {
  Bottom,
  Right,
  RightTop,
  RightBottom,
  LegendContainer,
  EmbedHeader,
  EmbedBackground,
} from './styles';

export const EmbedLayout = () => (
  <EmbedBackground>
    <EmbedHeader>
      <div>
        Visit full site at{' '}
        <a href="https://visualization.covid19mobility.org">
          visualization.covid19mobility.org
        </a>
      </div>
      <div>
        Updated <DataDateText />
      </div>
    </EmbedHeader>
    <Bottom>
      <Right>
        <MetricComplex isEmbed={true} />
        <CenteredCountryDropdown />
        <RightTop>
          <Map />
          <ColorLegend />
        </RightTop>
        <RightBottom isEmbed={true}>
          <LegendContainer>
            <Legend />
          </LegendContainer>
          <MovementLineChart />
        </RightBottom>
        <InterventionAttribution asterisk={true} />
        <Credits isEmbed={true} />
      </Right>
    </Bottom>
  </EmbedBackground>
);
