import React, { useContext, useState } from 'react';
import classNames from 'classnames';

import { Geocoder } from 'components/symptom/Geocoder';
import { InteractionContext } from 'common/InteractionContext';
import { metricsById } from 'common/MetricComplex/metrics';
import { MAP_ALLOWED_METRIC_IDS } from 'common/constants';
import MetricSelector from 'components/symptom/MetricSelector';
import AddLayerSelector from './AddLayerSelector';
import { isMobile } from 'styles/styles';
import chevronDown from 'assets/svg/ICON-chevron-down.svg';

import './MapControlPanel.scss';

const MapControlPanel = () => {
  const { activeMetric, setActiveMetric } = useContext(InteractionContext);

  function CollapsingHeader({ children }) {
    const [isOpen, setIsOpen] = useState(false);

    const onTogglePanel = () => {
      setIsOpen((prevValue) => !prevValue);
    };

    return (
      <div>
        <div onClick={onTogglePanel} className={'collapsing-control'}>
          <div className={'collapsing-header'}>
            Map Layers
            <img
              src={chevronDown}
              className={classNames({ rotate: isOpen })}
              alt=""
            />
          </div>
        </div>
        <div
          className={'map-filters'}
          style={{ display: isOpen ? '' : 'none' }}
        >
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className="map-control-panel">
      {isMobile ? (
        <CollapsingHeader>
          <ul className="info-hovers">
            <li>
              <Geocoder />
            </li>
            <li>
              <MetricSelector
                allowedMetrics={MAP_ALLOWED_METRIC_IDS.map(
                  (metricId) => metricsById[metricId]
                )}
                value={activeMetric}
                onChange={setActiveMetric}
              />
            </li>
            <li>
              <AddLayerSelector />
            </li>
          </ul>
        </CollapsingHeader>
      ) : (
        <ul className="info-hovers">
          <li>
            <Geocoder />
          </li>
          <li>
            <MetricSelector
              allowedMetrics={MAP_ALLOWED_METRIC_IDS.map(
                (metricId) => metricsById[metricId]
              )}
              value={activeMetric}
              onChange={setActiveMetric}
            />
          </li>
          <li>
            <AddLayerSelector />
          </li>
        </ul>
      )}
    </div>
  );
};

export default MapControlPanel;
