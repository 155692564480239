import React from 'react';

import './LineChartDOMTooltip.scss';

const TOOLTIP_MOUSE_MARGIN = 8;
const TOOLTIP_WIDTH = 150;

const LineChartDOMTooltip = ({
  lineGenerator,
  activeRow,
  activeLineData,
  margin,
  children,
  showTooltip,
}) => {
  const y = activeRow ? lineGenerator.y()(activeRow) + margin.top : 0;
  const x = activeRow ? lineGenerator.x()(activeRow) + margin.left : 0;
  const containerStyle = {
    top: y,
    display: showTooltip ? 'inherit' : 'none',
  };
  if (x > 300) {
    containerStyle.left = x - TOOLTIP_MOUSE_MARGIN - TOOLTIP_WIDTH;
  } else {
    containerStyle.left = x + TOOLTIP_MOUSE_MARGIN;
  }
  return (
    <div className="line-chart-dom-tooltip" style={containerStyle}>
      {children}
    </div>
  );
};

export default LineChartDOMTooltip;
