import React, { useContext } from 'react';

import { DataContext } from 'common/DataContext';
import { InteractionContext } from 'common/InteractionContext';
import { PopupSeparator, PopupTitle } from './styles';
import MapPopupDemographicEntries from './MapPopupDemographicEntries';
import MapPopupMetricsEntries from './MapPopupMetricsEntries';
import MapPopupCurrentMetricEntry from './MapPopupCurrentMetricEntry';
import MapPopupDemographicMetricEntry from './MapPopupDemographicMetricEntry';
import locale from 'common/locale';

import { CURRENT_PRODUCT, PRODUCTS } from 'common/constants';

const MobilityPopupContents = ({ demographics, activeRow }) => {
  const rows = [];
  if (demographics) {
    rows.push(
      <PopupTitle key="title">{locale('i18n_demographicData')}</PopupTitle>
    );
    rows.push(
      <MapPopupDemographicEntries
        key="demographics"
        demographics={demographics}
      />
    );
    rows.push(<PopupSeparator key="separator"></PopupSeparator>);
  }
  rows.push(<MapPopupMetricsEntries key="metrics" activeRow={activeRow} />);
  return <div>{rows}</div>;
};

const SymptomPopupContents = ({ demographics, activeRow }) => (
  <div>
    <MapPopupCurrentMetricEntry />
    <MapPopupDemographicMetricEntry />
    {demographics && <MapPopupDemographicEntries demographics={demographics} />}
  </div>
);

const popupContentsComponentByProduct = {
  [PRODUCTS.MOBILITY]: MobilityPopupContents,
  [PRODUCTS.SYMPTOM]: SymptomPopupContents,
};

const MapPopupContents = () => {
  const { activeRow } = useContext(InteractionContext);
  const {
    data: { getDemographicsForRegion },
  } = useContext(DataContext);
  const demographics = getDemographicsForRegion(activeRow.regionId);

  const PopupContentsComponent =
    popupContentsComponentByProduct[CURRENT_PRODUCT];

  return (
    <PopupContentsComponent demographics={demographics} activeRow={activeRow} />
  );
};

export default MapPopupContents;
