import React from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import HamburgerMenu from 'react-hamburger-menu';
import './index.scss';
import fbLogo from 'assets/svg/FACEBOOK_logo_BlueGray_RGB.svg';
import externalLinkIcon from 'assets/svg/icon-externallink.svg';
import { Link } from 'react-router-dom';
import locale from 'common/locale';
import { isMobile } from 'styles/styles';

const INTERNAL_LINKS = isMobile
  ? [
      { id: 'app', href: '/', label: locale('i18n_covid_map') },
      {
        id: 'data_info',
        href: '/survey_and_map_data.html',
        label: locale('i18n_survey_map_data'),
      },
    ]
  : [
      { id: 'app', href: '/', label: locale('i18n_covid_map') },
      {
        id: 'data_info',
        href: '/survey_and_map_data.html',
        label: locale('i18n_survey_map_data'),
      },
      { id: 'embed', href: '/embed.html', label: locale('i18n_embed_data') },
    ];

class FBNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      burgerOpen: false,
      parent: this.props.parent,
    };
  }

  openBurger() {
    // this.setState({burgerOpen: !this.state.burgerOpen});
    this.setState({ burgerOpen: !this.state.burgerOpen });
  }

  render() {
    return (
      <div className="section-outer fbnav">
        <div className="section-inner">
          <div className="logo-container">
            <a href="./" rel="noopener noreferrer">
              <img
                className="logo-svg"
                src={fbLogo}
                type="image/svg+xml"
                alt="Facebook logo"
              />
            </a>
            <div className="cheese-burger" onClick={this.openBurger.bind(this)}>
              <HamburgerMenu
                isOpen={this.state.burgerOpen}
                menuClicked={
                  () =>
                    false /* quiet HamburgerMenu required var. We need click above for a larger hit target, can't put padding on this element and have it look right. */
                }
                color="#677B8C"
                animationDuration={0.3}
                width={14}
                height={10}
                strokeWidth={1}
                borderRadius={1}
              />
            </div>
          </div>
          <ul
            className={classNames('header-link-group', {
              show: this.state.burgerOpen,
            })}
          >
            {INTERNAL_LINKS.map((link) => (
              <li key={link.id} className={'header-links'}>
                <Link
                  to={
                    link.id === 'embed' || link.id === 'app'
                      ? {
                          pathname: link.href,

                          // Preserve query params when navigating
                          // between the main app and the embed page.
                          search: this.props.location.search,
                        }
                      : link.href
                  }
                >
                  {link.label}
                </Link>
              </li>
            ))}
            <li className="header-links">
              <a
                href="https://dataforgood.fb.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {locale('i18n_d4g')}
                <img
                  src={externalLinkIcon}
                  type="image/svg+xml"
                  alt={locale('i18n_d4g')}
                />
              </a>
            </li>
            <li className="header-links">
              <a
                href="https://dataforgood.fb.com/docs/covid-19-symptom-survey-request-for-data-access/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {locale('i18n_request_access')}
                <img
                  src={externalLinkIcon}
                  type="image/svg+xml"
                  alt={locale('i18n_request_access')}
                />
              </a>
            </li>
            <li className="header-links">
              <a
                href="https://www.facebook.com/coronavirus_info/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {locale('i18n_covid_info')}
                <img
                  src={externalLinkIcon}
                  type="image/svg+xml"
                  alt={locale('i18n_covid_info')}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(FBNav);
