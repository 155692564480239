import React, { useContext } from 'react';
import classNames from 'classnames';
import { InteractionContext } from 'common/InteractionContext';
import { Map } from 'components/common/Map';
import MapControlPanel from 'components/symptom/MapControlPanel';
import { Container } from 'styles/styles';
import { Right, RightTop } from '../styles';
import { BreadLoaf } from './BreadLoaf';
import { Legend } from './Legend';
import RightPanel from './RightPanel';
import Scatterplot from 'components/symptom/Scatterplot';
import MapDisclaimer from './MapDisclaimer';
import FBCallbackPill from './FBCallbackPill';

// Embed layout.
export const SymptomSurveyEmbedLayout = () => {
  const {
    isGlossaryOpen,
    activeDemographicMetric,
    selectedRegion,
  } = useContext(InteractionContext);

  return (
    <Container isEmbed={true}>
      <div className={classNames({ 'modal-enabled': isGlossaryOpen })}>
        <Right>
          <RightTop>
            <Map isEmbed={true} />
            <MapControlPanel />
            {activeDemographicMetric ? <Scatterplot isEmbed={true} /> : null}
            <Legend isEmbed={true} />
            <BreadLoaf isEmbed={true} />
            <FBCallbackPill />
          </RightTop>
          <MapDisclaimer isEmbed={true} />
        </Right>
        {selectedRegion !== 'WORLD' ? <RightPanel isEmbed={true} /> : null}
      </div>
    </Container>
  );
};
