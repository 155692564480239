import React, { useContext } from 'react';
import { Popup } from 'react-mapbox-gl';

import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import { StyledPopup, PopupEntry, PopupLeft } from './styles';

import { CURRENT_PRODUCT, NO_DATA_MESSAGE_BY_PRODUCT } from 'common/constants';

const NoDataMapPopup = () => {
  const { activeNoDataRow } = useContext(InteractionContext);
  const {
    data: { fipsToName, getCoordinates },
  } = useContext(DataContext);
  return (
    <Popup
      key={activeNoDataRow}
      coordinates={getCoordinates(activeNoDataRow.regionId)}
    >
      <StyledPopup>
        <div>
          <strong>{fipsToName[activeNoDataRow.regionId]}</strong>
        </div>
        {(function () {
          let rows = [];
          rows.push(
            <PopupEntry key="no-data-message">
              <PopupLeft>
                {NO_DATA_MESSAGE_BY_PRODUCT[CURRENT_PRODUCT]}
              </PopupLeft>
            </PopupEntry>
          );
          return rows;
        })()}
      </StyledPopup>
    </Popup>
  );
};

export default NoDataMapPopup;
