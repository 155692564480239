import React, { useContext, useMemo } from 'react';
import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import { DateSelectorBody, formatDate } from './DateSelectorBody';

export const DateSelector = ({ isEmbed = false }) => {
  const { visibleDateInterval, selectedTimeResolution } = useContext(
    InteractionContext
  );

  const { data } = useContext(DataContext);

  const datesData = useMemo(() => {
    if (!data || !visibleDateInterval) return null;
    const lastDate = visibleDateInterval[1];

    // Build up an array of dates to feed the UI.
    const datesData = [];

    // Start at the last date and go backwards.
    let currentDate = lastDate;
    while (currentDate >= visibleDateInterval[0]) {
      datesData.push({
        date: formatDate(currentDate),
        dateObject: currentDate,
      });
      currentDate = selectedTimeResolution.timeFunction.offset(currentDate, -1);

      // Don't go beyond the bounds of the data.
      //if (currentDate <= visibleDateInterval[0]) break;
    }

    // Make the dates chronological from left to right.
    return datesData.reverse();
  }, [data, visibleDateInterval, selectedTimeResolution]);

  return <DateSelectorBody datesData={datesData} />;
};
