import React, { useContext } from 'react';
import { Layer } from 'react-mapbox-gl';
import { InteractionContext } from 'common/InteractionContext';
import { addPostfix } from 'common/DataContext/util';
import { isAdmin0, isAdmin1 } from 'common/DataContext/accessors';

import { proj, tileAdminKeys, mapboxLayers } from './mapConfig';

import { WORLD } from 'common/constants';

const SymbolLayer = () => {
  const { selectedRegion } = useContext(InteractionContext);
  const generateLabelPointFilter = () => {
    if (!selectedRegion || selectedRegion === WORLD) {
      return ['==', ['get', 'level'], '0'];
    } else if (selectedRegion && isAdmin0(selectedRegion)) {
      return [
        'all',
        ['==', ['get', 'level'], '1'],
        [
          '==',
          ['get', tileAdminKeys[proj]['admin1_parent']],
          addPostfix(selectedRegion),
        ],
      ];
    } else if (selectedRegion && isAdmin1(selectedRegion)) {
      return [
        'all',
        ['==', ['get', 'level'], '2'],
        [
          '==',
          ['get', tileAdminKeys[proj]['admin2_parent']],
          addPostfix(selectedRegion),
        ],
      ];
    } else {
      return false;
    }
  };
  return (
    <Layer
      type="symbol"
      id={mapboxLayers[proj].adminLabels.id}
      key={mapboxLayers[proj].adminLabels.key}
      filter={generateLabelPointFilter()}
      minZoom={0}
      layout={{
        'text-field': ['to-string', ['get', 'name']],
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          4,
          8,
          10,
          10,
          22,
          16,
        ],
        'text-max-width': ['interpolate', ['linear'], ['zoom'], 0, 4, 10, 8],
        'text-font': [
          'step',
          ['zoom'],
          ['literal', ['Lato Black', 'Arial Unicode MS Regular']],
          8,
          ['literal', ['Lato Bold Italic', 'Arial Unicode MS Regular']],
        ],
        'text-letter-spacing': [
          'interpolate',
          ['linear'],
          ['zoom'],
          4,
          0.1,
          22,
          0.2,
        ],
        'text-transform': 'uppercase',
        'text-rotation-alignment': 'viewport',
      }}
      paint={{
        'text-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          8,
          ['match', ['get', 'level'], '2', 0, 1],
          9,
          ['match', ['get', 'level'], '2', 0, 1],
          9.5,
          ['match', ['get', 'level'], '2', 0, 1],
          10,
          1,
        ],
        'text-color': 'rgba(255,255,255,1)',
        'text-halo-width': 1,
        'text-halo-color': 'rgba(159,172,183,0.8)',
        'text-halo-blur': 1,
      }}
      sourceId={mapboxLayers[proj].adminLabels.sourceId}
      sourceLayer={mapboxLayers[proj].adminLabels.sourceLayer}
      before={mapboxLayers[proj].admin2interaction.before}
    />
  );
};

export default SymbolLayer;
