import React from 'react';
import { AttributionWrapper, AttributionText } from './styles';

export const Credits = ({ isEmbed }) => (
  <AttributionWrapper isEmbed={isEmbed}>
    <AttributionText>
      This dashboard is the result of a collaboration between{' '}
      <a
        href="https://dataforgood.fb.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook Data for Good
      </a>{' '}
      and{' '}
      <a
        href="https://www.covid19mobility.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        COVID-19 Mobility Data Network
      </a>
      . Designed and built by{' '}
      <a target="_blank" rel="noopener noreferrer" href="https://stamen.com/">
        Stamen
      </a>
      .
    </AttributionText>
  </AttributionWrapper>
);
