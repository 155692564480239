import React, { useContext } from 'react';

import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import MetricEntry from './MetricEntry';

const MapPopupDemographicMetricEntry = () => {
  const { getDemographicsForRegion } = useContext(DataContext);
  const { activeRow, activeDemographicMetric } = useContext(InteractionContext);
  const rowDemographics = getDemographicsForRegion(activeRow.regionId);
  const rowWithDemographics = {
    ...activeRow,
    ...rowDemographics,
  };
  return activeDemographicMetric && rowDemographics ? (
    <MetricEntry
      highlight={true}
      normal={false}
      metric={activeDemographicMetric}
      row={rowWithDemographics}
    />
  ) : null;
};

export default MapPopupDemographicMetricEntry;
