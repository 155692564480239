import React from 'react';
import '../styles.scss';
import classNames from 'classnames';

export default function glossary(showGlossary, onCloseGlossary) {
  // prettier-ignore
  return (
<div className={classNames('map-glossary', {hidden: !showGlossary,})}>
      <div className="glossary-header"><h3>مسرد المصطلحات المستخدمة في الخريطة</h3><div className='close' onClick={onCloseGlossary}>
          <img alt="" src="svg/icon-close.svg" />
        </div>
      </div><ul className="glossary-body">
        <li><b className="term">أعراض الإصابة بفيروس كورونا (كوفيد-١٩)</b> - أعراض الإصابة بفيروس كورونا (كوفيد-١٩)
          تشمل الحمى وإما السعال وضيق التنفس أو
          صعوبة في التنفس كما هو محدد في استبيانات جامعة كارنيجي ميلون (CMU) وجامعة ميريلاند (UMD).</li>
        <li><b className="term">أعراض الإنفلونزا</b> - أعراض الإنفلونزا تشمل
          الحمى وإما التهاب الحلق أو السعال كما هو محدد في استبيانات جامعة كارنيجي ميلون (CMU) و
          جامعة ميريلاند (UMD).</li>
        <li><b className="term">النسبة المئوية للأشخاص الذين يعانون من الأعراض</b> -
           النسبة المئوية التقديرية للأشخاص الذين عانوا من الأعراض على مدار
          7 أيام في المتوسط.</li>
        <li><b className="term">إجمالي عدد السكان</b> - إجمالي عدد
           الأشخاص في موقع ما.</li>
        <li><b className="term">الكثافة السكانية لكل كيلومتر مربع</b> -
           عدد الأشخاص في كل كيلومتر مربع من مساحة الأرض.</li>
        <li><b className="term">النسبة المئوية لعدد الأشخاص بسن 65 عامًا فما فوق</b> -
           النسبة المئوية للأشخاص الذين تبلغ أعمارهم 65 عامًا أو أكثر.</li>
        <li><b className="term">إجمالي حالات الإصابة المؤكدة بفيروس كورونا (كوفيد-١٩)</b> -
           إجمالي عدد حالات الإصابة المؤكدة بفيروس كورونا (كوفيد-١٩).</li>
        <li><b className="term">معدل التغير في التحركات</b> - النسبة المئوية
          للتغير في تحركات الأشخاص مقارنة بمتوسط نفس
          اليوم من الأسبوع خلال شهر فبراير 2020.</li>
        <li><b className="term">
            النسبة المئوية للأشخاص الذين بقوا في مكانهم
          </b><span> </span>
          - النسبة المئوية للأشخاص الذين بقوا في مكان واحد لمدة 24 ساعة.</li>
        <li><b className="term">إغلاق المدارس</b> - تاريخ
          إغلاق المدارس العامة في الموقع.</li>
        <li><b className="term">حالة الطوارئ</b> - تاريخ إعلان 
          حالة الطوارئ في الموقع.</li>
        <li><b className="term">التباعد البدني</b> - تاريخ بدء
          ممارسة التباعد البدني في الموقع. التباعد البدني هو
          الحفاظ على مسافة آمنة تقدر بحوالي 6 أقدام بين الأشخاص للحد من
          انتشار فيروس كورونا (كوفيد-١٩). بيانات التباعد البدني تشمل السياسات التي فرضتها
          الحكومات للبقاء في المنازل والإيواء في الموقع. بالنسبة للمواقع
          خارج الولايات المتحدة، يمكن للباحثين رؤية مجموعة بيانات أكثر شمولاً
          تتضمن بيانات التباعد البدني وغيرها من تدابير الصحة
          العامة من<span> </span>
          <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/phsm">
            منظمة الصحة العالمية
          </a>
          .</li>
      </ul>
    </div>
  
);
}
