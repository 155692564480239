// @flow
/* eslint-disable no-undef */
import lookup from 'country-code-lookup';

import type { Datum } from 'types';

import { supportedCountries } from 'data/supportedCountries.js';

import {
  WORLD,
  COUNTRY_CODE_TYPES,
  CURRENT_PRODUCT,
  PRODUCTS,
} from 'common/constants';
import locale from 'common/locale';

export const getDate = (d: Datum) => d.date;

export const isSupportedCountry = (regionId: ?string) =>
  !!supportedCountries[regionId] && supportedCountries[regionId];

export const isAdmin0 = isSupportedCountry;

// Disable all NUTS checking. TODO: actually remove all the rest of the NUTS code
const nutsTwoLetterCodes = {
  //DE: true,
  //FR: true,
  //IT: true,
  //TR: true,
  //UK: true,
};
export const isNUTSCountry = (regionId: string) => {
  return nutsTwoLetterCodes[regionId.substring(0, 2)];
};

const isIso2CountryCode = (regionId) =>
  isSupportedCountry(regionId) && regionId.length === 2;
export const toISO3CountryCode = (regionId: string) => {
  if (isIso2CountryCode(regionId)) {
    const country = lookup.byInternet(regionId);
    return country.iso3;
  } else {
    return regionId;
  }
};

const twoDotsRegex = /(\..*){2,}/;
function hasTwoPlusDots(regionId) {
  return twoDotsRegex.test(regionId);
}

export const isAdmin1 = (regionId: string) =>
  //(regionId && regionId.length === 3 && regionId !== 'USA') ||
  (regionId &&
    regionId.length > 3 &&
    isSupportedCountry(regionId.substring(0, 3))) ||
  isUSStateFIPS(regionId);

export const getAdmin0FromAdmin1 = (regionId: string): ?string => {
  // console.log('getAdmin0FromAdmin1', regionId)
  if (regionId.length === 2 && isUSStateFIPS(regionId)) return 'USA';
  else if (isNUTSCountry(regionId)) return regionId.substring(0, 2);
  else if (regionId.length === 3 && isAdmin1(regionId)) return regionId;
  else if (regionId.length > 3 && isAdmin1(regionId))
    return regionId.substring(0, 3);
  else {
    return console.error('Could not determine admin 0 from regionId', regionId);
  }
};

export const isSupportedCountryAdmin1 = (regionId: string) => {
  if (regionId.length === 2 && isUSStateFIPS(regionId)) return true;
  if (
    regionId.length > 3 &&
    isSupportedCountry(regionId.substring(0, 3)) &&
    '.' === regionId[3] &&
    regionId.substring(4).indexOf('.') === -1
  )
    return true;
  if (
    isAdmin1(regionId) &&
    regionId.length === 3 &&
    isSupportedCountry(regionId.substring(0, 3))
  )
    return true;
  else return false;
};

export const isAdmin2 = (regionId: string) => {
  if (regionId.length !== 2 && isNUTSCountry(regionId)) return true;
  else if (regionId.length === 5) return true;
  else if ('.' === regionId[3] && isSupportedCountry(regionId.substring(0, 3)))
    return true;
  else return false;
};

export const getAdmin1FromAdmin2 = (regionId: string) => {
  const isUsCounty = isUSCountyFIPS(regionId);
  if (isUsCounty) return getUSStateFIPSFromUSCountyFIPS(regionId);
  if (isNUTSCountry(regionId) && regionId.length < 5)
    return regionId.substring(0, 2);
  if (hasTwoPlusDots(regionId))
    return regionId.split('.').slice(0, 2).join('.');
  else return regionId.substring(0, 3);
};

const isSupportedCountryAdmin2 = (regionId: string) => {
  return (
    regionId &&
    isAdmin2(regionId) &&
    (isSupportedCountry(getAdmin0FromAdmin1(getAdmin1FromAdmin2(regionId))) ||
      isUSCountyFIPS(regionId))
  );
};

export const isDigit = (character: string) =>
  character >= '0' && character <= '9';

export const isUSStateFIPS = (regionId: string) =>
  regionId &&
  regionId.length === 2 &&
  isDigit(regionId[0]) &&
  isDigit(regionId[1]);

export const isUSCountyFIPS = (regionId: string) =>
  regionId &&
  regionId.length === 5 &&
  isDigit(regionId[0]) &&
  isDigit(regionId[1]) &&
  isDigit(regionId[2]) &&
  isDigit(regionId[3]) &&
  isDigit(regionId[4]);

export const getUSStateFIPSFromUSCountyFIPS = (regionId: string) =>
  regionId.substring(0, 2);

const isWorld = (regionId) => regionId === WORLD;

// Returns true if drill-down interactions should be enabled
// for the given regionId.
export const hasChildren = (regionId: string) => {
  return (
    isWorld(regionId) ||
    isSupportedCountry(regionId) ||
    isUSStateFIPS(regionId) ||
    isSupportedCountryAdmin1(regionId)
  );
};

// Check for null, as this is the old way the US was represented.
export const isUS = (regionId: string) =>
  regionId === 'USA' || regionId === null;

// Pertains to labeling only.
// Returns true if the given region has "States" inside it,
// returns false if the given region has "Regions" inside it.
export const hasStates = isUS;

export const regionDescriptor = (
  regionId: string,
  getName: (string) => string
) => {
  let descriptor = isUSStateFIPS(regionId)
    ? `${locale('i18n_countiesIn')}`
    : hasStates(regionId)
    ? `${locale('i18n_statesIn')}`
    : isSupportedCountry(regionId)
    ? `${locale('i18n_regionsOf')}`
    : isSupportedCountryAdmin1(regionId)
    ? `${locale('i18n_regionsOf')}`
    : locale('i18n_countries');

  if (CURRENT_PRODUCT === PRODUCTS.MOBILITY) {
    if (regionId !== WORLD) {
      descriptor += ` ${getName(regionId)}`;
    }
  }
  return descriptor;
};

export const getParentRegionId = (regionId: string) => {
  return regionId === WORLD
    ? null
    : isSupportedCountry(regionId) || isUS(regionId)
    ? WORLD
    : isSupportedCountryAdmin1(regionId)
    ? getAdmin0FromAdmin1(regionId)
    : isSupportedCountryAdmin2(regionId)
    ? getAdmin1FromAdmin2(regionId)
    : null;
};

export const getCountryCodeTypeFromRegionId = (regionId: string) => {
  if (isUS(regionId) || isUSStateFIPS(regionId) || isUSCountyFIPS(regionId)) {
    return COUNTRY_CODE_TYPES.FIPS;
  } else {
    return COUNTRY_CODE_TYPES.GADM;
  }
};
