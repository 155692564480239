import React from 'react';
import { HoverDetectorRect } from './styles';

export const HoverDetector = ({ onHover, width, height }) => (
  <HoverDetectorRect
    onMouseEnter={onHover}
    onClick={onHover}
    width={width}
    height={height}
  />
);
