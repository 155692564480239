import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { InteractionContext } from 'common/InteractionContext';

import { timeSunday, timeDay, timeMonth } from 'd3-time';
import { dateLabelFormat } from './util';
import { INTERVAL_ID } from 'common/constants';

const BaseLabelText = css`
  text-transform: uppercase;
  font-size: 10px;
`;

const LabelText = styled.text`
  ${BaseLabelText}
  text-anchor: middle;
  color: #4a585e;
`;

export const ActiveLabelText = styled.text`
  ${BaseLabelText}
  text-anchor: ${(props) => props.anchor};
  color: #546369;
  font-weight: bold;
`;

const generateTicks = ({ selectedTimeInterval, xScale }) => {
  const innerWidth = xScale.range()[1];
  let labelTicks;
  switch (selectedTimeInterval.intervalId) {
    case INTERVAL_ID.ONE_W:
      labelTicks = xScale.ticks(timeDay.every(1));
      break;

    case INTERVAL_ID.ONE_M:
      labelTicks = xScale.ticks(timeSunday.every(1));
      if (innerWidth < 600) {
        labelTicks = xScale.ticks(timeSunday.every(2));
      }
      break;

    case INTERVAL_ID.THREE_M:
      labelTicks = xScale.ticks(timeSunday.every(3));
      if (innerWidth < 600) {
        labelTicks = xScale.ticks(timeSunday.every(4));
      }
      break;

    case INTERVAL_ID.ALL:
      labelTicks = xScale.ticks(timeMonth.every(1));
      break;

    default:
      break;
  }
  return labelTicks;
};

// The cutoff distance for hiding tick labels.
// Note that this is the distance from the end of the range
// to the center of the text label, so that's why
// it's larger than the actual size of the button,
// to account for the width of the tick label text
// (which thankfully is always about the same, e.g. "APR 12").
const pageButtonSize = 35;

const XAxisLabels = ({ xScale, height, showPageLeft, showPageRight }) => {
  const { selectedTimeInterval } = useContext(InteractionContext);

  const labelTicks = generateTicks({
    selectedTimeInterval,
    xScale,
  });
  const innerWidth = xScale.range()[1];

  return (
    <g transform={`translate(0,${height})`}>
      {labelTicks.map((t) => {
        const x = xScale(t);
        const tickOverlapsWithPageLeft = showPageLeft && x < pageButtonSize;
        const tickOverlapsWithPageRight =
          showPageRight && x > innerWidth - pageButtonSize;
        const showTick =
          !tickOverlapsWithPageLeft && !tickOverlapsWithPageRight;
        return showTick ? (
          <g key={t} transform={`translate(${x},0)`}>
            <LabelText>{dateLabelFormat(t)}</LabelText>
          </g>
        ) : null;
      })}
    </g>
  );
};

export default XAxisLabels;
