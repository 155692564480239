import React from 'react';
import locale from 'common/locale';

import './EyebrowHeader.scss';

const EyebrowHeader = () => {
  return <h4 className="eyebrow">{locale('i18n_1_embed')}</h4>;
};

export default EyebrowHeader;
