import React from 'react';

export default function intro() {
  // prettier-ignore
  return (
<div className="section-inner">
      <div className="story">
        <div className="section section-partnering">
          <div className="content"><h4 className="eyebrow">问卷调查和地图数据</h4>
            <h3>全力支持新冠疫情调研的开展</h3>
            <p>Facebook 拥有超过 20 亿用户，
              在支持开展公共卫生研究方面占有举足轻重的地位。自 2017 年以来，我们推出了 Data For Good 项目，
              与值得信赖的学术机构和非营利组织合作
              搭建起一个研究网络，
              专门解决全球范围内的一些人道主义问题。最近，我们
              与<span> </span>
              <a className="bold-link" href="https://delphi.cmu.edu/" target="_blank" rel="noopener noreferrer">
                  卡耐基·梅隆大学
              </a><span> </span>
              以及<span> </span>
              <a className="bold-link" href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data" target="_blank" rel="noopener noreferrer">
                    马里兰大学
              </a><span> </span>
              合作，邀请人们参与新冠病毒感染
              症状与风险因素相关的问卷调查。问卷收集到的数据将被用于我们的地图功能，结合经过
                  汇总处理的其他公开数据，
                  让人们对新冠大流行有一个更为全面的认识。</p>
            <p>我们的合作调研项目在设计之初就将用户隐私保护纳入
              考量。</p>
            <p className="link"><a href="./survey_and_map_data.html" rel="noopener noreferrer">
                <img className="link" alt="" src={`${process.env.PUBLIC_URL}/svg/ic-grey-link-arrow.svg`}/>
                详细了解问卷调查和地图数据
              </a></p></div>
          <div className="partner-logos">
            <a className="cmu" href="https://www.cmu.edu" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/svg/cmu-wordmark-square-w-on-70k.svg`} alt="Carnegie Mellon University logo" aria-label="Carnegie Mellon University logo" />
            </a>
            <a className="umd" href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/svg/university-of-maryland-1-1.svg`} aria-label="University of Maryland logo" alt="University of Maryland logo" />
            </a>
          </div>
        </div>
      </div>

      <div className="story">
        <div className="section tools-to-help">
          <div className="content"><h4 className="eyebrow">更多实用工具</h4><div className="more-info">
              <div className="request-access-section info">
                <a href="https://dataforgood.fb.com/docs/covid-19-symptom-survey-request-for-data-access/" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>汇总后的调查数据会向公众发布，
                      也可以向卡耐基·梅隆大学和
                      马里兰大学申请访问。Facebook 与其合作的大学院校也向
                      符合资格要求的学术机构和非营利研究机构
                      开放非汇总数据的访问申请。</p>
                    <p className="call-to-action">详细了解访问调研数据的相关信息</p></div>
                </a>
              </div>

              <div className="facebook-ai-section info">
                <a href="https://ai.facebook.com/blog/using-ai-to-help-health-experts-address-the-covid-19-pandemic" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>我们提供人工智能技术
                      帮助预测某些县一级地区的疾病传播情况和当地医院受到
                      的影响，帮助当地卫生系统了解
                      医院床位、口罩和呼吸机等方面的需求。</p>
                    <p className="call-to-action">详细了解 Facebook 人工智能技术</p></div>
                </a>
              </div>

              <div className="follow-covid-info-section info">
                <a href="https://www.facebook.com/coronavirus_info/" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>在 Facebook 获取卫生机构和新闻机构的
                      最新资讯及安全提示。</p>
                    <p className="call-to-action">关注新冠疫情信息中心</p></div>
                </a>
              </div>

              <div className="data-for-good-section info">
                <a href="https://dataforgood.fb.com/" target="_blank" rel="noopener noreferrer">
                  <div className="section-info"><p>作为 Data For Good 项目的一部分，我们提供疾病预防
                      地图，帮助卫生研究人员了解
                      人口的动态变化如何影响疾病传播。这份地图使用的数据经过汇总处理，
                      以此保护人们的隐私。</p>
                    <p className="call-to-action">详细了解 Data For Good 项目</p></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
