import React from 'react';

export default function intro() {
  // prettier-ignore
  return (
    <div className="section-inner">
      <div className="story">
        <div className="section section-partnering">
          <div className="content">
            <h4 className="eyebrow">Survey & map data</h4>
            <h3>Supporting COVID-19 Research</h3>
            <p>
              With over 2 billion people on Facebook, we are in a unique
              position to support public health research. We have partnered with
              trusted academic and nonprofit institutions since 2017, through
              our Data For Good program, to build a research network to address
              some of the world's greatest humanitarian issues. Recently, we
              partnered with<span> </span>
              <a
                className="bold-link"
                href="https://delphi.cmu.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Carnegie Mellon University
              </a><span> </span>
              and the<span> </span>
              <a
                className="bold-link"
                href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data"
                target="_blank"
                rel="noopener noreferrer"
              >
                University of Maryland
              </a><span> </span>
              to invite people to participate in surveys about COVID-19 symptoms
              and risk factors. We use the survey data on our map, and we also
              show other publicly available aggregate data to provide a more
              holistic view of the COVID-19 pandemic.
            </p>
            <p>
              Our partner initiatives are designed with privacy in mind from the
              start.
            </p>
            <p className="link">
              <a href="./survey_and_map_data.html" rel="noopener noreferrer">
                <img className="link" alt="" src={`${process.env.PUBLIC_URL}/svg/ic-grey-link-arrow.svg`}/>
                Learn more about the surveys and map data
              </a>
            </p>
          </div>
          <div className="partner-logos">
            <a
              className="cmu"
              href="https://www.cmu.edu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={`${process.env.PUBLIC_URL}/svg/cmu-wordmark-square-w-on-70k.svg`} alt="Carnegie Mellon University logo" aria-label="Carnegie Mellon University logo" />
            </a>
            <a
              className="umd"
              href="https://jpsm.umd.edu/news/jpsm%2C-facebook-partner-gather-global-covid-19-symptom-data"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={`${process.env.PUBLIC_URL}/svg/university-of-maryland-1-1.svg`} aria-label="University of Maryland logo" alt="University of Maryland logo" />
            </a>
          </div>
        </div>
      </div>

      <div className="story">
        <div className="section tools-to-help">
          <div className="content">
            <h4 className="eyebrow">More tools to help</h4>
            <div className="more-info">
              <div className="request-access-section info">
                <a
                  href="https://dataforgood.fb.com/docs/covid-19-symptom-survey-request-for-data-access/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="section-info">
                    <p>
                      Aggregate symptom survey data is publicly available and
                      accessible from Carnegie Mellon University and the
                      University of Maryland. Facebook and university partners
                      also allow eligible academic and nonprofit researchers to
                      request access to non-aggregate data.
                    </p>
                    <p className="call-to-action">
                      Learn more about research access
                    </p>
                  </div>
                </a>
              </div>

              <div className="facebook-ai-section info">
                <a
                  href="https://ai.facebook.com/blog/using-ai-to-help-health-experts-address-the-covid-19-pandemic"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="section-info">
                    <p>
                      We offer artificial intelligence to forecast disease
                      spread and hospital impact, at a county level in certain
                      areas, to help local health systems understand the need
                      for beds, masks and ventilators.
                    </p>
                    <p className="call-to-action">
                      Learn more about Facebook AI
                    </p>
                  </div>
                </a>
              </div>

              <div className="follow-covid-info-section info">
                <a
                  href="https://www.facebook.com/coronavirus_info/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="section-info">
                    <p>
                      Get the latest updates and safety tips from health and
                      news organizations on Facebook.
                    </p>
                    <p className="call-to-action">Follow COVID-19 Info</p>
                  </div>
                </a>
              </div>

              <div className="data-for-good-section info">
                <a
                  href="https://dataforgood.fb.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="section-info">
                    <p>
                      As part of Data for Good, we provide disease prevention
                      maps to help health researchers understand how population
                      dynamics influence spread. The maps use aggregated data to
                      protect privacy.
                    </p>
                    <p className="call-to-action">
                      Learn more about Data for Good
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
