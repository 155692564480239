import styled from 'styled-components';

export const DisclaimerWrapper = styled.div`
  background-color: white;
  ${(props) =>
    props.isEmbed
      ? `margin: 8px 8px 2px;
      border-radius: 6px 6px 2px 2px;`
      : null}
`;

export const DisclaimerContent = styled.div`
  padding: 20px 0;
  font-style: italic;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #979797;
  max-width: 888px;
  margin: auto;

  p {
    margin: unset;

    a {
      font-weight: bold;
      color: #344854;
    }
  }
`;
