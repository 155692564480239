import React, { useContext } from 'react';
import { DataContext } from 'common/DataContext';
import { InteractionContext } from 'common/InteractionContext';
import { lineValue } from './util';
import { WORLD } from 'common/constants';
import { regionDescriptor } from 'common/DataContext/accessors';

import './SymptomSurveyLineChartLegend.scss';

const SymptomSurveyLineChartLegend = ({
  activeLineColor,
  filteredData,
  hasParent,
}) => {
  const { activeRow, selectedRegion } = useContext(InteractionContext);
  const { getRegionName } = useContext(DataContext);
  const showParentLine = selectedRegion !== WORLD;
  const hasChildren = filteredData.length > 0;
  return (
    <div className="chart-info-legend">
      {activeRow && hasChildren ? (
        <div className="chart-info-legend-item">
          <div
            className="chart-info-legend-line"
            style={{ borderTop: `3px solid ${activeLineColor}` }}
          />
          {lineValue(activeRow)}
        </div>
      ) : null}
      {showParentLine && hasParent ? (
        <div className="chart-info-legend-item">
          <div className="chart-info-legend-line average-line" />
          {getRegionName(selectedRegion)}
        </div>
      ) : null}

      {hasChildren ? (
        <div className="chart-info-legend-item">
          <div className="chart-info-legend-line other-line" />
          {regionDescriptor(selectedRegion, getRegionName)}
        </div>
      ) : null}
    </div>
  );
};

export default SymptomSurveyLineChartLegend;
