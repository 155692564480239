import styled, { css } from 'styled-components';
import { SYMPTOM_SURVEY } from 'common/constants';

export const mobileBreakpoint = 578;
export const midBreakpoint = 960;

// The gap between things on mobile.
export const mobileGap = 5;

export const isMobile = window.innerWidth < mobileBreakpoint;
export const isMid = window.innerWidth < midBreakpoint;

// Expose this boolean in a way that feels parallel to isMobile.
export const isSymptomSurvey = SYMPTOM_SURVEY;

export const Container = styled.div`
  ${(props) =>
    isSymptomSurvey
      ? css`
          width: 100%;
          height: 908px;
          @media (max-width: ${mobileBreakpoint}px) {
            height: unset;
          }

          ${props.isEmbed
            ? css`
                background: #f5f6f6;
              `
            : ''}
        `
      : props.isEmbed
      ? css`
          width: 100%;
          height: 100%;
        `
      : css`
          width: 90%;
          max-width: 2400px;
          padding: 0;
          margin: auto;
          @media (max-width: ${mobileBreakpoint}px) {
            width: 100%;
          }
        `}
`;

export const VizContainer = styled.div`
  ${(props) =>
    props.isEmbed
      ? css`
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
        `
      : css`
          width: 100%;
          height: 908px;
          @media (max-width: ${mobileBreakpoint}px) {
            height: unset;
          }
        `}
  display: flex;
  flex-direction: column;
  background-color: #f5f5f6;
  @media (max-width: ${mobileBreakpoint}px) {
    padding: 1px;
    height: unset;
  }
`;

export const Content = styled.div`
  border-radius: 3px;
  color: ${(props) => (props.isActive ? '#ffffff' : '#738695')};
  background-color: ${(props) => (props.isActive ? '#738695' : '#ffffff')};
  font-size: 12px;
`;

export const crispness = 'border: solid 1px #e5ecf0;';
