import React from 'react';

const VerticalLine = ({ xScale, date, height, className }) => (
  <line
    className={className}
    x1={xScale(date)}
    x2={xScale(date)}
    y1={0}
    y2={height}
  />
);

export default VerticalLine;
