import React from 'react';
import { Credits } from './Credits';
import BelowVizTextBlock from 'components/mobility/Text/BelowVizTextBlock';

import { Map } from 'components/common/Map';
import { MeasuredLollipopChart } from 'components/mobility/LollipopChart';
import { MetricComplex } from '../MetricComplex';
import { FullWidthCountryDropdown } from 'components/mobility/CountryDropdown';
import MovementLineChart from './MovementLineChart';

import { Mobile, MobileRow, MobileMapContainer } from './styles';
import InterventionAttribution from './InterventionAttribution';
import { TimelineRangeButtons } from './TimelineRangeButtons';
import { LineLegend } from 'components/common/LineChart/Legend';

import Header from './Header';
import ColorLegend from './ColorLegend';

const mobileMargin = { left: 12, right: 12 };

export const MobileLayout = () => (
  <Mobile>
    <Header />
    <MobileRow>
      <MetricComplex />
      <FullWidthCountryDropdown />
    </MobileRow>
    <MobileRow>
      <MobileMapContainer>
        <Map />
        <ColorLegend />
      </MobileMapContainer>
    </MobileRow>
    <MobileRow>
      <TimelineRangeButtons />
    </MobileRow>
    <MobileRow>
      <LineLegend margin={mobileMargin} />
    </MobileRow>
    <MovementLineChart />
    <MobileRow>
      <InterventionAttribution asterisk={false} />
    </MobileRow>
    <MobileRow>
      <MeasuredLollipopChart />
    </MobileRow>
    <MobileRow>
      <Credits />
    </MobileRow>
    <BelowVizTextBlock />
  </Mobile>
);
