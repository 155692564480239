import React, { createContext, useContext, useEffect, useState } from 'react';
import { computeBreakpoints } from './computeBreakpoints';
import { rowHasMetric } from './util';
import { FetchingContext } from './FetchingContext';

export const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const {
    visibleDateInterval,
    selectedRegionStats,
    activeDateData,
    activeDateDataWithDemographics,
    parentTimeseries,
    govtActionsDataProvider,
    dateExtent,
    fipsToName,
    fipsToDemographics,
    fipsToCentroid,
    getCoordinates,
    getBoundsFromRegion,
    getDemographicsForRegion,
    metadata,
    nameToFips,
    activeMetric,
    worldStats,
  } = useContext(FetchingContext);

  // The value provided via context.
  // It represents the data for the selected region,
  // but it only changes after new data is loaded.
  // The stale data persists in view while the new data
  // is being loaded.
  const [value, setValue] = useState(null);

  useEffect(() => {
    // If this value is null, it means data is loading,
    // so don't change the value of data (let stale persist).
    if (
      fipsToName &&
      fipsToCentroid &&
      selectedRegionStats &&
      activeDateData &&
      activeDateDataWithDemographics &&
      govtActionsDataProvider &&
      getBoundsFromRegion &&
      nameToFips &&
      worldStats
    ) {
      // Method to lookup a region name from regionId
      const getRegionName = (regionId) => fipsToName[regionId || 'USA'];

      // Method to lookup a regionId from region name
      const getRegionId = (regionName) =>
        Object.keys(fipsToName).find((id) => fipsToName[id] === regionName);

      const getDataDateForStat = (statName) =>
        metadata.byStatName[statName]
          ? metadata.byStatName[statName].latestDate
          : null;

      // Method to filter data from currently visible date range and selected
      // region given a metric
      const getVisibleDataForMetric = (metric) => {
        if (!selectedRegionStats || !visibleDateInterval) return null;
        const [minDate, maxDate] = visibleDateInterval;
        return selectedRegionStats
          .filter((row) => row.date >= minDate && row.date <= maxDate)
          .filter((row) => rowHasMetric(metric, row));
      };

      const breakpoints = computeBreakpoints({ activeDateData, activeMetric });

      // Update the presented data after new integrated data loads.
      setValue({
        selectedRegionStats,
        data: {
          // TODO: refactor / rename these data structures once getter methods
          // are used and other components do not depend on these names
          mobilityStats: selectedRegionStats,
          fipsToName,
          fipsToDemographics,
          fipsToCentroid,
          getCoordinates,
          getDemographicsForRegion,
          nameToFips,
        },
        getRegionName,
        getRegionId,
        getDemographicsForRegion,
        //getRegionCentroid,
        activeDateData,
        breakpoints,
        activeDateDataWithDemographics,
        parentTimeseries,
        govtActionsDataProvider,
        dateExtent,
        getDataDateForStat,
        getBoundsFromRegion,
        getVisibleDataForMetric,
        worldStats,
      });
    }
  }, [
    visibleDateInterval,
    selectedRegionStats,
    activeDateData,
    activeDateDataWithDemographics,
    parentTimeseries,
    govtActionsDataProvider,
    dateExtent,
    fipsToName,
    fipsToDemographics,
    fipsToCentroid,
    getCoordinates,
    getBoundsFromRegion,
    getDemographicsForRegion,
    metadata,
    nameToFips,
    activeMetric,
    worldStats,
  ]);

  // Display stale data while new data is loading
  // for less jarring user experience.
  return value ? (
    <DataContext.Provider value={value}>{children}</DataContext.Provider>
  ) : null;
};
