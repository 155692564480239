import React from 'react';
import './index.scss';
import fbLogo from 'assets/svg/FACEBOOK_logo_BlueGray_RGB.svg';

export default class FBFooter extends React.PureComponent {
  render() {
    return (
      <div className="section-outer footer">
        <div className="section-inner" id="i18n-1713164951">
          <div className="logos">
            <div className="logo-container">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  height="20"
                  className="logo-svg"
                  src={fbLogo}
                  type="image/svg+xml"
                  alt="Facebook Wordmark"
                />
              </a>
            </div>
          </div>
          <div className="links">
            <div className="title">Company</div>
            <a
              href="https://about.fb.com/news/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Newsroom
            </a>
            <a
              href="https://about.fb.com/company-info/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Company Info
            </a>
            <a
              href="https://www.facebook.com/careers/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Careers
            </a>
            <a
              href="https://investor.fb.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              For Investors
            </a>
            <a
              href="https://en.facebookbrand.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Brand Resources
            </a>
            <div className="title">Facebook Policies</div>
            <a
              href="https://www.facebook.com/communitystandards/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Community Standards
            </a>
            <a
              href="https://www.facebook.com/policy.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              Data Policy
            </a>
            <a
              href="https://www.facebook.com/policy/cookies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie Policy
            </a>
            <a
              href="https://www.facebook.com/terms.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </div>
          <div className="links">
            <div className="title">Technologies</div>
            <a
              href="https://about.fb.com/technologies/facebook-app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook app
            </a>
            <a
              href="https://about.fb.com/technologies/messenger/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Messenger
            </a>
            <a
              href="https://about.fb.com/technologies/instagram/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://about.fb.com/technologies/whatsapp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              WhatsApp
            </a>
            <a
              href="https://about.fb.com/technologies/oculus/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Oculus
            </a>
            <a
              href="https://about.fb.com/technologies/workplace/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Workplace
            </a>
            <a
              href="https://about.fb.com/technologies/portal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Portal
            </a>
            <a
              href="https://about.fb.com/technologies/novi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Novi
            </a>
          </div>
          <div className="links">
            <div className="title">Help Center</div>
            <a
              href="https://www.facebook.com/help"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook app Help Center
            </a>
            <a
              href="https://www.facebook.com/help/151024075021791"
              target="_blank"
              rel="noopener noreferrer"
            >
              Messenger Help Center
            </a>
            <a
              href="https://help.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram Help Center
            </a>
            <a
              href="https://faq.whatsapp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              WhatsApp Help Center
            </a>
            <a
              href="https://support.oculus.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Oculus Support
            </a>
            <a
              href="https://www.facebook.com/help/work"
              target="_blank"
              rel="noopener noreferrer"
            >
              Workplace Help Center
            </a>
            <a
              href="https://portal.facebook.com/help"
              target="_blank"
              rel="noopener noreferrer"
            >
              Portal Help Center
            </a>
          </div>
          <div className="copyright">&copy; 2020 Facebook</div>
        </div>
      </div>
    );
  }
}
