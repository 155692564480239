import React, { useContext } from 'react';
import classNames from 'classnames';
import { InteractionContext } from 'common/InteractionContext';
import { DataContext } from 'common/DataContext';
import { DispatcherContext } from 'common/DispatcherContext';
import { breadcrumbsAncestors } from 'common/breadcrumbs';
import './styles.scss';

export const BreadLoaf = ({ isEmbed }) => {
  const { getRegionName } = useContext(DataContext);
  const { selectedRegion } = useContext(InteractionContext);

  const { dispatchSelectRegion } = useContext(DispatcherContext);

  const regionIds = breadcrumbsAncestors(selectedRegion);

  return (
    <div
      className={`bread-loaf
        ${
          isEmbed ? 'embed' : selectedRegion === 'WORLD' ? 'international' : ''
        }`}
    >
      {regionIds.map((regionId, i) => {
        const handleClick = () => {
          dispatchSelectRegion(regionId);
        };

        return i === regionIds.length - 1 ? (
          <div
            key={regionId}
            className={classNames('bread-crumb', { current: true })}
          >
            <p>{getRegionName(regionId)}</p>
          </div>
        ) : (
          <React.Fragment key={regionId}>
            <div
              className={classNames('bread-crumb', { current: false })}
              onClick={handleClick}
            >
              <p>{getRegionName(regionId)}</p>
            </div>
            <pre
              className={classNames('bread-crumb arrow', {
                hide: false,
              })}
            >
              {' '}
              >{' '}
            </pre>
          </React.Fragment>
        );
      })}
    </div>
  );
};
