import { getNiceTickValues } from 'recharts-scale';
import _ from 'lodash';

import { addPostfix, removePostfix } from 'common/DataContext/util';

export const transparentFillCondition = (key, value) => ({
  'fill-color': [
    'case',
    ['==', ['get', key], value],
    'hsla(0, 0%, 0%, 0)',
    //non-selected country
    '#ededed',
  ],
  'fill-outline-color': 'hsla(0, 0%, 0%, 0)',
});

export const bucketFillCondition = ({
  key,
  parentKey,
  parentValue,
  buckets,
  colors,
}) => {
  const createBucketCase = (index) => [
    ['in', ['get', key], ['literal', buckets[index]]],
    colors[index],
  ];
  const bucketCases = buckets.map((b, i) => createBucketCase(i)).flat();
  const condition = ['case'].concat(bucketCases).concat([
    [
      'case',
      ['==', ['get', parentKey], parentValue],
      //no data color
      '#d4d4d4',
      'rgba(0,0,0,0)',
    ],
  ]);

  return {
    'fill-color': condition,
    'fill-outline-color': 'hsla(0, 0%, 0%, 0)',
  };
};

export const bivariateBucketFillCondition = ({
  key,
  parentKey,
  parentValue,
  buckets,
  demographicBuckets,
  biVariatePallette,
}) => {
  return {
    'fill-color': [
      'case',
      [
        'all',
        ['in', ['get', key], ['literal', buckets[0]]],
        ['in', ['get', key], ['literal', demographicBuckets[0]]],
      ],
      biVariatePallette[0][0],
      [
        'all',
        ['in', ['get', key], ['literal', buckets[1]]],
        ['in', ['get', key], ['literal', demographicBuckets[0]]],
      ],
      biVariatePallette[0][1],
      [
        'all',
        ['in', ['get', key], ['literal', buckets[2]]],
        ['in', ['get', key], ['literal', demographicBuckets[0]]],
      ],
      biVariatePallette[0][2],
      [
        'all',
        ['in', ['get', key], ['literal', buckets[0]]],
        ['in', ['get', key], ['literal', demographicBuckets[1]]],
      ],
      biVariatePallette[1][0],
      [
        'all',
        ['in', ['get', key], ['literal', buckets[1]]],
        ['in', ['get', key], ['literal', demographicBuckets[1]]],
      ],
      biVariatePallette[1][1],
      [
        'all',
        ['in', ['get', key], ['literal', buckets[2]]],
        ['in', ['get', key], ['literal', demographicBuckets[1]]],
      ],
      biVariatePallette[1][2],
      [
        'all',
        ['in', ['get', key], ['literal', buckets[0]]],
        ['in', ['get', key], ['literal', demographicBuckets[2]]],
      ],
      biVariatePallette[2][0],
      [
        'all',
        ['in', ['get', key], ['literal', buckets[1]]],
        ['in', ['get', key], ['literal', demographicBuckets[2]]],
      ],
      biVariatePallette[2][1],
      [
        'all',
        ['in', ['get', key], ['literal', buckets[2]]],
        ['in', ['get', key], ['literal', demographicBuckets[2]]],
      ],
      biVariatePallette[2][2],
      [
        'case',
        ['==', ['get', parentKey], parentValue],
        //no data color bivariate when region selected
        '#d4d4d4',
        //fall through color for non-selected no data
        '#ededed',
      ],
    ],
    'fill-outline-color': 'hsla(0, 0%, 0%, 0)',
  };
};

export const createEmptyBucketsFromBreakpoints = (breakpoints) => {
  // Create a bucket corresponding to each of our breakpoints, plus one.
  const buckets = [];
  for (let i = 0; i <= breakpoints.length; i++) {
    buckets[i] = [];
  }
  return buckets;
};

export const createBivariateBucketsForData = ({
  unfilteredData,
  data,
  getValue,
}) => {
  // This function ignores any constant breakpoints for a metric,
  // and instead just calculates three even buckets, like the scatterplot does.

  // This code stolen from Scatterplot/Scatterplot.js

  const flattenData = _.flatMap(unfilteredData, (datum) =>
    datum != null ? getValue(datum) : null
  );
  let domain = flattenData.filter(
    (entry) => (_.isNumber(entry) && !_.isNaN(entry)) || parseFloat(entry, 10)
  );
  domain = domain.length
    ? [_.min(domain), _.max(domain)]
    : [Infinity, -Infinity];
  let niceTicks = getNiceTickValues(
    domain, // min/max of the data
    4, // number of ticks (includes the start and ending of the grid)
    true // allow decimals
  );
  let breakpoints = niceTicks.slice(1, -1);

  const buckets = createEmptyBucketsFromBreakpoints(breakpoints);

  data.forEach((row) => {
    var matchingIndex = breakpoints.length;
    for (let i = 0; i < breakpoints.length; i++) {
      let value = getValue(row);
      if (value < breakpoints[i]) {
        matchingIndex = i;
        break;
      }
    }
    buckets[matchingIndex].push(addPostfix(row.regionId));
  });

  return buckets;
};

export const createBucketsForData = ({ data, breakpoints, getValue }) => {
  const buckets = createEmptyBucketsFromBreakpoints(breakpoints);

  // TODO only run this when data changes, not on every interaction
  data.forEach((row) => {
    var matchingIndex = breakpoints.length;
    for (let i = 0; i < breakpoints.length; i++) {
      let value = getValue(row);
      if (value < breakpoints[i]) {
        matchingIndex = i;
        break;
      }
    }
    buckets[matchingIndex].push(addPostfix(row.regionId));
  });

  return buckets;
};

export const getRegionIdFromFeature = (feature) => {
  // NOTE, this doesn't work for the old Albers projection
  return removePostfix(feature.properties.id);
};
