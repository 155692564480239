import React, { useCallback, useContext } from 'react';
import { timeWeek } from 'd3-time';
import { InteractionContext } from 'common/InteractionContext';
import { PageLeftSVG } from 'assets/svg/PageLeftSVG';
import { PageRightSVG } from 'assets/svg/PageRightSVG';

const rectSide = 20;

// This is so the buttons don't collide with the daily action dots.
const verticalOffset = 8;

const PageLeftButton = ({ onClick }) => (
  <g onClick={onClick} style={{ cursor: 'pointer' }}>
    <rect width={rectSide} height={rectSide} fill="#e5ecf0" />
    <g transform="translate(10, 10)">
      <PageLeftSVG />
    </g>
  </g>
);

const PageRightButton = ({ onClick }) => (
  <g onClick={onClick} style={{ cursor: 'pointer' }}>
    <rect width={rectSide} height={rectSide} fill="#e5ecf0" />
    <g transform="translate(10, 10)">
      <PageRightSVG />
    </g>
  </g>
);

const page = (step) => (date) => timeWeek.offset(date, step);

export const PaginationButtons = ({
  innerWidth,
  showPageLeft,
  showPageRight,
}) => {
  const { visibleDateInterval, setVisibleDateInterval } = useContext(
    InteractionContext
  );

  const handleLeftPaginate = useCallback(() => {
    setVisibleDateInterval(visibleDateInterval.map(page(-1)));
  }, [visibleDateInterval, setVisibleDateInterval]);

  const handleRightPaginate = useCallback(() => {
    setVisibleDateInterval(visibleDateInterval.map(page(1)));
  }, [visibleDateInterval, setVisibleDateInterval]);

  return (
    <g transform={`translate(0, ${verticalOffset})`}>
      {showPageLeft ? <PageLeftButton onClick={handleLeftPaginate} /> : null}
      {showPageRight ? (
        <g transform={`translate(${innerWidth - rectSide}, 0)`}>
          <PageRightButton onClick={handleRightPaginate} />
        </g>
      ) : null}
      <rect stroke="#e5ecf0" fill="none" width={innerWidth} height={rectSide} />
    </g>
  );
  //  return <PageLeftSVG/>
  //  return <PageRightSVG/>
};
