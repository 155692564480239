import React from 'react';
import './styles.scss';

export default function mapExplainer(handleClick) {
  // prettier-ignore
  return (
    <div className="map-explainer">
      <div className="explainer-header">
        <h3>About This Map</h3>
        <div className="close" onClick={handleClick}><img alt="" src="svg/icon-close.svg" /></div>
      </div>
      <ul className="explainer-body">
        <li>
          This map uses aggregated public data to protect people’s privacy, and
          is not intended for diagnostic purposes or travel guidance.
        </li>
        <li>
          To learn more about the data, please see our<span> </span>
          <a href="./survey_and_map_data.html">Survey & Data</a> page.
        </li>
        <li>
          Boundaries for disputed administrative areas are from<span> </span>
          <a
            href="https://www.mapbox.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mapbox
          </a>
          . All other administrative areas represented in this map are from the<span> </span>
          <a
            href="https://www.census.gov/geographies/mapping-files/time-series/geo/cartographic-boundary.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            US Census Bureau
          </a>
          <span> and the </span>
          <a href="https://gadm.org/" target="_blank" rel="noopener noreferrer">
            Database of Global Administrative Areas
          </a>
          .
        </li>
      </ul>
    </div>
  );
}
