import locale from 'common/locale';

const COUNTY_LAYER_ID = 'county';
const HRR_LAYER_ID = 'hrr';
const Const = {
  DISCLAIMER_HTML: locale('i18n_disclaimer'),
  ACCESS_TOKEN:
    'pk.eyJ1IjoiZmJtYXBzIiwiYSI6ImNrOHE2cWUxYzAwczAzaW4wbWh4MGpyZWoifQ.WLqKOSxbcFMZIkwTB2gK_g',
  DEFAULT_MAPBOX_OPTIONS: {
    width: '100%',
    height: '100%',
    scrollZoom: false,
    dragRotate: false,
    minZoom: 2,
    maxZoom: 8,
    hash: true,
    mapStyle: 'mapbox://styles/fbmaps/ck8tehne90bcf1inx7ti24jij',
  },

  LAYERS: {
    COUNTY_LAYER_ID,
    HRR_LAYER_ID,
  },
  LAYER_CONTROL_BUTTONS: [
    { id: COUNTY_LAYER_ID, label: 'Counties', className: 'county-info' },
    {
      id: HRR_LAYER_ID,
      label: 'Hospital Referral Regions',
      className: 'hrr-info',
    },
  ],
  FIRST_METRICS: [
    {
      index: 0,
      id: 'Percent CLI',
      label: 'COVID-19 Symptoms',
      labelForMapTooltip: '% of People With Symptoms',
      labelForLegend: 'Percentage of People With Symptoms',
      display: (data) =>
        data != null ? data.toFixed(2).toString().concat('%') : '--',
      dataThresholds: {
        USStates: [0.6, 0.76, 0.9, 1.04, 1.07, 1.3],
        county: [0.6, 0.76, 0.9, 1.04, 1.07, 1.3],
      },
      colorScale: [
        '#FFE2E5',
        '#FFBCC2',
        '#F3727E',
        '#DC3746',
        '#B71726',
        '#92000E',
        '#72000A',
      ],
      biVariateDataThresholds: {
        USStates: [1.0, 2.5],
        county: [1.0, 2.5],
      },
      unit: '%',
      lessIndicator: 'Fewer Symptoms',
      moreIndicator: 'More Symptoms',
      tooltipText:
        'COVID-19 symptoms include a fever and either a cough, shortness of breath or difficulty breathing as defined in the CMU survey.',
      dataSource: 'Carnegie Mellon University',
      dataStartDate: 'April 06, 2020',
    },
    {
      index: 1,
      id: 'Percent ILI',
      label: 'Flu Symptoms',
      labelForMapTooltip: '% of People With Symptoms',
      labelForLegend: 'Percentage of People With Symptoms',
      display: (data) =>
        data != null ? data.toFixed(2).toString().concat('%') : '--',
      dataThresholds: {
        USStates: [0.6, 0.76, 0.9, 1.04, 1.07, 1.3],
        county: [0.6, 0.76, 0.9, 1.04, 1.07, 1.3],
      },
      colorScale: [
        '#FFE2E5',
        '#FFBCC2',
        '#F3727E',
        '#DC3746',
        '#B71726',
        '#92000E',
        '#72000A',
      ],
      biVariateDataThresholds: {
        USStates: [1.0, 2.5],
        county: [1.0, 2.5],
      },
      unit: '%',
      lessIndicator: 'Fewer Symptoms',
      moreIndicator: 'More Symptoms',
      tooltipText:
        'Flu symptoms include a fever and either a sore throat or cough as defined in the CMU survey.',
      dataSource: 'Carnegie Mellon University',
      dataStartDate: 'April 06, 2020',
    },
    {
      index: 2,
      id: 'cases',
      label: 'Total Confirmed COVID-19 Cases',
      labelForLegend: 'Total Confirmed COVID-19 Cases',
      display: (data) => (data != null ? data.toLocaleString('en') : '--'),
      tickFormatter: (value) =>
        value >= 1000 ? (value / 1000).toString() + 'K' : value,
      dataThresholds: {
        USStates: [10000, 30000, 50000, 55000, 100000, 200000],
        county: [50, 300, 700, 1500, 6000, 13000],
      },
      colorScale: [
        '#EDE7FF',
        '#D2CDF0',
        '#ADA5E0',
        '#9186CF',
        '#6A5EB1',
        '#4E4391',
        '#312770',
      ],
      biVariateDataThresholds: {
        USStates: [30000, 100000],
        county: [300, 6000],
      },
      unit: '',
      lessIndicator: 'Fewer Cases',
      moreIndicator: 'More Cases',
      tooltipText: 'The total number of confirmed cases of COVID-19.',
      dataSource: 'The New York Times',
      dataStartDate: 'March 01, 2020',
    },
    {
      index: 3,
      id: 'new_cases',
      label: 'New Confirmed COVID-19 Cases',
      labelForLegend: 'New Confirmed COVID-19 Cases',
      display: (data) => (data != null ? data.toLocaleString('en') : '--'),
      tickFormatter: (value) =>
        value >= 1000 ? (value / 1000).toString() + 'K' : value,
      dataThresholds: {
        USStates: [500, 1000, 1800, 2100, 2500, 3500],
        county: [2, 10, 25, 50, 200, 350],
      },
      colorScale: [
        '#EDE7FF',
        '#D2CDF0',
        '#ADA5E0',
        '#9186CF',
        '#6A5EB1',
        '#4E4391',
        '#312770',
      ],
      biVariateDataThresholds: {
        USStates: [1000, 2500],
        county: [10, 200],
      },
      unit: '',
      lessIndicator: 'Fewer Cases',
      moreIndicator: 'More Cases',
      tooltipText: 'The number of new confirmed cases of COVID-19.',
      dataSource: 'The New York Times',
      dataStartDate: 'March 01, 2020',
    },
    {
      index: 4,
      id: 'stayput',
      label: 'Percentage of People Staying in Place',
      labelForMapTooltip: '% of People Staying in Place',
      labelForLegend: 'Percentage of People Staying in Place',
      display: (data) =>
        data != null ? data.toFixed(1).toString().concat('%') : '--',
      dataThresholds: {
        USStates: [27, 30, 32, 33, 34, 35],
        county: [27, 30, 32, 33, 34, 35],
      },
      colorScale: [
        '#D8F5F3',
        '#A0E1DC',
        '#68C9C1',
        '#26A49A',
        '#077B72',
        '#02514B',
        '#023733',
      ],
      biVariateDataThresholds: {
        USStates: [30, 34],
        county: [30, 34],
      },
      unit: '%',
      lessIndicator: 'Fewer Staying in Place',
      moreIndicator: 'More Staying in Place',
      tooltipText:
        'The percentage of people who remained at their home over 3 consecutive 8-hour time periods during a single 24-hour day.',
      dataSource: 'Facebook Mobility Dashboard',
      dataStartDate: 'March 01, 2020',
    },
    {
      index: 5,
      id: 'relativeMobility',
      label: 'Change in Movement',
      labelForMapTooltip: 'Change in Movement',
      labelForLegend: 'Change in Movement',
      display: (data) =>
        data != null ? data.toFixed(1).toString().concat('%') : '--',
      dataThresholds: {
        USStates: [-100, -75, -50, -25, 0, 25, 50],
        county: [-100, -75, -50, -25, 0, 25, 50],
      },
      colorScale: [
        '#D8F5F3',
        '#A0E1DC',
        '#68C9C1',
        '#26A49A',
        '#077B72',
        '#02514B',
        '#023733',
      ],
      biVariateDataThresholds: {
        USStates: [-50, 50],
        county: [-50, 50],
      },
      unit: '%',
      lessIndicator: 'Less Movement',
      moreIndicator: 'More Movement',
      tooltipText:
        "The percentage of change in people's movement compared to the average for the same day of the week in February 2020.",
      dataSource: 'Facebook Mobility Dashboard',
      dataStartDate: 'March 01, 2020',
    },
  ],
  SECONDARY_METRICS: [
    {
      index: 0,
      id: 'Total population',
      label: 'Total Population',
      dataSource: 'US Census',
      biVariateDataThresholds: {
        USStates: [1000000, 10000000],
        county: [100000, 100000],
      },
    },
    {
      index: 1,
      id: '65+ percent',
      label: 'Percentage of People Age 65+',
      dataSource: 'US Census',
      biVariateDataThresholds: {
        USStates: [0.15, 0.2],
        county: [0.15, 0.2],
      },
    },
    {
      index: 2,
      id: 'Density (per sq km)',
      label: 'Population Density Per Sq Km',
      dataSource: 'US Census',
      biVariateDataThresholds: {
        USStates: [38.61, 386.1],
        county: [38.61, 386.1],
      },
    },
  ],
  biVariatePallette: [
    ['#D6F3FF', '#F6E6FB', '#C8A2D3'],
    ['#A0E1DC', '#A5D2EB', '#7E518B'],
    ['#68C9C1', '#26A49A', '#004872'],
  ],

  // breakpoints in pixels
  // If you change these, change them in common.scss also.
  MOBILE_BREAK: 578,
  MID_BREAK: 960,
  DESKTOP_BREAK: 1200,
};

export default Const;
