import styled, { css } from 'styled-components';
import { mobileBreakpoint } from 'styles/styles';

export const IntGovInterventions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;

  background: #ffffff;
  border-radius: 6px;

  img {
    align-self: flex-start;
    padding-bottom: 8px;
  }

  p {
    font-family: Facebook Reader;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: unset !important;

    a {
      font-weight: bold;
      color: #344854;
    }
  }

  ${(props) =>
    props.isEmbed
      ? css`
          width: 100%;
        `
      : css`
          position: absolute;
          width: 528px;
        `}

  @media (max-width: ${mobileBreakpoint}px) {
    position: static;
    width: unset;
    height: unset;
    box-shadow: none;
    border-radius: 2px;
    border-radius: 2px 2px 6px 6px;
  }
`;
