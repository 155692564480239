import React from 'react';

import {
  EmbedContentLink,
  EmbedParagraph,
  EmbedIntroHeader,
} from '../../styles';

const EmbedIntro = () => {
  return (
    // prettier-ignore

    <div>
      <EmbedIntroHeader>Intégrez la carte personnalisée à votre site</EmbedIntroHeader>
      <EmbedParagraph>
        En intégrant cette carte, vous acceptez les<span> </span>
        <EmbedContentLink href="https://www.facebook.com/legal/terms" style={{ textDecoration: 'none' }}>
          Conditions de service
        </EmbedContentLink><span> </span>
        et la<span> </span>
        <EmbedContentLink href="https://developers.facebook.com/policy/" style={{ textDecoration: 'none' }}>
          Politique applicable aux développeurs
        </EmbedContentLink>
        de Facebook.
      </EmbedParagraph>
    </div>
  );
};

export default EmbedIntro;
