import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 4px;
  color: #4a585e;
  ${({ margin, isMobile }) =>
    isMobile
      ? css``
      : css`
          padding-left: ${margin.left}px;
          padding-right: ${margin.right}px;
        `}

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Line = styled.div`
  width: 10px;
  height: 2px;
  background-color: ${(props) => props.color};
  margin-right: 30px;
`;

export const LegendLabel = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;

  white-space: nowrap;
`;

export const GovtLegendItem = styled.span`
  flex: 1 0 auto;

  display: flex;
  align-items: center;
  ${({ isMobile }) =>
    isMobile
      ? css`
          justify-content: center;
        `
      : css`
          justify-content: flex-end;
        `}
`;

const LegendContainer = css`
  flex: 1 0 auto;
`;

const MobileLegendContainer = css`
  flex: 1 0 auto;

  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const LineLegendContainer = styled.div`
  ${(props) =>
    props.isMobile
      ? css`
          ${MobileLegendContainer}
        `
      : css`
          ${LegendContainer}
          display: flex;
          align-items: center;
        `}
`;

export const GovtActionsLegendContainer = styled.div`
  ${(props) =>
    props.isMobile
      ? css`
          ${MobileLegendContainer}

          padding-top: 18px;
          padding-bottom: 18px;
          padding-left: ${(props) => props.margin.left}px;
          padding-right: ${(props) => props.margin.right}px;
        `
      : css`
          ${LegendContainer}

          display: flex;
          justify-content: flex-end;
        `}
`;
